import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import "./ag-grid.css";
// import 'ag-grid-enterprise';
import { AgGridReact  } from 'ag-grid-react'; 
import MUIDataTable from "mui-datatables";
import { getMuiTheme, headerProps } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import RawdataaddingwebsiteModal from '../pageModals/RawdataaddingwebsiteModal';
import moment from 'moment-timezone';
import RawdataAddNewLeadModal from '../pageModals/RawdataAddNewLeadModal';
import RawdataUpdateModal from '../pageModals/RawdataUpdateModal';
import RawdataExistingcustomerModal from '../pageModals/RawdataExistingcustomerModal';
import AssignRawdatauserModal from '../pageModals/AssignRawdatauserModal';
import { Button } from 'react-bootstrap';
import { connect, useSelector,useDispatch } from 'react-redux';
import {RawdataActions, RawDatabaseAddActivityModal, updateColumnsList, getColumnsList, fetchRawdatabaseList} from "../../store/Rawdatabase"
import CreateRawDatabaseDCRModal from "../pageModals/CreateRawDatabaseDCRModal";
import InactiveRawDataModal from '../pageModals/InactiveRawDataModal';
import axios from "axios";
import {meetingColumnUpdateddata} from "../../store/Common";

export const OriginalColumnsList = [
    {
      field:'_id',
      hide: true
    },
    // {
    //   field:'_id',
    //   headerName: 'Select',
    //   checkboxSelection: true,
      
    // },
    {
      field:'assigned_to_name',
      headerName: 'Assigned To',
      // checkboxSelection: true,
      
    },
    {
      field:'company_name',
      headerName: 'Company Name',
       sort: 'asc',
    },
    {
      field:'account_type',
      headerName: 'Account Type',
    },
    {
      field:'industry',
      headerName: 'Industry',
    },
    {
      field:'status',
      headerName: 'Status',
    },
    {
      field:'website',
      headerName: 'Website',
    },
    {
      field:'address',
      headerName: 'Address',
    },
    {
      field:'city',
      headerName: 'City',
    },
    {
      field:'state',
      headerName: 'State',
    },
    {
      field:'postal_code',
      headerName: 'Postal Code',
    },
    {
      field:'country',
      headerName: 'Country',
    },
    {
      field:'contact_person_name',
      headerName: 'Contact Person Name',
    },
    {
      field:'mobile',
      headerName: 'Mobile',
    },
    {
      field:'phone',
      headerName: 'Phone',
    },
    {
      field:'designation',
      headerName: 'Designation',
    },
    {
      field:'department',
      headerName: 'Department',
    },
    {
      field:'job_level',
      headerName: 'Job Level',
    },
    {
      field:'meddic_role',
      headerName: 'Meddic Role',
    },
    {
      field:'email',
      headerName: 'Email',
    },
    {
      field:'no_of_employees',
      headerName: 'No Of Employees',
    },
    {
      field:'is_existing_customer',
      headerName: 'Existing Customer',
    },
    {
      field:'source',
      headerName: 'Source',
    },
    {
      field:'status',
      headerName: 'Status',
    },     
   {
      field:'user_name',
      headerName: 'User',
    },
    {
      field:'remark',
      headerName: 'Remark',
    },
    {
      field:'reject_reason',
      headerName: 'Reject Reason',
    },
    {
      field:'created_at',
      headerName: 'Created At',
      cellRenderer: params => {
        return <span>{moment(params.value?.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY LT')}</span>
      }
    },
    {
      field:'assigned_date',
      headerName: 'Assigned Date',
    },
    
  ];

const  RawDataTablePagination = React.memo(({showAddRawDCR,updateShowAddRawDCR, rawdatalist,isFetching,RawDataExistingCustomerModal,RawDatadetailsModal,UpdateRawdataModal,AddingWebsiteModal,AssignRawdataModels,Updaterawdata_openmodal,setRowMetaData,setRowId,setAddwebsiteRowid,showAddwebistemodal,showAddnewlead,RawDatacustomeropen_modal,leadid_data,invalidOpenModal,showInactiveRawDataModal, columnsList, updateColumnsListData, getColumnsListData, fetchRawdatabaseData, rawDatabaseNew, tableRowCount, meetingColumnUpdated, meetingcolumnupdated, columnsObjects}) => {
    
    const rawData = rawdatalist
    const dispatch = useDispatch();
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const permissions = user.permissions;
  // const gridApi = useGridApi();
  // const [columnsList, setColumnsList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [rawDataTypeActive, setRawDataTypeActive] = useState("active");
  // const [rowId, setRowId] = useState("");
  const [agGridApi, setAgGridApi] = useState(null);
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  // const [rowMetaData, setRowMetaData] = useState("");
  // const [showRawDataExistingCustomerModal, setShowRawDataExistingCustomerModal] = useState(false);
  // const [showRawDatadetailsModal, setShowRawDatadetailsModal] = useState(false);
  // const [showUpdateRawdataModal, setShowUpdateRawdataModal] = useState(false);
  // const [showAddingWebsiteModal,setShowAddingWebsiteModal] = useState(false);
  // const[showAssignRawdataModel,setShowAssignRawdataModel] = useState(false);
  // const dataSourceRef = useRef(null);


  const handleRowClick = (rowData, rowMeta) => {
    // setShowUpdateRawdataModal(true);   
    // dispatch(RawdataActions.showUpdateRawdataModal_openmodal())
    // setRowMetaData(rowData);
    // setRowId(rawdatalist?.list[rowMeta.dataIndex]._id) 
    Updaterawdata_openmodal()
    // updaterawdatasetRowMetadata(rowData)
    // updaterawdatasetRowId(rawdatalist?.list[rowMeta.dataIndex]._id)
    setRowMetaData(rowData)
    setRowId(tableData[rowMeta.dataIndex]._id)
    
  };

  const addDCR = async (event, data, meta) => {
    event.stopPropagation();
    let newRowData = tableData[meta]

    updateShowAddRawDCR({_id: newRowData._id, company_name: newRowData.company_name})
  }

  const assignHandle = async (event, data, meta) => {
    event.stopPropagation();
    let newRowData = tableData[meta]
    if(newRowData.website===null){
      setRowId(newRowData._id)
      // setRowId(newRowData._id)
      // setAddwebsiteRowid(newRowData._id)
      showAddwebistemodal()
      // dispatch(RawdataActions.showAddingWebsiteModal_openmodal())
      // setShowAddingWebsiteModal(true)
    }else if(newRowData.is_existing_customer === "false"){
      setRowId(newRowData._id)
      showAddnewlead()
      // setRowId(newRowData._id)
      // dispatch(RawdataActions.showRawDatadetailsModal_openmodal())
      // setShowRawDatadetailsModal(true)
     
    
    }else if(newRowData.is_existing_customer === "true"){
      setRowId(newRowData._id)
      // dispatch(RawdataActions.showRawDataExistingCustomerModal_openmodal()
      // RawDataExistingCustomerModalSetRowId(newRowData._id)
      RawDatacustomeropen_modal()
        //  setShowRawDataExistingCustomerModal(true)
    }else{
      return;
    }    
   
  }
  const inValidHandle = (event, data, meta) => {
    event.stopPropagation();
    let newRowData = tableData[meta]._id
    setRowId(newRowData)
    //  console.log("raw data id is",newRowData)
     invalidOpenModal();
  }

  const dummyhandle = async(event,data,meta) => {
    event.stopPropagation();
    dispatch(RawdataActions.showRawDataExistingCustomerModal_openmodal())
    // setShowRawDataExistingCustomerModal(true)

  }



      const LoaderText = () => {
        return   isFetching ? <div className="parent-div">
        <div className="loader"></div>
      </div>:"No Data Found.." 
     }




  const options = {
    textLabels: {
      body: {
        noMatch: LoaderText()
      }
    },
    
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    rowsPerPageOptions : [20, 50, 100, 500, 1000, 1500, 2000, 2500],
    filter: true,
    print: false,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: (window.innerHeight - 130).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 130).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows:permissions.includes("canAssignRawdata")? true : "none",
    customToolbarSelect: (selectedRows, displayData) => {
      var lead_ids_data = selectedRows.data.map(item1 => {
        return displayData[item1.index]?.data[0];
      })
      leadid_data(lead_ids_data)
      return (
        <div className="assing_to_isales">
          {permissions.includes("canAssignRawdata") ?
          <>
            <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => dispatch(RawdataActions.AssignRawdataModel_openmodal())} >Assign To</Button>
            {AssignRawdataModels ? <AssignRawdatauserModal/>: ""}
          </>
          :false}
      </div>
      )
    },

    setRowProps: (row, dataIndex, rowIndex) => {
        if (row[22]) {
          if (row[22]['postponed_date']) {
            var difference = moment(moment(row[22]['postponed_date']), "DD/MM/YYYY HH:mm:ss").diff(moment(), "DD/MM/YYYY HH:mm:ss");
          } else {
            var difference = moment(moment(row[22]['action_date']), "DD/MM/YYYY HH:mm:ss").diff(moment(), "DD/MM/YYYY HH:mm:ss");
          }
          return {
          };
        } else {
          return {
          }
        }
      },
    onViewColumnsChange: async (changedColumn, action) => {
      var columns = Object.assign([], columnsList)
      if (action === "add") {
        columns.push(changedColumn);
      }

      if (action === "remove") {
        var columns = columns.filter(item => item !== changedColumn);
      }
      const updatedData = {
        user_id: userData._id,
        table_name: "rawdata",
        columns_list: columns,
      }
      updateColumnsListData(updatedData)
    },
    download: false,
    onRowClick: handleRowClick,


    onDownload: (buildHead, buildBody, columns, data) => {
      let rowDatas = data.map(item => {

        const temp = [

          item.data[0] ? item.data[0].first_name : "NA",
          item.data[1] ? item.data[1] : "NA",
          item.data[2] ? item.data[2].name : "NA",
          item.data[3] ? item.data[3].replace("T", " ").replace("Z", " ") : "NA",
          item.data[4] ? item.data[4].name : "NA",
          item.data[5] ? item.data[5] : "NA",
          item.data[6] ? item.data[6] : "NA",
          item.data[7] ? item.data[7] : "NA",
          item.data[8] ? item.data[8] : "NA",
          item.data[9] ? item.data[9] : "NA",
          item.data[10] ? item.data[10] : "NA",
          item.data[11] ? item.data[11] : "NA",
          item.data[12] ? item.data[12].replace("T", " ").replace("Z", " ") : "NA",
          item.data[13] ? item.data[13].name : "NA",
          item.data[14] ? item.data[14].name : "NA",
          item.data[15] ? item.data[15] : "NA",
          item.data[16] ? item.data[16] : "NA",
          item.data[17] ? item.data[17].replace("T", " ").replace("Z", " ") : "NA",
          item.data[18] ? item.data[18].replace("T", " ").replace("Z", " ") : "NA",
        ]
        return { data: temp }
      })

      let val = `${buildHead(columns)}${buildBody(rowDatas)}`
      return val

    }
  };
  const filterRawDataType = async (type) => {
  setRawDataTypeActive(type)
    var data = [];
      data = rawData.filter(function (item) {
        if (item.status === type) {
          return item;
        }
      });
    // }

    setTableData(data);
  }
  useEffect(() => {
    getColumnsListData()
  }, [])



  useEffect(() => {
    // setTableData(hrisdata);
    filterRawDataType("active")
  }, [rawData]);

  const getRowId = useCallback(function (params) {
    return params.data.id;
  }, []);

  function sortAndFilter(allOfTheData, sortModel, filterModel) {
    return sortData(sortModel, filterData(filterModel, allOfTheData));
  }
  
  function sortData(sortModel, data) {
    const sortPresent = sortModel && sortModel.length > 0;
    if (!sortPresent) {
      return data;
    }
    // do an in memory sort of the data, across all the fields
    const resultOfSort = data.slice();
    resultOfSort.sort(function (a, b) {
      for (let k = 0; k < sortModel.length; k++) {
        
        const sortColModel = sortModel[k];
        const valueA = a[sortColModel.colId];
        const valueB = b[sortColModel.colId];
        // this filter didn't find a difference, move onto the next one
        if (valueA == valueB) {
          continue;
        }
        const sortDirection = sortColModel.sort === 'asc' ? 1 : -1;
        if (valueA > valueB) {
          return sortDirection;
        } else {
          return sortDirection * -1;
        }
      }
      // no filters found a difference
      return 0;
    });
    return resultOfSort;
  }

  function filterData(filterModel, data) {
    const filterPresent = filterModel && Object.keys(filterModel).length > 0;
    if (!filterPresent) {
      return data;
    }
  
    const resultOfFilter = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
  
      if (filterModel.age) {
        const age = item.age;
        const allowedAge = parseInt(filterModel.age.filter);
        // EQUALS = 1;
        // LESS_THAN = 2;
        // GREATER_THAN = 3;
        if (filterModel.age.type == 'equals') {
          if (age !== allowedAge) {
            continue;
          }
        } else if (filterModel.age.type == 'lessThan') {
          if (age >= allowedAge) {
            continue;
          }
        } else {
          if (age <= allowedAge) {
            continue;
          }
        }
      }
  
      if (filterModel.year) {
        if (filterModel.year.values.indexOf(item.year.toString()) < 0) {
          // year didn't match, so skip this record
          continue;
        }
      }
  
      if (filterModel.country) {
        if (filterModel.country.values.indexOf(item.country) < 0) {
          continue;
        }
      }
  
      resultOfFilter.push(item);
    }
  
    return resultOfFilter;
  }

  // useEffect(() => {
  //   console.log("rawDatabaseNew", rawDatabaseNew)
  // const dataSource = {
  //   getRows: (params) => {
  //     // Fetch data based on the scroll position
  //     const startRow = params.startRow;
  //     const endRow = params.endRow;
  //     console.log("start Row", startRow)
  //     console.log("endRow", endRow)
  //     // fetchDataInRange(startRow, endRow, params.successCallback, params.failCallback);
  //   },
  // };
  // }, [rawDatabaseNew])


  // useEffect(() => {
    // console.log("rawDatabaseNew", rawDatabaseNew);
    // if (rawDatabaseNew && rawDatabaseNew.length > 0) {
    //   dataSourceRef.current = {
    //     getRows: (params) => {
    //       // Fetch data based on the scroll position
    //       const startRow = params.startRow;
    //       const endRow = params.endRow;
    //       console.log("start Row", startRow);
    //       console.log("endRow", endRow);
    //       // fetchDataInRange(startRow, endRow, params.successCallback, params.failCallback);
    //     },
    //   };
    // }
  // }, [rawDatabaseNew]);



  const onGridReady = useCallback((params) => {
    setAgGridApi(params.api);

    const isSortingApplied = params.sortModel && params.sortModel.length > 0;
    let sortedColumn = null;
    let sortDirection = null;
    
    // if (isSortingApplied) {
    //   console.log("issortingappliced")
    //   // Get the first sorted column
    //   const firstSortedColumn = params.sortModel[0];
    //   sortedColumn = firstSortedColumn.colId; // Column ID
    //   sortDirection = firstSortedColumn.sort; // Sort direction: 'asc' or 'desc'
    //   console.log("sortedColumn", sortedColumn,sortDirection)
    //   fetchRawdatabaseData({        
    //     sortedColumn: sortedColumn,
    //     sortDirection: sortDirection,
    //     pageSize: params.api.paginationGetPageSize(), 
    //     pageNumber: params.api.paginationGetCurrentPage()+1
    //   })
    // }else{
    //   fetchRawdatabaseData({        
    //     pageSize: params.api.paginationGetPageSize(), 
    //     pageNumber: params.api.paginationGetCurrentPage()+1
    //   })
    // }

    

  }, []);

  const defaultColDef = useMemo(() => {
    return {
      // editable: true,
      filter: true,
      // enablePivot: true,
      // enableValue: true,
      floatingFilter: true,
      // enablePivot: true,
    };
  }, []);

// const handlePaginationChanged = () => {}

// const handlePaginationChanged = () => {

//   console.log("inside handle pagination changed")

//   if(agGridApi){
//     const pageSize = agGridApi.paginationGetPageSize();
//     const pageNumber = agGridApi.paginationGetCurrentPage();
// //     console.log("page size ", pageSize, pageNumber)
//     setTimeout(() => {
//       fetchRawdatabaseData({ pageSize: pageSize, pageNumber: pageNumber });
//     }, 0);
//   }
// };
  // const dataSource = {
  //   getRows: (params) => {
  //     // Fetch data based on the scroll position
  //     const startRow = params.startRow;
  //     const endRow = params.endRow;
  //     console.log("start Row", startRow)
  //     console.log("endRow", endRow)
  //     // fetchDataInRange(startRow, endRow, params.successCallback, params.failCallback);
  //   },
  // };

  // gridOptions.api?.setDatasource(dataSource);

  // console.log("pagination ", agGridApi)
  // console.log('Page ' + agGridApi + ' of ' + agGridApi);

  // if (agGridApi) {



    // console.log("page size ", pageSize, pageNumber)

   
    // fetchData(params.api.paginationGetPageSize(), params.api.paginationGetCurrentPage())
    // fetchMeetingsData({pageSize: agGridApi.paginationGetPageSize(), pageNumber: agGridApi.paginationGetCurrentPage()+1})
  
  // }
// };

const handlePaginationChanged = (params) => {

  // const { currentPage, pageSize, agGridApi } = params;
  if(!agGridApi){
    return;
  }

  var pageSize = agGridApi.paginationGetPageSize()
  var pageNumber = agGridApi.paginationGetPageSize()

  // Calculate startRow and endRow based on current page and page size
  const startRow = pageNumber * pageSize;
  const endRow = startRow + pageSize - 1;

  
  

  // Make API call to fetch data for the current page
  // axios.post("/user/rawdata/list-new", { startRow, endRow })
  //   .then(response => {
  //     const { list, rowCount } = response.data;

  //     // Set the fetched data to the grid
  //     if (agGridApi) {
  //       agGridApi.applyTransaction({ add: list }); // Add new data to the grid
  //     }
  //   })
  //   .catch(error => {
  //     console.error("Error fetching data:", error);
  //     // Handle error
  //   });
};

const fetchData = (startRow, endRow) => {
  fetchRawdatabaseData({startRow: startRow, endRow: endRow})
}



const onPageSizeChanged = (newPageSize) => {
  console.log("pag size changed")
  // agGridApi.paginationSetPageSize(newPageSize);
  // fetchPageData(1, newPageSize);
};

const onColumnMoved = useCallback((e) => {
  updateColumnsState(e)
}, []);

const renderVal =(param)=> {
  return param.first_name
}

const updateColumnsState = (e) => {

  var newColumnList = []
  var newColumnOrder = Object.assign({}, e.columnApi.getColumnState())
  for (let i = 0; i < Object.keys(newColumnOrder)?.length; i++) {
    var col = newColumnOrder[i]
    var existing = OriginalColumnsList?.filter(item => item.field === col.colId)
    
    newColumnList.push({
      field: col.colId,
      colId: col.colId,
      headerName: existing[0]?.headerName,
      sort: existing[0]?.sort ? existing[0]?.sort : col.sort,
      pinned: col.pinned,
      width: col.width,
      hide: col.hide,
      sortIndex: col.sortIndex,
    })
  }

  var input = {
    user_id: user?.user?._id,
    table_name: "rawdata",
    column_objects: newColumnList, 
    table_version:'2'
  }
  meetingColumnUpdated(input)
}

const onColumnPinned = useCallback((e) => {

  updateColumnsState(e)
  // console.log("OriginalColumnsList lk ", OriginalColumnsList)
  // var newColumns = Object.assign({}, OriginalColumnsList)
  // let targetIndex = -1;

  // const newColumnOrder = e.columnApi.getColumnState().map((col) => col.colId);
  // console.log("e.columnApi.getColumnState() ", newColumnOrder?.length, e.columnApi.getColumnState())


  // console.log("newColumnList newColumnList ", newColumnList)
 
  // console.log("newColumnOrder ", newColumnOrder)
  // for (let i = 0; i < newColumnOrder?.length; i++) {
  //   // console.log("newColumns ", newColumnOrder[i])
  //   // console.log("column ", newColumnOrder[i])
  //   var newCol = newColumnOrder[i]
  //   var existingIndex = OriginalColumnsList.findIndex(item => item.field === newCol.colId)
  //   console.log("existingIndex ", existingIndex, newCol.colId)
  //   if (OriginalColumnsList[i].field === newCol.colId) {
  //     newColumns[targetIndex] = {
  //       ...newColumnOrder[targetIndex], // Preserve existing properties
  //       'pinned': newCol.pinned, // Add the new key-value pair
  //     };
  //   }
  // }
  // console.log("newColumnOrder ", newColumnOrder)

  // Iterate over the array to find the index of the target object
  // for (let i = 0; i < Object.keys(newColumns).length; i++) {
  //   if (newColumns[i].field === e.column.colId) { // Replace 'key' with the property you want to match
  //     targetIndex = i;
  //     break; // Exit loop once the target object is found
  //   }
  // }

  // Check if the target object was found
  // if (targetIndex !== -1) {
  //   newColumns[targetIndex] = {
  //     ...newColumns[targetIndex], // Preserve existing properties
  //     'pinned': e.pinned, // Add the new key-value pair
  //   };
  // }


}, []);

const onSelectionChanged = (e) => {
  const selectedNodes = agGridApi.getSelectedNodes();
  const selectedData = selectedNodes.map(node => node.data);
}

// const onSortChanged = useCallback(() => {
//   // Get the sort model from the grid API
//   const sortModel = gridApi.getSortModel();

//   // If sort model is not empty, there is at least one column being sorted
//   if (sortModel.length > 0) {
//     // Get the first sorted column and its sort direction
//     const sortedColumn = sortModel[0].colId;
//     const sortDirection = sortModel[0].sort;

//     // Perform sorting logic based on sorted column and direction
//     console.log(`Column ${sortedColumn} sorted in ${sortDirection} order`);
//     // Example: Call a function to apply sorting to your data
//     applySorting(sortedColumn, sortDirection);
//   } else {
//     // No columns are being sorted, handle as needed
//     console.log('No columns are being sorted');
//     // Example: Reset sorting or apply default sorting
//     resetSorting();
//   }
// }, [gridApi]);

// return;
//     const dataAfterSortingAndFiltering = sortAndFilter(
//       data,
//       params.sortModel,
//       params.filterModel
//     );
//     const rowsThisPage = dataAfterSortingAndFiltering.slice(
//       params.startRow,
//       params.endRow
//     );
//     // if on or after the last page, work out the last row.
//     let lastRow = -1;
//     if (dataAfterSortingAndFiltering.length <= params.endRow) {
//       lastRow = dataAfterSortingAndFiltering.length;
//     }
//     // call the success callback
//     params.successCallback(rowsThisPage, lastRow);
// }

// const onSortChanged = useCallback((event) => {

//   // const sortModel = event.api.getSortModel();
//   console.log("api.getModel().rowsToDisplay ", event.api.getModel().rowsToDisplay)
//   // console.log("sort model ", sortModel)
//   datasource.getRows({
//     // Pass necessary parameters to the getRows function
//     startRow: 0, // Example: Adjust as needed
//     endRow: 10, // Example: Adjust as needed
//     sortModel: [], // Example: Provide sort model if required
//     filterModel: {}, // Example: Provide filter model if required
//     successCallback: (rowsThisPage, lastRow) => {
//       // Handle success callback
      
//       console.log('Rows fetched:', rowsThisPage);
//       console.log('Last row:', lastRow);
//     },
//   });
// }, [datasource]);


var datasource = {
  // rowCount: null, // Total number of rows, set by the grid after the first request
  getRows: function(params) {

    const isSortingApplied = params.sortModel && params.sortModel.length > 0;
    let sortedColumn = null;
    let sortDirection = null;

    if (isSortingApplied) {
      const firstSortedColumn = params.sortModel[0];
      sortedColumn = firstSortedColumn.colId; // Column ID
      sortDirection = firstSortedColumn.sort; // Sort direction: 'asc' or 'desc'
    }

    // Extract search filter parameters
    const searchFilters = params.filterModel;

    const startRow = params.startRow | 0;
    const endRow = params.endRow | 10;


    var input = { 
      startRow: startRow,
      endRow: endRow,
      sortedColumn: sortedColumn,
      sortDirection: sortDirection,
      searchFilters: searchFilters,
    }
    axios.post("/user/rawdata/list-new", input)
      .then(response => {
        const { list, rowCount } = response.data;
        this.rowCount = rowCount;
        params.successCallback(list, rowCount);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        params.failCallback(); // Handle error in UI or retry logic
      });
  }
};

// const datasource = {
//   getRows: function async(params) {
//     console.log("inside data source")
//       if(params?.startRow>=10){
//         axios.post("/user/rawdata/list-new", {startRow: params?.startRow, endRow: params?.endRow}).then((res) => {
//           console.log('res. data ', res.data)
//           if (res.data.status === "success") {
//             // params.successCallback(res.data.list, res.data.rowCount);
//             params.successCallback(res.data.list, res.data.rowCount);
//               // dispatch(RawdataActions.RawdatabaseDataUpdatedSuccess(res.data))
//           }
//           if (res.data.status === "failure") {
//               // toast.error(res.data.msg)
//               // dispatch(RawdataActions.RawdatabaseDataUpdatedFailure(res.data.msg))
//           }
//         })
//         console.log("params datasource", params)
//         // fetchData(params.startRow, params.endRow)
//       }else{
//         params.successCallback(rawDatabaseNew, tableRowCount);
//       }
//   }
// };

  return (
    
   <>
            <React.Fragment>
                  {/* <MuiThemeProvider theme={getMuiTheme()}> */}
                  <div style={containerStyle}>
                  <div className="ag-theme-alpine" style={{ height: '500px', width: '100%' }}>
          
          <AgGridReact
            // title={<div style={{ display: "flex", flexDirection: "row", justifyContent:"flex-start", alignItems:"center" }} >
            //   <b>Raw Database List</b>&nbsp;&nbsp;&nbsp;
            //   <span onClick={() => filterRawDataType("active")} className={rawDataTypeActive === "active" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> Active </span>&nbsp;&nbsp;
            //   <span onClick={() => filterRawDataType("inactive")} className={rawDataTypeActive === "inactive" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> Inactive </span>&nbsp;&nbsp;
            // </div>
            
            // }
            // data={rawDatabaseNew}
            // rowData={rawDatabaseNew}
            // rowModelType= 'serverSide'
            // serverSideInitialRowCount={0}
            // serverSideFilterOnServer={true}
            // columns={columns}
            // options={options}
            // totalRows={1000}
            // rowData={rawDatabaseNew}
            // columnsObjects || 
            columnDefs={columnsObjects}
            defaultColDef={defaultColDef}
            rowCount={10}
            rowBuffer={0}
            // domLayout={'pagination'}
            rowModelType={'infinite'}
            cacheBlockSize={10}
            rowsThisPage={10}
            rowsThisBlock={10}
            cacheOverflowSize={1}
            maxConcurrentDatasourceRequests={1}
            infiniteInitialRowCount={10}
            maxBlocksInCache={10}
            pagination={true} 
            paginationPageSize={10}
            paginationAutoPageSize={false}
            getRowId={getRowId}
            paginationPageSizeSelector={[10, 20, 50, 100, 500]}
            rowSelection="multiple"
            onSelectionChanged={onSelectionChanged}
            paginationGetRowCount={10}
            allowDragFromColumnsToolPanel={true}
            // pivotPanelShow='always'
            // sideBar={'columns'}
            // enableRangeSelection={true}
            onGridReady={onGridReady}
            datasource={datasource}
            onColumnMoved={onColumnMoved}
            onColumnPinned={onColumnPinned}
            // onPageSizeChanged={onPageSizeChanged}
            onColumnVisible ={(e) => updateColumnsState(e)}
            onSortChanged={(e) => updateColumnsState(e)}
            
            // datasource={dataSourceRef.current}
            // onPaginationChanged={(params) => handlePaginationChanged({ ...params, agGridApi })}
          />
          </div>
           </div>
        {/* </MuiThemeProvider> */}
            </React.Fragment>
            
            {RawDataExistingCustomerModal ? <RawdataExistingcustomerModal /> : ""}
           {RawDatadetailsModal ? <RawdataAddNewLeadModal/>: ""}
            {UpdateRawdataModal ? <RawdataUpdateModal /> : ""}
            {AddingWebsiteModal ? <RawdataaddingwebsiteModal /> : ""}
            {showAddRawDCR ? <CreateRawDatabaseDCRModal />: ""}
            {showInactiveRawDataModal ? <InactiveRawDataModal /> :""}
           
    </>    
  )

});

const mapStateToProps = (state) => ({
  user : state.auth.user,
  rawdatalist:state.Rawdatalist.rawdatalist,
  AssignRawdataModels :state.Rawdatalist.AssignRawdataModel,
  RawDataExistingCustomerModal:state.Rawdatalist.showRawDataExistingCustomerModal,
  RawDatadetailsModal:state.Rawdatalist.showRawDatadetailsModal,
  UpdateRawdataModal:state.Rawdatalist.showUpdateRawdataModal, 
  AddingWebsiteModal:state.Rawdatalist.showAddingWebsiteModal,
  isFetching : state.Rawdatalist.isFetching,
  showAddRawDCR  : state.Rawdatalist.showAddRawDCR,
  showInactiveRawDataModal: state.Rawdatalist.inactiveModal,
  columnsList : state.Rawdatalist.columnsList,
  rawDatabaseNew : state.Rawdatalist.rawDatabaseNew,
  tableRowCount: state.Rawdatalist.tableRowCount,
  meetingcolumnupdated : state.Commonlist.meetingcolumnupdated,
  columnsObjects : state.Rawdatalist.columnsObjects
});

const mapDispatchToProps = (dispatch) => ({
  Updaterawdata_openmodal:(data) => dispatch(RawdataActions.showUpdateRawdataModal_openmodal(data)),
  setRowMetaData:(data) => dispatch(RawdataActions.setrowMetaData(data)),
  setRowId:(data) => dispatch(RawdataActions.setRowId(data)),
  setAddwebsiteRowid:(data) => dispatch(RawdataActions.setAddwebisteRowId(data)),
  showAddwebistemodal:(data) => dispatch(RawdataActions.showAddingWebsiteModal_openmodal(data)),
  RawDataExistingCustomerModalSetRowId:(data) => dispatch(RawdataActions.setRawDataExistingCustomerModalSetRowId(data)),
  RawDatacustomeropen_modal:(data) => dispatch(RawdataActions.showRawDataExistingCustomerModal_openmodal(data)),
  showAddnewlead:(data) => dispatch(RawdataActions.showRawDatadetailsModal_openmodal(data)),
  leadid_data:(data) => dispatch(RawdataActions.setleadid_data(data)),
  updateShowAddRawDCR: (data) => dispatch(RawDatabaseAddActivityModal(data)),
  invalidOpenModal:(data) => dispatch(RawdataActions.inactiveModalOpen(data)),
  updateColumnsListData : (data) => dispatch(updateColumnsList(data)),
  getColumnsListData : (data) => dispatch(getColumnsList()),
  fetchRawdatabaseData : (data) => dispatch(fetchRawdatabaseList(data)),
  meetingColumnUpdated: (data) => dispatch(meetingColumnUpdateddata(data)),
});
// RawdataActions.showAddRawDCR
export default connect(mapStateToProps,mapDispatchToProps)(RawDataTablePagination)

