import { createSlice } from "@reduxjs/toolkit";
import Api  from './axios';
import { toast } from 'react-toastify';
var userObj = JSON.parse(localStorage.getItem("user")) || null

const intialAuthState = {  
    isFetching:false,
    error: false,
    errorPayload : false,
    user:userObj,
    msg: "",
    leadsAnalysis: null,
    isalesMeetings: null,
    followUpsList: null,
    opportunitiesTasksList: null,
    accountManagersList: [],
    months : [{ label: 'January', value: 'Jan' }, { label: 'February', value: 'Feb' }, { label: 'March', value: 'Mar' }, { label: 'April', value: 'Apr' }, { label: 'May', value: 'May' }, { label: 'June', value: 'Jun' }, { label: 'July', value: 'Jul' }, { label: 'August', value: 'Aug' }, { label: 'September', value: 'Sep' }, { label: 'October', value: 'Oct' }, { label: 'November', value: 'Nov' }, { label: 'December', value: 'Dec' }],
    yearsList: [],
    quartersList: [{ label: "Q1", value: 'Q1' }, { label: "Q2", value: 'Q2' }, { label: "Q3", value: 'Q3' }, { label: "Q4", value: 'Q4' }],
    salesConfidenceList: [],
    businessTypesList: [],
    salesStagesList: [],
    regionWiseRevenueData: [],
    regionWiseRevenueTableData: [],
    instantReport: {},
    employeeLeaveCount: {},
    employeeLeaveStatus : null,
    allReportsData: [],
    salesIsalesTechUsers: [],
    productsList: [],
    newproductsList:[],
    productFamilyList: [],
    preSalesChartData: [],
    courseTypeList: [{ label: "Basic", value: 'Basic' }, { label: "Advanced", value: 'Advanced' }],
    sessionsTypeList : [{ label: "Online", value: 'Online' }, { label: "Offline", value: 'Offline' }],
    postSalesChartData: [],
    totalTickets: 0,
    ticketsChartData: [],
    techiesList: [],
    queriesClosedChartData: [],
    achievedTargetFilterData: [],
    monthlyTechActivity: [],
    usersList: [],
    lineChartSalesData: [],
    lineChartTechData: [],
    lineChartTechPostSales: [],
    lineChartSalesStages: [],
    forecastSalesStagesData: [],
    forecastSalesStagesTableData: [],
    leaveRequestChart: [],
    leaveRequestChartTableData: [],
    salesActivitiesChart: [],
    salesActivitiesTableData: [],
    isalesUsersList: [],
    isalesChart: [],
    isalesChartTableData: [],
    teamMembersList: [],
    efficiencyChart: [],
    regionsList : [],
    statesList : [],
    businessTypesData : [],
    leadSalesStagesList : [],
    salesActivitiesList : [],
    stateWiseRevenue:[],
    stateWiseRevenueData:[],
    StateWiseTableData:[],
    TechieClocktime:[],
    TechieClocktimeData:[],
    TechieClocktimeTableData:[],
    userRolesList:[],
    dynamicUserListData:[],
    getIsalesChart : false,
    getProductsList: false,
    getProductFamilyList: false,
    getForecastSalesStages : false,
    getYearsList : false,
    getTechiesList: false,


    cardElementsLoader : false,
    isalesLoader: false,
    updateIsalesmeetingLoader:false,
    instaReportLoader:false,
    EmployeeleaveLoader:false,
    AllreportLoader:false,
    PresalesLoader:false,
    PostsalesLoader:false,
    TicketschartLoader:false,
    QueriesclosedLoader:false,
    QuartervsQuarterLoader:false,
    MonthvsMonthLoader:false,
    FunnelchartLoader:false,
    LineChartsalesLoader:false,
    LinechartLoader:false,
    LinechartpostLoader:false,
    SalesstageLoader:false,
    getForecastSalesStagesLoader:false,
    LeaverequestchartLoader:false,
    ActivitychartLoader:false,
    EfficiencychartLoader:false,
    SalestargetLoader:false,
    StateChartWiseLoader:false,
    techieClocktimeLoader:false,
    getProductsNewList: false

}


const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: intialAuthState,
    reducers: {
        loaderStart(state, action) {
            state.isFetching = true
            state.msg = "Fetching..."
            state[action.payload] = true;
        },
        loaderStop(state,action){
            state.isFetching = false
            state.msg = "Fetching..."
        },
        leadsAnalysisSuccess(state, action){
            state.cardElementsLoader = false;
            state.error = false
            state.errorPayload = null
            state.leadsAnalysis = action.payload
        },
        leadsAnalysisFailure(state, action){
            state.cardElementsLoader = false;
            state.error = true;
            state.errorPayload = action.payload;
            state.leadsAnalysis = null;
            toast.error(action.payload);
        },
        isalesMeetingsSuccess(state, action){
            state.updateIsalesmeetingLoader = false;
            state.error = false
            state.errorPayload = null
            state.isalesMeetings = action.payload
            
        },
        isalesMeetingsFailure(state, action){
            state.updateIsalesmeetingLoader = false;
            state.error = true;
            state.errorPayload = action.payload;
            state.isalesMeetings = null;
            toast.error(action.payload);
        },
        isalesMeetingUpdateSuccess(state, action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = null
            state.msg = action.payload
        },
        isalesMeetingUpdateFailure(state, action){
            state.isFetching = false;
            state.error = true;
            state.errorPayload = action.payload;
            state.isalesMeetings = null;
            toast.error(action.payload);
        },
        followUpTasksListSuccess(state, action){
            state.cardElementsLoader = false;
            state.error = false
            state.errorPayload = null
            state.followUpsList = action.payload?.followUpsList
            state.opportunitiesTasksList = action.payload?.opportunitiesTasksList
        },
        followUpTasksListFailure(state, action){
            state.cardElementsLoader = false;
            state.error = true;
            state.errorPayload = action.payload;
            state.followUpsList = null;
            state.opportunitiesTasksList = null;
            toast.error(action.payload);
        },
        followUpTasksUpdateSuccess(state, action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = null
            state.msg = action.payload;
        },
        followUpTasksUpdateFailure(state, action){
            state.isFetching = false;
            state.error = true;
            state.errorPayload = action.payload;
            state.msg = null;
            toast.error(action.payload);
        },
        accountManagersListSuccess(state, action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = null
            state.accountManagersList = action.payload;
        },
        accountManagersListFailure(state, action){
            state.isFetching = false;
            state.error = true;
            state.errorPayload = action.payload;
            state.accountManagersList = null;
            toast.error(action.payload);
        },
        yearsListSuccess(state, action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = null
            state.yearsList = action.payload;
        },
        yearsListFailure(state, action){
            state.isFetching = false;
            state.error = true;
            state.errorPayload = action.payload;
            state.yearsList = null;
            toast.error(action.payload);
        },
        salesConfidenceListSuccess(state, action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = null
            state.salesConfidenceList = action.payload;
        },
        salesConfidenceListFailure(state, action){
            state.isFetching = false;
            state.error = true;
            state.errorPayload = action.payload;
            state.salesConfidenceList = null
            toast.error(action.payload);
        },
        businessTypesListSuccess(state, action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = null
            state.businessTypesList = action.payload;
        },
        businessTypesListFailure(state, action){
            state.isFetching = false;
            state.error = true;
            state.errorPayload = action.payload;
            state.businessTypesList = null
            toast.error(action.payload);
        },
        salesStagesListSuccess(state, action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = null
            state.salesStagesList = action.payload;
        },
        salesStagesListFailure(state, action){
            state.isFetching = false;
            state.error = true;
            state.errorPayload = action.payload;
            state.salesStagesList = null
            toast.error(action.payload);
        },
        regionWiseRevenueSuccess(state, action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = null
            state.regionWiseRevenueData = action.payload.data;
            state.regionWiseRevenueTableData = action.payload.list;
        }, 
        instantReportDataSuccess(state, action){
            state.instaReportLoader = false;
            state.error = false
            state.errorPayload = null
            state.instantReport = action.payload;
        },
        instantReportDataFailure(state, action){
            state.instaReportLoader = false;
            state.error = true;
            state.errorPayload = action.payload;
            state.instantReport = null
            toast.error(action.payload);
        },
        employeeLeaveStatusSuccess(state, action){
            state.EmployeeleaveLoader = false;
            state.error = false
            state.errorPayload = null
            state.employeeLeaveCount = action.payload;
        },
        employeeLeaveStatusFailure(state, action){
            state.EmployeeleaveLoader = false;
            state.error = true;
            state.errorPayload = action.payload;
            state.employeeLeaveStatus = null
            toast.error(action.payload);
        },
        salesIsalesTechUserListSuccess(state, action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = null
            state.salesIsalesTechUsers = action.payload;
        },
        salesIsalesTechUserListFailure(state, action){
            state.isFetching = false;
            state.error = true;
            state.errorPayload = action.payload;
            state.salesIsalesTechUsers = null
            toast.error(action.payload);
        },
        UpdateMomStatusSuccess(state, action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = null
            state.msg = action.payload;
        },
        UpdateMomStatusFailure(state, action){
            state.isFetching = false;
            state.error = true;
            state.errorPayload = action.payload;
            state.msg = null
            toast.error(action.payload);
        },
        allReportsListSuccess(state, action){
            state.AllreportLoader = false;
            state.error = false
            state.errorPayload = null
            state.allReportsData = action.payload;
        },
        allReportsListFailure(state, action){
            state.AllreportLoader = false;
            state.error = true;
            state.errorPayload = action.payload;
            state.allReportsData = null
            toast.error(action.payload);
        },
        productsListSuccess(state, action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = null
            state.productsList = action.payload;
        },
        productsListFailure(state, action){
            state.isFetching = false;
            state.error = true;
            state.errorPayload = action.payload;
            state.productsList = null
            toast.error(action.payload);
        },
        newProductsListSuccess(state, action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = null
            state.newproductsList = action.payload;
        },
        newProductsListFailure(state, action){
            state.isFetching = false;
            state.error = true;
            state.errorPayload = action.payload;
            state.newproductsList = null
            toast.error(action.payload);
        },
        productFamilyListSuccess(state, action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = null
            state.productFamilyList = action.payload;
        },
        productFamilyListFailure(state, action){
            state.isFetching = false;
            state.error = true;
            state.errorPayload = action.payload;
            state.productFamilyList = null
            toast.error(action.payload);
        },
        preSalesChartDataSuccess(state, action){
            state.PresalesLoader = false;
            state.error = false
            state.errorPayload = null
            state.preSalesChartData = action.payload;
        },
        preSalesChartDataFailure(state, action){
            state.PresalesLoader = false;
            state.error = true;
            state.errorPayload = action.payload;
            state.preSalesChartData = null
            toast.error(action.payload);
        },
        postSalesChartDataSuccess(state, action){
            state.PostsalesLoader = false;
            state.error = false
            state.errorPayload = null
            state.postSalesChartData = action.payload;
        },
        postSalesChartDataFailure(state, action){
            state.PostsalesLoader = false;
            state.error = true;
            state.errorPayload = action.payload;
            state.postSalesChartData = null
            toast.error(action.payload);
        },
        totalTicketsCountSuccess(state, action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = null
            state.totalTickets = action.payload;
        },
        totalTicketsCountFailure(state, action){
            state.isFetching = false;
            state.error = true
            state.errorPayload = action.payload;
            state.totalTickets = 0
        },
        ticketsChartDataSuccess(state, action){
            state.TicketschartLoader = false;
            state.error = false
            state.errorPayload = null
            state.ticketsChartData = action.payload;
        },
        ticketsChartDataFailure(state, action){
            state.TicketschartLoader = false;
            state.error = true
            state.errorPayload = action.payload;
            state.ticketsChartData = []
        },
        techiesListSuccess(state, action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = null
            state.techiesList = action.payload;
        },
        techiesListFailure(state, action){
            state.isFetching = false;
            state.error = true
            state.errorPayload = action.payload;
            state.techiesList = []
        },
        dynamicUserListSuccess(state,action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = null
            state.dynamicUserListData = action.payload;
        },
        dynamicUserListFailure(state, action){
            state.isFetching = false;
            state.error = true
            state.errorPayload = action.payload;
            state.dynamicUserListData = []
        },
        userRolesListSuccess(state,action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = null
            state.userRolesList = action.payload;
        },
        userRolesListFailure(state, action){
            state.isFetching = false;
            state.error = true
            state.errorPayload = action.payload;
            state.userRolesList = []
        },
        queriesClosedChartDataSuccess(state, action){
            state.QueriesclosedLoader = false;
            state.error = false
            state.errorPayload = null
            state.queriesClosedChartData = action.payload;
        },
        queriesClosedChartDataFailure(state, action){
            state.QueriesclosedLoader = false;
            state.error = true
            state.errorPayload = action.payload;
            state.queriesClosedChartData = []
        },
        achievedTargetFilterDataSuccess(state, action){
            state.QuartervsQuarterLoader = false;
            state.error = false
            state.errorPayload = null
            state.achievedTargetFilterData = action.payload;
        },
        achievedTargetFilterDataFailure(state, action){
            state.QuartervsQuarterLoader = false;
            state.error = true
            state.errorPayload = action.payload;
            state.achievedTargetFilterData = []
        },
        salesTargetChartSuccess(state, action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = null
            state.achievedTargetFilterData = action.payload;
        },
        salesTargetChartFailure(state, action){
            state.isFetching = false;
            state.error = true
            state.errorPayload = action.payload;
            state.achievedTargetFilterData = []
        },
        monthlyTechActivitySuccess(state, action){
            state.MonthvsMonthLoader = false;
            state.error = false
            state.errorPayload = null
            state.monthlyTechActivity = action.payload;
        },
        monthlyTechActivityFailure(state, action){
            state.MonthvsMonthLoader = false;
            state.error = true
            state.errorPayload = action.payload;
            state.monthlyTechActivity = []
        },
        usersListSuccess(state, action){
            state.FunnelchartLoader = false;
            state.error = false
            state.errorPayload = null
            state.usersList = action.payload;
        },
        usersListFailure(state, action){
            state.FunnelchartLoader = false;
            state.error = true
            state.errorPayload = action.payload;
            state.usersList = []
        },
        lineChartSalesDataSuccess(state, action){
            state.LineChartsalesLoader = false;
            state.error = false
            state.errorPayload = null
            state.lineChartSalesData = action.payload;
        },
        lineChartSalesDataFailure(state, action){
            state.LineChartsalesLoader = false;
            state.error = true
            state.errorPayload = action.payload;
            state.lineChartSalesData = []
        },
        lineChartTechDataSuccess(state, action){
            state.LinechartLoader = false;
            state.error = false
            state.errorPayload = null
            state.lineChartTechData = action.payload;
        },
        lineChartTechDataFailure(state, action){
            state.LinechartLoader = false;
            state.error = true
            state.errorPayload = action.payload;
            state.lineChartTechData = []
        },
        lineChartTechPostSalesSuccess(state, action){
            state.LinechartpostLoader = false;
            state.error = false
            state.errorPayload = null
            state.lineChartTechPostSales = action.payload;
        },
        lineChartTechPostSalesFailure(state, action){
            state.LinechartpostLoader = false;
            state.error = true
            state.errorPayload = action.payload;
            state.lineChartTechPostSales = []
        },
        lineChartSalesStagesDataSuccess(state, action){
            state.SalesstageLoader = false;
            state.error = false
            state.errorPayload = null
            state.lineChartSalesStages = action.payload;
            state.lineChartSalesData = action.payload;
        },
        lineChartSalesStagesDataFailure(state, action){
            state.SalesstageLoader = false;
            state.error = true
            state.errorPayload = action.payload;
            state.lineChartSalesStages = []
            state.lineChartSalesData = [];
        },
        regionsListSuccess(state, action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = null
            state.regionsList = action.payload;
        },
        regionsListFailure(state, action){
            state.isFetching = false;
            state.error = true
            state.errorPayload = action.payload;
            state.regionsList = []
        },
        statesListSuccess(state, action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = null
            state.statesList = action.payload;
        },
        statesListFailure(state, action){
            state.isFetching = false;
            state.error = true
            state.errorPayload = action.payload;
            state.statesList = []
        },
        forecastSalesStagesDataSuccess(state, action){
            state.getForecastSalesStagesLoader = false;
            state.error = false
            state.errorPayload = null
            state.forecastSalesStagesData = action.payload.data;
            state.forecastSalesStagesTableData = action.payload.tableData;
        },
        forecastSalesStagesDataFailure(state, action){
            state.getForecastSalesStagesLoader = false;
            state.error = true
            state.errorPayload = action.payload
            state.forecastSalesStagesData = [];
            state.forecastSalesStagesTableData = [];
        },
        achievedTargetFilterSuccess(state, action){
            state.SalestargetLoader = false;
            state.error = false
            state.errorPayload = null
            state.achievedTarget = action.payload;
        },
        achievedTargetFilterFailure(state, action){
            state.SalestargetLoader = false;
            state.error = true
            state.errorPayload = action.payload
            state.achievedTarget = [];
        },
        achievedTargetSuccess(state, action){
            state.SalestargetLoader = false;
            state.error = false
            state.errorPayload = null
            state.achievedTarget = action.payload;
        },
        achievedTargetFailure(state, action){
            state.SalestargetLoader = false;
            state.error = false
            state.errorPayload = null
            state.achievedTarget = [];
        },
        leaveRequestChartSuccess(state, action){
            state.LeaverequestchartLoader = false;
            state.error = false
            state.errorPayload = null
            state.leaveRequestChart = action.payload.data;
            state.leaveRequestChartTableData = action.payload.tableData;
        },
        leaveRequestChartFailure(state, action){
            state.LeaverequestchartLoader = false;
            state.error = true
            state.errorPayload = null
            state.leaveRequestChart = [];
            state.leaveRequestChartTableData = [];
        },
        salesActivitiesChartSuccess(state, action){
            state.ActivitychartLoader = false;
            state.error = false
            state.errorPayload = null
            state.salesActivitiesChart = action.payload.data;
            state.salesActivitiesTableData = action.payload.tableData;
        },
        salesActivitiesChartFailure(state, action){
            state.ActivitychartLoader = false;
            state.error = true
            state.errorPayload = null
            state.salesActivitiesChart = [];
            state.salesActivitiesTableData = [];
        },
        IsalesUsersListSuccess(state, action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = null
            state.isalesUsersList = action.payload;
        },
        IsalesUsersListFailure(state, action){
            state.isFetching = false;
            state.error = true
            state.errorPayload = null
            state.isalesUsersList = [];
        },
        IsalesChartDataSuccess(state, action){
            state.isalesLoader = false;
            state.error = false
            state.errorPayload = null
            state.isalesChart = action.payload.data;
            state.isalesChartTableData = action.payload.tableData;
            state.getIsalesChart = false;
        },
        IsalesChartDataFailure(state, action){
            state.isalesLoader = false;
            state.error = false
            state.errorPayload = null
            state.isalesChart = [];
            state.isalesChartTableData = [];
            state.getIsalesChart = false;
        },
        teamMembersListSuccess(state, action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = null
            state.teamMembersList = action.payload;
        },
        teamMembersListFailure(state, action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = null
            state.teamMembersList = [];
        },
        fetchEfficiencyChartDataSuccess(state, action){
            state.EfficiencychartLoader = false;
            state.error = false
            state.errorPayload = null
            state.efficiencyChart = action.payload;
        },
        fetchEfficiencyChartDataFailure(state, action){
            state.EfficiencychartLoader = false;
            state.error = false
            state.errorPayload = null
            state.efficiencyChart = [];
        },
        regionSearchSuccess(state, action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = false
            state.regionsList = action.payload
        },
        regionSearchFailure(state, action){
            state.isFetching = false;
            state.error = true
            state.errorPayload = action.payload
            state.regionsList = []
        },
        businessTypesDataSuccess(state, action){
            state.isFetching = false;
            state.error = false
            state.errorPayload = false;
            state.businessTypesData = action.payload
        },
        businessTypesDataFailure(state, action){
            state.isFetching = false;
            state.error = false;
            state.errorPayload = action.payload;
            state.businessTypesData = [];
        },
        leadStagesListSuccess(state, action){
            state.isFetching = false;
            state.error = false;
            state.errorPayload = false;
            state.leadSalesStagesList = action.payload?.salesStagesList;
            state.salesActivitiesList = action.payload?.salesActivitiesList;
        },
        leadStagesListFailure(state, action){
            state.isFetching = false;
            state.error = false;
            state.errorPayload = false;
            state.leadSalesStagesList = [];
            state.salesActivitiesList = [];
        },

        StateWiseRevenueSuccess (state,action) {
            state.StateChartWiseLoader = false;
            state.error = false
            state.errorPayload = null
            state.stateWiseRevenue = action.payload;
            state.stateWiseRevenueData = action.payload?.data;
            state.StateWiseTableData = action.payload?.tableData;
        },
        StateWiseRevenueFailure(state, action){
            state.StateChartWiseLoader = false;
            state.error = true
            state.errorPayload = false;
            state.stateWiseRevenue = [];
            state.stateWiseRevenueData = [];
            state.StateWiseTableData= []
        },
        TechieClocktimeSuccess(state,action) {
            state.techieClocktimeLoader = false;
            state.error = false
            state.errorPayload = null
            state.TechieClocktime = action.payload;
            state.TechieClocktimeData = action.payload?.data;
            state.TechieClocktimeTableData = action.payload?.tableDatas;
        },
        TechieClocktimeFailure(state, action){
            state.techieClocktimeLoader = false;
            state.error = true
            state.errorPayload = false;
            state.TechieClocktime = [];
            state.TechieClocktimeData = [];
            state.TechieClocktimeTableData= []
        },


    }
})


export const dashboardActions = dashboardSlice.actions;

export const LoaderStart = () => {
    return async(dispatch) => {
        dispatch(dashboardActions.loaderStart())
    }
}

export const LoaderStop = () => {
    return async(dispatch) => {
        dispatch(dashboardActions.loaderStop())
    }
}

export const getLeadsAnalysis = (filters) => {
    return async (dispatch) => {
        dispatch(dashboardActions.loaderStart("cardElementsLoader"))
        try {
            await Api.post("/leads/analysis", filters).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.leadsAnalysisSuccess(res.data))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.leadsAnalysisFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.leadsAnalysisFailure("Network Error"));
        }
    }
}

export const getIsalesMeeting = () => {
    return async (dispatch) => {
        // dispatch(dashboardActions.loaderStart())
        dispatch(dashboardActions.loaderStart("updateIsalesmeetingLoader"))
        try {
            await Api.get("/user/reports/isales-meetings").then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.isalesMeetingsSuccess(res.data.data))
                        
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.isalesMeetingsFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.isalesMeetingsFailure("Network Error"));
        }
    }
}

export const updateCompletedMeeting = (data) => {
    return async (dispatch) => {
        dispatch(dashboardActions.loaderStart())
        try {
            await Api.post("/user/reports/isales-meetings/update", data).then((res) => {
                    if (res.data.status === "success") {
                        toast.success("Isales meeting updated!")
                        dispatch(dashboardActions.isalesMeetingUpdateSuccess(res.data))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(dashboardActions.isalesMeetingUpdateFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error!")
            dispatch(dashboardActions.isalesMeetingUpdateFailure("Network Error"));
        }
    }
}

export const getFollowUpsList = (data) => {
    return async (dispatch) => {
        dispatch(dashboardActions.loaderStart())
        try {
            await Api.post("/user/reports/tasks-followups", data).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.followUpTasksListSuccess(res.data))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.followUpTasksListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.followUpTasksListFailure("Network Error"));
        }
    }
}

export const updateTaskFollowup = (data) => {
    return async (dispatch) => {
        dispatch(dashboardActions.loaderStart())
        try {
            await Api.post("/user/reports/tasks-followups/update", data).then((res) => {
                    if (res.data.status === "success") {
                        toast.success("Follow up task updated")
                        dispatch(dashboardActions.followUpTasksUpdateSuccess(res.data))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(dashboardActions.followUpTasksUpdateFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error!")
            dispatch(dashboardActions.followUpTasksUpdateFailure("Network Error"));
        }
    }
}

export const getManagers = (data) => {
    return async (dispatch, getState) => {
        const { dashboard } = getState();

        if (dashboard.getManagers) {
            return;
        }
        
        dispatch(dashboardActions.loaderStart("getManagers"))
        try {
            await Api.get("user/account-managers-list").then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.accountManagersListSuccess(res.data.usersList))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.accountManagersListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.accountManagersListFailure("Network Error"));
        }
    }
}

export const getYears = (data) => {
    return async (dispatch, getState) => {
        const { dashboard } = getState();

        // dispatch(dashboardActions.loaderStart())
        if (dashboard.getYearsList) {
            return;
        }
        dispatch(dashboardActions.loaderStart("getYearsList"))
        try {
            await Api.get("user/reports/years-list").then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.yearsListSuccess(res.data.yearsList))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.yearsListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.yearsListFailure("Network Error"));
        }
    }
}

export const getSalesConfidenceStages = (data) => {
    return async (dispatch) => {
        dispatch(dashboardActions.loaderStart())
        try {
            await Api.get("user/reports/sales-confidence-stages-list").then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.salesConfidenceListSuccess(res.data.salesConfidenceStagesList))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.salesConfidenceListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.salesConfidenceListFailure("Network Error"));
        }
    }
}

export const getBusinessTypes = (data) => {
    return async (dispatch) => {
        dispatch(dashboardActions.loaderStart())
        try {
            await Api.get("user/reports/business-types-list").then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.businessTypesListSuccess(res.data.businessTypes))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.businessTypesListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.businessTypesListFailure("Network Error"));
        }
    }
}


export const getSalesStages = (data) => {
    return async (dispatch) => {
        dispatch(dashboardActions.loaderStart())
        try {
            await Api.get("user/reports/sales-stages-list").then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.salesStagesListSuccess(res.data.salesStagesList))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.salesStagesListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.salesStagesListFailure("Network Error"));
        }
    }
}


// export const getRegionWiseRevenueChart = (data) => {
//     return async (dispatch) => {
//         dispatch(dashboardActions.loaderStart())
//         try {
//             await Api.post("/user/reports/region-chart", data).then((res) => {
//                     if (res.data.status === "success") {
//                         dispatch(dashboardActions.regionWiseRevenueSuccess({data:data, list: res.data.tableData}))
//                     }
//                     if (res.data.status === "failure") {
//                         dispatch(dashboardActions.regionWiseRevenueFailure(res.data.msg))
//                     }
//                 })
//         }
//         catch (err) {
//             dispatch(dashboardActions.salesStagesListFailure("Network Error"));
//         }
//     }
// }

export const  getInstantReportData = (data) => {
    return async (dispatch) => {
        dispatch(dashboardActions.loaderStart("instaReportLoader"))
        try {
            await Api.post("user/reports/insta-report", data).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.instantReportDataSuccess(res.data.data))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.instantReportDataFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.instantReportDataFailure("Network Error"));
        }
    }
}

export const fetchEmployeeLeaveStatus = (filterDate)=> {
    return async (dispatch) => {
        dispatch(dashboardActions.loaderStart("EmployeeleaveLoader"))
        try {
            await Api.get(`user/employee-leave-status?date=${filterDate}`).then((res) => {
                    // if (res.data.status === "success") {
                        dispatch(dashboardActions.employeeLeaveStatusSuccess(res.data))
                    // }
                    // if (res.data.status === "failure") {
                        // dispatch(dashboardActions.employeeLeaveStatusFailure(res.data.msg))
                    // }
                })
        }
        catch (err) {
            dispatch(dashboardActions.employeeLeaveStatusFailure("Network Error"));
        }
    }
}

export const salesIsalesTechUsersList = () => {
    return async (dispatch) => {
        dispatch(dashboardActions.loaderStart())
        try {
            await Api.get('user/isales-tech-sales-users').then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.salesIsalesTechUserListSuccess(res.data.list))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.salesIsalesTechUserListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.salesIsalesTechUserListFailure("Network Error"));
        }
    }
}

export const getAllReportsData  = (filter) => {
    return async (dispatch) => {
        dispatch(dashboardActions.loaderStart("AllreportLoader"))
        try {
            await Api.post('/user/reports/all-reports/', filter).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.allReportsListSuccess(res.data.data))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.allReportsListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.allReportsListFailure("Network Error"));
        }
    }
}

export const updateMoMStatus = (value) => {
    return async (dispatch) => {
        dispatch(dashboardActions.loaderStart())
        try {
            await Api.post('/user/reports/update-mom-status/', {status:'completed', id:value}).then((res) => {
                    if (res.data.status === "success") {
                        toast.success("MoM Updated!")
                        dispatch(dashboardActions.UpdateMomStatusSuccess(res.data.msg))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(dashboardActions.UpdateMomStatusFailure(res.data.msg))
                    }
                    dispatch(getAllReportsData("summary"))
                })
        }
        catch (err) {
            toast.error("Network Error!")
            dispatch(dashboardActions.UpdateMomStatusFailure("Network Error"));
        }
    }
}

export const getProductsList = () => {
    return async (dispatch, getState) => {
        const { dashboard } = getState();

        if (dashboard.getProductsList) {
            return;
        }
        dispatch(dashboardActions.loaderStart("getProductsList"))
        try {
            await Api.get('/products/search').then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.productsListSuccess(res.data.productsList))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.productsListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.productsListFailure("Network Error"));
        }
    }
}

export const getNewProductsList = () => {
    return async (dispatch, getState) => {
        const { dashboard } = getState();

        if (dashboard.getProductsNewList) {
            return;
        }
        dispatch(dashboardActions.loaderStart("getProductsList"))
        try {
            await Api.get('/products/categories').then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.newProductsListSuccess(res.data.ProductsCategoriesList))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.newProductsListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.newProductsListFailure("Network Error"));
        }
    }
}
export const getProductFamilyList = () => {
    return async (dispatch, getState) => {
        const { dashboard } = getState();

        if (dashboard.getProductFamilyList) {
            return;
        }
        
        dispatch(dashboardActions.loaderStart("getProductFamilyList"))
        try {
            await Api.get('/product-family/list').then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.productFamilyListSuccess(res.data.productFamilyList))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.productFamilyListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.productFamilyListFailure("Network Error"));
        }
    }
}


export const fetchPreSalesChartData = (filters) => {
    return async (dispatch) => {
        const dataEmptySelectors = Object.entries(filters[0]).every(([key, value]) => {
            if (key === 'year' && value === filters[1]) {
                return true;
            }
            if (Array.isArray(value)) {
                return value.length === 0;
            }
            return value === null || value === undefined || value === "";
        });

        if (dataEmptySelectors) {
            dispatch(dashboardActions.loaderStart("PresalesLoader"));
        }
        try {
            await Api.post('/user/techie-charts/pre-sales', filters[0]).then((res) => {
               
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.preSalesChartDataSuccess(res.data.data))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.preSalesChartDataFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.preSalesChartDataFailure("Network Error"));
        }
    }
}

export const fetchPostSalesChartData = (filters) => {
    return async (dispatch) => {
        // dispatch(dashboardActions.loaderStart("PostsalesLoader"))
        const dataEmptySelectors = Object.values(filters).every(filter => (
            Array.isArray(filter) ? filter.length === 0 : filter === null || filter === undefined || filter === ""
        ));
        if (dataEmptySelectors) {
            dispatch(dashboardActions.loaderStart("PostsalesLoader"));
        }
        
        try {
            await Api.post('/user/techie-charts/post-sales', filters).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.postSalesChartDataSuccess(res.data.data))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.postSalesChartDataFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.postSalesChartDataFailure("Network Error"));
        }
    }
}

export const fetchTotalTickets = () => {
    return async (dispatch) => {
        dispatch(dashboardActions.loaderStart())
        try {
            await Api.get('/tickets/countTotalTickets').then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.totalTicketsCountSuccess(res.data.totalTickets))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.totalTicketsCountFailure(res.data.msg))
                    }

                })
        }
        catch (err) {
            dispatch(dashboardActions.totalTicketsCountFailure("Network Error"));
        }
    }
}

export const fetchTicketsChartData = (filters) => {
    return async (dispatch) => {
        // dispatch(dashboardActions.loaderStart("TicketschartLoader"))
        const dataEmptySelectors = Object.entries(filters[0]).every(([key, value]) => {
            if (key === 'year' && value === filters[1]) {
                return true;
            }
            if (Array.isArray(value)) {
                return value.length === 0;
            }
            return value === null || value === undefined || value === "";
        });
        if (dataEmptySelectors) {
            dispatch(dashboardActions.loaderStart("TicketschartLoader"));
        }
        try {
            await Api.post('/user/techie-charts/tickets-chart', filters[0]).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.ticketsChartDataSuccess(res.data.data))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.ticketsChartDataFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.ticketsChartDataFailure("Network Error"));
        }
    }
}

export const fetchTechiesList = () => {
    return async (dispatch, getState) => {
        const { dashboard } = getState();

        if (dashboard.getTechiesList) {
            return;
        }
        dispatch(dashboardActions.loaderStart("getTechiesList"))
        try {
            await Api.get('/user/techies-list').then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.techiesListSuccess(res.data.techiesList))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.techiesListFailure(res.data.msg))
                    }

                })
        }
        catch (err) {
            dispatch(dashboardActions.techiesListFailure("Network Error"));
        }
    }
}
export const fetchDynamicUsersList = (data) => {
    return async (dispatch, getState) => {
        // const { dashboard } = getState();

        // if (dashboard.getTechiesList) {
        //     return;
        // }
        dispatch(dashboardActions.loaderStart("getTechiesList"))
        try {
            await Api.post('/user/dynamic-user-list',data).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.dynamicUserListSuccess(res.data.usersList))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.dynamicUserListFailure(res.data.msg))
                    }

                })
        }
        catch (err) {
            dispatch(dashboardActions.dynamicUserListFailure("Network Error"));
        }
    }
}
export const fetchUserRoles = () => {
    return async (dispatch, getState) => {
        // const { dashboard } = getState();

        // if (dashboard.getTechiesList) {
        //     return;
        // }
        // dispatch(dashboardActions.loaderStart("getTechiesList"))
        try {
            await Api.get('/user/user-roles').then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.userRolesListSuccess(res.data.userRoles))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.userRolesListFailure(res.data.msg))
                    }

                })
        }
        catch (err) {
            dispatch(dashboardActions.userRolesListFailure("Network Error"));
        }
    }
}

export const fetchQueriesClosedChart = (filters) => {
    return async (dispatch) => {
        // dispatch(dashboardActions.loaderStart("QueriesclosedLoader"))
        const dataEmptySelectors = Object.entries(filters[0]).every(([key, value]) => {
            if (key === 'year' && value === filters[1]) {
                return true;
            }
            if (Array.isArray(value)) {
                return value.length === 0;
            }
            return value === null || value === undefined || value === "";
        });
        if (dataEmptySelectors) {
            dispatch(dashboardActions.loaderStart("QueriesclosedLoader"));
        }
        try {
            await Api.post('/user/techie-charts/queries-closed', filters[0]).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.queriesClosedChartDataSuccess(res.data.data))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.queriesClosedChartDataFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.queriesClosedChartDataFailure("Network Error"));
        }
    }
}

export const getAchievedTargetFilteredData = (filters) => {
    return async (dispatch) => {
        // dispatch(dashboardActions.loaderStart("QuartervsQuarterLoader"))
        const dataEmptySelectors = Object.values(filters).every(filter => (
            Array.isArray(filter) ? filter.length === 0 : filter === null || filter === undefined || filter === ""
        ));
        if (dataEmptySelectors) {
            dispatch(dashboardActions.loaderStart("QuartervsQuarterLoader"));
        }
        try {
            await Api.post('/user/reports/achieved-target-filter', filters).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.achievedTargetFilterDataSuccess(res.data.chartData))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.achievedTargetFilterDataFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.achievedTargetFilterDataFailure("Network Error"));
        }
    }
}


export const getSalesTargetChartData = (filters) => {
    return async (dispatch) => {
        // dispatch(dashboardActions.loaderStart())
        const dataEmptySelectors = Object.values(filters).every(filter => (
            Array.isArray(filter) ? filter.length === 0 : filter === null || filter === undefined || filter === ""
        ));
        if (dataEmptySelectors) {
            dispatch(dashboardActions.loaderStart("QuartervsQuarterLoader"));
        }
        try {
            await Api.post('/user/techie-charts/quarterly-chart', filters).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.salesTargetChartSuccess(res.data.chartData))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.salesTargetChartFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.salesTargetChartFailure("Network Error"));
        }
    }
}

export const fetchMonthlyTechActivity = (filters) => {
    return async (dispatch) => {
        // dispatch(dashboardActions.loaderStart("MonthvsMonthLoader"))
        const dataEmptySelectors = Object.entries(filters[0]).every(([key, value]) => {
            if (key === 'Categories' && value === filters[1]) {
                return true;
            }
            if (Array.isArray(value)) {
                return value.length === 0;
            }
            return value === null || value === undefined || value === "";
        });

        if (dataEmptySelectors) {
            dispatch(dashboardActions.loaderStart("MonthvsMonthLoader"));
        }
        try {
            await Api.post('/user/techie-charts/monthly-chart', filters).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.monthlyTechActivitySuccess(res.data.chartData))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.monthlyTechActivityFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.monthlyTechActivityFailure("Network Error"));
        }
    }
}

export const fetchUsersList = () => {
    return async (dispatch) => {
        dispatch(dashboardActions.loaderStart("FunnelchartLoader"))
        try {
            await Api.get('user/reports/users-list').then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.usersListSuccess(res.data.chartData))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.usersListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.usersListFailure("Network Error"));
        }
    }
}

export const getLineChartSalesData = (filters) => {
    return async (dispatch) => {
        // dispatch(dashboardActions.loaderStart("SalesstageLoader"))
        const dataEmptySelectors = Object.values(filters).every(filter => (
            Array.isArray(filter) ? filter.length === 0 : filter === null || filter === undefined || filter === ""
        ));
        if (dataEmptySelectors) {
            dispatch(dashboardActions.loaderStart("SalesstageLoader"));
        }

        try {
            await Api.post('/user/reports/line-chart-sales', filters).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.lineChartSalesStagesDataSuccess(res.data.chartData))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.lineChartSalesStagesDataFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.lineChartSalesStagesDataFailure("Network Error"));
        }
    }
}

export const getLineChartTechData = (filters) => {
    return async (dispatch) => {
        // dispatch(dashboardActions.loaderStart("LinechartLoader"))
        const dataEmptySelectors = Object.entries(filters[0]).every(([key, value]) => {
            if (key === 'type' && value === filters[1]) {
                return true;
            }
            if (Array.isArray(value)) {
                return value.length === 0;
            }
            return value === null || value === undefined || value === "";
        });
        if (dataEmptySelectors) {
            dispatch(dashboardActions.loaderStart("LinechartLoader"));
        }
        try {
            await Api.post('/user/techie-charts/line-chart', filters[0]).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.lineChartTechDataSuccess(res.data.chartData))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.lineChartTechDataFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.lineChartTechDataFailure("Network Error"));
        }
    }
}

export const getLineChartTechPostSalesData = (filters) => {
    return async (dispatch) => {
        // dispatch(dashboardActions.loaderStart("LinechartpostLoader"))
        const dataEmptySelectors = Object.entries(filters[0]).every(([key, value]) => {
            if (key === 'type' && value === filters[1]) {
                return true;
            }
            if (Array.isArray(value)) {
                return value.length === 0;
            }
            return value === null || value === undefined || value === "";
        });
        if (dataEmptySelectors) {
            dispatch(dashboardActions.loaderStart("LinechartpostLoader"));
        }
        try {
            await Api.post('/user/techie-charts/line-chart-post', filters[0]).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.lineChartTechPostSalesSuccess(res.data.chartData))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.lineChartTechPostSalesFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.lineChartTechPostSalesFailure("Network Error"));
        }
    }
}

export const getLineSalesChartData = (filters) => {
    return async (dispatch) => {
        // dispatch(dashboardActions.loaderStart("LineChartsalesLoader"))
        const dataEmptySelectors = Object.entries(filters[0]).every(([key, value]) => {
            if (key === 'year' && value === filters[1]) {
                return true;
            }
            if (Array.isArray(value)) {
                return value.length === 0;
            }
            return value === null || value === undefined || value === "";
        });
        if (dataEmptySelectors) {
            dispatch(dashboardActions.loaderStart("LineChartsalesLoader"));
        }

        try {
            await Api.post('/user/reports/sales-chart', filters[0]).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.lineChartSalesDataSuccess(res.data))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.lineChartSalesDataFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.lineChartSalesDataFailure("Network Error"));
        }
    }
}

export const getRegions = () => {
    return async (dispatch) => {
        dispatch(dashboardActions.loaderStart())
        try {
            await Api.get('user/reports/regions-list').then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.regionsListSuccess(res.data.regionsList))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.regionsListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.regionsListFailure("Network Error"));
        }
    }
}

export const getStatesList = () => {
    return async (dispatch) => {
        dispatch(dashboardActions.loaderStart())
        try {
            await Api.get('user/reports/states-list').then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.statesListSuccess(res.data.statesList))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.statesListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.statesListFailure("Network Error"));
        }
    }
}

export const fetchForecastSalesStagesData = (filters) => {
    return async (dispatch, getState) => {
        // dispatch(dashboardActions.loaderStart())
        const { dashboard } = getState();

        if (dashboard.getForecastSalesStages) {
            return;
        }
        // dispatch(dashboardActions.loaderStart("getForecastSalesStagesLoader"))
        const dataEmptySelectors = Object.values(filters[1]).every(filter => (
            Array.isArray(filter) ? filter.length === 0 : filter === null || filter === undefined || filter === ""
        ));
        if (dataEmptySelectors) {
            dispatch(dashboardActions.loaderStart("getForecastSalesStagesLoader"));
        }
        try {
            await Api.post('/user/reports/forecast-sales-stages', filters[0]).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.forecastSalesStagesDataSuccess(res.data))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.forecastSalesStagesDataFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.forecastSalesStagesDataFailure("Network Error"));
        }
    }
}

export const achievedTargetFilter = (filters) => {
    return async (dispatch) => {
        // dispatch(dashboardActions.loaderStart("SalestargetLoader"))

        const dataEmptySelectors = Object.values(filters).every(filter => (
            Array.isArray(filter) ? filter.length === 0 : filter === null || filter === undefined || filter === ""
        ));
        if (dataEmptySelectors) {
            dispatch(dashboardActions.loaderStart("SalestargetLoader"));
        }
        try {
            await Api.post('/user/reports/achieved-target-filter', filters).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.achievedTargetFilterSuccess(res.data.chartData))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.achievedTargetFilterFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.achievedTargetFilterFailure("Network Error"));
        }
    }
}

export const getSalesTargetData = (filters) => {
    return async (dispatch) => {
        // dispatch(dashboardActions.loaderStart("SalestargetLoader"))
        // const dataEmptySelectors = Object.values(filters[1]).every(filter => (
        //     Array.isArray(filter) ? filter.length === 0 : filter === null || filter === undefined || filter === ""
        // ));
        // if (dataEmptySelectors) {
        //     dispatch(dashboardActions.loaderStart("SalestargetLoader"));
        // }
        try {
            await Api.post('/user/reports/achieved-target', filters[0]).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.achievedTargetSuccess(res.data.chartData))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.achievedTargetFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.achievedTargetFailure("Network Error"));
        }
    }
}
export const getLeaveRequestChart = (filters) => {
    return async (dispatch) => {
        // const dataEmptySelectors = Object.values(filters[1]).every(filter => (
        //     Array.isArray(filter) ? filter.length === 0 : filter === null || filter === undefined || filter === ""
        // ));
        const dataEmptySelectors = Object.entries(filters[0]).every(([key, value]) => {
            if (key === 'year' && value === filters[1]) {
                return true;
            }
            if (Array.isArray(value)) {
                return value.length === 0;
            }
            return value === null || value === undefined || value === "";
        });
        if (dataEmptySelectors) {
            dispatch(dashboardActions.loaderStart("LeaverequestchartLoader"));
        }
        try {
            await Api.post('/user/reports/leave-requests-chart', filters[0]).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.leaveRequestChartSuccess(res.data))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.leaveRequestChartFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.leaveRequestChartFailure("Network Error"));
        }
    }
}

export const getActivitesChart = (filters) => {
    return async (dispatch) => {
        // dispatch(dashboardActions.loaderStart("ActivitychartLoader"))
        const dataEmptySelectors = Object.values(filters).every(filter => (
            Array.isArray(filter) ? filter.length === 0 : filter === null || filter === undefined || filter === ""
        ));
        if (dataEmptySelectors) {
            dispatch(dashboardActions.loaderStart("ActivitychartLoader"));
        }

        try {
            await Api.post('/user/reports/activities-chart', filters).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.salesActivitiesChartSuccess(res.data))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.salesActivitiesChartFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.salesActivitiesChartFailure("Network Error"));
        }
    }
}

export const getIsalesUsers = () => {
    return async (dispatch) => {
        dispatch(dashboardActions.loaderStart())
        try {
            await Api.get('user/reports/isales-users').then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.IsalesUsersListSuccess(res.data.usersList))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.IsalesUsersListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.IsalesUsersListFailure("Network Error"));
        }
    }
}

export const fetchIsalesChartData = (filters) => {
    return async (dispatch, getState) => {
        // dispatch(dashboardActions.loaderStart())
        const { dashboard } = getState();

        if (dashboard.getIsalesChart) {
            return;
        }
        // dispatch(dashboardActions.loaderStart("getIsalesChart"))
        // dispatch(dashboardActions.loaderStart("isalesLoader"))
        
        const dataEmptySelectors = Object.entries(filters[0]).every(([key, value]) => {
            if (key === 'year' && value === filters[1]) {
                return true;
            }
            if (Array.isArray(value)) {
                return value.length === 0;
            }
            return value !== null || value !== undefined || value !== "";
        });
        if (dataEmptySelectors) {
            dispatch(dashboardActions.loaderStart("getIsalesChart"));
        }
        if (dataEmptySelectors) {
            dispatch(dashboardActions.loaderStart("isalesLoader"));
        }
        try {
            await Api.post('/user/reports/isales-chart', filters[0]).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.IsalesChartDataSuccess(res.data))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.IsalesChartDataFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.IsalesChartDataFailure("Network Error"));
        }
    }
}

export const getTeamMembersList = () => {
    return async (dispatch) => {
        dispatch(dashboardActions.loaderStart())
        try {
            await Api.get('/user/team-members-list').then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.teamMembersListSuccess(res.data.list))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.teamMembersListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.teamMembersListFailure("Network Error"));
        }
    }
}


export const fetchEfficiencyChart = (filters) => {
    return async (dispatch) => {
        // dispatch(dashboardActions.loaderStart("EfficiencychartLoader"))
        const dataEmptySelectors = Object.values(filters[1]).every(filter => (
            Array.isArray(filter) ? filter.length === 0 : filter === null || filter === undefined || filter === ""
        ));
        if (dataEmptySelectors) {
            dispatch(dashboardActions.loaderStart("EfficiencychartLoader"));
        }

        try {
            await Api.post('/user/reports/daily-task-report', filters[0]).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.fetchEfficiencyChartDataSuccess(res.data))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.fetchEfficiencyChartDataFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.fetchEfficiencyChartDataFailure("Network Error"));
        }
    }
}

export const getRegionsList = () => {
    return async (dispatch) => {
        dispatch(dashboardActions.loaderStart(""))
        try {
            await Api.get('/region/search').then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.regionSearchSuccess(res.data.regionList))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.regionSearchFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.regionSearchFailure("Network Error"));
        }
    }
}

export const getBusinessTypesList = () => {
    return async (dispatch) => {
        dispatch(dashboardActions.loaderStart(""))
        try {
            await Api.get('/business-types/search').then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.businessTypesDataSuccess(res.data.businessTypesList))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.businessTypesDataFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.businessTypesDataFailure("Network Error"));
        }
    }
}
// 

export const getLeadStagesList = () => {
    return async (dispatch) => {
        dispatch(dashboardActions.loaderStart(""))
        try {
            await Api.get("/leads/stages/opportunities").then((res) => {
                    if (res.data.status === "success") {
                        dispatch(dashboardActions.leadStagesListSuccess(res.data))
                    }
                    if (res.data.status === "failure") {
                        dispatch(dashboardActions.leadStagesListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            dispatch(dashboardActions.leadStagesListFailure("Network Error"));
        }
    }
}
 
    //////////// state reenue chart //////////////
export const fetchStatesChart = (filters) => {
    return async (dispatch) => {
        // dispatch(dashboardActions.loaderStart("StateChartWiseLoader"))
        const dataEmptySelectors = Object.values(filters).every(filter => (
            Array.isArray(filter) ? filter.length === 0 : filter === null || filter === undefined || filter === ""
        ));
        if (dataEmptySelectors) {
            dispatch(dashboardActions.loaderStart("StateChartWiseLoader"));
        }
        try {
            await Api.post('/user/reports/states-chart',filters).then((res) => {
                if (res.data.status === "success") {
                    dispatch(dashboardActions.StateWiseRevenueSuccess(res.data))
                }
                if (res.data.status === "failure") {
                    dispatch(dashboardActions.StateWiseRevenueFailure(res.data.msg))
                }
            })
        }
        catch (err) {
            dispatch(dashboardActions.StateWiseRevenueFailure("Network Error"));
        }
    }
}
/// techie Clock time hr chart /////////
export const fetchTechieClockTimeChart = (filters) => {
    return async (dispatch) => {
        // dispatch(dashboardActions.loaderStart("techieClocktimeLoader"))
        const dataEmptySelectors = Object.values(filters[1]).every(filter => (
            Array.isArray(filter) ? filter.length === 0 : filter === null || filter === undefined || filter === ""
        ));
        if (dataEmptySelectors) {
            dispatch(dashboardActions.loaderStart("techieClocktimeLoader"));
        }
        try {
            await Api.post('/user/techie-charts/techie-meetings-chart',filters[0]).then((res) => {
                if (res.data.status === "success") {
                    dispatch(dashboardActions.TechieClocktimeSuccess(res.data))
                }
                if (res.data.status === "failure") {
                    dispatch(dashboardActions.TechieClocktimeFailure(res.data.msg))
                }
            })
        }
        catch (err) {
            dispatch(dashboardActions.TechieClocktimeFailure("Network Error"));
        }
    }
}

export default dashboardSlice.reducer;