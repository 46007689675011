import { createSlice } from "@reduxjs/toolkit";
import Api  from './axios';
import { toast } from 'react-toastify';
var userObj = JSON.parse(localStorage.getItem("user")) || null

const intialAuthState = {  
    isFetching:false,
    error: false,
    errorPayload : false,
    user:userObj,
    opportunitiesList: [],
    techieOpportunitiesList:[],
    leadsListCount: 0,
    page: 0,
    rowsPerPage: 20,
    columnsList: [],
    msg: "",
    subPartnersList: [],
    rowId:"",
    RowMetaData:[],
    showUpdateLeadModal: false,
    demosList: [],
    benchmarksList: [],
    trainingsList : [],
    showOpportunityModal: false,
    opportuntieslistLoader:false,
    createNewOpportunityModal : false,
    opportunitiesYearFilter:2024,
    activeTab : 2,
    techTeamManagersList: [],
    techiesListByManager: []
}

const opportunitiesSlice = createSlice({
    name: 'opportunities',
    initialState: intialAuthState,
    reducers: {
        loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
          },
          loaderStop(state, action) {
            state.isFetching = false;
            state.msg = "Fetching...";
          },

          opportunitiesListSuccess(state, action) {
            state.opportuntieslistLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.opportunitiesList = action.payload;
            // toast.success("contact list data") 
            // toast.success(action.payload.status) 
          },
          opportunitiesListFailure(state, action) {
            state.opportuntieslistLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.opportunitiesList = [];
             toast.error(" Error in opportunitiesList")
          },
          techieOpportunitiesSuccess(state,action){
            state.opportuntieslistLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.techieOpportunitiesList = action.payload;
          },
          techieOpportunitiesFailure(state, action) {
            state.opportuntieslistLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.techieOpportunitiesList = [];
             toast.error(" Error in opportunitiesList")
          },
        ansysAccountManagersListSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.ansysAccountManagers = action.payload
        },
        ansysAccountManagersListFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.ansysAccountManagers = []
        },
        opportunitiesColumnsListSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.columnsList = action.payload
        },
        opportunitiesColumnsListFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.columnsList = []
        },
        opportunitiesColumnsUpdateSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.msg = action.payload
        },
        opportunitiesColumnsUpdateFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.msg = null
        },
        setRowId(state, action) {
            state.rowId = action.payload;
          },
          OpportunitiesFilteredYear(state,action){
            state.opportunitiesYearFilter = action.payload;
          },
          setRowMetaData(state, action) {
            state.RowMetaData = action.payload;
          },
        updateViewRowDetailsData(state, action){
            state.viewRowMeta = action.payload.meta
            state.viewRowId = action.payload.rowId  
            state.viewAccountId = action.payload.accountId
            state.showOpportunityModal = true
        },
        opportunityDetailsListSuccess(state, action){
            state.opportunityDetails = action.payload.opportunityDetails
            state.leadDetail = action.payload.leadDetail
            state.meetingsList = action.payload.meetingsList
            state.contactsList = action.payload.contactsList
            state.opportunitiesTasks = action.payload.opportunitiesTasks
            state.chartData = action.payload.chartData
        },
        opportunityDetailsListFailure(state, action){
            state.opportunityDetails = {}
            state.leadDetail = []
            state.meetingsList = []
            state.contactsList = []
            state.opportunitiesTasks = []
            state.chartData = {}
        },
        demosListSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.demosList = action.payload
            state.msg = null
        },
        demosListFailure(state, action){
            state.isFetch = false
            state.error = true
            state.demosList = []
            state.msg = null
        },
        benchmarksListSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.benchmarksList = action.payload
            state.msg = null
        },
        benchmarksListFailure(state, action){
            state.isFetch = false
            state.error = true
            state.benchmarksList = []
            state.msg = null
        },
        trainingsListSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.trainingsList = action.payload
            state.msg = null
        },
        trainingsListFailure(state, action){
            state.isFetch = false
            state.error = true
            state.trainingsList = []
            state.msg = null
        },
        updateOpportunityModal(state, action){
            state.showOpportunityModal = action.payload
            // if(action.payload === false){
            //     state.opportunityDetails.multi_products = {}
            // }
        },
        createNewOpportunity(state, action){
            state.createNewOpportunityModal = action.payload
            state.opportunityDetails = null
            state.leadDetail = []
            state.meetingsList = []
            state.contactsList = []
            state.opportunitiesTasks = []
            state.chartData = {}
        },
        opportunityCreateSuccess(state, action){
            state.createNewOpportunityModal = false
            state.isFetch = false
            state.error = false
            state.msg = null
        },
        opportunityCreateFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.msg = null
        },
        updateColumnsList(state, action){
            state.columnsList = action.payload
        },
        updateActiveTab(state, action){
            return {
                ...state, // Spread the existing state to create a new object
                activeTab: action.payload // Update the activeTab property
            };
        },
        techTeamManagersListSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.techTeamManagersList = action.payload
            state.msg = null
        },
        techTeamManagersListFailure(state, action){
            state.isFetch = false
            state.error = false
            state.techTeamManagersList = []
            state.msg = null
        },
        techiesByManagerListSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.techiesListByManager = action.payload
            state.msg = null
        },
        techiesByManagerListFailure(state, action){
            state.isFetch = false
            state.error = true
            state.techiesListByManager = []
            state.msg = null
        }
    }
})

export const opportunitiesActions = opportunitiesSlice.actions;


export const getOpportunitiesList =  (opportunity_id=null) => {
    return async (dispatch) => {
        dispatch(opportunitiesActions.loaderStart("opportuntieslistLoader"))
        try {
            var url = ""
            var userData = JSON.parse(localStorage.getItem("user"))
            if(opportunity_id){
                url = "/opportunities/view-individual/"+opportunity_id
            }else{
                url = "/opportunities/"+userData?.user._id
            }
            await Api.get(url).then((res) => {
                if (res.data.status === "success") {
                    dispatch(opportunitiesActions.opportunitiesListSuccess(res.data.opportunities))
                }
                if (res.data.status === "failure") {
                    toast.error(res.data.msg)
                    dispatch(opportunitiesActions.opportunitiesListFailure(res.data.msg))
                }
            })
        }
        catch (err) {
            toast.error("Network Error!")
            dispatch(opportunitiesActions.opportunitiesListFailure("Network Error"));
        }
    }
  };
  export const getTechieOpportunitiesList =  () => {
    return async (dispatch) => {
        dispatch(opportunitiesActions.loaderStart("opportuntieslistLoader"))
        try {
            await Api.get("/opportunities/techie/opportunities/list").then((res) => {
                    if (res.data.status === "success") {
                        dispatch(opportunitiesActions.techieOpportunitiesSuccess(res.data.opportunities))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(opportunitiesActions.techieOpportunitiesFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error!")
            dispatch(opportunitiesActions.techieOpportunitiesFailure("Network Error"));
        }
    }
  };

  export const getAnsysAccountManagersList =  () => {
    return async (dispatch) => {
        dispatch(opportunitiesActions.loaderStart())
        try {
            await Api.get("/ansys-account-managers/search").then((res) => {
                    if (res.data.status === "success") {
                        dispatch(opportunitiesActions.ansysAccountManagersListSuccess(res.data.AnsysAccountManagers))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(opportunitiesActions.ansysAccountManagersListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error!")
            dispatch(opportunitiesActions.ansysAccountManagersListFailure("Network Error"));
        }
    }
  };

  export const getColumnsList = () => {
    return async (dispatch) => {
        dispatch(opportunitiesActions.loaderStart())
        try {
            var userData = JSON.parse(localStorage.getItem("user"))
            await Api.get("/columns-hide-show/list/opportunities/" + userData?.user?._id).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(opportunitiesActions.opportunitiesColumnsListSuccess(res.data.columnsList?.columns_list))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(opportunitiesActions.opportunitiesColumnsListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error!")
            dispatch(opportunitiesActions.opportunitiesColumnsListFailure("Network Error"));
        }
    }
  }

  export const updateColumns = (data) => {
    return async (dispatch) => {
        dispatch(opportunitiesActions.loaderStart())
        try {
            await Api.post("columns-hide-show/create", data).then((res) => {
                    if (res.data.status === "success") {
                        toast.success("Columns Updated!")
                        dispatch(opportunitiesActions.opportunitiesColumnsUpdateSuccess("Columns Updated"))
                        dispatch(opportunitiesActions.updateColumnsList(data.columns_list))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(opportunitiesActions.opportunitiesColumnsUpdateFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error!")
            dispatch(opportunitiesActions.opportunitiesColumnsUpdateFailure("Network Error"));
        }
    }
  }

  export const updateViewRowDetails = (data) => {
    return async (dispatch) => {
        dispatch(opportunitiesActions.loaderStart())
        // dispatch(getEconomicBuyersList(data.accountId))
        // dispatch(getPersonsList(data.accountId))       
        dispatch(opportunitiesActions.updateViewRowDetailsData(data))
        try {
            // await Api.get("/opportunities/details/"+data.rowId).then((res) => {
                await Api.post("/opportunities/details",data).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(opportunitiesActions.opportunityDetailsListSuccess(res.data))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(opportunitiesActions.opportunityDetailsListFailure(res.data.msg))
                    }
                })
        }catch (err) {
            toast.error("Network Error")
            dispatch(opportunitiesActions.opportunityDetailsListFailure("Network Error"))
        }
    }
}

export const addSalesActivityDetails = (data) => {
    return async (dispatch) => {
        dispatch(opportunitiesActions.loaderStart())
        try {
            await Api.post("opportunities/add-sales-meeting-activity", data).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(opportunitiesActions.updateOpportunityModal(false))
                        toast.success("New Meeting Added!")
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                    }
                })
        }catch (err) {
            toast.error("Network Error")
        }
    }
}

export const addNewOpportunityTask  = (data) => {
    return async (dispatch) => {
        dispatch(opportunitiesActions.loaderStart())
        try {
            await Api.post("opportunities/add-opportunity-task", data).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(opportunitiesActions.updateOpportunityModal(false))
                        toast.success("New Task Added Successfully!")
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                    }
                })
        }catch (err) {
            toast.error("Network Error")
        }
    }
}

export const updateForecast = (data) => {
    return async (dispatch) => {
        dispatch(opportunitiesActions.loaderStart())
        try {
            var userData = JSON.parse(localStorage.getItem("user"))
            await Api.post("opportunities/update-forecast-details", data).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(opportunitiesActions.updateOpportunityModal(false))
                        toast.success("Forecast details updated successfully!")
                        if(userData?.user?.user_role_id?.role_name === "techie"){
                            dispatch(getTechieOpportunitiesList())
                        }else{
                            dispatch(getOpportunitiesList())
                        }
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                    }
                })
        }catch (err) {
            toast.error("Network Error")
        }
    }
}

export const UpdateOpportunityHandleDetails = (data) => {
    return async (dispatch) => {
        dispatch(opportunitiesActions.loaderStart())
        try {
            await Api.post("opportunities/update", data).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(opportunitiesActions.updateOpportunityModal(false))
                        toast.success("Successfully Updated!")
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                    }
                })
        }catch (err) {
            toast.error("Network Error")
        }
    }
}

export const getDemosListData = (data) => {
    return async (dispatch) => {
        dispatch(opportunitiesActions.loaderStart())
        try {
            await Api.post("/demos/list", data).then((res) => {
                    if (res.data.status === "success") {
                        opportunitiesActions.demosListSuccess(res.data.demosList)
                    }
                    if (res.data.status === "failure") {
                        opportunitiesActions.demosListFailure(res.data.msg)
                        toast.error(res.data.msg)
                    }
                })
        }catch (err) {
            opportunitiesActions.demosListFailure("Network Error")
            toast.error("Network Error")
        }
    }
}

export const getBenchmarksList = (data) => {
    return async (dispatch) => {
        dispatch(opportunitiesActions.loaderStart())
        try {
            await Api.post("/benchmarks/list", data).then((res) => {
                    if (res.data.status === "success") {
                        opportunitiesActions.benchmarksListSuccess(res.data.benchmarksList)
                    }
                    if (res.data.status === "failure") {
                        opportunitiesActions.benchmarksListFailure(res.data.msg)
                        toast.error(res.data.msg)
                    }
                })
        }catch (err) {
            opportunitiesActions.benchmarksListFailure("Network Error")
            toast.error("Network Error")
        }
    }
}

export const getTrainingsList = (data) => {
    return async (dispatch) => {
        dispatch(opportunitiesActions.loaderStart())
        try {
            await Api.post("/trainings/list", data).then((res) => {
                    if (res.data.status === "success") {
                        opportunitiesActions.trainingsListSuccess(res.data.trainingsList)
                    }
                    if (res.data.status === "failure") {
                        opportunitiesActions.trainingsListFailure(res.data.msg)
                        toast.error(res.data.msg)
                    }
                })
        }catch (err) {
            opportunitiesActions.trainingsListFailure("Network Error")
            toast.error("Network Error")
        }
    }
}

export const createNewOpportunity = (data) => {
    return async (dispatch) => {
        dispatch(opportunitiesActions.loaderStart())
        try {
            await Api.post("/opportunities/create", data).then((res) => {
                    if (res.data.status === "success") {
                        toast.success("Successfully created new opportunity!")
                        opportunitiesActions.opportunityCreateSuccess(res.data)
                        opportunitiesActions.createNewOpportunity(false)
                        dispatch(getOpportunitiesList())
                    }
                    if (res.data.status === "failure") {
                        opportunitiesActions.opportunityCreateFailure(res.data.msg)
                        toast.error(res.data.msg)
                    }
                })
        }catch (err) {
            opportunitiesActions.opportunityCreateFailure("Network Error")
            toast.error("Network Error")
        }
    }
}

export const getTechTeamManagers = (data) => {
    return async (dispatch) => {
        dispatch(opportunitiesActions.loaderStart())
        try {
            await Api.get("/opportunities/tech-team-managers/list", data).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(opportunitiesActions.techTeamManagersListSuccess(res.data.techiesList))
                    }
                    if (res.data.status === "failure") {
                        dispatch(opportunitiesActions.techTeamManagersListFailure(res.data.msg))
                        toast.error(res.data.msg)
                    }
                })
        }catch (err) {
            dispatch(opportunitiesActions.techTeamManagersListFailure("Network Error"))
            toast.error("Network Error")
        }
    }
}

export const getTechiesListByManager = (data) => {
    return async (dispatch) => {
        dispatch(opportunitiesActions.loaderStart())
        try {
            await Api.post("/user/manager-techies-list", data).then((res) => {
                    if (res.data.status === "success") {
                        dispatch(opportunitiesActions.techiesByManagerListSuccess(res.data.techiesList))
                    }
                    if (res.data.status === "failure") {
                        dispatch(opportunitiesActions.techiesByManagerListFailure(res.data.msg))
                        toast.error(res.data.msg)
                    }
                })
        }catch (err) {
            dispatch(opportunitiesActions.techiesByManagerListFailure("Network Error"))
            toast.error("Network Error")
        }
    }
}

export const reAssignTechie = (data) => {
    
    return async (dispatch) => {
        dispatch(opportunitiesActions.loaderStart())
        try {
            await Api.post("/opportunities/assign-to-techie", data).then((res) => {
                    if (res.data.status == "success") {
                        toast.success('Successfully assigned!')
                        // dispatch(opportunitiesActions.techiesByManagerListSuccess(res.data.techiesList))
                    }
                    if (res.data.status === "failure") {
                        // dispatch(opportunitiesActions.techiesByManagerListFailure(res.data.msg))
                        toast.error(res.data.msg)
                    }
                })
        }catch (err) {
            // dispatch(opportunitiesActions.techiesByManagerListFailure("Network Error"))
            toast.error("Network Error")
        }
    }
}

export default opportunitiesSlice.reducer;