import React, { useState, useEffect, useRef } from "react";
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-cartesian';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Badge from 'react-bootstrap/Badge'
import { AiOutlineBarChart } from 'react-icons/ai';
import { BsArrowsFullscreen } from 'react-icons/bs';
import {SelectYear, ProductFamilyFilter, StartDate, EndDate, AccountMangers, QuartersFilter, MonthlyFilter,ProductsFilter, SessionFilter,CourseFilter,  config} from './chartConfig'
import moment from "moment";
import { toast } from 'react-toastify';
import {getProductsList, getProductFamilyList, fetchPreSalesChartData} from "../../store/dashboard";
import { useDispatch, useSelector, connect } from "react-redux";
import { useMemo } from "react";
import { toPng } from 'html-to-image'

const PlotlyComponent = createPlotlyComponent(Plotly);

const PreSalesReport = React.memo(({productsList, productFamilyList, preSalesChartData, yearsList, courseTypeList, sessionsTypeList, getProductsListData, getProductFamilyListData, fetchPreSalesChartDataList,PresalesLoader}) => {
// console.log("productFamilyList",productFamilyList)
  // const [techieList, setTechieList] = useState([]);
  // const [productsList, setProductsList] = useState([]);
  // const [requestedbyList, setRequestedbyList] = useState([]);
  // const [filterTechieList, setFilterTechieList] = useState([]);
  // const [filterProductsList, setFilterProductsList] = useState([]);
  // const [filterRequestedbyList, setFilterRequestedbyList] = useState([]);
  // const [filterSessionTypeList, setFilterSessionTypeList] = useState([]);
  // const [filterCourseTypeList, setFilterCourseTypeList] = useState([]);
  // const [tableHeight, setTableHeight] = useState("310px");
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [filterDisplay, setFilterDisplay] = useState(true);
  const [columnsDisplay, setColumnsDisplay] = useState(true);
  const [showChart, setShowChart] = useState(true);
  const handle = useFullScreenHandle();
  // var nf = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 })
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  // const years = [{ value: 2021, label: 2021 }, { value: 2022, label: 2022 }, { value: 2023, label: 2023 }, { value: 2024, label: 2024 }]
  var  monthsList = [{ label: "All", value: "All" }, { label: 'January', value: 'January' }, { label: 'February', value: "February" }, { label: 'March', value: "March" }, { label: 'April', value: "April" }, { label: 'May', value: "May" }, { label: 'June', value: "June" }, { label: 'July', value: "July" }, { label: 'August', value: "August" }, { label: 'September', value: "September" }, { label: 'October', value: "October" }, { label: 'November', value: "November" }, { label: 'December', value: "December" }]
  const quartersList = [{ value: "all", label: "Annual" }, { value: 'Q1', label: 'Q1' }, { value: 'Q2', label: "Q2" }, { value: 'Q3', label: 'Q3' }, { value: 'Q4', label: 'Q4' }]
  
  // const courseTypeList = 
  var defaultMonth = { label: "All", value: false };

  var deYear = moment().format('YY');
  var deYearName = moment().format('YYYY')
  var defaultYear = {value: parseInt(deYear), label:deYearName}

  const currentyear = moment().year();

  
  // const dispatch = useDispatch();
  // const {  } = useSelector(state => state.dashboard);


  const [filter, setFilter] = useState({});
  console.log("PreSalesReportNew",filter)
  // const [productFamilyList, setProductFamilyList] = useState([]);
  // const getTechieList = async () => {
  //   const res = await axios.get("/user/techies-list");
  //   setTechieList(res.data.techiesList)
  // }
  // const  = async () => {
 
  // }
  // const getRequestedbyList = async () => {
  //   const res = await axios.get("/user/techies-list");
  //   setRequestedbyList(res.data.techiesList)
  // }
  // const getProductFamilyList = async () => {
    
  // }


  const chartButtonClicked = async () => {

    setShowChart(true);
    if (!columnsDisplay) {
      handle.enter()
    }
  }

  // const [data, setData] = useState([]);

  // var techiesList = filterTechieList.map(item => {
  //   return item.value;
  // });

  // var products_list = filterProductsList.map(item => {
  //   return item.value;
  // });

  // var requestedby_list = filterRequestedbyList.map(item => {
  //   return item.value;
  // });

  // var session_type = filterSessionTypeList.map(item => {
  //   return item.value;
  // });

  // var course_type = filterCourseTypeList.map(item => {
  //   return item.value;
  // });

  const getPreSalesChartData = async () => {

    var products = []
    var sessions = []
    var courses = []
    var yearvalue="";
    let quatervalue;
    let monthvalue;

    if(filter.products && filter.products.length>0){
      products = filter.products.map(item => {
        return item.value
      })
    }
    // if(filter.year && filter.year.length>0){
    //   yearvalue = filter.year.map(item => {
    //     return item.value
    //   })
    // }
     if(filter.year){
      yearvalue = filter.year.value
    }
// console.log("q value is",filter?.quarter)
    // if(filter.quarter && filter.quarter.length>0){
    //   quatervalue = filter?.quarter?.map(item => {
    //     return item.value
    //   })
    // }
    if(filter.sessions_list && filter.sessions_list.length >0){
      sessions = filter.sessions_list.map(item => {
        return item.value
      })
    }

    if(filter.courses_list && filter.courses_list.length > 0){
      courses = filter.courses_list.map(item => {
        return item.value
      })
    }
    
  
  if(!filter.from_date && !filter.to_date){
    filter.from_date = moment().startOf('month').format("YYYY-MM-DD")
    filter.to_date = moment().endOf('month').format("YYYY-MM-DD")
  }

  
// Quater //
  if (filter?.quarter === "Q1") {
    quatervalue = 1;
  } else if (filter?.quarter === "Q2") {
    quatervalue = 2;
  } else if (filter?.quarter === "Q3") {
    quatervalue = 3;
  } else if (filter?.quarter === "Q4") {
    quatervalue = 4;
  } else {
    quatervalue = null;
  }
// Months//
    if (filter?.month === "All") {
      monthvalue = false;
    }if (filter?.month === "January") {
    monthvalue = 1;
  } else if (filter?.month === "February") {
    monthvalue = 2;
  } else if (filter?.month === "March") {
    monthvalue = 3;
  } else if (filter?.month === "April") {
    monthvalue = 4;
  }else if (filter?.month === "May") {
    monthvalue = 5;
  }else if (filter?.month === "June") {
    monthvalue = 6;
  }else if (filter?.month === "July") {
    monthvalue = 7;
  }else if (filter?.month === "August") {
    monthvalue = 8;
  }else if (filter?.month === "September") {
    monthvalue = 9;
  } else if (filter?.month === "October") {
    monthvalue = 10;
  }else if (filter?.month === "November") {
    monthvalue = 11;
  } else if (filter?.month === "December") {
    monthvalue = 12;
  } else {
    monthvalue = null;
  }
  
  if(filter.year || filter.month || filter.quarter){
    delete filter.from_date;
    delete filter.to_date;
    filter.from_date = undefined
    filter.to_date = undefined
   }

   
    const filteredData={
      year : yearvalue?yearvalue:currentyear,
      month : monthvalue,
      quarter : quatervalue,
      products_list: products,
      session_type: sessions,
      course_type: courses,
      from_date: filter.from_date,
      to_date: filter.to_date,
      product_family : filter.product_family?.value
    }
/// only for Loader purpose///
    const DemoData={
      year : yearvalue,
      month : monthvalue,
      quarter : quatervalue,
      products_list: products,
      session_type: sessions,
      course_type: courses,
      // from_date: filter.from_date,
      // to_date: filter.to_date,
      product_family : filter.product_family?.value
    }
/// only for Loader purpose///

    fetchPreSalesChartDataList([filteredData,DemoData])    // const res = await axios.post("",filteredData)
    
    // if (res.data.status === "success") {
    //   setData(res.data.data);
    // }
  }

  
  const containerRef = useRef(null); 
const downloadContent = () => {
const container = containerRef.current

toPng(container)
.then(function(dataUrl) {
  const link = document.createElement('a')
  link.download = 'Presaleschart.png';
  link.href = dataUrl;
  link.click();
})
.catch(function(error) {
  console.log("error:",error)
});

}


  useMemo(() => {
    console.log("filter change")
    getPreSalesChartData();
  }, [filter]);

  useMemo(() => {
    // getTechieList();
    getProductsListData();
    // getRequestedbyList();
    getProductFamilyListData();
    // getPreSalesChartData();
  }, [])

  const reportChange = ((state, handle) => {
    var preSalesChat = document.getElementById("preSalesChatComponents");
    if (preSalesChat) {
      preSalesChat = document.getElementById("preSalesChatComponents").getElementsByClassName("user-select-none")[0]
      preSalesChat.id = "preSalesChat";
    }
    if (state) {
      if (preSalesChat) {
        document.getElementById("preSalesChat").style.height = (window.innerHeight - 60).toString() + 'px';
      }
      // setTableHeight((window.innerHeight - 160).toString() + 'px');
      // setRowsPerPage(15);
      setColumnsDisplay(false);
      // setFilterDisplay(false);
    } else {
      if (preSalesChat) {
        document.getElementById("preSalesChat").style.height = "450px";
      }

      // setTableHeight('333px');
      // setRowsPerPage(10);
      setColumnsDisplay(true);
      // setFilterDisplay(true);
    }

  });
  // const removeFilterTechieListBadge = (value) => {
  //   var inputValue = value.target.getAttribute('value');
  //   const filterTechieLists = filterTechieList.filter(item => item.value !== inputValue);
  //   setFilterTechieList(filterTechieLists);
  // }
  
  const removequatervalueBadge = (value) => {
    var inputValue = value.target.getAttribute('value');
    console.log("yearbadge2", inputValue);
    var updatedQuarter = filter.quarter === inputValue ? "" : filter.quarter;
 
    setFilter({ ...filter, quarter: updatedQuarter });
  }
  
  const removeMonthrvalueBadge = (value) => {
    var inputValue = value.target.getAttribute('value');
    console.log("yearbadgemonth", inputValue);
    var updatedMonth = filter.month === inputValue ? "" : filter.month;
    setFilter({ ...filter, month: updatedMonth });
  }

  
  // const removeProduct_familyBadge = (value) => {
  //   var inputValue = value.target.getAttribute('value');
  //   console.log("yearProductFamilymonth", inputValue);
  //   var updatedproduct_family = filter.product_family === inputValue ? "" : filter.product_family;
  //   setFilter({ ...filter, product_family: updatedproduct_family });
  // }

  const removeYearProductListBadge = (value) => {
    var inputValue = value.target.getAttribute('value');
    // var Yearfilter = filter.year.filter(item => item.value.toString() !== inputValue.toString())
    var Yearfilter = filter.year.value.toString() === inputValue.toString() ? "" : filter.year.value;
    setFilter({...filter, year: Yearfilter})
  }


  const removeFilterProductListBadge = (value) => {
    var inputValue = value.target.getAttribute('value');
    var productfilter = filter.products.filter(item => item.value !== inputValue)
    setFilter({...filter, products: productfilter})
  }

  // const removeFilterRequestedListBadge = (value) => {
  //   var inputValue = value.target.getAttribute('value');
  //   const filterRequestedbyLists = filterRequestedbyList.filter(item => item.value !== inputValue);
  //   setFilterRequestedbyList(filterRequestedbyLists);
  // }

  const removeFilterSessionTypeBadge = (value) => {
    var inputValue = value.target.getAttribute('value');
    var sessionsfilter = filter.sessions_list.filter(item => item.value !== inputValue)
    setFilter({...filter, sessions_list: sessionsfilter})
  }

  const removeFilterCourseTypeBadge = (value) => {
    var inputValue = value.target.getAttribute('value');
    var coursesfilter = filter.courses_list.filter(item => item.value !== inputValue)
    setFilter({...filter, courses_list: coursesfilter})
  }

  const removeFilterproduct_familyTypeBadge = (value) => {
    var inputValue = value.target.getAttribute('value');
    console.log("yearbadge2", inputValue);
    var filterproduct_familyLists = filter.product_family.value === inputValue ? "" : filter.product_family.value;
    setFilter({ ...filter, product_family: filterproduct_familyLists });
  }
  const layout = {
    paper_bgcolor: '#fff ',
    font: {
      size: 12,
      color: '#6E8898'
    },
    margin: {
      l: 60,
      r: 60,
      b: 60,
      t: 60,

    },
    xaxis: {
      tickangle: 360,
      wrap:true
     },

    title: "Pre Sales",
    useResizeHandler: true,
    style: { width: "100%", height: "100%" },
    showlegend: true,
    legend: { "orientation": "h" },
  }

 const config = {
  responsive: true,
  displaylogo: false,
  showTips: true,
  pan2d: true,
  modeBarButtonsToRemove: ['sendDataToCloud', 'hoverClosestPie', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage'],
  modeBarButtonsToAdd: [
    {
      name: 'Download',
      icon: Plotly.Icons.camera, // Download icon
      click: function() {
        // Handle click on custom lock icon
        // alert('Custom Lock Icon Clicked!');
        toast.success("Downloaded Successfully!")
        // Download the chart as PNG
        downloadContent();
      },
      title: 'Download As Png'
    },

  ],
};





  return (
    <>
    {PresalesLoader ?  
      <div className="parent-div">
      <div className="loader"></div>
    </div>
   :
   <> 
   <div ref={containerRef}> 
      <FullScreen enabled={true} handle={handle} onChange={reportChange} >

        <div className="container-fluid py-2 px-2">
          <div className="row" style={{margin:'0px'}}>

            <div className="col-lg-12 col-md-12 col-12 mx-auto">
              <div className="row row_gutter">
                <div className="col-md-1 col-lg-1" style={{ width: '7%' }}>
                  <button onClick={chartButtonClicked}
                    className={showChart === true ? "tab1 active_tab1 " : "tab1 "}><AiOutlineBarChart className={showChart === true ? "common_chart_icons common_chart_icons11 " : "common_chart_icons "} /></button>
                </div>
                <div className="col-md-1 col-lg-1">
                  <button onClick={handle.enter} className={columnsDisplay === false ? "tab1 active_tab1 " : "tab1 "}>
                    <BsArrowsFullscreen className={columnsDisplay === false ? "common_chart_icons33 common_chart_icons22 " : "common_chart_icons33 "} />
                  </button>
                </div>
                {(userData.user_role_id.role_name !== "techie") ?
                <div className="col-md-2 col-lg-2" >
                      <SelectYear value={filter.year} years={yearsList}  disableYearFilter={false} updateFilter={ (value) => setFilter({ ...filter, year: value }) } />
                </div>
                : ""}

                <div className="col-md-2 col-lg-1">
                    <QuartersFilter values={filter.quarter} quartersList={quartersList}  disableQuarterFilter={false} updateFilter={ (value) => setFilter({ ...filter, quarter: value }) } />
                </div>

                <div className="col-md-2 col-lg-1" >
                    <MonthlyFilter defaultMonth={defaultMonth} values={filter.month} monthsList={monthsList} disableMonthFilter={false}  updateFilter={ (value) => setFilter({ ...filter, month: value }) } />
                </div>
                {(userData.user_role_id.role_name !== "techie") ?
                <div className="col-md-2 col-lg-2" >
                  <div>
                      <ProductsFilter values={filter.products}  disableFilter={false} updateFilter={(value) => setFilter({ ...filter, products: value }) }  productsList ={productsList} />
                  </div>
                </div>
                : ""}
                {(userData.user_role_id.role_name !== "techie") ?
                <div className="col-md-2 col-lg-1" >
                  <SessionFilter values={filter.sessions_list} disableFilter={false} updateFilter={(value) => setFilter({ ...filter, sessions_list: value }) }  sessionsList={sessionsTypeList}   />
                </div>
                : ""}
                {(userData.user_role_id.role_name !== "techie") ?
                <div className="col-md-2 col-lg-1" >
                      <CourseFilter values={filter.courses_list} disableFilter={false} updateFilter={(value) => setFilter({ ...filter, courses_list: value })  } courseTypeList={courseTypeList} />
                </div>
                : ""}
                <div className="col-md-2 col-lg-2" >
                  <ProductFamilyFilter values={filter.product_family}  selectSingle ={true} placeholder={"Product Family pre"} productFamilyList={productFamilyList} disableCategoryFilter={false}  updateFilter={ (value) => setFilter({ ...filter, product_family: value }) } />
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
          {/* {
            filter?.year?.map((filter) => (
              <Badge onClick={removeYearProductListBadge}
                pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
            ))
          } */}
         
          {
            filter.year ?
            filter.year && 
              <Badge onClick={removeYearProductListBadge}
              pill data={filter.year.value} value={filter.year.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter?.year?.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
          
          :
          <Badge 
          pill data={currentyear} value={currentyear} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{currentyear}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
            }

          {
            filter.quarter && 
            <Badge onClick={removequatervalueBadge}
                pill data={filter.quarter} value={filter.quarter} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.quarter}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
          }

          {
            filter.month&& 
            <Badge onClick={removeMonthrvalueBadge}
                pill data={filter.month} value={filter.month} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.month}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
          }
            {
                filter.products?.map((filter) => (
                  <Badge onClick={removeFilterProductListBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
            }

            {
                filter.sessions_list?.map((filter) => (
                  <Badge onClick={removeFilterSessionTypeBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
            }

            {
                filter.courses_list?.map((filter) => (
                  <Badge onClick={removeFilterCourseTypeBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
            }

            {
              filter.product_family && 
              <Badge onClick={removeFilterproduct_familyTypeBadge}
                  pill data={filter.product_family?.value} value={filter.product_family?.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.product_family?.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
            }

           
        
          </div>
        </div>

        {showChart ?
          <PlotlyComponent
            id="preSalesChatComponents"
            data={JSON.parse(JSON.stringify(preSalesChartData))}
            layout={layout}
            className="w-full h-full"
            config={config}
          />
          : <React.Fragment>
          </React.Fragment>}

      </FullScreen>
      </div>
      </>
    }
    </>
  )
});

const mapStateToProps = (state) => ({
  allReportsData : state.dashboard.allReportsData,
  productsList : state.dashboard.productsList, 
  productFamilyList : state.dashboard.productFamilyList, 
  preSalesChartData : state.dashboard.preSalesChartData, 
  yearsList : state.dashboard.yearsList, 
  courseTypeList : state.dashboard.courseTypeList, 
  sessionsTypeList : state.dashboard.sessionsTypeList,
  PresalesLoader:state.dashboard.PresalesLoader
});

const mapDispatchToProps = (dispatch) => ({
  fetchPreSalesChartDataList: (data) => dispatch(fetchPreSalesChartData(data)),
  getProductFamilyListData : () => dispatch(getProductFamilyList()),
  getProductsListData : () => dispatch(getProductsList())
});

export default connect(mapStateToProps, mapDispatchToProps)(PreSalesReport);