import React, {useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Navbar from '../home/Navbar';
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from 'react-bootstrap';

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ButtonMaterialUi from '@material-ui/core/Button';

import { getRegions, getAccountManagers, updateTargetsValues, getRegionTargetDetails } from "../../store/targets";

const AddTargets = () => {

    const dispatch = useDispatch();
    const {regionsList, accountManagersList, targetDetails,isFetching, msg}  = useSelector(state => state.targets);
    const [accountManager, setAccountManager] = useState();
    const [year, setYear] = useState(new Date().getFullYear());
    const [updated, setUpdated] = useState(false);


    console.log("isFetching", isFetching);

    // useEffect(()=> {
        const [regionTargets, setRegionTargets] = useState(     
        regionsList.reduce((acc, region) => {
            acc[region.value] = {
                q1New : 0,
                q1Renewal : 0,
                q2New: 0,
                q2Renewal : 0,
                q3New: 0,
                q3Renewal: 0,
                q4New: 0,
                q4Renewal: 0,
                totalNew : 0,
                totalRenewal : 0
            };
            return acc;
          }, {})
        );

        useEffect(() => {
            console.log("targetDetails", targetDetails)
            var obj = regionsList.reduce((acc, region) => {
                acc[region.value] = {
                    q1New : 0,
                    q1Renewal : 0,
                    q2New: 0,
                    q2Renewal : 0,
                    q3New: 0,
                    q3Renewal: 0,
                    q4New: 0,
                    q4Renewal: 0,
                    totalNew : 0,
                    totalRenewal : 0
                };
                return acc;
            }, {})
            obj["Q1"] = {new:0, renewel:0}
            obj["Q2"] = {new:0, renewel:0}
            obj["Q3"] = {new:0, renewel:0}
            obj["Q4"] = {new:0, renewel:0}
            obj["target"] = {new:0, renewel:0}
            if(targetDetails){
                Object.keys(targetDetails).forEach(element => {
                    obj[element] = targetDetails[element]
                })
                console.log("obj", obj)
            }
            console.log("obj", obj)
            setRegionTargets(obj)
        }, [targetDetails])

       
    // }, [regionsList])


    useEffect(() => {
      console.log("regionTargets", regionTargets)
    }, [regionTargets])
    
      
      const handleInputChange = (regionValue, field, value) => {
        
        // Split the field into quarter and status
        const quarter = field.substring(0, 2).toUpperCase();
        const status = field.substring(2, 15).toLowerCase();

        // Update the state using functional updates
        setRegionTargets((prevState) => {

          var state = Object.assign({}, prevState)
          const updatedRegion = {
            ...prevState[regionValue],
            [field]: value,
          };

          console.log("updated region ", updatedRegion);

          // Perform operations and update other variables in updatedRegion
          updatedRegion.totalNew = parseInt(updatedRegion.q1New) + parseInt(updatedRegion.q2New) + parseInt(updatedRegion.q3New) + parseInt(updatedRegion.q4New);
          updatedRegion.totalRenewal = parseInt(updatedRegion.q1Renewal) + parseInt(updatedRegion.q2Renewal) + parseInt(updatedRegion.q3Renewal) + parseInt(updatedRegion.q4Renewal);

          var sum  = 0
          for(const item in state){

            if (!["Q1", "Q2", "Q3", "Q4", "target"].includes(item)){
              
              // console.log("totalNew", state[item]["q1New"], field)
              if(state[item].hasOwnProperty('totalNew') && state[item][field]){
                // console.log("itemfield", state[item][field])
                var v = parseInt(state[item][field])
                if(v>0){
                  sum += v
                }
                
              }

            }

          }

          

          var obj = Object.assign({}, updatedRegion[quarter])

          console.log("after forloop", sum);
          sum += parseInt(updatedRegion[field])
          console.log("prev2", sum)

          console.log("object object", obj)
          var new1 = obj["new"] || 0
          var renewel = obj["renewel"] || 0
          if(status == "new"){

            new1 = sum
            
          }else if(status == "renewal"){
            
            renewel = sum
          }

          console.log("status", field, new1, renewel)
          obj["new"] = new1
          obj["renewel"] = renewel
          
          console.log('prev', obj)
          console.log('prev1', updatedRegion[field])

          return {
            ...prevState,
            [regionValue]: updatedRegion,
            [quarter] : obj
          };
        });
        console.log("region Targets", regionTargets)
        
      };

      // regionTargets[region.value]?.q1New + regionTargets[region.value]?.q2New + regionTargets[region.value]?.q3New + regionTargets[region.value]?.q4New
      const updateTargets = () => {
        if(!accountManager){
            alert("Please select Account Manager")
            return;
        }else if(!accountManager && !year){
          alert("Please select Account Manager and Year")
          return;
        }
        else if(!year){
          alert("Please select Year")
          return;
        }
        const data = {
            targets: regionTargets,
            user_id : accountManager,
            year : year
        }
        dispatch(updateTargetsValues(data))
        console.log("regionTargets", regionTargets);
      }

      useEffect(() => {
        if(accountManager){
            dispatch(getRegionTargetDetails(year, accountManager));
        }
      }, [year, accountManager])

      const changeYear = (e) => {
        setYear(e.target.value)
        
      }

      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        // setOpen(false);
      };


    useEffect(() => {
        dispatch(getRegions())
        dispatch(getAccountManagers())
    }, [])


    const onAccountManagerChange = (e) => {
        setAccountManager(e.target.value)
        console.log("value", e.target.value)
    }

    function calculateFormattedValue(regionTargets, regionValue) {
      const q1New = parseInt(regionTargets) || 0;
      const q1Renewal = parseInt(regionValue) || 0;
    
      const totalValue = (q1New + q1Renewal) / 10000000;
      const formattedValue = totalValue.toFixed(3) + ' Cr';
    
      return totalValue > 0 ? formattedValue : null;
    }

    return (

        <>
        {/* <Navbar value="setTargets" className="activity_list_navbar" /> */}
        <div className="main_demo_section d-flex flex-column">
          <div className="request_teche my-3">
            {/* <>
              <a href="/set-target-tree">
                <Button className="btn-sm navbarAddBtn btn_add_common">Set Target</Button>
              </a>
            </> */}
          </div>
          {/* <form onSubmit={setTargetHandleSubmit(fechData)}> */}
            <div className="container-fluid">
              <div className="row">
                <h4>Update Sales Targets<Button href="/assign-segments" className="btn-sm navbarAddBtn btn_add_common">Assign Segments</Button><br /></h4><br />
                <br />
                <div class="col-lg-5 col-md-8 col-sm-12 col-12">
                  <div className="row">
                    <div className="col-md-10 col-10 col-sm-10">
                      <TextField variant="outlined"
                        // {...targetRegister("year", {
                        // })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // value={targetData.year}
                        select
                        // helperText={targetErrors.year ? targetErrors.year.message : ''}
                        // error={Boolean(targetErrors.year)}
                        onChange={onAccountManagerChange}
                        required
                        type="text" className="form-control" name="account_manager" label="Account Manager"
                      >

                        {accountManagersList.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))
                        }
                      </TextField>

                    
                    </div>
                    {/* <div className="col-md-2 col-sm-2 col-2">

                      <button className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" id="submit">Submit</button>
                    </div> */}
                  </div>
                </div>

                <div class="col-lg-5 col-md-8 col-sm-12 col-12">
                  <div className="row">
                    <div className="col-md-10 col-10 col-sm-10">
                      <TextField variant="outlined"
                        // {...targetRegister("year", {
                        // })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // value={targetData.year}
                        defaultValue={new Date().getFullYear()}
                        select
                        // helperText={targetErrors.year ? targetErrors.year.message : ''}
                        // error={Boolean(targetErrors.year)}
                        onChange={changeYear}
                        required
                        type="text" className="form-control" name="year" label="Select Year"
                      >
                        {["2023", "2024", "2025"].map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))
                        }
                      </TextField>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br />
            <div className="container-fluid">
              <div className="row">
                <br />
                <div>
                <table>
                    <thead>
                        <tr>
                        <th>Region</th>
                        <th colSpan={3}>Quarter 1</th>
                        <th colSpan={3}>Quarter 2</th>
                        <th colSpan={3}>Quarter 3</th>
                        <th colSpan={3}>Quarter 4</th>
                        <th colSpan={4}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td></td>
                        <td>New</td>
                        <td>Renewal</td>
                        <td style={{ width: '70px' }}>Total</td>
                        <td>New</td>
                        <td>Renewal</td>
                        <td style={{ width: '70px' }}>Total</td>
                        <td>New</td>
                        <td>Renewal</td>
                        <td style={{ width: '70px' }}>Total</td>
                        <td>New</td>
                        <td>Renewal</td>
                        <td style={{ width: '70px' }}>Total</td>
                        <td>New</td>
                        <td>Renewal</td>
                        <td style={{ width: '70px' }}>Total</td>
                        </tr>
                        <tr>
                        <td>Total</td>
                        {["Q1", "Q2", "Q3", "Q4", "target"].map((region) => (
                            <>
                            
                            <td>
                                <input
                                    type="number"
                                    style={{width:'90px'}}
                                    value={regionTargets[region]?.new}
                                    onChange={(e) =>
                                    handleInputChange(region, "new", e.target.value)
                                    }
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    style={{width:'90px'}}
                                    value={regionTargets[region]?.renewel}
                                    onChange={(e) =>
                                    handleInputChange(region, "renewel", e.target.value)
                                    }
                                />
                            </td>

                            <td style={{ width: '70px' }}>{calculateFormattedValue(regionTargets[region]?.new, regionTargets[region]?.renewel)}</td>
                            </>
                        ))}
                        </tr>
                        {regionsList.map((region) => (
                        <tr key={region.value}>
                            <td>{region.label}</td>
                            <td>
                            <input
                                type="number"
                                style={{width:'90px'}}
                                value={regionTargets[region.value]?.q1New}
                                onChange={(e) =>
                                handleInputChange(region.value, "q1New", e.target.value)
                                }
                            />
                            </td>
                            <td>
                            <input
                                type="number"
                                style={{width:'90px'}}
                                value={regionTargets[region.value]?.q1Renewal}
                                onChange={(e) =>
                                handleInputChange(region.value, "q1Renewal", e.target.value)
                                }
                            />
                            </td>
                            <td style={{ width: '70px' }}>{calculateFormattedValue(regionTargets[region.value]?.q1New, regionTargets[region.value]?.q1Renewal)}</td>
                            <td>
                            <input
                                style={{width:'90px'}}
                                type="number"
                                value={regionTargets[region.value]?.q2New}
                                onChange={(e) =>
                                handleInputChange(region.value, "q2New", e.target.value)
                                }
                            />
                            </td>
                            <td >
                            <input
                                type="number"
                                style={{width:'90px'}}
                                value={regionTargets[region.value]?.q2Renewal}
                                onChange={(e) =>
                                handleInputChange(region.value, "q2Renewal", e.target.value)
                                }
                            />
                            </td>
                            <td style={{ width: '70px' }}>{calculateFormattedValue(regionTargets[region.value]?.q2New, regionTargets[region.value]?.q2Renewal)}</td>
                            <td>
                            <input
                                type="number"
                                style={{width:'90px'}}
                                value={regionTargets[region.value]?.q3New}
                                onChange={(e) =>
                                handleInputChange(region.value, "q3New", e.target.value)
                                }
                            />
                            </td>
                            <td>
                            <input
                                type="number"
                                style={{width:'90px'}}
                                value={regionTargets[region.value]?.q3Renewal}
                                onChange={(e) =>
                                handleInputChange(region.value, "q3Renewal", e.target.value)
                                }
                            />
                            </td>
                            <td style={{ width: '70px' }}>{calculateFormattedValue(regionTargets[region.value]?.q3New, regionTargets[region.value]?.q3Renewal)}</td>
                            <td>  
                            <input
                                type="number"
                                style={{width:'90px'}}
                                value={regionTargets[region.value]?.q4New}
                                onChange={(e) =>
                                handleInputChange(region.value, "q4New", e.target.value)
                                }
                            />
                            </td>
                            <td>
                            <input
                                type="number"
                                style={{width:'90px'}}
                                value={regionTargets[region.value]?.q4Renewal}
                                onChange={(e) =>
                                handleInputChange(region.value, "q4Renewal", e.target.value)
                                }
                            />
                            </td>
                            <td style={{ width: '70px' }}>{calculateFormattedValue(regionTargets[region.value]?.q4New, regionTargets[region.value]?.q4Renewal)}</td>
                            <td>
                            <input
                                type="number"
                                disabled={true}
                                style={{width:'90px'}}
                                value={regionTargets[region.value]?.totalNew}
                                onChange={(e) =>
                                handleInputChange(region.value, "totalNew", e.target.value)
                                }
                            />
                            </td>
                            <td>
                            <input
                                type="number"
                                disabled={true}
                                style={{width:'90px'}}
                                value={regionTargets[region.value]?.totalRenewal}
                                onChange={(e) =>
                                handleInputChange(region.value, "totalRenewal", e.target.value)
                                }
                            />
                            </td>
                            {calculateFormattedValue(regionTargets[region.value]?.totalNew, regionTargets[region.value]?.totalRenewal)}
                            {/* <td style={{ width: '70px' }}>{((parseInt() + parseInt())/10000000).toFixed(1)+' Cr'}</td> */}
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
                </div>
            </div>

            <div className="container">
                <br />
                <div className="row col-md-10">
                    <button className="btn btn-sm btn-primary" onClick={() => updateTargets()}>Submit</button>
                </div>
            </div>
  
            {/* <Snackbar
                severity="success"

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={isFetching}
                autoHideDuration={2000}
                onClose={handleClose}
                message={msg}
                action={
                    <React.Fragment>
                        <ButtonMaterialUi severity="success" color="success" size="small" onClick={handleClose}>
                        </ButtonMaterialUi>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            /> */}
          {/* </form> */}
          <br />
        </div>
      </>
    )

}

export default AddTargets;