import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "bootstrap/dist/css/bootstrap.css";
import { Provider } from 'react-redux';
import store from './store'

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>
    ,
    document.getElementById("root"));

     // <AuthContextProvider>
     
     // </AuthContextProvider>
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals


    // "start": "react-scripts --openssl-legacy-provider start",
    // "build": "react-scripts --openssl-legacy-provider build",


        // "start": "react-scripts --max_old_space_size=4096 start",
    // "build": "react-scripts --max_old_space_size=4096 build",

