import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import moment from "moment";
import { connect, useDispatch } from 'react-redux'
import {HrisActions} from "../../store/Hris"
import {createHrislist,fetchRegionlist} from "../../store/Hris"

const HrisaddModal = React.memo(({showModal,createlist,openshowModal,closemodal,regionList,getfetchRegionlist}) => { 
  console.log("regionList",regionList)

  const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [Relationtype, Setrelationtype] = useState([]);
    const[gendarList,setGendarList] =useState([]); 
    const [managerslist, setManagerslist] = useState([]);;
    const {
      register: RawdataupdateRegister,
      handleSubmit: contactHandleSubmit,
      setError: contactSetError,
      formState: { errors: rawdataupdateErrors },
    } = useForm();
    const {
      payslipregister,
      handleSubmit,
      formState: { errors: errors },
      setError: setActivityError,
      reset: reset,
    } = useForm();
    const [disable, setDisable] = React.useState(false);
  
    const [addHris, SetHrisAdd] = useState({
    //   user_id: "",
      first_name: "",
      last_name: "",
      email: "",
      job_title: "",
      company: "Kaizenat",
      phone: "",
      region:"",
      employee_id: "",
      date_of_join: "",
  
      manager_id: "",
      // managerslist:"",
      previous_company: "",
      no_of_yrs_of_experience: "",
      gender: "",
      DOB: "",
      blood_group: "",
      highest_education_qualification: "",
      current_address: "",
      permanent_address: "",
      personal_contact_number: "",
      personal_email_id: "",
      aadhar_number: "",
      PAN_number: "",
      emergency_contact_number: "",
      person_name: "",
      relation_with_employee: "",
      remarks: "",
      leave_balance: "",
      name_on_pass_book: "",
      salary_account_number: "",
      IFSC_code: "",
      branch_name: "",
      pf_uan_number:"",
      insurance_id:""
    });
  // console.log("manager id is", Hrisupdate.manager_id)
    
    const getrelationTypelist = () => {
      Setrelationtype([
        { label: "Spouse", value: "spouse" },
        { label: "Parents", value: "parents" },
        { label: "Brother", value: "brother" },
        { label: "Sister", value: "sister" },
      ]);
    };
  
    const getgendarlist = () => {
      setGendarList([
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" }
      ]);
    };
    const getUsers = async () => {
      try {
        const res = await axios.get("/user/list");
        if (res.data.status === "success") {
          setManagerslist(res.data.usersList);
        }
      } catch (error) {
        alert(error);
      }
    };
    useEffect(() => {
      getrelationTypelist();
      getgendarlist();
      getUsers();
      getfetchRegionlist()
    }, []);
  
    const HrisDataSubmitHandle = async () => {
         const activityformdata = {
      
        first_name: addHris.first_name,
        last_name: addHris.last_name,
        email: addHris.email,
        job_title: addHris.job_title,
        company: addHris.company,
        phone: addHris.phone,
        region:addHris.region,
        employee_id: addHris.employee_id,
        date_of_join: addHris.date_of_join,
        manager_id: addHris.manager_id,
        // managerslist:Hrisupdate.managerslist,
        previous_company: addHris.previous_company,
        no_of_yrs_of_experience: addHris.no_of_yrs_of_experience,
        gender: addHris.gender,
        DOB: addHris.DOB,
        blood_group: addHris.blood_group,
        highest_education_qualification:

        //   Hrisupdate.highest_education_qualification,
        // current_address: Hrisupdate.current_address,
        // permanent_address: Hrisupdate.permanent_address,
        // personal_contact_number: Hrisupdate.personal_contact_number,
        // personal_email_id: Hrisupdate.personal_email_id,
        // aadhar_number: Hrisupdate.aadhar_number,
        // PAN_number: Hrisupdate.PAN_number,
        // emergency_contact_number: Hrisupdate.emergency_contact_number,
        // person_name: Hrisupdate.person_name,
        // relation_with_employee: Hrisupdate.relation_with_employee,
        // remarks: Hrisupdate.remarks,
        // leave_balance: Hrisupdate.leave_balance,
        // name_on_pass_book: Hrisupdate.name_on_pass_book,
        // salary_account_number: Hrisupdate.salary_account_number,
        // IFSC_code: Hrisupdate.IFSC_code,
        // branch_name: Hrisupdate.branch_name,
        // pf_uan_number:Hrisupdate.pf_uan_number,
        // insurance_id:Hrisupdate.insurance_id

        addHris.highest_education_qualification,
        current_address: addHris.current_address,
        permanent_address: addHris.permanent_address,
        personal_contact_number: addHris.personal_contact_number,
        personal_email_id: addHris.personal_email_id,
        aadhar_number: addHris.aadhar_number,
        PAN_number: addHris.PAN_number,
        emergency_contact_number: addHris.emergency_contact_number,
        person_name: addHris.person_name,
        relation_with_employee: addHris.relation_with_employee,
        remarks: addHris.remarks,
        leave_balance: addHris.leave_balance,
        name_on_pass_book: addHris.name_on_pass_book,
        salary_account_number: addHris.salary_account_number,
        IFSC_code: addHris.IFSC_code,
        branch_name: addHris.branch_name,
        pf_uan_number:addHris.pf_uan_number,
        insurance_id:addHris.insurance_id

      };
      // console.log("adddhris",activityformdata)
      
      createlist(activityformdata)

      


    }
    const onInputchange = (e) => {
      SetHrisAdd({ ...addHris, [e.target.name]: e.target.value }, []);
    };
  

  return (
    <>

    <Modal
    show={() => openshowModal()}
    onHide={() => closemodal()}

    >
    <Modal.Header>
      <Modal.Title> HRIS </Modal.Title>
      <a
      onClick={() => dispatch(HrisActions.closeModal())}
        className="btn btn-sm btn-white btn-danger bottomBarButtons"
      >
        Cancel
      </a>
    </Modal.Header>
          <Modal.Body>
            <form onSubmit={contactHandleSubmit(HrisDataSubmitHandle)}>
              <div className="headerBand">
                <label>Professional Details </label>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="first_name"
                    label="First Name"
                    value={addHris.first_name}
                    onChange={(e) => onInputchange(e)}
                    required
                    size="small"
                    className="form-control"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="last_name"
                    label="Last Name"
                    value={addHris.last_name}
                    onChange={(e) => onInputchange(e)}
                    required
                    size="small"
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="employee_id"
                    label="Employee Id"
                    value={addHris.employee_id}
                    onChange={(e) => onInputchange(e)}
                    required
                    size="small"
                    className="form-control"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="email"
                    label="Official Email"
                    type="email"
                    value={addHris.email}
                    onChange={(e) => onInputchange(e)}
                    required
                    size="small"
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="job_title"
                    label="Desigination"
                    value={addHris.job_title}
                    onChange={(e) => onInputchange(e)}
                    required
                    size="small"
                    className="form-control"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    value={addHris.date_of_join}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    type="date"
                    className="form-control"
                    name="date_of_join"
                    label="Date Of Join"
                  ></TextField>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  {/* <TextField
                              variant="outlined"
                             name="managerslist"
                              label="Reporting Manager"
                              value={Hrisupdate.managerslist}
                              onChange={e => onInputchange(e)}
                              // required
                              size="small"
                              className="form-control"
                          /> */}
  
                  <TextField
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    select
                    name="manager_id"
                    label="Reporting Manager"
                    value={addHris.manager_id}
                    onChange={(e) => onInputchange(e)}
                    required
                    size="small"
                    type="text"
                    className="form-control"
                  >
                    {managerslist.map((option, manager_id) => (
                      <MenuItem
                        key={option.value}
                        selected={manager_id.toString() === option.value.toString()}
                        value={option.value}
                      >
                        {option.label}
                      
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="previous_company"
                    label="Most Recent Previous Company"
                    value={addHris.previous_company}
                    onChange={(e) => onInputchange(e)}
                    required
                    size="small"
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    value={addHris.no_of_yrs_of_experience}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    type="text"
                    className="form-control"
                    name="no_of_yrs_of_experience"
                    label="No.Of.Years.Of.Experience"
                  ></TextField>
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="phone"
                    label="Phone"
                    type="number"
                    value={addHris.phone}
                    onChange={(e) => onInputchange(e)}
                    required
                    size="small"
                    className="form-control"
                  />
                </div>
              </div>
              <br/>
              <div className="row">
              <div className="col-md-6">
              <TextField
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              select
              name="region"
              label="Region"
              value={addHris.region}
              onChange={(e) => onInputchange(e)}
              // required
              size="small"
              type="text"
              className="form-control"
            >
              {regionList?.map((option, manager_id) => (
                <MenuItem
                  key={option.value}
                  selected={manager_id.toString() === option.value.toString()}
                  value={option.value}
                >
                  {option.label}
                
                </MenuItem>
              ))}
            </TextField>

              </div>
              </div>
  
              <br />
  
              <div className="headerBand">
                <label>Personal Details </label>
              </div>
              <br />
  
              <div className="row">
                <div className="col-md-6">
                <TextField
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    select
                    name="gender"
                    label="Gender"
                    value={addHris.gender}
                    onChange={(e) => onInputchange(e)}
                    required
                    size="small"
                    type="text"
                    className="form-control"
                  >
                    {gendarList.map((option, gender) => (
                      <MenuItem
                        key={option.value}
                        selected={gender === option.value}
                        value={option.value}
                      >
                        {option.label}
                      
                      </MenuItem>
                    ))}
                  </TextField>
                  {/* <TextField
                    variant="outlined"
                    name="gender"
                    label="Gender"
                    value={Hrisupdate.gender}
                    onChange={(e) => onInputchange(e)}
                    required
                    size="small"
                    className="form-control"
                  /> */}
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    value={addHris.DOB}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    type="date"
                    className="form-control"
                    name="DOB"
                    label="D.O.B"
                  ></TextField>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="blood_group"
                    label="Blood Group"
                    value={addHris.blood_group}
                    onChange={(e) => onInputchange(e)}
                    required
                    size="small"
                    className="form-control"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="highest_education_qualification"
                    label="Highest Education Qualification"
                    value={addHris.highest_education_qualification}
                    onChange={(e) => onInputchange(e)}
                    required
                    size="small"
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    multiline
                    rows={3}
                    variant="outlined"
                    name="current_address"
                    label="Current Address"
                    value={addHris.current_address}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    required
                    className="form-control"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    multiline
                    rows={3}
                    variant="outlined"
                    name="permanent_address"
                    label="Permanent Address"
                    value={addHris.permanent_address}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    required
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    required
                    name="personal_contact_number"
                    label="Personal Contact Number"
                    type="number"
                    value={addHris.personal_contact_number}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    className="form-control"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    required
                    name="personal_email_id"
                    label="Personal Email Id"
                    value={addHris.personal_email_id}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    required
                    name="aadhar_number"
                    label="Aadhar Number"
                    type="number"
                    value={addHris.aadhar_number}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    className="form-control"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="PAN_number"
                    label="Pan Number"
                    size="small"
                    required
                    value={addHris.PAN_number}
                    onChange={(e) => onInputchange(e)}
                    className="form-control"
                  />
                </div>
              </div>
  
              <br />
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="emergency_contact_number"
                    label="Emergency Contact Number"
                    size="small"
                    type="number"
                    required
                    value={addHris.emergency_contact_number}
                    onChange={(e) => onInputchange(e)}
                    className="form-control"
                  />
                </div>
  
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="person_name"
                    label="Person Name"
                    size="small"
                    required
                    value={addHris.person_name}
                    onChange={(e) => onInputchange(e)}
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    select
                    name="relation_with_employee"
                    label="Relation With Employee"
                    value={addHris.relation_with_employee}
                    onChange={(e) => onInputchange(e)}
                    required
                    size="small"
                    type="text"
                    className="form-control"
                  >
                    {Relationtype.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="remarks"
                    label="Remarks"
                    size="small"
                    required
                    value={addHris.remarks}
                    onChange={(e) => onInputchange(e)}
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="leave_balance"
                    label="Leave Balance"
                    size="small"
                    // required
                    value={addHris.leave_balance}
                    onChange={(e) => onInputchange(e)}
                    className="form-control"
                  />
                </div>
              </div>
              <br />
  
              <div className="headerBand">
                <label>Bank Details </label>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    required
                    name="name_on_pass_book"
                    label="Name on passBook"
                    value={addHris.name_on_pass_book}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    className="form-control"
                  />
                </div>
                <br />
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="salary_account_number"
                    label="Salary Account Number"
                    type="number"
                    size="small"
                    required
                    value={addHris.salary_account_number}
                    onChange={(e) => onInputchange(e)}
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    required
                    name="IFSC_code"
                    label="Ifsc Code"
                    value={addHris.IFSC_code}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    className="form-control"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="branch_name"
                    label="Branch Name"
                    size="small"
                    required
                    value={addHris.branch_name}
                    onChange={(e) => onInputchange(e)}
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="headerBand">
                <label>Other Details </label>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    // required
                    name="pf_uan_number"
                    label="PF UAN Number"
                    value={addHris.pf_uan_number}
                    onChange={(e) => onInputchange(e)}
                    size="small"
                    className="form-control"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    variant="outlined"
                    name="insurance_id"
                    label="Insurance Id"
                    size="small"
                    // required
                    value={addHris.insurance_id}
                    onChange={(e) => onInputchange(e)}
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div>
                  <button
                    disabled={disable}
                    className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn"
                    id="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>

      </>
  )
});
const mapStateToProps = (state) => ({
 showModal:state.Hrislist.showModal,
 regionList:state.Hrislist.regionList
})
const mapDispatchToProps = (dispatch) => ({
 createlist : (data) => dispatch(createHrislist(data)),
 openshowModal:(data) => dispatch(HrisActions.openModal(data)),
    closemodal:(data) => dispatch(HrisActions.closeModal(data)),
    getfetchRegionlist:() => dispatch(fetchRegionlist())
});

export default connect(mapStateToProps,mapDispatchToProps) (HrisaddModal)
