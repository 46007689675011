import React, { useState, useEffect } from "react";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import MUIDataTable from "mui-datatables";
import { FaCalendarAlt } from 'react-icons/fa';
import { Dropdown, SplitButton, ButtonGroup } from 'react-bootstrap';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { FaCalendar  } from 'react-icons/fa';
import { FaCalendarWeek, FaRegCalendarAlt, FaTasks } from 'react-icons/fa';
import Navbar from '../home/Navbar';
import AddCalendarevent from "../../components/pageModals/AddCalendarevent";
import axios from 'axios';
import moment from 'moment-timezone';
import { getMuiTheme, headerProps } from '../../components/commonColumns/commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import Viewcalendarevents from "../../components/pageModals/Viewcalendarevents";
import UpdateCalendarModal from "../../components/pageModals/UpdateCalendarModal";
import { connect, useSelector } from 'react-redux';
import {getcalendarlist,CalendarActions,updateColumns,getColumnsList} from "../../store/Calendar"

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
    
});


const events = [
    {
        title: "Big Meeting",
        allDay: true,
        start: new Date(2021, 6, 0),
        end: new Date(2021, 6, 0),
    },
    {
        title: "Vacation",
        start: new Date(2021, 6, 7),
        end: new Date(2021, 6, 10),
    },
    {
        title: "Conference",
        start: new Date(2021, 6, 20),
        end: new Date(2021, 6, 23),
    },
];

  const Crmcalendar = React.memo (({getcalendardata,calendarlist,showUpdateEventModal,showCalendarEventModal,openshowModal,rowMetaData,rowId,openUpdateModal,calendarloader,calendarUpdateColumnsdata,updateColumnsList,columnsList,getColumnsList}) => {
  const {user} = useSelector(state => state.auth)

  const userData = user.user;
  const permissions = user.permissions;
     const[eventsjoinurl,setjoinurl] = useState()
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState([]);
  
  const calendardata = calendarlist?.data;
  const tableData = [];

let i = 0;
while (i < calendardata?.length) {
  let event = calendardata[i];
  let id = event.id;
  
    let start = event.start;
    const end = event.end;
    const subjects = event.subject;
    // console.log("data_sub", subjects);
  
    const attendees = event?.attendees;
    const organizer = event?.organizer;
    const joinUrl = event.onlineMeeting?.joinUrl;
    const isOnlineMeeting = event?.onlineMeetingProvider;
    const startDateTime = new Date(start.dateTime);
    const options = { timeZone: 'Asia/Kolkata', timeZoneName: 'short' };
    const formattedTime = startDateTime.toLocaleString('en-US', options);
  
    const endDateTime = new Date(end.dateTime);
  const Descriptions = event.bodyPreview
    const organizerInfo = {
      id: id,
      email: attendees?.emailAddress?.name,
      attendees: attendees?.emailAddress?.address,
      start_time: new Date(startDateTime.getFullYear(), startDateTime.getMonth(), startDateTime.getDate(), startDateTime.getHours() + 5, startDateTime.getMinutes() + 30),
      end_time: new Date(endDateTime.getFullYear(), endDateTime.getMonth(), endDateTime.getDate(), endDateTime.getHours() + 5, endDateTime.getMinutes() + 30),
      title:subjects,
      Description:Descriptions,
      eventurl: joinUrl,
      isOnlineMeeting:isOnlineMeeting

    };
  
    tableData.push(organizerInfo);
    i++;
  }
 
    useEffect(() => {
    //  userids();
    getColumnsList();
     getcalendardata()
    }, []);
   

    const [newEvent, setNewEvent] = useState({ title: "", start: "", end: "" });
    const [allEvents, setAllEvents] = useState(events);
    const [progress, setProgress] = useState("Loading...");
    // const [columnsList, setColumnsList] = useState([]);
 
    const MyCalendarToolbar = (props) => {
        const [showAddeventmodal, setshowAddeventModal] = useState(false);
       
        const { label, onView, onNavigate } = props; 
        return (    
    <>
    <div >
    <div className="calendar_tool">
    
            <div className="calendar-toolbar">
            <button className="addevent" onClick={() => openshowModal()}>
            <FaCalendar style={{ marginRight: '8px',marginBottom:"3.5px" }} />
            Add Event
          </button>
          {showCalendarEventModal ? <AddCalendarevent /> : ""}
           
            <button onClick={() => onNavigate("TODAY")}>Today</button>
            <button onClick={() => onNavigate("PREV")}>
            <AiOutlineLeft /> 
          </button>
          <button onClick={() => onNavigate("NEXT")}>
         <AiOutlineRight />
          </button>
            <span>{label}</span>
            </div>
            <div className="splt_btn">
            <SplitButton
            as={ButtonGroup}
            align={{ sm: 'start' }}
            size="md"
            id="dropdown-menu-align-responsive-2"
            onClick={() => onView("month")}
            variant="primary" // Set variant to 'primary' for blue color
            title={
              <span style={{ color: 'white', display: 'flex', alignItems: 'center' }}>
                <FaCalendarAlt style={{ marginRight: '8px' }} />
                Month
              </span>
            }
          >
          
          <Dropdown.Item eventKey="2" onClick={() => onView("week")}>
            <FaCalendarWeek style={{ marginRight: '8px' }} /> Week
          </Dropdown.Item>
          <Dropdown.Item eventKey="3" onClick={() => onView("day")}>
            <FaRegCalendarAlt style={{ marginRight: '8px' }} /> Day
          </Dropdown.Item>
          <Dropdown.Item eventKey="4" onClick={() => onView("agenda")}>
            <FaTasks style={{ marginRight: '8px' }} /> Agenda
          </Dropdown.Item>
            </SplitButton>
          </div>

          </div>
          </div>
 
</>

        );
      };

      const [showUpdatecalendarModal, setShowUpdatecalendarModal] = useState(false);

    const [showviewcalendartask , setshowviewcalendartask] = useState(false);
   const [rowidsde,setRowidde] = useState("")
    const [selectedEvent, setSelectedEvent] = useState(null);
    const handleEventClick = (event) => {
      setSelectedEvent(event);
      setshowviewcalendartask(true);
    };
      const [showCalendar, setshowcalendar] = useState(false);
      const [showTable, setshowtable] = useState(false);
  


      const handleClick = () => {
        setOpen(true);
      };

      const handleRowClick = (rowData, rowMeta) => {

        openUpdateModal()
              rowMetaData(rowData);
              rowId(tableData[rowMeta.dataIndex].id)
              setRowidde(tableData[rowMeta.dataIndex].id)
              };

 const containerStyle = {
        height: 500,
        margin: "50px"
    };
      
      
const handleReloadClick = () => {
  setshowcalendar(!showCalendar);
};

    const columns = [

      {
        name:"id",
        label:"id",  
        options: {
          filter: true,
          sort: true,
          display: false,
          setCellHeaderProps: headerProps,
        }
      },

      
      {
        name: "email",
        label: "Email Name",
        options: {
          filter: true,
          sort: true,
          display: columnsList?.includes('email') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
            (value ? value: "")
          ),
     
          setCellHeaderProps: headerProps,
        }
 
      },
      {
        name: "attendees",
        label: "Email Address",
        options: {
          filter: true,
          sort: true,
          display: columnsList?.includes('attendees') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
            (value ? value: "")
          ),
     
          setCellHeaderProps: headerProps,
        }
      },
      {

   
        name: "start_time",
        label: "Start Time",
        options: {
          display: columnsList?.includes('start_time') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
              const formattedDateTime = moment(value);
              const formattedDate = formattedDateTime.format('D MMM YYYY');
              const formattedTime = formattedDateTime.format('LT');
              return `${formattedDate} ${formattedTime}`;
            } else {
              return "";
            }
          },
          setCellHeaderProps: headerProps,
        }
      },
      {  
      
        name: "end_time",
        label: "End Time",
        options: {
          display: columnsList?.includes('end_time') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
              const formattedDateTime = moment(value);
              const formattedDate = formattedDateTime.format('D MMM YYYY');
              const formattedTime = formattedDateTime.format('LT');
              return `${formattedDate} ${formattedTime}`;
            } else {
              return "";
            }
          },
          setCellHeaderProps: headerProps,
        }
      },
  
      {
        name: "Description",
        label: "Description",
        options: {
          filter: true,
          sort: true,
          display: columnsList?.includes('Description') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
            (value ? value: "")
          ),
          setCellProps: () => ({
            style: {
              whiteSpace: "nowrap",
              left: "0",
              zIndex: 100,
              color: '#243d6a',
              maxWidth: '120px',
              overflow: 'auto',
              fontWeight: 'bold'
            },
          
          }),
     
          setCellHeaderProps: headerProps,
        }
      },
      

      {
        name: "title",
        label: "Title",
        options: {
          filter: true,
          sort: true,
          display: columnsList?.includes('title') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
            (value ? value: "")
          ),
          setCellProps: () => ({
            style: {
              whiteSpace: "nowrap",
              left: "0",
              zIndex: 100,
              color: '#243d6a',
              maxWidth: '120px',
              overflow: 'auto',
              fontWeight: 'bold'
            },
          
          }),
     
          setCellHeaderProps: headerProps,
        }
      },

    
      

      {
        name: "isOnlineMeeting",
        label: "isOnlineMeeting",
        options: {
          filter: false,
          sort: false,
          display: columnsList?.includes('isOnlineMeeting') ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => (
            (value ? value: "")
          ),
     
          setCellHeaderProps: headerProps,
        }
      },
     
    ]

    
    const LoaderText = () => {
  return   calendarloader ? <div className="parent-div">
  <div className="loader"></div>
</div>:"No Data Found.." 
}

    
    const options = {
      textLabels: {
        body: {
          noMatch: LoaderText()
          
        }
      },
      rowsPerPage: window.innerHeight > 720 ? 20 : 15,
      filter: true,
      filterType: "dropdown",
      responsive: "standard",
      tableBodyHeight: (window.innerHeight - 210).toString() + 'px',
      tableBodyMaxHeight: (window.innerHeight - 210).toString() + 'px',
      fixedHeader: true,
      fixedSelectColumn: false,
      selectableRows: "none",
    onRowClick: handleRowClick,
      onViewColumnsChange: async (changedColumn, action) => {
        var columns = Object.assign([], columnsList)
        if (action === "add") {
          columns.push(changedColumn);
        }
      
  
        if (action === "remove") {
          columns = columns.filter(item => item !== changedColumn);
        }
        
  
        const updatedData = {
          user_id: userData._id,
          table_name: "calendar",
          columns_list: columns,
        }
  
        calendarUpdateColumnsdata(updatedData)
      updateColumnsList(columns)
      },
      download: false,
      print: false,
  
    };
  return (
    <div>
    <Navbar value="calendar" className="activity_list_navbar"/>
    <div style={{marginTop:"1rem", marginLeft:"0.5rem"}}>

    <SplitButton
    as={ButtonGroup}
    align={{ sm: 'start' }}
    size="md"
    id="dropdown-menu-align-responsive-2"
    onClick={() => setshowcalendar(true)}
    variant="primary" // Set variant to 'primary' for blue color
    title={
      <span style={{ color: 'white', display: 'flex', alignItems: 'center' }}>
        <FaCalendarAlt style={{ marginRight: '8px' }} />
        Calendar 
      </span>
    }
  >
  
  <Dropdown.Item eventKey="2"  onClick={handleReloadClick}>
    <FaCalendarWeek style={{ marginRight: '8px' }} /> Table
  </Dropdown.Item>
    </SplitButton>
    </div>

       {showCalendar ? (
        <div >
        <Calendar localizer={localizer}  
        startAccessor="start_time" 
       endAccessor="end_time"      
       style={{height:"1200px", margin: "0px" ,paddingTop:"5px"}}
       titleAccessor="title"
       events={tableData}
   onSelectEvent={handleEventClick} 
   defaultView="month" 
   step={30} 
    timeslots={2} 
   components={{
       toolbar: MyCalendarToolbar,
     }}
   />
       {selectedEvent && (
  <Viewcalendarevents
    show={showviewcalendartask}
    onHide={() => {
      setshowviewcalendartask(false);
      setSelectedEvent(null);
    }}
    email={selectedEvent.email}
    attendees={selectedEvent.attendees}
    start_time={selectedEvent.start_time}
    end_time={selectedEvent.end_time}
    title={selectedEvent.title}
    joinUrl={selectedEvent.eventurl} // Include joinUrl here

    id={selectedEvent.id}
    event={selectedEvent}
    // reFreshViewContactModal={() => userids()}
  />
)}
</div>

       ) :( 
      
        <div>
     
        <React.Fragment>
       
        <MuiThemeProvider theme={getMuiTheme()}>
     
            <MUIDataTable
            title="Calendar Events Data"
            data={tableData}
            columns={columns}
            options={options}
           /> 
           </MuiThemeProvider>
           
         </React.Fragment> 
       {showUpdateEventModal ? <UpdateCalendarModal/> :""}
        
        </div>
       )
      }
    </div>
  )

}); 
const mapStateToProps = (state) => ({ 
    user : state.auth.user,
    calendarlist:state.Calendarlist.calendarlist, 
    showCalendarEventModal:state.Calendarlist.showCalendarEventModal,
    showUpdateEventModal:state.Calendarlist.showUpdateEventModal,
    calendarloader:state.Calendarlist.calendarloader,
    columnsList:state.Calendarlist.columnsList
});


const mapDispatchToProps = (dispatch) => ({
  getcalendardata:(data) => dispatch(getcalendarlist(data)),
  calendarUpdateColumnsdata:(data) => dispatch(updateColumns(data)),
  updateColumnsList:(data) => dispatch(CalendarActions.updateColumns(data)),
  openshowModal:(data) => dispatch(CalendarActions.openModal(data)),
  rowMetaData:(data) => dispatch(CalendarActions.setrowMetaData(data)),
  rowId:(data) => dispatch(CalendarActions.setRowId(data)),
  openUpdateModal:() => dispatch(CalendarActions.UpdateOpenModal()),
  getColumnsList:(data) => dispatch(getColumnsList(data)),

});
export default connect(mapStateToProps,mapDispatchToProps) (Crmcalendar)