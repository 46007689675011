import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import { useHistory } from 'react-router-dom'; 
import AssignTicketModal from '../pageModals/AssignTicketModal';
import AddNewTicket from '../pageModals/AddNewTicket';
import moment from 'moment-timezone';
import { getMuiTheme, headerProps } from './commonComponents';
import {TicketActions} from "../../store/Ticketlist"
import { MuiThemeProvider } from "@material-ui/core/styles";
import { useSelector,useDispatch, connect } from 'react-redux';
import {meetingColumnUpdateddata,} from "../../store/Common"

const TicketTable = React.memo(({getticketlists,user,showTicketModal,showAssignModal,isFetching,ticketcolumnData,assignopenmodal,newrowId,newrowMetaData,ticketlistid}) => {
  const ticketdata =  getticketlists
  const dispatch = useDispatch();
  const userData = user.user;
  const permissions = user.permissions;
  const [ticketListId, setTicketListId] = useState(null);
  // const [showAssignModal, setShowAssignModal] = useState(false);
  const history = useHistory();
  const [columnsList, setColumnsList] = useState([]);
  const [rowId, setRowId] = useState("");
  const [rowMetaData, setRowMetaData] = useState("");

  const handleRowClick = (rowData, rowMeta) => {
    newrowMetaData(rowMeta)
    newrowId(ticketdata[rowMeta.dataIndex]._id); 
    history.push({
      pathname: '/queries-feedback',
      search:ticketdata[rowMeta.dataIndex]._id,
      state:ticketdata[rowMeta.dataIndex]._id
    })
  }
  const getColumnsList = async () => {
    const res = await axios.get("/columns-hide-show/list/tickets/" + userData._id);
    setColumnsList(res.data.columnsList ? res.data.columnsList.columns_list : []);
  }

  useEffect(() => {
    getColumnsList();
  
  }, []);

  const assignTechnical = async (event, data, meta) => {
    event.stopPropagation();
    assignopenmodal()
    // setShowAssignModal(true)
    // setTicketListId(meta);;
    ticketlistid(meta)
    
  }
  const columns = [
    {
      name: "_id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "assigned_to",
      label: "Techie Name",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('assigned_to') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "company_name",
      label: "Account Name",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('company_name') ? true : false,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
            color: '#243d6a',
            fontWeight: 'bold',
            maxWidth: '500px',
            overflow: 'auto',
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 1,
            background: "#e6e6e6",
            zIndex: 101,
            color: '#243d6a',
            fontWeight: 'bold',
            maxWidth: '500px',
            overflow: 'auto',
          }
        })
      }
    },
    {
      name: "family",
      label: "Product Family",
      options: {
        filter: true,
        sort: true,
        display: columnsList.includes('family') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: columnsList.includes('status') ? true : false,
        onRowClick: false,
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => {

          if (value === "open" && permissions.includes('canAssignTickets')) {
            return (
              <div style={{ display: "flex" }}>
                <a onClick={(e) => { assignTechnical(e, value, tableMeta.rowData[0]) }}>
                  <button className="btn-sm btn-group-sm btn btn-table-commone btn-success">Assign</button>
                </a>
              </div>
            );
          } else if (value === "assigned") {
            return <span style={{ textTransform: 'capitalize' }}>{value}</span>;
          }
          else if (value === "closed") {
            return <span style={{ textTransform: 'capitalize' }}>{value}</span>;
          }
        }
      }
    },
    {
      name: "products",
      label: "Application",
      options: {
        filter: true,
        sort: true,
        display: columnsList.includes('products') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "area",
      label: "Area",
      options: {
        filter: true,
        sort: true,
        display: columnsList.includes('area') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "sub_area",
      label: "SubArea",
      options: {
        filter: true,
        sort: true,
        display: columnsList.includes('sub_area') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "product_version",
      label: "Product Version",
      options: {
        filter: true,
        sort: true,
        display: columnsList.includes('product_version') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "platform",
      label: "Platform",
      options: {
        filter: true,
        sort: true,
        display: columnsList.includes('platform') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "operating_system",
      label: "OS",
      options: {
        filter: true,
        sort: true,
        display: columnsList.includes('operating_system') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "summary",
      label: "Summary",
      options: {
        filter: true,
        sort: true,
        display: columnsList.includes('summary') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: true,
        display: columnsList.includes('description') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "opened_on",
      label: "Received Date",
      options: {
        display: columnsList.includes('opened_on') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "closed_on",
      label: "Closed Date",
      options: {
        display: columnsList.includes('closed_on') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
  ];

  const LoaderText = () => {
    return   isFetching ? <div className="parent-div">
    <div className="loader"></div>
  </div>:"No Data Found.." 
 }

  
  const options = {
    textLabels: {
      body: {
        noMatch: LoaderText()
      }
    },
    
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    tableBodyHeight: (window.innerHeight - 130).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 130).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    onRowClick: handleRowClick,
    onViewColumnsChange: async (changedColumn, action) => {
      var columns = Object.assign([], columnsList)
      if (action === "add") {
        columns.push(changedColumn);
      }

      if (action === "remove") {
        // var index = columnsList.indexOf(changedColumn);
        // if (index !== -1) {
        //   columnsList.splice(index, 1);
        //   setColumnsList(columnsList);
        // }
        var columns = columns.filter(item => item !== changedColumn);
        
      }
      setColumnsList(columns)

      const updatedData = {
        user_id: userData._id,
        table_name: "tickets",
        columns_list: columns,
      }
      ticketcolumnData(updatedData)
      // updatecolumnsList(updatedData)
    },
    download: false,
    print: false,
  };


  return (
    <>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={<div style={{ display: "flex", flexDirection: "row" }} >
          <b>Tickets List</b>&nbsp;&nbsp;&nbsp; 
          <button type="button" onClick={() => dispatch(TicketActions.openModal())} class="btn btn-primary btn-sm">Add Ticket</button>&nbsp;&nbsp;</div>}
          data={ticketdata}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
   
      {showAssignModal ? <AssignTicketModal/> : ""}
      {showTicketModal ? <AddNewTicket /> : ""}

    </>
  )
});

const mapStateToProps = (state) => ({
  user : state.auth.user,
  getticketlists:state.Ticketlist.getticketlists,
  showTicketModal : state.Ticketlist.showModal,
  showAssignModal: state.Ticketlist.showAssignModal,
  isFetching : state.Ticketlist.isFetching

});

 const mapDispatchToProps = (dispatch) => ({
  ticketcolumnData:(data) => dispatch(meetingColumnUpdateddata(data)),
  assignopenmodal:(data) => dispatch(TicketActions.Assign_openModal(data)),
  newrowId:(data) => dispatch(TicketActions.setRowId(data)),
  newrowMetaData:(data) => dispatch(TicketActions.setrowMetaData(data)),
  ticketlistid:(data) => dispatch(TicketActions.setTicketListId(data)),
  // updatecolumnsList:(data) => dispatch(TicketActions.updateColumnsList(data)),
 });

export default connect(mapStateToProps,mapDispatchToProps) (TicketTable)