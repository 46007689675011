import React, { useContext, useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
// Snackbar notification
import MaterialUiButton from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ViewTargetModal from '../pageModals/ViewTargetModal';
//Context 
import { AuthContext } from '../../context/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import { getMuiTheme, headerProps } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import {roisheetupdateColumns,CommonsliceActions,getroiColumnsList} from "../../store/Common"
const RoiSheetTable = ({data=null, refreshTargetData, getId, showRoiForm}) => {

    
  const dispatch = useDispatch();
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const columnsList = useSelector(state => state.Commonlist.roicolumnsList);
  const [rowId, setRowId] = useState("");
  const [rowMetaData, setRowMetaData] = useState("");
  const [showTargetModal, setShowTargetModal] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    dispatch(getroiColumnsList())
    if(data.length>0){
        setTableData(data)
    }
    
  }, [data])

  const handleRowClick = (rowData, rowMeta) => {

    // getDetailsHandle(data[rowMeta.dataIndex]._id, rowData);
    // setShowUpdateLeadModal(true);

    // data[rowMeta.dataIndex]._id, rowData
    setShowTargetModal(true);
    setRowMetaData(rowMeta);
    setRowId(data[rowMeta.dataIndex]._id)
    getId(data[rowMeta.dataIndex]._id)

    showRoiForm()

    const targetDiv = document.getElementById('roicalc');
    targetDiv.scrollIntoView({ behavior: 'smooth' });

  };
  const columns = [
    {
      name: "_id",
      label: "Id",
      options: {
        filter: true,
        display: false,
        sort: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name:"username",
      label: "Account Manager",

      options: {
        filter: true,
        display: columnsList?.includes('username') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "account",
      label: "Company Name",
      options: {
        filter: true,
        display: columnsList?.includes('account') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
        name:"total_direct_savings_in_money",
        label:"ROI at the end of (3 years)",
        options: {
            filter: true,
            display: columnsList?.includes('total_direct_savings_in_money') ? true : false,
            sort: true,
            setCellHeaderProps: headerProps,
            customBodyRender: (value, meta) => {
              return (
                <div style={{textAlign:"right"}}>
                  <span>  {value ? "₹" + value.toLocaleString("en-IN") : ""}</span>
                </div>
         
              )
            }
          }
    },
    {
        name:"total_direct_savings_in_time",
        label:"Total Direct Savings in Time",
        options: {
            filter: true,
            display: columnsList?.includes('total_direct_savings_in_time') ? true : false,
            sort: true,
            setCellHeaderProps: headerProps,
            customBodyRender: (value, meta) => {
              return (
                <div style={{textAlign:"right"}}>
                  <span>  {value ? value.toLocaleString("en-IN") : ""}</span>
                </div>
         
              )
            }
          }
    }
  ];

  const options = {
    textLabels: {
      body: {
        noMatch: data.length > 0 ? "Loading..." : "No Records Found..!"
      }
    },
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: (window.innerHeight - 130).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 130).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    print: false,

    onViewColumnsChange: async (changedColumn, action) => {
      var columns = Object.assign([], columnsList)
      if (action === "add") {
        columns.push(changedColumn);
      }

      if (action === "remove") {
         columns = columns.filter(item => item !== changedColumn);
      }

      const updatedData = {
        user_id: userData._id,
        table_name: "roisheet",
        columns_list: columns,
      }
        dispatch(roisheetupdateColumns(updatedData));
        dispatch(CommonsliceActions.roisheetupdateColumns(columns));

    },

  };

  
  return (
    <>
      <MuiThemeProvider theme={getMuiTheme()}>
        
            <MUIDataTable
                // styles={{ styles }}
                title={"Roi Sheet List"}
                data={tableData}
                columns={columns}
                options={options}
            />
            
        
      </MuiThemeProvider>
      {/* {showTargetModal ? <ViewTargetModal show={showTargetModal} reFeshViewTargetModal={() => refreshTargetData()} onHide={() => setShowTargetModal(false)} rowId={rowId} metaData={rowMetaData} /> : ""} */}
     

    </>
  )
}
export default RoiSheetTable;