
import { createSlice } from "@reduxjs/toolkit";
import Api  from "./axios";
import { toast } from "react-toastify";
var userObj = JSON.parse(localStorage.getItem("user")) || null;


const intialAuthState = {
    isFetching: false,
    error: false,
    errorPayload: false,
    user: userObj,
    msg: "",
    leadsAnalysis: null,
    open:false,
    showModal: false,
    AssignRawdataModel:false,
    showRawDataExistingCustomerModal:false,
    showRawDatadetailsModal:false,
    showUpdateRawdataModal:false,
    showAddingWebsiteModal:false,
    contactmodal:false,
    contactpage:false,
    updateModal:false,
    inactiveModal:false,
    rawdatalist:[],
  createrawdatalist:[],
  updaterawdatalist:[],
  assignedrawdatalist:[],
  getuserslist:[],
  contactcreatelist:[],
  updaterawdatarowId:[],
  updaterawdatarowMetadata:[],
  AddwebisterowId:[],
  AddwebisterowMetadata:[],
  RawDataExistingCustomerRowId:[],
  rowMetadata:[],
  rowId:[],
  leadidData:[],
  dcrRawId : null,
  showAddRawDCR : false,
  dcrRawCompanyName: null,
  rawDatabaseNew: [],
  tableRowCount: 0,
  rawDatabasePagination: [],
  totalRows : 0,
  columnsList:[],
  columnsObjects:null
}

const RawdataSlice = createSlice({
    name: "Rawdatalist",
    initialState: intialAuthState,
    reducers: {
        Open: (state, action) => {
          state.open = true; 
        },
        close : (state, action) => {
          state.open = false; 
        },
    
        openModal: (state, action) => {
            state.showModal = true; // Correct the property name to "showModal"
          },
          closeModal: (state, action) => {
            state.showModal = false; // Correct the property name to "showModal"
          },
          AssignRawdataModel_openmodal:(state, action) => {
            state.AssignRawdataModel = true; 
          },
          AssignRawdataModel_closemodal:(state, action) => {
            state.AssignRawdataModel = false; 
          },

          showRawDataExistingCustomerModal_openmodal :(state, action) => {
            state.showRawDataExistingCustomerModal = true; 
          },
          showRawDataExistingCustomerModal_closemodal :(state, action) => {
            state.showRawDataExistingCustomerModal = false; 
          },
          showRawDatadetailsModal_openmodal :(state, action) => {
            state.showRawDatadetailsModal = true; 
          },
          showRawDatadetailsModal_closemodal :(state, action) => {
            state.showRawDatadetailsModal = false; 
          },
          showUpdateRawdataModal_openmodal :(state, action) => {
            state.showUpdateRawdataModal = true; 
          },
          showUpdateRawdataModal_closemodal :(state, action) => {
            state.showUpdateRawdataModal = false; 
          },
          showAddingWebsiteModal_openmodal:(state, action) => {
            state.showAddingWebsiteModal = true; 
          },
          showAddingWebsiteModal_closemodal:(state, action) => {
            state.showAddingWebsiteModal = false; 
          },
          contactmodal_openModal: (state, action) => {
            state.contactmodal = true; // Correct the property name to "showModal"
          },
          contactmodal_closeModal: (state, action) => {
            state.contactmodal = false; // Correct the property name to "showModal"
          },
          contactpage_open:(state, action) => {
            state.contactpage = true; 
          },
          contactpage_close:(state, action) => {
            state.contactpage = false; 
          },
          update_openModal: (state, action) => {
            state.updateModal = true; 
          },
          update_closeModal: (state, action) => {
            state.updateModal = false; 
          },
          inactiveModalOpen:(state) => {
           state.inactiveModal=true
          }, 
          inactiveModalClose:(state) => {
            state.inactiveModal=false
           }, 
          setupdateRawdataRowId(state, action) {
            state.updaterawdatarowId = action.payload;
          },
          setupdateRawdatarowMetaData(state, action) {
            state.updaterawdatarowMetadata = action.payload;
          },

          // setAddwebisteRowId(state, action) {
          //   state.AddwebisterowId = action.payload;
          // },
          // setAddwebisterowMetaData(state, action) {
          //   state.AddwebisterowMetadata = action.payload;
          // },
          // setRawDataExistingCustomerModalSetRowId(state,action) {
          //   state.RawDataExistingCustomerRowId = action.payload;
          // },

          setRowId(state, action) {
            state.rowId = action.payload;
          },
          setrowMetaData(state, action) {
            state.rowMetadata = action.payload;
          },
          setleadid_data(state, action) {
            state.leadidData = action.payload;
          },






          loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
          },
          loaderStop(state, action) {
            state.isFetching = false;
            state.msg = "Fetching...";
          },

          rawdatalistSuccess(state,action) {
            state.isFetching = false;
            state.error = false;
            state.errorPayload = null;
            state.rawdatalist = action.payload;
            // toast.success("Raw data list ") 
    
          },
          rawdatalistFailure(state,action) {
            state.isFetching = false;
            state.error = false;
            state.errorPayload = null;
            state.rawdatalist = [];
            toast.error("Error in Raw data list ") 
    
    
          },
       
          updatedRawdataSuccess (state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.updaterawdatalist = action.payload;
            toast.success("Updated successfully!") 
            state.showUpdateRawdataModal = false 
          },
    
          updatedRawdataFailure (state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.updaterawdatalist = [];
            toast.error("Error in updating rawdata") 
            state.showUpdateRawdataModal = true
          },
          updatedRawdataStatusSuccess(state,action){
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            // state.updaterawdatalist = action.payload;
            toast.success("Updated successfully!") 
            state.inactiveModal = false 
          },
          updatedRawdataStatusFailure(state,action){
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            // state.updaterawdatalist = [];
            toast.error("Error in updating rawdata") 
            state.inactiveModal = true
          },
          createRawdataSuccess(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.createrawdatalist = action.payload;
            toast.success("Raw database imported successfully!")
            state.showModal = false 
          },
          createRawdataFailure(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.createrawdatalist = action.payload;
            toast.error("Error in uploading raw database") 
            state.showModal = true 
          },
          createSingleRawdataSuccess(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            toast.success("Raw database Created successfully!")
            state.showModal = false 
          },
          createSingleRawdataFailure(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            toast.error(action.payload) 
            state.showModal = true 
          },
          assignedRawdataSuccess(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.assignedrawdatalist = action.payload;
            toast.success("Succesfully Assigned")
            state.AssignRawdataModel = false 
          },
    
          assignedRawdataFailure(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.assignedrawdatalist =[];
            toast.error("Error occured while assigning")
            state.AssignRawdataModel = true 
          },
          getuserlistSuccess(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.getuserslist = action.payload;
            // toast.success("Assigned Raw data")
            // state.showModal = false 
          },
          getuserlistFailure(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.getuserslist = [];
            // toast.error("Assigned Raw data")
            // state.showModal = false 
          },
          contactcreateSuccess(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.contactcreatelist = action.payload;
            toast.success("Contact created Succesfully")
            state.contactmodal = false 
          },
          contactcreateFailure(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.contactcreatelist =[];
            toast.error("Error in creating contact")
            state.contactmodal = false 
          },
        
          leadscreateSuccess(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.leadscreatelist = action.payload;
            toast.success("Lead created succesfully ")
            state.showRawDatadetailsModal = false 
          },
          leadscreateFailure(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            state.leadscreatelist =[];
            // toast.error("Error in creating lead")
            toast.error(action.payload)
            state.showRawDatadetailsModal = true 
          },
          rawdataLeadsCreateSuccess(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            toast.success("Lead created succesfully ")
            state.showRawDatadetailsModal = false 
          },
          rawdataLeadsCreateFailure(state,action) {
            state.meetingLoader = false;
            state.error = false;
            state.errorPayload = null;
            // toast.error("Error in creating lead")
            toast.error(action.payload)
            state.showRawDatadetailsModal = true 
          },
          showAddRawDCR(state, action){
            state.dcrRawId = action.payload._id
            state.dcrRawCompanyName = action.payload.company_name
            state.showAddRawDCR = true;
          },
          dcr_openModal(state, action){
            state.showAddRawDCR = true;
          },
          dcr_closeModal(state, action){
            state.showAddRawDCR = false;
            state.tasksList = [];
          },
          updateTasksList(state, action){
            state.tasksList = action.payload
            state.showAddRawDCR = true;
          },
          columndataSuccess(state, action){
            state.error = false;
            state.errorPayload = null;
            state.columnsList = action.payload
            
          },
          columndataFailure(state, action){
            state.error = true;
            state.errorPayload = action.payload;
          },
          columnsListSuccess(state, action){
            state.columnsList = action.payload.columnsList
            state.columnsObjects = action.payload.column_objects
          },
          columnsListFailure(state, action){
            state.columnsList = []
          },
          RawdatabaseDataUpdatedSuccess(state, action){
            return {
              ...state, 
              rawDatabaseNew : action.payload.list,
              tableRowCount : action.payload.rowCount
            }
          },
          RawdatabaseDataUpdatedFailure(state, action){
            state.rawDatabaseNew = []
          },
          rawDataUpdatedSuccess(state, action){
            state.totalRows = action.payload.rowCount 
            state.rawDatabasePagination = action.payload.list
          },
          rawDataUpdatedFailure(state, action){
            state.totalRows = 0
            state.rawDatabasePagination = []
          },
          paginationLoading(state, action){
            state.rawDatabasePagination = []
          }
    }  
});
export const RawdataActions = RawdataSlice.actions;


/// RAW DATA START HERE:

export const getrawdata = (updtaed) => {
    return async(dispatch) => {
        dispatch(RawdataActions.loaderStart())
        try {
            await Api.get("/user/rawdata/list",updtaed).then((res) => {
                if(res.data.status === "success"){
                dispatch(RawdataActions.rawdatalistSuccess(res?.data?.list));
                }
                if(res.data.status ==="failure") {
                    dispatch(RawdataActions.rawdatalistFailure(res.data.msg))
                }
            })
        }
        catch (err) {
          dispatch(RawdataActions.rawdatalistFailure("Network Error"));
        }
    }
 
}


export const updaterawdata = (activityUpdateformdata) => {
  return async(dispatch) => {
      dispatch(RawdataActions.loaderStart("meetingloader"))

      try {
          await Api.post("/user/rawdata/update",activityUpdateformdata).then((res) => {
              if(res.data.status === "success"){
              dispatch(RawdataActions.updatedRawdataSuccess(res.data));
              dispatch(getrawdata())
              }
              if(res.data.status ==="failure") {
                  dispatch(RawdataActions.updatedRawdataFailure(res.data.msg))
              }
          })
      }
      catch (err) {
        dispatch(RawdataActions.updatedRawdataFailure("Network Error"));
      }
  }

}

export const updaterawdataStatus = (activityUpdateformdata) => {
  return async(dispatch) => {
      dispatch(RawdataActions.loaderStart("meetingloader"))

      try {
          await Api.post("/user/rawdata/update/status",activityUpdateformdata).then((res) => {
              if(res.data.status === "success"){
              dispatch(RawdataActions.updatedRawdataStatusSuccess(res.data));
              dispatch(getrawdata())
              }
              if(res.data.status ==="failure") {
                  dispatch(RawdataActions.updatedRawdataStatusFailure(res.data.msg))
              }
          })
      }
      catch (err) {
        dispatch(RawdataActions.updatedRawdataStatusFailure("Network Error"));
      }
  }

}
export const createrawdata = (updtaed) => {
    return async(dispatch) => {
        dispatch(RawdataActions.loaderStart("meetingloader"))

        try {
            await Api.post("/user/rawdata/upload/file",updtaed).then((res) => {
                if(res.data.status === "success"){
                  dispatch(RawdataActions.createRawdataSuccess(res.data));
                  dispatch(getrawdata())
                }
                if(res.data.status ==="failure") {
                    dispatch(RawdataActions.createRawdataFailure(res.data.msg))
                }
            })
        }
        catch (err) {
          dispatch(RawdataActions.createRawdataFailure("Network Error"));
        }
    }
 
}

export const assignRawdata = (updtaed) => {
  return async(dispatch) => {
      dispatch(RawdataActions.loaderStart("meetingloader"))

      try {
          await Api.post("/user/rawdata/assign",updtaed).then((res) => {
              if(res.data.status === "success"){
              dispatch(RawdataActions.assignedRawdataSuccess(res.data));
              }
              if(res.data.status ==="failure") {
                  dispatch(RawdataActions.assignedRawdataFailure(res.data.msg))
              }
          })
      }
      catch (err) {
        dispatch(RawdataActions.assignedRawdataFailure("Network Error"));
      }
  }

}


export const getUsers = () => {
  return async(dispatch) => {
      dispatch(RawdataActions.loaderStart("meetingloader"))

      try {
          await Api.get('/user/isales-sales-users').then((res) => {
              if(res.data.status === "success"){
              dispatch(RawdataActions.getuserlistSuccess(res.data));
              }
              if(res.data.status ==="failure") {
                  dispatch(RawdataActions.getuserlistFailure(res.data.msg))
              }
          })
      }
      catch (err) {
        dispatch(RawdataActions.getuserlistFailure("Network Error"));
      }
  }

}

export const contactcreatedata = (updtaed) => {
  return async(dispatch) => {
      dispatch(RawdataActions.loaderStart("meetingloader"))

      try {
          await Api.post("contacts/create",updtaed).then((res) => {
              if(res.data.status === "success"){
              dispatch(RawdataActions.contactcreateSuccess(res.data));
              dispatch(getrawdata())
              }
              if(res.data.status ==="failure") {
                  dispatch(RawdataActions.contactcreateFailure(res.data.msg))
              }
          })
      }
      catch (err) {
        dispatch(RawdataActions.contactcreateFailure("Network Error"));
      }
  }

}

export const leadscreatedata = (updtaed) => {
  return async(dispatch) => {
      dispatch(RawdataActions.loaderStart("meetingloader"))

      try {
          await Api.post("leads/create",updtaed).then((res) => {
              //   if(res.data.status === "account_exist"){

              //         const result = window.confirm(`Account already exist Do you want to create new account
              //         ? or Proceed with existing account. <br />
              //         Click yes to create new account or Cancel to continue with same account`);

              //         if (result) {
              //             // fsdfs
              //             updtaed.create_new_account = true
              //             console.log("data lead ", updtaed)
              //             dispatch(leadscreatedata(updtaed))
              //         } else {
              //           updtaed.create_new_account = false
              //             console.log("data lead ", updtaed)
              //             dispatch(leadscreatedata(updtaed))
                          
              //         }
              // }
              // else 
              if(res.data.status === "success"){
                dispatch(RawdataActions.leadscreateSuccess(res.data));
                dispatch(getrawdata())
              }
              if(res.data.status ==="failure") {
                dispatch(RawdataActions.leadscreateFailure(res.data.msg))
              }
          })
      }
      catch (err) {
        dispatch(RawdataActions.leadscreateFailure("Network Error"));
      }
  }

}

export const RawdataLeadscreatedata = (updtaed) => {
  return async(dispatch) => {
      dispatch(RawdataActions.loaderStart("meetingloader"))

      try {
          await Api.post("leads/create",updtaed).then((res) => {
              //   if(res.data.status === "account_exist"){

              //         const result = window.confirm(`Account already exist Do you want to create new account
              //         ? or Proceed with existing account. <br />
              //         Click yes to create new account or Cancel to continue with same account`);

              //         if (result) {
              //             // fsdfs
              //             updtaed.create_new_account = true
              //             console.log("data lead ", updtaed)
              //             dispatch(leadscreatedata(updtaed))
              //         } else {
              //           updtaed.create_new_account = false
              //             console.log("data lead ", updtaed)
              //             dispatch(leadscreatedata(updtaed))
                          
              //         }
              // }
              // else 
              if(res.data.status === "success"){
                dispatch(RawdataActions.rawdataLeadsCreateSuccess(res.data));
                dispatch(getrawdata())
              }
              if(res.data.status ==="failure") {
                dispatch(RawdataActions.rawdataLeadsCreateFailure(res.data.msg))
              }
          })
      }
      catch (err) {
        dispatch(RawdataActions.rawdataLeadsCreateFailure("Network Error"));
      }
  }

}
export const createRawDatabaseActivityData = (data) => {
  return async(dispatch) => {
    dispatch(RawdataActions.loaderStart("meetingloader"))

    try {
        await Api.post("/user/rawdata/add-activity",data).then((res) => {
            if(res.data.status === "success"){
            // dispatch(RawdataActions.dcrcreateSuccess(res.data));
            dispatch(RawdataActions.dcr_closeModal())
              toast.success("Call Recording created successfully")
              
            }
            if(res.data.status ==="failure") {
              toast.error("Failed to create call recording")
                // dispatch(RawdataActions.dcrcreateFailure(res.data.msg))
            }
        })
    }
    catch (err) {
      toast.error("Network Error")
      dispatch(RawdataActions.leadscreateFailure("Network Error"));
    }
  }
}

export const RawDatabaseAddActivityModal = (data) => {
  return async(dispatch) => {
    dispatch(RawdataActions.loaderStart("meetingloader"))
    
    try {
        await Api.get("/user/rawdata/activity-list/"+data?._id).then((res) => {
            if(res.data.status === "success"){
              
              dispatch(RawdataActions.showAddRawDCR(data));
              dispatch(RawdataActions.updateTasksList(res.data?.tasksList))
              // dispatch(RawdataActions.dcr_closeModal())
              // toast.success("Call Recording created successfully")
            }
            if(res.data.status ==="failure") {
              toast.error("Failed to create call recording")
            }
        })
    }
    catch (err) {
      toast.error("Network Error")
      dispatch(RawdataActions.leadscreateFailure("Network Error"));
    }
  }
}
export const createSingleRawData = (updtaed) => {
  return async(dispatch) => {
      dispatch(RawdataActions.loaderStart("meetingloader"))

      try {
          await Api.post("/user/rawdata/create-rawdata-with-field",updtaed).then((res) => {
              if(res.data.status === "success"){
                dispatch(RawdataActions.createSingleRawdataSuccess(res.data));
                dispatch(getrawdata())
              }
              if(res.data.status ==="failure") {
                  dispatch(RawdataActions.createSingleRawdataFailure(res.data.msg))
              }
          })
      }
      catch (err) {
        dispatch(RawdataActions.createSingleRawdataFailure("Network Error"));
      }
  }

}

// 
export const updateColumnsList = (updatedData) => {
  
  return async (dispatch) => {
    dispatch(RawdataActions.loaderStart("meetingLoader"));
    try {
      await Api.post("columns-hide-show/create", updatedData).then((res) => {
        if (res.data.status === "success") {
          dispatch(RawdataActions.columndataSuccess(updatedData.columns_list));
        }
        if (res.data.status === "failure") {
          dispatch(RawdataActions.columndataFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(RawdataActions.columndataFailure("Network Error"));
    }
  };
};


export const getColumnsList  = () => {
  return async(dispatch) => {
      dispatch(RawdataActions.loaderStart("meetingloader"))

      try {
        var userData = JSON.parse(localStorage.getItem("user"))
          await Api.get("/columns-hide-show/list/rawdata/"+ userData?.user?._id).then((res) => {
              if(res.data.status === "success") {
                
                dispatch(RawdataActions.columnsListSuccess(res.data));
              }
              if(res.data.status ==="failure") {
                  dispatch(RawdataActions.columnsListFailure(res.data.msg))
              }
          })
      }
      catch (err) {  
        dispatch(RawdataActions.columnsListFailure("Network Error"));
      }
  }
  }

  export const fetchRawdatabaseList = (data) => {
    return async (dispatch) => {
      // console.log("data ", data)
      // dispatch(RawdataActions.loaderStart())
      try {
          var userData = JSON.parse(localStorage.getItem("user"))
          await Api.post("/user/rawdata/list-new", data).then((res) => {
            if (res.data.status === "success") {
                dispatch(RawdataActions.RawdatabaseDataUpdatedSuccess(res.data))
            }
            if (res.data.status === "failure") {
                toast.error(res.data.msg)
                dispatch(RawdataActions.RawdatabaseDataUpdatedFailure(res.data.msg))
            }
          })
      }catch (err) {
          toast.error("Network Error!")
          dispatch(RawdataActions.RawdatabaseDataUpdatedFailure("Network Error"));
      }
    }
  }
  
  export const fetchRawdataPagination = (data) => {
    return async (dispatch) => {
      
      dispatch(RawdataActions.loaderStart())
      dispatch(RawdataActions.paginationLoading())
      try {
          var userData = JSON.parse(localStorage.getItem("user"))
          await Api.post("/user/rawdata/rawdata-pagination", data).then((res) => {
  
            if (res.data.status === "success") {
                dispatch(RawdataActions.rawDataUpdatedSuccess(res.data))
            }
            if (res.data.status === "failure") {
                toast.error(res.data.msg)
                dispatch(RawdataActions.rawDataUpdatedFailure(res.data.msg))
            }
        })
      }
      catch (err) {
          toast.error("Network Error!")
          dispatch(RawdataActions.rawDataUpdatedFailure("Network Error"));
      }
    }
  }

export default RawdataSlice.reducer;