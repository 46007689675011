import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Badge from 'react-bootstrap/Badge'
// Date Time library
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
// React hook use form
import { useForm } from "react-hook-form";
// Select Search
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import moment from 'moment';
import PhoneCodes from '../../components/json/phoneCodes';
import countryNames from '../../components/json/countryNames';
import statesList from '../../components/json/statesList';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import AddNewContact from './AddNewContact';
import {getDistrictsList, getPersonsList, getLeadStages, getIndustriesList, getAnnualRevenueRangeList, getLeadsList, getAccontManagersList, leadsActions, addNewLeadData, getCompaniesList} from "../../store/leads";
import {getProductsList,getNewProductsList, getRegionsList} from "../../store/dashboard";
import {ContactActions } from "../../store/Contact"
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

   const AddNewLead = React.memo(({districtsData, companiesList,companyContactsList, showAddNewLeadModal,user,productsList,newproductsLists,regionsList,existingLeadStages,notExistingLeadStages,industriesList,annualRevenueRangeList,accountManagersList, getProductsData,getNewProductsData,getRegionsData,getLeadStagesData,getIndustriesData,getAnnualRevenueRangeData,getAccontManagersData,addNewLeadApi, getLeadsData, updateShowAddNewModalData, getCompaniesData, getDistrictsData, getPersonsListData,AddcontactModal,openmodal,leadAlreadyExist, closeDialogWindow}) => {

    const dispatch = useDispatch();
    const userData = user.user;
    const permissions = user.permissions;
    const [leadType, setLeadType] =  useState(true);
    const [showAddContactModal, setShowAddContactModal] = useState(false);
    const { register, handleSubmit, formState: { errors: errors }, setError: setActivityError, reset: reset } = useForm();
    const [industry, setIndustry] = useState({ value: "", value: "" });
    const [meetingId, setMeetingId] = useState(false);
    const [phoneCode, setSelectedPhoneCode] = useState({ label: "India(+91)", value: "+91" });
    const [companyName, setCompanyName] = useState(false);
    const [accountId, setAccountId] = useState(false);
    const [existingCompany, setExistingCompany] = useState(false);
    const [updated, setUpdated] = useState(false);
    // const [isDropdownOpen, setDropdownOpen] = useState(false);



    const [query, setQuery] = useState('');
  const [expandedCategories, setExpandedCategories] = useState([]);
  const [expandedProducts, setExpandedProducts] = useState([]);
  const [expandedSubproducts, setExpandedSubproducts] = useState([]);
  const [subProductsList, setSubProductsList] = useState([]);
  const [productsListData, setProductsListData] = useState(newproductsLists);
    const [expandedAccordions, setExpandedAccordions] = useState([]);
    const [isSearchListVisible, setIsSearchListVisible] = useState(true);
    console.log("account details are//", productsListData)
    const searchContainerRef = useRef(null);

// const productsArry = subProductsList.map(item => item.product_id)

    const activity_status = useRef();
    const remarks = useRef();
    const task_name = useRef();
    const task_description = useRef();
    const action_date = useRef();
    const reminder_date = useRef();
    const initial_meeting_info = useRef();
    const new_meeting_date_time = useRef();
    const contact_name = useRef();
    const street = useRef();
    const company_website = useRef();
// console.log("comapny website is", company_website.current.value)
    const toggleTab = (index) => {
        settoggleTab(index);
    };

    const [toggleState, settoggleTab] = useState(5);
    const [actionDate, setActionDate] = React.useState(moment(threedays).format('LLLL'));
    const [reminderDate, setReminderDate] = React.useState(moment(twodays).format('LLLL'));
    const [newMeetingDateTime, setNewMeetingDateTime] = React.useState(moment(twodays).format('LLLL'));

    const [disable, setDisable] = React.useState(false);
    var threedays = moment().add(3, 'days');
    var twodays = moment().add(2, 'days');

    const colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? "#000" : '#000080',
                color: "white",
                opacity: '1.5'
            };
        }
    };
    useEffect(() => {
        if(newproductsLists?.length>0){
            setProductsListData(newproductsLists)
        }
     },[newproductsLists])



    const formatWeekSelectLabel = (date, invalidLabel) => {
        return `${moment(date).format("MMM Do YYYY LT")}`
      };
    //   const handleSearch = (event) => {
    //     setQuery(event.target.value.toLowerCase());
    //     console.log("inside handle search ", event.target.value.toLowerCase())
    //   };
    const handleSearch = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        setQuery(searchTerm);
      
        // Filter the data based on the search query
        const filteredData = newproductsLists.filter((category) =>
          category.name.toLowerCase().includes(searchTerm) ||
          category.subproducts.some((subproduct) => subproduct.name.toLowerCase().includes(searchTerm)) ||
          category.subproducts.some((subproduct) =>
            subproduct.childProducts.some((childProduct) => childProduct.name.toLowerCase().includes(searchTerm))
          )
        );
    //   console.log("filterd data are inside handle search", filteredData)
        // Update the state with the filtered data
        setProductsListData(filteredData);
      };
      useEffect(() => {
        if (query.trim() === '') {
          // If the query is empty, close all accordions
          setExpandedCategories([]);
          setExpandedProducts([]);
          setExpandedSubproducts([]);
        } else {
          // Open accordions based on search results
          const categoriesToExpand = productsListData
            .filter(
              (category) =>
                category.name.toLowerCase().includes(query) ||
                category.subproducts.some((subproduct) => subproduct.name.toLowerCase().includes(query)) ||
                category.subproducts.some((subproduct) =>
                  subproduct.childProducts.some((childProduct) =>
                    childProduct.name.toLowerCase().includes(query)
                  )
                )
            )
            .map((category) => category.name);
  
          setExpandedCategories(categoriesToExpand);
          console.log('inside filter data are//', categoriesToExpand)
          const productsToExpand = productsListData.reduce((acc, category) => {
            category.subproducts.forEach((product) => {
              if (
                product.name.toLowerCase().includes(query) ||
                product.childProducts.some((childProduct) =>
                  childProduct.name.toLowerCase().includes(query)
                )
              ) {
                acc.push(product.name);
              }
            });
    
            return acc;
          }, []);
    
          setExpandedProducts(productsToExpand);
       
          const subproductsToExpand = productsListData.reduce((acc, category) => {
            category.subproducts.forEach((subproduct) => {
              if (
                subproduct.name.toLowerCase().includes(query) ||
                subproduct.childProducts.some((childProduct) =>
                  childProduct.name.toLowerCase().includes(query)
                )
              ) {
                acc.push(subproduct.name);
              }
            });
    
            return acc;
          }, []);
    
          setExpandedSubproducts(subproductsToExpand);
        }
      }, [query, productsListData]);
    
      useEffect(() => {
        const handleOutsideClick = (event) => {
          // Close the search list if the click is outside the search container
          if (
            searchContainerRef.current &&
            !searchContainerRef.current.contains(event.target)
          ) {
            setIsSearchListVisible(false);

            // setQuery('')
            // If the query is empty, close all accordions
            setExpandedCategories([]);
            setExpandedProducts([]);
            setExpandedSubproducts([]);
          }
        };
    
        // Attach the event listener to the document body
        document.body.addEventListener('click', handleOutsideClick);
    
        // Cleanup the event listener when the component is unmounted
        return () => {
          document.body.removeEventListener('click', handleOutsideClick);
        };
      }, []);
    
      const handleCategoryAccordionChange = (categoryName) => (event, isExpanded) => {
        setExpandedCategories((prevExpanded) =>
          isExpanded
            ? [...prevExpanded, categoryName]
            : prevExpanded.filter((name) => name !== categoryName)
        );
      };
    
      const handleProductAccordionChange = (productName) => (event, isExpanded) => {
        setExpandedProducts((prevExpanded) =>
          isExpanded
            ? [...prevExpanded, productName]
            : prevExpanded.filter((name) => name !== productName)
        );
      };
    
    //   const childProductDataHandle = (listData) => {
    //     if (!subProductsList.includes(listData)) {
    //       setSubProductsList((prevSelected) => [...prevSelected, listData]);
    //     }
    //   };
    const childProductDataHandle = (listData, isChecked) => {
        if (isChecked) {
          // Checkbox is checked, add the data
          setSubProductsList((prevSelected) => [...prevSelected, listData]);
        } else {
          // Checkbox is unchecked, remove the data
          const updatedList = subProductsList.filter(item => item.product_id !== listData.product_id);
          setSubProductsList(updatedList);
        }
      };
    const removeFilterSubProductsBadge = (event) => {
        const element = event.currentTarget;
        const productId = element.getAttribute('data');
        const filterProducts = subProductsList.filter(item => item.product_id !== productId);
        setSubProductsList(filterProducts);
      }
      
    useEffect(() => {
        getNewProductsData();
        getProductsData();
        // getNewProductsData();
        getRegionsData();
        getLeadStagesData();
        getIndustriesData();
        getAnnualRevenueRangeData();
        getAccontManagersData();
        // handleSearch();
        // getProductsListHandle()
    }, []);

 const handleSetCompanyName = (value) => {
    // console.log("again here ", value)
    // If the value is null, clear the selected company
    if (!value) {
        activity.selectedCompany = false
        setCompanyName("");
        setAccountId(null);
        setExistingCompany(true);
        activity.company_name = null;
        activity.industry =null;
        activity.company_website = "";
        activity.annual_revenue = null;
        activity.product = null;
        activity.street = "";
        activity.postal_code =  "";
        activity.department =  "";
        activity.state =  "";
        activity.city = "";
        activity.country =  "";

        setIndustry(false);
             // Clear the company name or set it to an empty string
        // Optionally, reset other relevant state variables here
        return; // Exit the function
    }

    activity.selectedCompany = true
    setCompanyName(value.value);

    if (value.__isNew__ === true) {
        activity.new_company = true
        activity.company_name = value.value;
        setExistingCompany(false);

    } else {
        activity.new_company = false
        // console.log("company address ", value)

        setAccountId(value.data_id);
        setExistingCompany(true);
        activity.company_name = value.value;
        activity.industry = value.industry;
        activity.company_website = value.company_website;
        activity.annual_revenue = value.annual_revenue;
        activity.product = value.product;
        activity.department = value.department;
        activity.street = value.company_address ? value.company_address.street : "";
        activity.postal_code = value.company_address ? Number(value.company_address.postal_code) : "";
        activity.state = value.company_address ? value.company_address.state : "";
        activity.city = value.company_address ? value.company_address.city : "";
        activity.country = value.company_address ? value.company_address.country : "";
        activity.industry = value.industry;
        activity.region = value?.region;
        console.log(" multiple products ", value.products)

        if(value?.products?.length > 0){
            var filteredProducts =  productsList.filter((option) => value.products.includes(option.value))
            activity.products = filteredProducts
            console.log("filtere products if ", filteredProducts)
        }else{
            var filteredProducts =  productsList.filter((option) => [value?.product].includes(option.value))
            activity.products = filteredProducts
            console.log("filteredProducts ", filteredProducts)
        }

        setIndustry({ value: value.industry, label: value.industry });
        activity.state = {value: value?.company_address?.state, label:value.company_address?.state}
        activity.city = {value: value?.company_address?.city, label:value.company_address?.city}
        getPersonsListData(value.data_id);
        if(value?.company_address?.state){
            getDistrictsData(value?.company_address?.state)
        }
        
    }
}

    const getCompaniesList = async (search) => {
        // console.log("get companyies list", activity.selectedCompany)
        if(activity?.selectedCompany){
            setCompanyName("");
            setAccountId(null);
            setExistingCompany(true);
            activity.company_name = null;
            activity.industry =null;
            activity.company_website = "";
            activity.annual_revenue = null;
            activity.product = null;
            activity.street = "";
            activity.postal_code =  "";
            activity.state =  "";
            activity.city = "";
            activity.country =  "";

            setIndustry(false);
        }

        if(search.target.value){
            getCompaniesData({user_id : userData._id, value: search.target.value})
            setExistingCompany(false);
        }

    }

    const handleSetContactName = (value) => {

        setSelectedPhoneCode({ label: value.phone_code || "India(+91)", value: value.phone_code || "India(+91)" });

        if (value.__isNew__ !== true) {
            activity.contact_name = value.label ? value.label : "";
            activity.contact_number = value.contact_number ? value.contact_number : "";
            activity.designation = value.designation;
            activity.email = value.email;
            activity.department = value.department;
            activity.phone_code = value.phone_code ? value.phone_code : "+91";
        }

        if (value.__isNew__ === true) {
            setShowAddContactModal(true);
            openmodal();
          }

        activity.contact_person = value.label;
    }

    const getCityStateCountry = async (event) => {
        activity.postal_code = event.target.value;
    };

    const handleSetPhoneCodes = (value) => {
        setSelectedPhoneCode({ label: value.label, value: value.value });
        activity.phone_code = value.value;
    }

    const handleMeetingDateTimeChange = (date) => {
        var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
        activity.new_meeting_date_time = newDate;
    }

    const handleReminderDateChange = (date) => {

        var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss')
        var action = moment(actionDate)
        let remind = moment(newDate)
        let diff = remind.diff(action, 'minutes')
        
        if(diff > 0){
          let remidDate = moment(actionDate).subtract(30, 'm').format('YYYY-MM-DD HH:mm:ss')
          setReminderDate(remidDate)
          activity.reminder_date = remidDate
          toast.error("Reminder date should be earlier than action date")
          return;
        }else{
          setReminderDate(newDate)
          activity.reminder_date = newDate
        }
        
    };


    const handleActionDateChange = (date) => {

        var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
        var remidDate = moment(date).subtract(30, 'm').format('YYYY-MM-DD HH:mm:ss'); 
        
        setActionDate(newDate);
        setReminderDate(remidDate)
        activity.reminder_date = remidDate;
        activity.action_date = newDate;

    }

    const onInputChange = (e) => {

        setActivity({ ...activity, [e.target.name || e.target?.key]: e.target.value }, []);
        console.log("input", e.target)
        if(e.target.name === "products" || e.target?.key === "products"){
            console.log("activity ", activity)
            return;
        }

        if(e.target.name === "lead_type" && e.target.value === "Prospect"){
            setLeadType(true);
        }

        if(e.target.name === "lead_type" && e.target.value === "Suspect"){
            setLeadType(false);
        }

        console.log("e. target ", e)

        var sales_stage_id = e.currentTarget.dataset.id
        if (sales_stage_id !== undefined) {
            activity.sales_stage = sales_stage_id;
        } else {
            activity.sales_stage = "";
        }
    };


    const [activity, setActivity] = useState({
        company_name: "",
        contact_person: "",
        industry: "",
        phone_code: "+91",
        contact_number: "",
        designation: "",
        email: "",
        department: "",
        company_website: "",
        street: "",
        postal_code: "",
        state: "",
        city: "",
        country: "India",
        // activity_status: " ",
        follow_up_task: "",
        follow_up_task_detail: "",
        follow_up_date: threedays,
        annual_revenue: "",
        lead_id: "",
        sales_stage: "",
        remarks: "",
        product: "",
        products: [],
        meeting_date_time : moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        region: ""

    });

    const addActivityHandle = async (e) => {

        setDisable(true);
        
        // if(activity.products?.length <1){
        //     toast.error("Please select product")
        //     return;
        // }
        if(leadType === "Prospect"){
            if(activity.remarks === null){
                toast.error("Ramarks is required")
                return;
            }

            if(activity.task_name === null){
                toast.error("Task Name is required")
                return;
            }

            if(activity.task_description === null){
                toast.error("Task Description is required")
                return;
            }
         
        }
       
        if(activity.company_website === ""){
            toast.error("Company Website is required")
            return;
        }
        // if(activity.contact_number?.length !== 10){
        //     toast.error("Please enter valid phone Number")
        //     return;
        // }

        toast.info("Uploading data...")

        // setTimeout(() => { }, 500);

        var actionDates = activity.action_date ? activity.action_date : threedays
        var reminderDates = activity.reminder_date ? activity.reminder_date : twodays

        if(Object.keys(activity).length < 1){
            toast.info("Kindly fill reqiured data");
            return;
        }
      
        const productsArry = subProductsList.map(item => item.product_id)
        console.log("all products", productsArry)
        console.log("all products", productsArry[0])
        // New Method 
        const activityData = {
            company_name: activity.company_name,
            contact_name: activity.contact_name,
            industry: activity.industry,
            phone_code: activity.phone_code ? activity.phone_code : "+91",
            contact_number: activity.contact_number?.toString(),
            designation: activity.designation,
            email: activity.email,
            department: activity.department,
            company_website: activity.company_website,
            street: activity.street,
            postal_code: activity.postal_code.toString(),
            state: activity.state,
            city: activity.city,
            country: activity.country || "India",
            lead_stage_id: activity_status.current.value,
            // sales_activity_id: activity.sales_activity_id,
            task_name: activity.task_name,
            task_description: activity.task_description,
            action_date: moment(actionDates).format('YYYY-MM-DD HH:mm:ss'),
            reminder_date: moment(reminderDates).format('YYYY-MM-DD HH:mm:ss'),
            user_id: userData._id,
            annual_revenue: activity.annual_revenue,
            
            // product: activity.product ? activity.product : "", 
            product:productsArry[0],
            products:productsArry,
            isales_assign_to: activity.assigning_account_manager,
            isales_meeting_date_time: moment(newMeetingDateTime).format('YYYY-MM-DD HH:mm:ss'),
            isales_meeting_info: activity.initial_meeting_info,

            lead_type : activity.lead_type,
            meeting_date_time : activity.meeting_date_time,
            region: activity.region,
        }
    
        // activityData.products = activity.products.map((option) => option.value);
        // if(activityData.products?.length>0){
        //     activityData.product =  activityData.products[0]
        // }

        if(activity.state.hasOwnProperty("label")){
            activityData.state = activity.state?.value
        }

        if(activity.city.hasOwnProperty("label")){
            activityData.city = activity.city?.value
        }

        if(activity.remarks){
            activityData.remarks = activity.remarks
        }

        if (meetingId) {
            activityData.meeting_id = meetingId
        }

        try {

            addNewLeadApi(activityData)
            setDisable(false);
            setSubProductsList([])
            // if (addAnotherPerson === true && meetingId === false) {
                // setMeetingId(res.data.savedLead._id)
                // sdlfjlsdjf
            // }

            // if (addAnotherPerson === false) {
                // setPostalCode();
                // setActivity({});
                // setCity("");
                // setState("");
                // setCountry("");
                // setContactsList();
            // }

            // if (addAnotherPerson === true) {
           
            //     activity.contact_name = ({ value: "", label: "" });
            //     activity.contact_number = "";
            //     activity.designation = "";
            //     activity.department = "";
            //     activity.email = "";
            //     setDisable(false);
            // }
            // if (addAnotherPerson === false) {
            //     getLeadsData()
            //     updateShowAddNewModalData(false)
            // }
          
            // if (e.target === undefined) {
            //     setMeetingId(false);
            //     addAnotherPerson = false
            // }

            reset({
                keepErrors: false,
                keepDirty: true,
                keepIsSubmitted: false,
                keepTouched: false,
                keepIsValid: false,
                keepSubmitCount: false,
            })

            // if (addAnotherPerson === false) {
            //     updateShowAddNewModalData(false)
            // }
            getLeadsData()
            setQuery('')
            // If the query is empty, close all accordions
            setExpandedCategories([]);
            setExpandedProducts([]);
            setExpandedSubproducts([]);
              

        } catch (err) {
            setDisable(false);
            setUpdated(!updated);
            // setOpen(true);
            if(err.response && err.response.data.status === "failure"){
                toast.error("Error " + err.response.data.msg)
            }
            if (err.response && (err.response.status === 400)) {
                setActivityError(err.response.data.key, {
                    type: "manual",
                    message: err.response.data.message,
                });
                toast.error(err.response.data.message)
            }
        }

    }

    const handleStateChange = (value) => {
        activity.state = value
        getDistrictsData(value.value)
        
    }
    const handleDistrictChange = (value) => {
        activity.city = value
        setUpdated(!updated)
    }

    const onClickOkay = () => {
        closeDialogWindow(false)
    }

    // useEffect(() => {

    // }, [leadAlreadyExist])


    return(
        <>
        <div>
            <Modal
                show={showAddNewLeadModal}
                size="lg"
                onHide={() => updateShowAddNewModalData(false)}
                backdrop={false}
                disableBackdrop={true}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title">

                {/* <Modal.Header closeButton> */}
                <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Add Lead
                    </Modal.Title>
                    <a onClick={() => updateShowAddNewModalData(false)} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
                </Modal.Header>
                <Modal.Body>
                    {/* onSubmit={handleSubmit(onSubmit)} */}
                    {/* addActivityHandle */}
                    <form key={1}  >
                    {/* onSubmit={handleSubmit(addActivityHandle)} */}
                        <div class="row">
                            <div class="col-md-8 col-md-offset-2">

                        
                            <CreatableSelect
                                isClearable 
                                className="basic-single"
                                classNamePrefix="select"
                                styles={colourStyles}
                                options={companiesList} 
                                placeholder="Search Oganization Name"
                                onChange={handleSetCompanyName}
                                onKeyDown={getCompaniesList}
                            />

                                <br />
                                <br />
                            </div>
                            <div className='col-md-6'>
                                <TextField variant="outlined"
                                    // InputProps={{
                                    //     startAdornment: <InputAdornment position="start"><b></b></InputAdornment>
                                    // }}

                                    {...register("lead_type", {
                                        //     // required: { 
                                        //     //     value: true, 
                                        //     //     message: "Company Name is required" 
                                        //     // },
                                        minLength: {
                                            value: 3,
                                            message: "Lead Type must be at least 3 characters"
                                        }
                                    })}
                                    style={{
                                        borderLeftWidth: 3,
                                        borderLeftColor: 'red',
                                        borderLeftStyle: 'solid'
                                    }}
                                    select
                                    required
                                    value={activity.lead_type}
                                    helperText={errors.lead_type ? errors.lead_type.message : ''}
                                    error={Boolean(errors.lead_type)}

                                    type="text" className="form-control" name="lead_type" label="Lead Type" onChange={e => onInputChange(e)} >
                                    {
                                        ["Prospect", "Suspect"].map(item => (
                                            <MenuItem key={item} selected={"Prospect" === item} value={item}>{item}</MenuItem>
                                        ))
                                    }

                                </TextField>
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            

                            {activity?.new_company ?
                                <div class="col-md-6">
                                <TextField variant="outlined"
                                    {...register("contact_name", {
                                        required: { 
                                            value: true, 
                                            message: "Contact Name is required" 
                                        },
                                        
                                    })}
                                    name="contact_name"
                                    className="form-control" 
                                    label="Contact person"
                                    onChange={e => onInputChange(e)}
                                    helperText={errors.contact_name ? errors.contact_name.message : ''}
                                    error={Boolean(errors.contact_name)} 
                                />
                                </div>
                                
                            : 
                            <div class="col-md-6">
                                <CreatableSelect
                                    closeMenuOnSelect={true}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable="true"
                                    options={companyContactsList}
                                    placeholder="Search Contact Person"
                                    inputRef={contact_name} 
                                    name="contact_name"
                                    onChange={handleSetContactName}
                                    styles={colourStyles}
                                    autoComplete="none"
                                    formatCreateLabel={() => `Add New Contact`}
                                    isValidNewOption={() => true}>
                                </CreatableSelect>
                                </div>
                            }
                            
                            <div class="col-md-6">
                               
                                <TextField variant="outlined"
                                    {...register("industry", {
                                       
                                       
                                    })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    
                                    value={activity.industry}
                                    select
                                    helperText={errors.industry ? errors.industry.message : ''}
                                    error={Boolean(errors.industry)}
                                    onChange={e => onInputChange(e)}
                                   
                                    type="text" className="form-control" inputRef={industry} name="industry" label="Industry"
                                >
                                    {industriesList?.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))
                                    }
                                </TextField>

                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-3">
                                <Select
                                    closeMenuOnSelect={true}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable="true"
                                    options={PhoneCodes}
                                    placeholder="Search code"
                                    
                                    name="phone_code"
                                    defaultValue="+91"
                                    value={phoneCode}
                                    
                                    onChange={handleSetPhoneCodes}
                                    
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    // required
                                />
                            </div>
                            <div className="col-md-3">

                                <TextField variant="outlined" autoComplete="none"
                                    {...register("contact_number", {
                                        pattern: {
                                            value: /^\d{10}$/,
                                            message: "Contact Number must be exactly 10 digits",
                                          },

                                        minLength: {
                                            value: 10,
                                            message: "Contact Number must be at least 10 characters"
                                        },
                                        maxLength: {
                                            value: 10,
                                            message: "Contact Number cannot exceed 10 digits"
                                        }
                                    })}
                                    InputProps={{ inputProps: { minLength: 10, maxLength: 10 } }}
                                    // required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    
                                    onChange={e => onInputChange(e)}
                                    value={activity.contact_number}
                                    helperText={errors.contact_number ? errors.contact_number.message : ''}
                                    error={Boolean(errors.contact_number)}
                                  
                                    type="number" className="form-control" id="contact_number" name="contact_number" label="Contact Number" />
                            </div>
                            <div className="col-md-6">
                                <TextField variant="outlined" autoComplete="none"
                                    {...register("designation", {
                                      
                                        minLength: {
                                            value: 3,
                                            message: "Designation must be at least 3 characters"
                                        }
                                    })}
                                    // required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    
                                    onChange={e => onInputChange(e)}
                                    value={activity.designation}
                                    helperText={errors.designation ? errors.designation.message : ''}
                                    error={Boolean(errors.designation)}
                                    // inputRef={designation}
                                    type="text" className="form-control" id="designation" name="designation" label="Designation" />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-6">
                                <TextField variant="outlined" autoComplete="none"
                                    {...register("email", {
                                     
                                        minLength: {
                                            value: 6,
                                            message: "Email must be at least 6 characters"
                                        }
                                    })}
                                    // required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    
                                    onChange={e => onInputChange(e)}
                                    value={activity.email}
                                    helperText={errors.email ? errors.email.message : ''}
                                    error={Boolean(errors.email)}
                                    // inputRef={email}
                                    className="form-control" type="email" id="email" name="email" label="Email Id" />
                            </div>
                            <div className="col-md-6">
                                <TextField variant="outlined" autoComplete="none"
                                    {...register("department", {
                                        
                                        minLength: {
                                            value: 3,
                                            message: "Department must be at least 3 characters"
                                        }
                                    })}
                                    
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    
                                    onChange={e => onInputChange(e)}
                                    value={activity.department}
                                    helperText={errors.department ? errors.department.message : ''}
                                    error={Boolean(errors.department)}
                                    
                                    className="form-control" id="department" type="text" name="department" label="Department" />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-6">
                                <TextField multiline rows={2} autoComplete="none"
                                    {...register("street", {
                                       
                                        minLength: {
                                            value: 3,
                                            message: "Street must be at least 6 characters"
                                        }
                                    })}
                                    // required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    
                                    value={activity.street}
                                    onChange={e => onInputChange(e)}
                                    helperText={errors.street ? errors.street.message : ''}
                                    error={Boolean(errors.street)}
                                    name="street" className="form-control" inputRef={street} label="Street" variant="outlined" />
                            </div>
                            <div className="col-md-6">
                                <TextField variant="outlined" autoComplete="none"
                                    {...register("company_website", {
                                       
                                        minLength: {
                                            value: 6,
                                            message: "Organization Website must be at least 6 characters"
                                        }
                                    })}
                                    
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={e => onInputChange(e)}
                                    required
                                    value={activity.company_website}
                                    helperText={errors.company_website ? errors.company_website.message : ''}
                                    error={Boolean(errors.company_website)}
                                    type="text" className="form-control" inputRef={company_website} name="company_website" label="Ogranization Website" />
                                <br />

                                <div style={{ marginTop: "15px" }}>
                                    <TextField variant="outlined"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                                        }}
                                       

                                        {...register("annual_revenue", {
                                            
                                            minLength: {
                                                value: 3,
                                                message: "Annual Revenue must be at least 3 characters"
                                            }
                                        })}
                                        select
                                        // required
                                        value={activity.annual_revenue}
                                        helperText={errors.annual_revenue ? errors.annual_revenue.message : ''}
                                        error={Boolean(errors.annual_revenue)}

                                        type="number" className="form-control" name="annual_revenue" label="Annual Revenue" onChange={e => onInputChange(e)} >
                                       

                                        {
                                            annualRevenueRangeList?.map((option, annual_revenue) => (
                                                <MenuItem key={option.value} selected={annual_revenue === option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))
                                        }

                                    </TextField>
                                </div>
                            </div>

                        </div>
                        <br />

                        <div class="row">
                            <div className="col-md-3">
                                

                                    <Select

                                    closeMenuOnSelect={true}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable="true"
                                    options={countryNames}
                                    placeholder="Search country"
                                    
                                    name="country"
                                    defaultValue={countryNames[0]}
                                    value={countryNames[0]}
                                    
                                    // onChange={handleSetPhoneCodes}
                                    // onChange={countryChange}
                                    
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    // required
                                />
                                
                            </div>


                            <div className="col-md-3">
                                {/* <TextField variant="outlined" autoComplete="none"
                                    {...register("state", {
                                      
                                    })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required
                                    helperText={errors.state ? errors.state.message : ''}
                                    value={state}
                                    onChange={e => onInputChange(e)}
                                    error={Boolean(errors.state)}
                                  
                                    type="text" className="form-control" name="state" label="State" /> */}
                                    
                                <Select
                                    closeMenuOnSelect={true}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable="true"
                                    options={statesList}
                                    placeholder="Search State"
                                    
                                    name="state"
                                    value = {activity?.state}
                                    // defaultValue={statesList[0]}
                                    // value={statesList[0]}
                                    
                                    // onChange={handleSetPhoneCodes}
                                    onChange={handleStateChange}
                                    
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    // required
                                />
                            </div>
                            <div className="col-md-6">
                                {/* <Select
                                    isMulti={true}
                                    name="products"
                                    label="Product"
                                    placeholder="Select Product"
                                    options={productsList}
                                    className="basic-multi-select"
                                    onChange={value => onInputChange({target: {name: "products", value:value}})}
                                    value = {activity?.products}
                                    classNamePrefix="select">
                                </Select> */}

                                <TextField variant="outlined"
                                   
                                    style={{
                                        borderLeftWidth: 3,
                                        borderLeftColor: 'red',
                                        borderLeftStyle: 'solid'
                                    }}
                                    select 
                                    multiple
                                    value={activity.region}
                                    helperText={errors.region ? errors.region.message : ''}
                                    error={Boolean(errors.region)}

                                    type="number" className="form-control" name="region" label="Region" onChange={e => onInputChange(e)} >
                                   {/* selected={activity.region === option.value}  */}

                                    {
                                        regionsList?.map((option, region) => (
                                            <MenuItem key={option.value} selected={region === option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                    }

                                </TextField>
                            </div>
                            {/* <div className="col-md-6">
                              


                                {/* <TextField variant="outlined"
                                   

                                    {...register("product", {
                                       
                                        minLength: {
                                            value: 3,
                                            message: "Product must be at least 3 characters"
                                        }
                                    })}
                                    style={{
                                        borderLeftWidth: 3,
                                        borderLeftColor: 'red',
                                        borderLeftStyle: 'solid'
                                    }}
                                    select 
                                    multiple
                                    // required
                                    value={activity.product}
                                    helperText={errors.product ? errors.product.message : ''}
                                    error={Boolean(errors.product)}

                                    type="number" className="form-control" name="product" label="Product" onChange={e => onInputChange(e)} >
                                   

                                    {
                                        productsList?.map((option, product) => (
                                            <MenuItem key={option.value} selected={product === option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                    }


                                </TextField> */}
                            </div>



                            
                        <br />
                        <div className="row">
                            <div className="col-md-3">
                                {/* <TextField variant="outlined" autoComplete="none"
                                    {...register("city", {
                                       
                                    })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required
                                    value={city}
                                    onChange={e => onInputChange(e)}
                                    helperText={errors.city ? errors.city.message : ''}
                                    error={Boolean(errors.city)}
                                    
                                    type="text" className="form-control" name="city" label="City" /> */}


                                    <Select
                                        closeMenuOnSelect={true}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isSearchable="true"
                                        options={districtsData}
                                        placeholder="Search District"
                                        value={activity?.city}
                                        
                                        name="city"
                                        // defaultValue={}
                                        // value={statesList[0]}
                                        
                                        // onChange={handleSetPhoneCodes}
                                        onChange={handleDistrictChange}
                                        
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        // required
                                    />
                            </div>

                            <div className="col-md-3">
                                {/* <TextField variant="outlined" autoComplete="none"
                                    {...register("country", {
                                    
                                    })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required
                                    value={country}
                                    helperText={errors.country ? errors.country.message : ''}
                                    error={Boolean(errors.country)}
                                    onChange={e => onInputChange(e)}
                                   
                                    type="text" name="country" label="Country" className="form-control" /> */}
                                    
                                <TextField variant="outlined" autoComplete="none"
                                    {...register("postal_code", {
                                        
                                        minLength: {
                                            value: 6,
                                            message: "Postal Code must be at least 6 characters"
                                        },
                                        maxLength: {
                                            value: 6,
                                            message: "Postal Code cannot exceed 6 digits"
                                        }
                                    })}
                                    // style={{
                                    //     borderLeftWidth: 3,
                                    //     // borderLeftColor: 'red',
                                    //     borderLeftStyle: 'solid'
                                    // }}
                                   
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    
                                    value={activity.postal_code}
                                    helperText={errors.postal_code ? errors.postal_code.message : ''}
                                    error={Boolean(errors.postal_code)}
                                    // onChange={getCityStateCountry}
                                    onChange={e => onInputChange(e)} 
                                    type="number" label="Postal Code"
                                    
                                    className="form-control" name="postal_code" />
                                
                            </div>

                           

                            
                        
                        </div>
                        <br />

                      
                        <div className="row">
                             <div className="col-md-6">
                             <div ref={searchContainerRef} className="search-container">
                                
      <TextField
        variant="outlined"
        type="text"
        value={query}
        onChange={handleSearch}
        onFocus={() => setIsSearchListVisible(true)}
        className="form-control search_products_field"
        placeholder="Search Products from Below List"
      />

      {isSearchListVisible && (
        <div className="dropdown_content12">
          {productsListData?.map((category, index) => (
            <Accordion
              key={index}
              expanded={expandedCategories.includes(category.name)}
              onChange={handleCategoryAccordionChange(category.name)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`category-${index}-content`}
                id={`category-${index}-header`}
                className="styel_summary"
              >
                <Typography className="accordion-text">
                  {category.name}
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography className="typography_list">
                  {category?.subproducts?.length > 0 && (
                    <ul className="subProducts_list">
                      {category?.subproducts.map((subproduct, subIndex) => (
                        <Accordion
                          key={subIndex}
                          expanded={expandedProducts.includes(subproduct.name)}
                          onChange={handleProductAccordionChange(subproduct.name)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`product-${index}-${subIndex}-content`}
                            id={`product-${index}-${subIndex}-header`}
                            className="styel_summary"
                          >
                            <Typography className="accordion-text">
                              {subproduct.name}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography className="typography_list">
                                {subproduct?.childProducts.length > 0 && (
                                <ul className="childProducts_list">
                                    {subproduct?.childProducts.map((childProduct, childIndex) => (
                                    <li key={childIndex}>
                                        <input
                                        type="checkbox"
                                        id={`checkbox-${childIndex}`}
                                        // checked={}
                                        // onChange={() => childProductDataHandle(childProduct)}
                                        checked={subProductsList.some(item => item.product_id === childProduct.product_id)}
                                        onChange={(e) => childProductDataHandle(childProduct, e.target.checked)}
                                        />
                                        <label htmlFor={`checkbox-${childIndex}`}  className="checkbox-label">
                                          {childProduct.name}
                                        </label>
                                    </li>
                                    ))}
                                </ul>
                                )}
                            </Typography>
                            </AccordionDetails>
                          {/* <AccordionDetails>
                            <Typography className="typography_list">
                              {subproduct.childProducts.length > 0 && (
                                <ul className="childProducts_list">
                                  {subproduct.childProducts.map(
                                    (childProduct, childIndex) => (
                                      <li
                                        onClick={() =>
                                          childProductDataHandle(childProduct)
                                        }
                                        key={childIndex}
                                      >
                                        {childProduct.name}
                                      </li>
                                    )
                                  )}
                                </ul>
                              )}
                            </Typography>
                          </AccordionDetails> */}
                        </Accordion>
                      ))}
                    </ul>
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}
                             </div>
                                </div>
                                <div className="col-md-6">
                                {
                                subProductsList?.length > 0 ?
                                    subProductsList.map((filter) => (
                                    <Badge onClick={removeFilterSubProductsBadge}
                                        pill data={filter.product_id} value={filter.product_id} style={{ cursor: 'pointer', background: 'grey', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.name}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                                    ))
                                    : ""
                                }
                                {/* {
                                subProductsList?.length > 0 ?
                                    subProductsList.map((filter) => (
                                    <Badge onClick={removeFilterSubProductsBadge}
                                        pill data={filter} value={filter} style={{ cursor: 'pointer', background: 'grey', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                                    ))
                                    : ""
                                } */}
                                </div>
                        </div>
                       <br />

                            <div className={leadType ? "showLeadType meeting_border shadow withText" : "hideLeadType meeting_border shadow withText"} style={{border:'1px solid #c4c4c4', padding:'1rem' , background:'#fff', }}>
                            <label className='labels'>Meeting Details (<span style={{fontSize:'10px'}}>All fields mandatory to add new meeting</span>)</label>
                            <br />
                            
                            <div className="row">
                           
                                <div className="col-md-6">
                                    <TextField 
                                    // required
                                        multiline 
                                        rows={2}
                                        variant="outlined"
                                        inputRef={remarks}
                                        helperText={errors.remarks ? errors.remarks.message : ''}
                                        error={Boolean(errors.remarks)}
                                        onChange={e => onInputChange(e)}
                                        label="Meeting Discussion"
                                        value={activity.remarks}
                                        { ...leadType ? "required" : ""}
                                        // style={{
                                            
                                        //     borderLeftWidth: 3,
                                        //     borderLeftColor: 'red',
                                        //     borderLeftStyle: 'solid'
                                        // }}
                                        name="remarks" className="form-control" />
                                </div>
                       
                                <div className="col-md-6">
                                    <div className="row">
                                    <TextField autoComplete="none"
                                    // required
                                        inputRef={activity_status}
                                        className="form-control"
                                        id="standard-select-currency"
                                        select
                                        // style={{
                                        //     borderLeftWidth: 3,
                                        //     borderLeftColor: 'red',
                                        //     borderLeftStyle: 'solid'
                                        // }}
                                        { ...leadType ? "required" : ""}
                                        helperText={errors.activity_status ? errors.activity_status.message : ''}
                                        error={Boolean(errors.activity_status)}
                                        onChange={e => onInputChange(e)}
                                       
                                        label="Select Lead Stage"
                                        variant="outlined"
                                        value={activity.activity_status}
                                       
                                        name="activity_status"
                                       

                                        {...register("activity_status", {
                                          
                                        })}
                                    >
                                       
                                        {existingCompany ?

                                            existingLeadStages?.map((option) => (
                                                <MenuItem key={option.value} data-id={option.sales_stage} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))
                                            :
                                            notExistingLeadStages?.map((option) => (
                                                <MenuItem key={option.value} data-id={option.sales_stage} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))
                                        }

                                    </TextField>

                                  
                                    </div>
                                    <br />
                                    <div className="row">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DateTimePicker
                                        required
                                            className="form-control"
                                            label="Meeting Date Time"
                                            inputVariant="outlined"
                                            value={activity.meeting_date_time}
                                            onChange={(date) => setActivity({...activity, meeting_date_time: moment(date).format('YYYY-MM-DD HH:mm:ss') })}
                                            labelFunc={formatWeekSelectLabel}
                                            showTodayButton
                                            // disablePast
                                            maxDate={new Date()}
                                            minDate={new Date()}
                                            inputRef={action_date}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                                
                            </div>
                            
                            </div>
                        
                        {/* Container for toggle tabs  */}
                                    
                        <div className="container">
                            <div className="lock_tabs"  style={{whiteSpace:"nowrap", maxWidth: '500px', overflowX: 'auto', marginTop:"1rem" }}>
                            
                                <a className={toggleState === 5 ? "tab active_tab" : "tab"} onClick={() => toggleTab(5)} >
                                    Add New Task
                                </a>
                                {permissions.includes('isalesFixNewMeeting')  && 
                                    <a className={toggleState === 4 ? "tab active_tab" : "tab"} onClick={() => toggleTab(4)} >
                                        Fix New Meeting
                                    </a>
                                }
                                
                            </div>
                            
                            <div className="tab_content">
                                <div className={toggleState === 5 ? "content active_content" : "content"} >
                                <br />

                                    <div className={leadType ? "showLeadType" : "hideLeadType"}>
                                        {/* <div className="row headerBand" >
                                            <label>{"Add New Task"}</label>
                                        </div>
                                        <br /> */}
                                        <div class="row">
                                            <div>
                                                <div className="row">
                                                    <div className="col-md-10">
                                                        <TextField variant="outlined"
                                                            type="text" label="Action Item Name"
                                                            inputRef={task_name}
                                                            name="task_name"
                                                            { ...leadType ? "required" : ""}
                                                            value={activity.task_name}
                                                            onChange={e => onInputChange(e)}
                                                            className="form-control" />
                                                    </div>
                                                </div>
                                                <br />
                                                <div className="row">
                                                    <div className="col-md-10 ">
                                                        <TextField multiline rows={2}
                                                            variant="outlined"
                                                            type="text" label="Action Item Description"
                                                            inputRef={task_description} name="task_description"
                                                            onChange={e => onInputChange(e)}
                                                            value={activity.task_description}
                                                            { ...leadType ? "required" : ""}
                                                            className="form-control" />
                                                    </div>
                                                </div>
                                                <br />
                                                <div className="row">
                                                   

                                                    <div className="col-md-5">
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <DateTimePicker
                                                                className="form-control"
                                                                label="Action Date"
                                                                inputVariant="outlined"
                                                                value={actionDate}
                                                                onChange={handleActionDateChange}
                                                                labelFunc={formatWeekSelectLabel}
                                                                showTodayButton
                                                                disablePast
                                                                inputRef={action_date}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                          
                                                            />

                                                         
                                                        </MuiPickersUtilsProvider>
                                                    
                                                    </div>

                                                    <div className="col-md-5">
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <DateTimePicker
                                                                className="form-control"
                                                                label="Reminder Date"
                                                                inputVariant="outlined"
                                                                value={reminderDate}
                                                                onChange={handleReminderDateChange}
                                                                labelFunc={formatWeekSelectLabel}
                                                                showTodayButton
                                                                disablePast
                                                                inputRef={reminder_date}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            
                                                            />

                                                          
                                                        </MuiPickersUtilsProvider>

                                                    </div>

                                                </div>
                                                <br />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className={toggleState === 4 ? "content active_content" : "content"} >
                                {permissions.includes('isalesFixNewMeeting') ?
                                    <div>
                                     
                                        <br />
                                        <div class="row">
                                            <div>
                                                <div className="row">
                                                    <div className="col-md-5">

                                                        <TextField variant="outlined"
                                                            {...register("assigning_account_manager", {
                                                                minLength: {
                                                                    value: 3,
                                                                    message: "Account Manager must be at least 1 characters"
                                                                }
                                                            })}
                                                           
                                                            select
                                                           
                                                            value={activity.assigning_account_manager}
                                                            helperText={errors.assigning_account_manager ? errors.assigning_account_manager.message : ''}
                                                            error={Boolean(errors.assigning_account_manager)}

                                                            type="text" className="form-control" name="assigning_account_manager" label="Select Account Manager" onChange={e => onInputChange(e)} >
                                                          

                                                            {
                                                                accountManagersList?.map((option, product) => (
                                                                    <MenuItem key={option.value} selected={product === option.value} value={option.value}>
                                                                        {option.label}
                                                                    </MenuItem>
                                                                ))
                                                            }

                                                        </TextField>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <DateTimePicker
                                                                className="form-control"
                                                                label="Meeting Date & Time"
                                                                inputVariant="outlined"
                                                                value={newMeetingDateTime}
                                                                onChange={handleMeetingDateTimeChange}
                                                                labelFunc={formatWeekSelectLabel}
                                                                showTodayButton
                                                                disablePast
                                                                inputRef={new_meeting_date_time}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            // format='yyyy-MM-dd'
                                                            />

                                                        </MuiPickersUtilsProvider>

                                                    </div>
                                                    <br />
                                                </div>
                                                <br />

                                                <div className="row">
                                                    <div className="col-md-10 ">
                                                        <TextField multiline rows={2}
                                                            variant="outlined"
                                                            type="text" label="Initial Meeting Information"
                                                            inputRef={initial_meeting_info} name="initial_meeting_info"
                                                            onChange={e => onInputChange(e)}
                                                            value={activity.initial_meeting_info}
                                                            // required
                                                            className="form-control" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                : ""}
                                <br />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                           
                        </div>
                        <br />
                        <div className="row">
                            <div >
                               
                                <a disabled={disable} onClick={() => addActivityHandle()}  className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" id="submit"  >Submit</a>&nbsp;&nbsp;
                                


                            </div>
                        </div>
                    </form>
                   
                </Modal.Body>
            </Modal>
            </div>                                                                      
            {/* {showAddContactModal ? <AddNewContact show={showAddContactModal} refreshContactslist={() => getPersonsList()} accountId={accountId} companyName={companyName}  onHide={() => setShowAddContactModal(false)} /> : ""} */}
            {AddcontactModal ? <AddNewContact show={AddcontactModal} keyData="CommonKeyData" otherCompanyName={companyName} otherAccountId={accountId} onHide={() => dispatch(ContactActions.AddContactcloseModal())}/> : ""}

            <Dialog
                open={leadAlreadyExist}
                onClose={onClickOkay}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Account already exist"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    The Entered Account already exist in the database. It is moved to temp leads for approval.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button  autoFocus onClick={onClickOkay}>
                    Understood
                </Button>
                </DialogActions>
            </Dialog>
        </>   
    )
});
           
const mapStateToProps = (state) => ({
    user : state.auth.user,
    productsList:state.dashboard.productsList,
    newproductsLists :state.dashboard.newproductsList,
    regionsList : state.dashboard.regionsList,
    existingLeadStages : state.leads.existingLeadStages,
    notExistingLeadStages : state.leads.notExistingLeadStages,
    industriesList : state.leads.industriesList,
    annualRevenueRangeList : state.leads.annualRevenueRangeList,
    accountManagersList : state.leads.accountManagersList,
    showAddNewLeadModal : state.leads.showAddNewLeadModal,
    companiesList : state.leads.companiesList,
    companyContactsList : state.leads.companyContactsList,
    districtsData: state.leads.districtsData,
    AddcontactModal : state.Contactlist.AddcontactModal,
    leadAlreadyExist : state.leads.leadAlreadyExist
});

const mapDispatchToProps = (dispatch) => ({
    getProductsData : () => dispatch(getProductsList()),
    getNewProductsData :() => dispatch(getNewProductsList()),
    getRegionsData : () => dispatch(getRegionsList()),
    getLeadStagesData : () => dispatch(getLeadStages()),
    getIndustriesData : () => dispatch(getIndustriesList()),
    getAnnualRevenueRangeData : () => dispatch(getAnnualRevenueRangeList()),
    getAccontManagersData : () => dispatch(getAccontManagersList()),
    getLeadsData : () => dispatch(getLeadsList()),
    updateShowAddNewModalData : (data) => dispatch(leadsActions.updateShowAddNewModal(data)),
    addNewLeadApi : (data) => dispatch(addNewLeadData(data)),
    getCompaniesData : (data) => dispatch(getCompaniesList(data)),
    getPersonsListData : (data) => dispatch(getPersonsList(data)),
    getDistrictsData : (data) => dispatch(getDistrictsList(data)),
    openmodal :(data) => dispatch(ContactActions.AddContactopenModal(data)),
    closeDialogWindow : (data) => dispatch(leadsActions.updateShowDialogWindow(data))
})
  
export default connect(mapStateToProps,mapDispatchToProps)(AddNewLead)