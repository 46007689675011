import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import { getMuiTheme, headerProps } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import CallActionModal from '../pageModals/CallActionModal';
import ViewLead from '../pageModals/ViewLead';
import moment from 'moment-timezone';
import {CallrecordsActions, getColumnsList} from "../../store/Callrecords"
import{leadsActions,updateKey} from "../../store/leads"
import {ContactColumnUpdateddata,} from "../../store/Common"
import{updateViewRowDetails} from "../../store/leads"
import { connect, useSelector ,useDispatch} from 'react-redux';
import { Alert } from '@mui/material';



const CallRecordsTable = React.memo (({callrecorddata,showUpdateLeadModal,callactionmodal,callcolumnupdate,isFetching,callrecordlists,callactionopenmodal,setCallRecordId,setRowLeadId,setRowMetaData,setRowId,setAccountId,showViewLeadModal,updateViewRowData,updateKeyData,setCalltype, updateColumns, columnsList, getColumnsListData}) => {
  
  const dispatch = useDispatch();
  const calldata = callrecordlists

  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const permissions = user.permissions;
  // const [columnsList, setColumnsList] = useState([]);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [dcrTypeOrNot, setDcrTypeOrNot] =useState(true)
  // const [rowId, setRowId] = useState("");
  // const [rowMetaData, setRowMetaData] = useState("");
  // const [accountId, setAccountId] = useState(false);
  const [showCallActionModal,setShowCallActionModal]= useState("");
  // const [showViewLeadModal,setShowViewLeadModal]= useState("");
  // const[rowLeadId,setRowLeadId]= useState("")
  // const [callRecordId, setCallRecordId] = useState(null)
  const [tableData, setTableData] = useState([]);



  const onCellClickHandler = (meta) => {
    if (meta.colIndex === 1) {
      setShowAccountModal(true);
      setRowMetaData(meta);
      setRowId(tableData[meta.dataIndex].account_id?._id)
    }
  }
  useEffect(() => {
    getColumnsListData()
  }, [])


  const filterDCRType = async (type) => {
    setDcrTypeOrNot(type)

    var data = [];
    if (type === false) {
      data = calldata.filter((data) => !data.account_id);
      
    } else {
      data = calldata.filter((data) => data.account_id)
      
    }
    setTableData(data);
  }

  useEffect(() => {

    filterDCRType(true)

  }, [calldata]);

  

  const callactionHandle = async (event, data, meta) => {
      let newRowData = tableData[meta]
    
    setCalltype(newRowData.call_type)
    // // setCallRecordId(meta)
    // setRowLeadId(data)
    setCallRecordId(tableData[meta]._id)
    setRowLeadId(tableData[meta].lead_id)
    callactionopenmodal()
    // setShowCallActionModal(true)
  }

  const meetdropactionHandle = async (event, data, meta) => {
  
    let newRowData = tableData[meta]
    
    // setRowMetaData(newRowData);
    // setRowId(newRowData.lead_id)
    // setAccountId(newRowData.account_id);
    // setShowViewLeadModal(true);
    updateViewRowData({meta: meta, rowId:tableData[meta].lead_id, accountId:tableData[meta].account_id })
    setRowMetaData(newRowData);
    setRowId(newRowData.lead_id);
     setAccountId(newRowData.account_id);
    updateKeyData("showUpdateLeadModal", true)
    //  showViewLeadModal()


  }



  const columns = [
    {
      name: "_id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "account_manager",
      label: "Account Manager",
      options: {
        display: columnsList?.includes('account_manager') ? true : false,
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "company_name",
      label: "Company Name",
      options: {
        display: columnsList?.includes('company_name') ? true : false,
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "lead_id",
      label: "Action",
      options: {
        filter: true,
        sort: false,
        empty: true,
        filterOptions: {
          renderValue: val => {
          }
        },
      display: (columnsList?.includes('lead_id') || dcrTypeOrNot) ? true : false,
        onRowClick: false,
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          var status = tableMeta.rowData[15]
          if(!status){
            return (

              <div style={{ display: "flex", textAlign: 'center', justifyItems: 'center', justifyContent: 'center' }}>
                <>
                  <a style={{ textAlign: 'center' }} className="btn-sm btn-group-sm btn btn-table-commone btn-success " onClick={(e) => { callactionHandle(e, value, tableMeta.rowIndex) }}>
                    <span style={{ cursor: 'pointer', textAlign: 'center', paddingTop:'5px', width: '48px', height: '20px' }}>Call</span>
                  </a>
                  &nbsp;&nbsp;

                  <a style={{ textAlign: 'center' }} className="btn-sm btn-group-sm btn btn-table-commone btn-success " onClick={(e) => { meetdropactionHandle(e, value, tableMeta.rowIndex) }}>
                    <span style={{ cursor: 'pointer', textAlign: 'center', paddingTop:'5px', width: '48px', height: '20px'  }}>Meet</span>
                  </a>
                  &nbsp;&nbsp;
                
                
                  <a style={{ textAlign: 'center' }} className="btn-sm btn-group-sm btn btn-table-commone btn-danger" onClick={(e) => { meetdropactionHandle(e, value, tableMeta.rowIndex) }}>
                    <span style={{ cursor: 'pointer', textAlign: 'center', paddingTop:'5px', width: '48px', height: '20px'  }} >Drop</span>
                  </a>
                </>
              </div>
            );
              }else{
                return <span>{status}</span>
              }
        }
      }
    },
    {
      name: "company_website",
      label: "Company Website",
      options: {
        display: columnsList?.includes('company_website') ? true : false,
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "industry",
      label: "Industry",
      options: {
        display: columnsList?.includes('industry') ? true : false,
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "contact_person",
      label: "Contact Person",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('contact_person') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
        name: "designation",
        label: "Designation",
        options: {
          filter: true,
          sort: true,
          display: columnsList?.includes('designation') ? true : false,
          setCellHeaderProps: headerProps,
        }
      },
      {
        name: "contact_number",
        label: "Contact No",
        options: {
          filter: true,
          sort: true,
          display: columnsList?.includes('contact_number') ? true : false,
          setCellHeaderProps: headerProps,
        }
      },
      {
        name: "email",
        label: "Email Id",
        options: {
          filter: true,
          sort: true,
          display: columnsList?.includes('email') ? true : false,
          setCellHeaderProps: headerProps,
        }
      },
      {
        name: "product",
        label: "Product",
        options: {
          filter: true,
          sort: true,
          display: columnsList?.includes('product') ? true : false,
          setCellHeaderProps: headerProps,
        }
      },
      {
        name: "call_type",
        label: "Call Type",
        options: {
          filter: true,
          sort: true,
          display: columnsList?.includes('call_type') ? true : false,
          setCellHeaderProps: headerProps,
        }
      },
      {
        name: "call_objective",
        label: "Call Objective",
        options: {
          filter: true,
          sort: true,
          display: columnsList?.includes('call_objective') ? true : false,
          setCellHeaderProps: headerProps,
        }
      },
      {
        name: "meeting_date_time",
        label: "Calling Date & Time",
        options: {
          display: columnsList?.includes('meeting_date_time') ? true : false,
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY LT') : ""
            ),
            setCellHeaderProps: headerProps,
          }
      },
      {
        name: "remarks",
        label: "Remarks",
        options: {
            setCellProps: (value, tableMeta) => (
                {
                  style: {
                    whiteSpace: "nowrap",
                    textColor: '#FFFFFF',
                    fontWeight: 'bold',
                    width: '500px',
                    maxWidth: '500px',
                    overflow: 'auto',
                    color: '#243d6a',
                  }
                }),
          filter: true,
          sort: true,
          display: columnsList?.includes('remarks') ? true : false,
          setCellHeaderProps: headerProps,
        }
      },
      {
        name: "status",
        label: "Status",
        options: {
          display: columnsList?.includes('status') ? true : false,
          filter: false,
          sort: false,
          empty: true,
        }
      }
   
  ];

  const LoaderText = () => {
    return   isFetching ? <div className="parent-div">
    <div className="loader"></div>
  </div>:"No Data Found.." 
 }

  const options = {
    textLabels: {
      body: {
        noMatch: LoaderText()
      }
    },
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    rowsPerPageOptions : [20, 50, 100, 500, 1000, 1500, 2000, 2500],
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: (window.innerHeight - 130).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 130).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    print: false,
    setRowProps: (row, dataIndex, rowIndex) => {
        if (row[22]) {
          if (row[22]['postponed_date']) {
            var difference = moment(moment(row[22]['postponed_date']), "DD/MM/YYYY HH:mm:ss").diff(moment(), "DD/MM/YYYY HH:mm:ss");
          } else {
            var difference = moment(moment(row[22]['action_date']), "DD/MM/YYYY HH:mm:ss").diff(moment(), "DD/MM/YYYY HH:mm:ss");
          }
          return {
          };
        } else {
          return {
          }
        }
      },
    onViewColumnsChange: async (changedColumn, action) => {
      var columns = Object.assign([], columnsList)
      if (action === "add") {
        columns.push(changedColumn);
      }

      if (action === "remove") {
        var columns = columns.filter(item => item !== changedColumn);

      }

      const updatedData = {
        user_id: userData._id,
        table_name: "callrecords",
        columns_list: columns,
      }

      callcolumnupdate(updatedData)
      updateColumns(columns)
    },
    download: false,
    onCellClick: (colData, cellMeta) => onCellClickHandler(cellMeta),
  };


  return (
    
   <>
            <React.Fragment>
                {/* <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={"Call Records"}
                        data={calldata}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider> */}


                <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={<div style={{ display: "flex", flexDirection: "row", justifyContent:"flex-start", alignItems:"center" }} >
              <b>Call Records</b>&nbsp;&nbsp;&nbsp;
              <span onClick={() => filterDCRType(true)} className={dcrTypeOrNot === true ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> Lead list </span>&nbsp;&nbsp;
              <span onClick={() => filterDCRType(false)} className={dcrTypeOrNot === false ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> RawData list </span>&nbsp;&nbsp;
            </div>
           
            }
            data={tableData}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
            </React.Fragment>

           
               {callactionmodal ? <CallActionModal /> : ""}
               {showUpdateLeadModal ? <ViewLead show={showUpdateLeadModal}  onHide={() => updateKeyData("showUpdateLeadModal", false)}  /> : ""}           
    </>
  )
});

const mapStateToProps = (state) => ({
    user : state.auth.user,
    callactionmodal:state.Callrecordlist.callactionmodal,
    isFetching : state.Callrecordlist.isFetching,
    callrecordlists:state.Callrecordlist.callrecordlists,
    showUpdateLeadModal : state.leads.showUpdateLeadModal,
    columnsList : state.Callrecordlist.columnsList,
});

const mapDispatchToProps = (dispatch) => ({
  callcolumnupdate :(data) => dispatch(ContactColumnUpdateddata(data)),
  callactionopenmodal : () => dispatch(CallrecordsActions.callactionmodal_openModal()),
  setCallRecordId : (data) => dispatch(CallrecordsActions.setCallRecordId(data)),
  setRowLeadId :(data) => dispatch(CallrecordsActions.setRowLeadId(data)),
  setRowMetaData:(data) => dispatch(CallrecordsActions.setRowMetaData(data)),
  setRowId:(data) =>dispatch(CallrecordsActions.setRowId(data)),
  setAccountId:(data) => dispatch(CallrecordsActions.setAccountId(data)),
  showViewLeadModal:() => dispatch(leadsActions.viewLeadmodal_openModal()),
  updateKeyData : (key, value) => dispatch(updateKey(key, value)),
  updateViewRowData : (data) => dispatch(updateViewRowDetails(data)),
  setCalltype : (data) => dispatch(CallrecordsActions.setCalltype(data)),
  updateColumns : (data) => dispatch(CallrecordsActions.updateColumns(data)),
  getColumnsListData : () => dispatch(getColumnsList())
});

export default connect(mapStateToProps,mapDispatchToProps) (CallRecordsTable)