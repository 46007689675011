import { createSlice } from "@reduxjs/toolkit";
import Api  from './axios';
import { toast } from 'react-toastify';
var userObj = JSON.parse(localStorage.getItem("user")) || null

const intialAuthState = {  
    isFetching:false,
    error: false,
    errorPayload : false,
    user:userObj,
    opportunitiesList: [],
    leadsListCount: 0,
    page: 0,
    rowsPerPage: 20,
    columnsList: [],
    msg: "",
    subPartnersList: [],
    showUpdateLeadModal: false,
    showCreateTimeSheetModal : false,
    showUpdateTimeSheetModal : false,
    viewModalData: {},
    taskListFilter : {},
    tasksList: [],
    columnsList:[]

}

const timesheetSlice = createSlice({
    name: 'timesheet',
    initialState: intialAuthState,
    reducers: {
        loaderStart(state, action) {
            state.isFetching = true
            state.msg = "Fetching..."
        },
        loaderStop(state,action){
            state.isFetching = false
            state.msg = "Fetching..."
        },
        tasksListSuccess(state, action) { 
            state.isFetching = false
            state.error = false
            state.errorPayload = false
            state.tasksList = action.payload
        },
        tasksListFailure(state, action){
            state.isFetching = false
            state.error = true
            state.errorPayload = action.payload
            state.tasksList = []
        },
        tasksListUpdateSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.msg = action.payload
        },
        tasksListUpdateFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.msg = ""
        },
        addNewTaskSuccess(state, action){
            state.isFetch = false
            state.error = false
            state.errorPayload = false
            state.msg = action.payload
        },
        addNewTaskFailure(state, action){
            state.isFetch = false
            state.error = true
            state.errorPayload = action.payload
            state.msg = ""
        },
        updateShowModalState(state, action){
            state.showCreateTimeSheetModal = action.payload
        },
        updateModalViewState(state, action){
            state.showUpdateTimeSheetModal = action.payload
            if(!action.payload){
                state.viewModalData = {}
            }
        },
        updateViewModalDat(state, action){
            state.viewModalData = action.payload
            state.showUpdateTimeSheetModal = true
            
        },
        updateFilters(state, action){
            
            state.taskListFilter = action.payload
        },
        columnsListSuccess(state, action){
            state.isFetch = false
            state.error = true
            state.columnsList = action.payload
            state.msg = ""
        },
        columnsListFailure(state, action){
            state.isFetch = false
            state.error = true
            state.columnsList = []
            state.msg = ""
        },
        updateColumns(state, action){
            state.columnsList = action.payload
        }

    }
});

export const timesheetActions = timesheetSlice.actions;


export const LoaderStart = () => {
    return async(dispatch) => {
        dispatch(timesheetActions.loaderStart())
    }
}

export const LoaderStop = () => {
    return async(dispatch) => {
        dispatch(timesheetActions.loaderStop())
    }
}



export const getTasksList = (data) => {
    return async (dispatch) => {
        dispatch(timesheetActions.loaderStart())
        dispatch(timesheetActions.updateFilters(data))
        try {
            await Api.post("/task/list", data).then((res) => {
                    if (res.data.status === "success") {
                        
                        dispatch(timesheetActions.tasksListSuccess(res?.data?.tasks_list))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(timesheetActions.tasksListFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error")
            dispatch(timesheetActions.tasksListFailure("Network Error"));
        }
    }
  }

  export const updateColumns = (data) => {
    return async (dispatch) => {
        dispatch(timesheetActions.loaderStart())
        try {
            await Api.post("/columns-hide-show/create", data).then((res) => {
                    if (res.data.status === "success") {
                        toast.success("Task Created Successfully!")
                        dispatch(timesheetActions.tasksListUpdateSuccess("Columns Updated"))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(timesheetActions.tasksListUpdateFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error")
            dispatch(timesheetActions.tasksListUpdateFailure("Network Error"));
        }
    }
  }

  export const addNewDailyTask = (data) => {
    return async (dispatch, getState) => {
        dispatch(timesheetActions.loaderStart())
        const { timesheet } = getState();
        try {
            await Api.post("/task/create", data).then((res) => {
                    if (res.data.status === "success") {
                        toast.success("Task Created Successfully!")
                        dispatch(timesheetActions.addNewTaskSuccess("Task Created Successfully!"))
                        dispatch(timesheetActions.updateShowModalState(false))
                        dispatch(getTasksList(timesheet.taskListFilter))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(timesheetActions.addNewTaskFailure(res.data.msg))
                    }
                })
        }
        catch (err) {
            toast.error("Network Error")
            dispatch(timesheetActions.addNewTaskFailure("Network Error"));
        }
    }
  }
  export const updateShowModal = (data) => {
    return async (dispatch) => {
        
        dispatch(timesheetActions.updateShowModalState(data))
    }
  }
  export const updateViewModal = (data) => {
    return async (dispatch) => {
        dispatch(timesheetActions.updateModalViewState(data))
    }
  }
  
  
  export const updateDailyTask = (data) => {
    return async (dispatch, getState) => {
        dispatch(timesheetActions.loaderStart())
        const { timesheet } = getState();
        try {
            await Api.post("/task/update", data).then((res) => {
                    if (res.data.status === "success") {
                        if(data.task_status === "Closed"){
                            toast.success("Task Closed!")
                        }else{
                            toast.success("Task Updated!")
                        }
                        dispatch(timesheetActions.addNewTaskSuccess("Data Updated!"))
                        dispatch(timesheetActions.updateModalViewState(false))
                        dispatch(getTasksList(timesheet.taskListFilter))
                    }
                    if (res.data.status === "failure") {
                        toast.error(res.data.msg)
                        dispatch(timesheetActions.addNewTaskFailure(res.data.msg))
                    }
                    // 
                })
        }
        catch (err) {
            toast.error(err)
            dispatch(timesheetActions.addNewTaskFailure("Network Error"));
        }
    }
  }

  export const getColumnsList  = () => {
    return async(dispatch) => {
        dispatch(timesheetActions.loaderStart("meetingloader"))
  
        try {
          var userData = JSON.parse(localStorage.getItem("user"))
            await Api.get("/columns-hide-show/list/timesheet/"+ userData?.user?._id).then((res) => {
                
                if(res.data.status === "success") {
                  dispatch(timesheetActions.columnsListSuccess(res.data.columnsList?.columns_list));
                  // toast.success(`${activityData.leave_type} Successfully Added...!`)
                    // dispatch(LeaverequestActions.createleaveSuccess(res.data));
                    // dispatch(getleavelist())
                }
                if(res.data.status ==="failure") {
                 
                    dispatch(timesheetActions.columnsListFailure(res.data.msg))
                }
            })
        }
        catch (err) {  
          dispatch(timesheetActions.columnsListFailure("Network Error"));
        }
    }
    }
  
//   export const updateTaskModal = (data) => {
//     // console.log("data", data)
//     return (dispatch) => {
//         // console.log("dis", data);
//         try {
//             console.log("dispatch", data)
//             dispatch(timesheetActions.updateViewModalDat(data))
//         }
//         catch (err) {
//             console.log("in catch", err)
//         }
//     }
//   }

export default timesheetSlice.reducer;