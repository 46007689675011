import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import moment from 'moment-timezone';
import { getMuiTheme, headerProps } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import LeaveRequestReject from "../../components/pageModals/LeaveRequestReject";
import { connect, useDispatch, useSelector } from 'react-redux';
import {approvedLeave,getcancelLeave,LeaverequestActions,getLeaveListColumns} from "../../store/Leaverequest"
import{ContactColumnUpdateddata} from "../../store/Common"

const LeaveRequestTable = React.memo (({leavelist,cancelLeavedata,isFetching,leaveupdatecolumn,RejectLeaveRejectId,Rejectopenmodal,RejectshowModal,approvedleavedata,getLeaveListColumnsData, columnsList, updateLeaveColumns}) => {

  const dispatch = useDispatch();
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const permissions = user.permissions;
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [activityShow, setActivityShow] = useState(false);
  // const [columnsList, setColumnsList] = useState([]);


  const [rejectLeaveRejectId, setRejectLeaveRejectId] = useState(null);

  useEffect(() => {
    getLeaveListColumnsData();
  }, []);

 



  // const getColumnsList = async () => {
  //   const res = await axios.get("/columns-hide-show/list/leaverequests/" + userData._id);
  //   setColumnsList(res.data.columnsList ? res.data.columnsList.columns_list : []);
  // }


  // const handleClose = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   setOpen(false);
  // };


  const approveLeave = async (event, data, meta) => {
    event.stopPropagation();

    var updatedData = {
      'approval_status': "approved",
      'leave_request_id': meta,
    }

    approvedleavedata(updatedData);
  }


  const rejectLeave = async (event, data, meta) => {

    // setRejectLeaveRejectId(meta);
    // setShowRejectModal(true);
    Rejectopenmodal()
    RejectLeaveRejectId(meta)
  }
  
  const cancelLeave = async (event, data, meta) => {
    event.stopPropagation();

    var updatedData = {
      'approval_status': "cancelled",
      'leave_request_id': meta,
    } 
cancelLeavedata(updatedData)
 
   
  }

  const columns = [
    {
      name: "_id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "requestor_id",
      label: "Name of Requestor",
      options: { 
        customBodyRender: (value) => (
          value ? value.first_name + " " + value.last_name : "user not found"
        ),
        display: columnsList?.includes('requestor_id') ? true : false,
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "manager_id",
      label: "Manager",
      options: {
        display: columnsList?.includes('manager_id') ? true : false,
        filter: true,
        customBodyRender: (value) => (
          value ? value.first_name + " " + value.last_name : "user not found"
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "leave_type",
      label: "Leave Type",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('leave_type') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "no_of_days",
      label: "No of Days",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('no_of_days') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "approval_status",
      label: "Leave Approval & Reject",
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: columnsList?.includes('approval_status') ? true : false,
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          var manager_id = tableMeta.rowData[2]
          if (value === "pending" && (userData._id == manager_id?._id)  && permissions.includes("canApproveRejectLeaveRequest")) {
            return (
              <div style={{ display: "flex" }}>
                <a onClick={(e) => { approveLeave(e, value, tableMeta.rowData[0]) }}>
                  <button style={{ cursor: 'pointer', textAlign: 'center', border:"none" }} className="btn-sm btn-group-sm btn btn-table-commone btn-success">Approve</button>
                </a>
                <>
                  <a onClick={(e) => { rejectLeave(e, value, tableMeta.rowData[0]) }}>
                    <button style={{ cursor: 'pointer', textAlign: 'center', border:"none" }} className="btn-sm btn-group-sm btn btn-table-commone btn-danger">Reject</button>
                  </a>

                </>
              </div>
            );
          }
          else {
            return <span style={{ textTransform: 'capitalize' }}>{value}</span>;
          }

        }
      }
    },
    {
      name: "approval_status",
      label: "Cancel Leave",
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: columnsList?.includes('approval_status') ? true : false,
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          var requsted_by = tableMeta.rowData[1]
          if (value === "pending" && (userData._id == requsted_by?._id) ) {
            return (
              <div style={{ display: "flex" }}>
                <>
                  <a onClick={(e) => { cancelLeave(e, value, tableMeta.rowData[0]) }}>
                    <button className="btn-sm btn-group-sm btn btn-table-commone btn-danger">Cancel</button>
                  </a>

                </>

              </div>
            );
            }else {
            return <span style={{ textTransform: 'capitalize' }}>-</span>;
          }

        }
      }
    },
    {
      name: "reject_reason",
      label: "Rejected Reason",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('reject_reason') ? true : false,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            left: "0",
            zIndex: 100,
            color: '#243d6a',
            maxWidth: '200px',
            overflow: 'auto',
            fontWeight: 'bold'
          }
        }),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "start_date",
      label: "Start Date",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('start_date') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "end_date",
      label: "End Date",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('end_date') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "leave_reason",
      label: "Leave Reason",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('leave_reason') ? true : false,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            left: "0",
            zIndex: 100,
            color: '#243d6a',
            maxWidth: '300px',
            overflow: 'auto',
            fontWeight: 'bold'
          }
        }),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "created_at",
      label: "Requested On",
      options: {
        filter: true,
        sort: true,
        display: columnsList?.includes('created_at') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },

  ];

  const hideModal = () => {
    setShowRejectModal(false); 

  }
  const LoaderText = () => {
    return   isFetching ? <div className="parent-div">
    <div className="loader"></div>
  </div>:"No Data Found.." 
 }


  const options = {
    textLabels: {
      body: {
        noMatch: LoaderText()
      }
    },
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: (window.innerHeight - 210).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 210).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    onViewColumnsChange: async (changedColumn, action) => {
      var columns = Object.assign([], columnsList)
      if (action === "add") {
        columns.push(changedColumn);
      }
      
      if (action === "remove") {
        var columns = columns.filter(item => item !== changedColumn);
        // setColumnsList(columns)
        // var index = columnsList.indexOf(changedColumn);
        // if (index !== -1) {
        //   columnsList.splice(index, 1);
        //   setColumnsList(columnsList);
        // }
      }

      const updatedData = {
        user_id: userData._id,
        table_name: "leaverequests",
        columns_list: columns,
      }
      leaveupdatecolumn(updatedData)
      updateLeaveColumns(columns)
    },
    download: false,
    print: false,

  };

  return (
    <>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={"Leave List"}
          data={leavelist}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
     
     {RejectshowModal ? <LeaveRequestReject/> : ""}
    </>
  )
});
const mapStateToProps = (state) => ({
  leavelist : state.LeaveRequestlists.leavelist,
  user : state.auth.user,
  isFetching : state.LeaveRequestlists.isFetching, 
  RejectshowModal : state.LeaveRequestlists.Reject_showModal,
  columnsList : state.LeaveRequestlists.columnsList,
});

const mapDispatchToProps = (dispatch) => ({
  leaveupdatecolumn: (data) => dispatch(ContactColumnUpdateddata(data)),
  cancelLeavedata: (data) => dispatch(getcancelLeave(data)),
  RejectLeaveRejectId:(data) => dispatch(LeaverequestActions.setRejectLeaveRejectId(data)),
  Rejectopenmodal:(data) => dispatch(LeaverequestActions.Reject_openModal(data)),
  approvedleavedata:(data) => dispatch(approvedLeave(data)),
  getLeaveListColumnsData : () => dispatch(getLeaveListColumns()),
  updateLeaveColumns : (data) => dispatch(LeaverequestActions.updateLeaveColumns(data))
});
export default connect(mapStateToProps,mapDispatchToProps)(LeaveRequestTable);

