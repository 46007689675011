import React, { useState,useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import { getMuiTheme, headerProps } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import axios from 'axios';
import moment from 'moment-timezone';
import {Deletepayslipdata} from "../../store/Payslip"
import { connect, useDispatch, useSelector } from 'react-redux';


const PayslipTable = React.memo(({Deletepayslip,paysliplist,isFetching}) => {
  const {user} = useSelector(state => state.auth)
  const permissions = user.permissions;
  const [columnsList, setColumnsList] = useState([]);
  const Deletevalue = async (event, data, meta) => {
    
     event.stopPropagation();
     const datas = {
       fileName:data,
      payslip_id:meta
      
     }
     Deletepayslip(datas)
    

    }
  const columns = [
    {
      name: "_id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        setCellHeaderProps: headerProps,
      }
    },
    {
     name: "assigned_user_id",
     label: "Name",
     options: {
      filter: true,
      sort: true,
      display: true,
      setCellHeaderProps: headerProps,
      customBodyRender: (value, tableMeta, updateValue) => (
        value ? (value.first_name + ' '+ value.last_name) : "user not found"
      ),
    
     }
    },
    {
     name: "month_year",
     label: "Month and Year",
     options: {
      filter: true,
      sort: false,
      display:true,
      setCellHeaderProps:headerProps,
      customBodyRender: (value, tableMeta, updateValue) => (
        value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format(' MMM  YYYY') : ""
      ),
     }
    },
    {
     name: "payslip_pdf",
     label: "Payslip",
     options: {
      filter: true,
      sort: false,
      display:true,
      setCellHeaderProps:headerProps,

      customBodyRender : (value,tableMeta,updateValue) => {
        
        return (
          <>
          <a target = "_blank" href = {value} download>  
        <b> Download </b>  
        </a>  
          </>
        )
      }

     }
    },
    {
      name:"fileName",
      label:"Actions",
      options : {
          filter:true,
          sort:true,
          display:(permissions.includes("deleteOwnPayslips")  && permissions.includes("deleteAnyPayslips")) ? true :false,
          setCellHeaderProps:headerProps,

          customBodyRender : (value,tableMeta,updateValue) => {
              return (
              
                      <>
               {permissions.includes("deleteOwnPayslips")  && permissions.includes("deleteAnyPayslips") ? 
                 <a style={{ textAlign: 'center' }} className="btn-sm btn-group-sm btn btn-table-commone btn-danger "onClick={(e) => Deletevalue(e, value, tableMeta.rowData[0])}>
                 <span style={{ cursor: 'pointer', textAlign: 'center', paddingTop:'5px', width: '58px', height: '30px' }}>Delete</span> 
                 </a>
               :false}
                      </> 
              )

          }
      }

    } 
   ];

   const LoaderText = () => {
    return   isFetching ? <div className="parent-div">
    <div className="loader"></div>
  </div>:"No Data Found.." 
 }
 
   const options = {
    
    textLabels: {
      body: {
        noMatch: LoaderText()
      }
    },
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: (window.innerHeight - 280).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 280).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    print: false,
    setRowProps: (row, dataIndex, rowIndex) => {
        if (row[22]) {
          if (row[22]['postponed_date']) {
            var difference = moment(moment(row[22]['postponed_date']), "DD/MM/YYYY HH:mm:ss").diff(moment(), "DD/MM/YYYY HH:mm:ss");
          } else {
            var difference = moment(moment(row[22]['action_date']), "DD/MM/YYYY HH:mm:ss").diff(moment(), "DD/MM/YYYY HH:mm:ss");
          }
          return {
          };
        } else {
          return {
          }
        }
      },
    onViewColumnsChange: async (changedColumn, action) => {

      if (action === "add") {
        columnsList.push(changedColumn);
      }

      if (action === "remove") {
        var index = columnsList.indexOf(changedColumn);
        if (index !== -1) {
          columnsList.splice(index, 1);
          setColumnsList(columnsList);
        }
      }

     
    },
  };

  return (
    <>
       <React.Fragment>
             <MuiThemeProvider theme={getMuiTheme()}>
                 <MUIDataTable
                title={"Payslip table"}
                data={paysliplist?.list}
                columns={columns}
                options={options}
                />
                </MuiThemeProvider>
              </React.Fragment>  

            
    </>
  );

})


const mapStateToProps = (state) => ({
  paysliplist : state.Paysliplist.paysliplist,
  user : state.auth.user,

})

const mapDispatchToProps = (dispatch) => ({
  Deletepayslip : (data) => dispatch(Deletepayslipdata(data))

});

export default connect(mapStateToProps,mapDispatchToProps) (PayslipTable)
