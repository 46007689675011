import { createSlice } from "@reduxjs/toolkit";
import Api  from "./axios";
import { toast } from "react-toastify";
var userObj = JSON.parse(localStorage.getItem("user")) || null;


const intialAuthState = {
    isFetching: false,
    error: false,
    errorPayload: false,
    user: userObj,
    msg: "",
    leadsAnalysis: null,
    open:false,
    showModal: false,
    showMetricModal:false,

    getindustrylists:[],
    industrymetriclists:[]
  
}

const Industryslice = createSlice({
    name: "Industrylist",
    initialState: intialAuthState,
    reducers: {
        Open: (state, action) => {
          state.open = true; 
        },
        close : (state, action) => {
          state.open = false; 
        },
    
        openModal: (state, action) => {
            state.showModal = true; // Correct the property name to "showModal"
          },
          closeModal: (state, action) => {
            state.showModal = false; // Correct the property name to "showModal"
          },
         MetricopenModal: (state, action) => {
            state.showMetricModal = true; // Correct the property name to "showModal"
          },
          MetriccloseModal: (state, action) => {
            state.showMetricModal = false; // Correct the property name to "showModal"
          },


          loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
          },
          loaderStop(state, action) {
            state.isFetching = false;
            state.msg = "Fetching...";
          },


          Viewcontact_openModal : (state, action) => {
            state.showModal_Viewcontact = true; 
          },
          Viewcontact_CloseModal: (state, action) => {
            state.showModal_Viewcontact = false; 
          },

          getIndustrymetricSuccess(state, action) {
            state.isFetching = false;
            state.error = false;
            state.errorPayload = null;
            state.getindustrylists = action.payload;
            
           
            
            
          },
          getIndustrymetricFailure(state, action) {
            state.isFetching = false;
            state.error = false;
            state.errorPayload = null;
            state.getindustrylists = [];
            toast.error("Error in industry lists") 
           
             
            
          },

    AddindustrySuccess(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.industrymetriclists = action.payload;
    toast.success("Successfully created") 
   
    state.showMetricModal  = false;
    
  },
  AddindustryFailure(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.industrymetriclists =[];
    toast.error("Error in created") 
   
    state.showMetricModal  = true;
    
  },



    }  
});
export const IndustrysActions = Industryslice.actions;

export const getindustrymetricdata = (updatedData) => {
  return async (dispatch) => {
    dispatch(IndustrysActions.loaderStart());
    try {
      await Api.get("/user/resources/industry-metrics/list").then((res) => {
        if (res.data.status === "success") {
          dispatch(IndustrysActions.getIndustrymetricSuccess(res?.data?.metricsList));
        }
        if (res.data.status === "failure") {
          dispatch(IndustrysActions.getIndustrymetricFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(IndustrysActions.getIndustrymetricFailure("Network Error"));
    }
  };
};


export const Addindustrydata = (formData) => {
  return async (dispatch) => {
    dispatch(IndustrysActions.loaderStart("meetingLoader"));
    try {
      await Api.post("/user/resources/upload-industry-metric", formData).then((res) => {
        if (res.data.status === "success") {
          dispatch(IndustrysActions.AddindustrySuccess(res.data));
          dispatch(getindustrymetricdata())
        }
        if (res.data.status === "failure") {
          dispatch(IndustrysActions.AddindustryFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(IndustrysActions.AddindustryFailure("Network Error"));
    }
  };
};




  export default Industryslice.reducer;