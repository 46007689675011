import { createSlice } from "@reduxjs/toolkit";
import Api from "./axios";
import { toast } from "react-toastify";
var userObj = JSON.parse(localStorage.getItem("user")) || null;


const intialAuthState = {
    isFetching: false,
    error: false,
    errorPayload: false,
    user: userObj,
    msg: "",
    leadsAnalysis: null,
    open:false,
    Email_campaignshowModal:false,
    showModal: false,
    showViewEmailContent:false,
    columnsList:[],
    getdigitalmarketinglists:[],
    Emailcampaignlists:[],
    viewContentModal:false,
    Rowcontent:[]
}

const Emailcampaignslice = createSlice({
    name: "Emailcampaignlist",
    initialState: intialAuthState,
    reducers: {
        Open: (state, action) => {
          state.open = true; 
        },
        close : (state, action) => {
          state.open = false; 
        },
    
        openModal: (state, action) => {
            state.showModal = true; // Correct the property name to "showModal"
          },
          closeModal: (state, action) => {
            state.showModal = false; // Correct the property name to "showModal"
          },
          Email_campaign_openModal: (state, action) => {
            state.Email_campaignshowModal = true; // Correct the property name to "showModal"
          },
          Email_campaign_closeModal: (state, action) => {
            state.Email_campaignshowModal = false; // Correct the property name to "showModal"
          },
          showViewEmailContent_openmodal :(state, action) => {
            state.showViewEmailContent = true; 
          },
          showViewEmailContent_closemodal :(state, action) => {
            state.showViewEmailContent = false; 
          },
         show_viewContentModal_openmodal:(state, action) => {
          state.viewContentModal = true; 
        },
        show_viewContentModal_closemodal:(state, action) => {
          state.viewContentModal = false; 
        },
        setRowcontent(state, action) {
          state.Rowcontent = action.payload;
        },
          loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
          },
          loaderStop(state, action) {
            state.isFetching = false;
            state.msg = "Fetching...";
          },

          activity_openModal: (state, action) => {
            state.showModal_activity = true; 
          },
          activity_closeModal: (state, action) => {
            state.showModal_activity = false; 
          },

          Viewcontact_openModal : (state, action) => {
            state.showModal_Viewcontact = true; 
          },
          Viewcontact_CloseModal: (state, action) => {
            state.showModal_Viewcontact = false; 
          },
      // get column table
      getdigitalmarketingsuccess(state,action) {
        state.isFetching = false;
        state.error = false;
        state.errorPayload = null;
        state.getdigitalmarketinglists = action.payload;
        // toast.success("Meeting_columnlistSuccess") 
  
      },
      getmeetingColumnFailure(state,action) {
        state.isFetching = false;
        state.error = false;
        state.errorPayload = null;
        state.getdigitalmarketinglists = [];
        toast.error("Error in list") 
  
      },
      createEmaillistsuccess(state,action) {
        state.isFetching = false;
        state.error = false;
        state.errorPayload = null;
        state.Emailcampaignlists = action.payload;
        toast.success("Sucessfully Updated") 
  
      },
      createEmaillistFailure(state,action) {
        state.isFetching = false;
        state.error = false;
        state.errorPayload = null;
        state.Emailcampaignlists = [];
        toast.error("Not created")
      },

      tasksListUpdateSuccess(state, action){
        state.isFetch = false
        state.error = false
        state.errorPayload = false
        state.msg = action.payload
    },
    tasksListUpdateFailure(state, action){
        state.isFetch = false
        state.error = true
        state.errorPayload = action.payload
        state.msg = ""
    },

    emailCampaignColumnsListSuccess(state, action){
      state.isFetch = false
      state.error = false
      state.errorPayload = false
      state.columnsList = action.payload
  },
 emailCampaignColumnsListFailure(state, action){
      state.isFetch = false
      state.error = true
      state.errorPayload = action.payload
      state.columnsList = []
  },
  updateColumns(state, action){
    state.columnsList = action.payload
  }


    }  
});
export const EmailcampaignActions = Emailcampaignslice.actions;


 ///common 
  
export const getdigitalmarketingdata = () => {
  return async (dispatch) => {
    dispatch(EmailcampaignActions.loaderStart(""));
    try {
   
      await Api.get("/digital-marketing/list").then((res) => {
        if (res.data.status === "success") {
          dispatch(EmailcampaignActions.getdigitalmarketingsuccess(res?.data?.emailCampaign));
        }
        if (res.data.status === "failure") {
          dispatch(EmailcampaignActions.getdigitalmarketingFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(EmailcampaignActions.getdigitalmarketingFailure("Network Error"));
    }
  };
};

export const createEmailcampaign = () => {
  return async (dispatch) => {
    dispatch(EmailcampaignActions.loaderStart(""));
    try {
      await Api.get('/digital-marketing/create').then((res) => {
        if (res.data.status === "success") {
          dispatch(EmailcampaignActions.createEmaillistsuccess(res.data));
        }
        if (res.data.status === "failure") {
          dispatch(EmailcampaignActions.createEmaillistFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(EmailcampaignActions.createEmaillistFailure("Network Error"));
    }
  };
};




export const updateColumns = (data) => {
  return async (dispatch) => {
      dispatch(EmailcampaignActions.loaderStart())
      try {
          await Api.post("/columns-hide-show/create", data).then((res) => {
                  if (res.data.status === "success") {
                    toast.success("Columns Updated ")
                      dispatch(EmailcampaignActions.tasksListUpdateSuccess("Columns Updated"))
                  }
                  if (res.data.status === "failure") {
                      toast.error(res.data.msg)
                      dispatch(EmailcampaignActions.tasksListUpdateFailure(res.data.msg))
                  }
              })
      }
      catch (err) {
          toast.error("Network Error")
          dispatch(EmailcampaignActions.tasksListUpdateFailure("Network Error"));
      }
  }
}


export const getColumnsList = () => {
  return async (dispatch) => {
      dispatch(EmailcampaignActions.loaderStart())
      try {
          var userData = JSON.parse(localStorage.getItem("user"))
          await Api.get("/columns-hide-show/list/emailCampaign/" + userData?.user?._id).then((res) => {
                  if (res.data.status === "success") {
                      dispatch(EmailcampaignActions.emailCampaignColumnsListSuccess(res.data.columnsList?.columns_list))
                  }
                  if (res.data.status === "failure") {
                      toast.error(res.data.msg)
                      dispatch(EmailcampaignActions.emailCampaignColumnsListFailure(res.data.msg))
                  }
              })
      }
      catch (err) {
          toast.error("Network Error!")
          dispatch(EmailcampaignActions.emailCampaignColumnsListFailure("Network Error"));
      }
  }
}



  export default Emailcampaignslice.reducer;