import { createSlice } from "@reduxjs/toolkit";
import  Api  from "./axios";
import { toast } from "react-toastify";
import {getorganizationlist} from "./Organization"
import{getdemolist} from "./Demo"
import {gettraininglist} from "./Training"
import{getbenchmarklist} from "./Benchmark"
var userObj = JSON.parse(localStorage.getItem("user")) || null;


const intialAuthState = {
    isFetching: false,
    error: false,
    errorPayload: false,
    user: userObj,
    msg: "",
    leadsAnalysis: null,
    open:false,
    showModal: false,
    demo_showModal:false,
  
    showModal_activity:false,
    
    showModal_Viewcontact:false,

    addtechactivity:[],
    accountupdate:[],
    getaccountdetails:[],
    getannualrevenuelists:[],
    Contactcolumnupdated:[],
    meetingcolumnupdated:[],
    accountList:[],
    rowId:[],
    rowMetadata:[],
    activityrowId:[],
    data:[],
    contactaccountId:[],
    contactcompanyName:[],
    columnsList:[],
    roicolumnsList:[]
}

const Commonslice = createSlice({
    name: "Commonlist",
    initialState: intialAuthState,
    reducers: {
        Open: (state, action) => {
          state.open = true; 
        },
        close : (state, action) => {
          state.open = false; 
        },
    
        openModal: (state, action) => {
            state.showModal = true; 
          },
          closeModal: (state, action) => {
            state.showModal = false; 
          },
          demo_openModal: (state, action) => {
            state.demo_showModal = true; 
          },
          demo_closeModal: (state, action) => {
            state.demo_showModal = false; 
          },

          setRowId(state, action) {
            state.rowId = action.payload;
          },
          // setAccountRowId(state, action) {
          //   state.AccountrowId = action.payload;
          // },
          setrowMetaData(state, action) {
            state.rowMetadata = action.payload;
          },
          setaddActivityRowId(state, action) {
            state.activityrowId = action.payload;
          },
          setcontactaccountId(state, action) {
            state.contactaccountId = action.payload;
          },
          setcompanyName(state, action) {
            state.contactcompanyName = action.payload;
          },
          

          setdata(state, action) {
            state.data = action.payload;
          },
          loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
          },
          loaderStop(state, action) {
            state.isFetching = false;
            state.msg = "Fetching...";
          },

          activity_openModal: (state, action) => {
            state.showModal_activity = true; 
          },
          activity_closeModal: (state, action) => {
            state.showModal_activity = false; 
          },

          Viewcontact_openModal : (state, action) => {
            state.showModal_Viewcontact = true; 
          },
          Viewcontact_CloseModal: (state, action) => {
            state.showModal_Viewcontact = false; 
          },
      // get column table
      getmeetingColumnsuccess(state,action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.Meeting_columns = action.payload;
        // toast.success("Meeting_columnlistSuccess") 
  
      },
      getmeetingColumnFailure(state,action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.Meeting_columns = [];
        // toast.success("Meeting_columnlistSuccess") 
  
      },

      addTechActivitySuccess(state, action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.addtechactivity = action.payload;;
        toast.success("Succesfully Added tech activity ") 
        state.showModal_activity = false;
      },
      addTechActivityFailure(state, action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.addtechactivity = [];
        toast.error("Error in tech activity") 
        state.showModal_activity = true;
      },

      getaccountdetailsSuccess(state, action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.getaccountdetails = action.payload;
        // toast.error("Error Account list updated") 
      },
      getaccountdetailFailure(state, action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.getaccountdetails =[];
        // toast.error("Error Account list updated") 
      },
       //get annual reneve
  getannualrevenueSuccess(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.getannualrevenuelists = action.payload;
    // toast.error("Error Account list updated") 
  },
  getannualrevenueFailure(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.getannualrevenuelists = [];
    // toast.error("Error Account list updated") 
  },
  ViewaccountupdatedSuccess(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.accountupdate = action.payload;
    toast.success("Account updated") 
   
    state.demo_showModal = false;
    
  },

  ViewaccountupdatedFailure(state, action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.accountupdate = [];
    toast.error("Error in Account updated") 
  
    state.demo_showModal = true;
  },

//common for all tables 
  ContactcolumndataSuccess(state,action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.Contactcolumnupdated = action.payload;
    toast.success("column updated ") 

  },
  ContactcolumndataFailure(state,action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.Contactcolumnupdated = action.payload;
    toast.error("column failed ") 
    

  },
  meetingcolumndataSuccess (state,action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.meetingcolumnupdated = action.payload;
    // toast.success("meeting column updated") 
    
  },
  meetingcolumndataFailure (state,action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.meetingcolumnupdated = [];
    toast.error("Error in column updated") 
  },
  getAccountListSuccess(state,action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.accountList = action.payload;
    // toast.success("meeting column updated") 
  },
  getAccountListFailure(state,action) {
    state.meetingLoader = false;
    state.error = false;
    state.errorPayload = null;
    state.accountList =[];
    // toast.success("meeting column updated") 
  },
  roisheetupdateColumns(state, action){
    state.roicolumnsList = action.payload
  },

  ColumnsListSuccess(state, action){
    state.isFetch = false
    state.error = false
    state.errorPayload = false
    state.columnsList = action.payload
},
ColumnsListFailure(state, action){
    state.isFetch = false
    state.error = true
    state.errorPayload = action.payload
    state.columnsList = []
},
leadAssignedupdateColumns(state, action){
  state.columnsList = action.payload
},
//roi sheet
roiSheetColumnsListSuccess(state, action){
  state.isFetch = false
  state.error = false
  state.errorPayload = false
  state.roicolumnsList = action.payload
},
roiSheetColumnsListFailure(state, action){
  state.isFetch = false
  state.error = true
  state.errorPayload = action.payload
  state.roicolumnsList = []
},

roiSheetListUpdateSuccess(state, action){
  state.isFetch = false
  state.error = false
  state.errorPayload = false
  state.msg = action.payload
},
roiSheetListUpdateFailure(state, action){
  state.isFetch = false
  state.error = true
  state.errorPayload = action.payload
  state.msg = ""
},

    }  
});
export const CommonsliceActions = Commonslice.actions;


 ///common 
  
export const getmeetingColumnListHandle = (userid) => {

  return async (dispatch) => {
    dispatch(CommonsliceActions.loaderStart("meetingLoader"));
    try {
   
      await Api.get(`/columns-hide-show/list/meetings/${userid}`).then((res) => {
        if (res.data.status === "success") {
          dispatch(CommonsliceActions.getmeetingColumnsuccess(res.data));
        }
        if (res.data.status === "failure") {
          dispatch(CommonsliceActions.getmeetingColumnFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(CommonsliceActions.getmeetingColumnFailure("Network Error"));
    }
  };
};
///Add activity
export const add_tech_activity = (type) => {
    return async (dispatch) => {
      dispatch(CommonsliceActions.loaderStart("meetingLoader"));
      try {
        await Api.post("/demos/add-tech-activity", type).then((res) => {
          if (res.data.status === "success") {
            dispatch(CommonsliceActions.addTechActivitySuccess(res?.data));
            dispatch(getdemolist({type:"all"}))
            dispatch(getbenchmarklist())
            dispatch(gettraininglist())
          }
          if (res.data.status === "failure") {
            dispatch(CommonsliceActions.addTechActivityFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(CommonsliceActions.addTechActivityFailure("Network Error"));
      }
    };
  }; 




  /// view contact
  export const Viewaccountupdated = (type) => {
    return async (dispatch) => {
      dispatch(CommonsliceActions.loaderStart("meetingLoader"));
      try {
        await Api.post("accounts/update", type).then((res) => {
          if (res.data.status === "success") {
            dispatch(CommonsliceActions.ViewaccountupdatedSuccess(res.data));

            dispatch(getorganizationlist());
            
            // dispatch(getdemolist({type:"all"}))
            // dispatch(getbenchmarklist())
            // dispatch(gettraininglist())
            
          }
          if (res.data.status === "failure") {
            dispatch(CommonsliceActions.ViewaccountupdatedFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(CommonsliceActions.ViewaccountupdatedFailure("Network Error"));
      }
    };
  };

  export const getaccountdetailsHandle = (userid) => {
  
  
    return async (dispatch) => {
      dispatch(CommonsliceActions.loaderStart("meetingLoader"));
      try {
            await Api.get(`/accounts/details/${userid}`).then((res) => {
          if (res.data.status === "success") {
            dispatch(CommonsliceActions.getaccountdetailsSuccess(res.data));
          }
          if (res.data.status === "failure") {
            dispatch(CommonsliceActions.getaccountdetailFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(CommonsliceActions.getaccountdetailFailure("Network Error"));
      }
    };
  };


  export const getannualrevenue = () => {
  
  
    return async (dispatch) => {
      dispatch(CommonsliceActions.loaderStart("meetingLoader"));
      try {
            await Api.get("/annual-revenue-range/search").then((res) => {
          if (res.data.status === "success") {
            dispatch(CommonsliceActions.getannualrevenueSuccess(res.data?.annualRevenueRange));
          }
          if (res.data.status === "failure") {
            dispatch(CommonsliceActions.getannualrevenueFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(CommonsliceActions.getannualrevenueFailure("Network Error"));
      }
    };
  };



   /// column column for all /////////////
export const ContactColumnUpdateddata = (updatedData) => {

  return async (dispatch) => {
    dispatch(CommonsliceActions.loaderStart("meetingLoader"));
    try { 
      await Api.post("columns-hide-show/create", updatedData).then((res) => {
        if (res.data.status === "success") {
          dispatch(CommonsliceActions.ContactcolumndataSuccess(res.data));
        }
        if (res.data.status === "failure") {
          dispatch(CommonsliceActions.ContactcolumndataFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(CommonsliceActions.ContactcolumndataFailure("Network Error"));
    }
  };
};


// lead Assigned table//
export const leadAssignedupdateColumns = () => {
  return async (dispatch) => {
      dispatch(CommonsliceActions.loaderStart())
      try {
          var userData = JSON.parse(localStorage.getItem("user"))
          await Api.get("/columns-hide-show/list/leadassigned/"+ userData?.user?._id).then((res) => {
                  if (res.data.status === "success") {
                      dispatch(CommonsliceActions.ColumnsListSuccess(res.data.columnsList?.columns_list))
                  }
                  if (res.data.status === "failure") {
                      toast.error(res.data.msg)
                      dispatch(CommonsliceActions.ColumnsListFailure(res.data.msg))
                  }
              })
      }
      catch (err) {
          toast.error("Network Error!")
          dispatch(CommonsliceActions.ColumnsListFailure("Network Error"));
      }
  }
}

// roi sheet 

export const getroiColumnsList = () => {
  return async (dispatch) => {
      dispatch(CommonsliceActions.loaderStart())
      try {
          var userData = JSON.parse(localStorage.getItem("user"))
          await Api.get("/columns-hide-show/list/roisheet/" + userData?.user?._id).then((res) => {
                  if (res.data.status === "success") {
                      dispatch(CommonsliceActions.roiSheetColumnsListSuccess(res.data.columnsList?.columns_list))
                  }
                  if (res.data.status === "failure") {
                      toast.error(res.data.msg)
                      dispatch(CommonsliceActions.roiSheetColumnsListFailure(res.data.msg))
                  }
              })
      }
      catch (err) {
          toast.error("Network Error!")
          dispatch(CommonsliceActions.roiSheetColumnsListFailure("Network Error"));
      }
  }
}


export const roisheetupdateColumns = (data) => {
  return async (dispatch) => {
      dispatch(CommonsliceActions.loaderStart())
      try {
          await Api.post("/columns-hide-show/create", data).then((res) => {
                  if (res.data.status === "success") {
                    toast.success("Columns Updated ")
                      dispatch(CommonsliceActions.roiSheetListUpdateSuccess("Columns Updated"))
                  }
                  if (res.data.status === "failure") {
                      toast.error(res.data.msg)
                      dispatch(CommonsliceActions.roiSheetListUpdateFailure(res.data.msg))
                  }
              })
      }
      catch (err) {
          toast.error("Network Error")
          dispatch(CommonsliceActions.roiSheetListUpdateFailure("Network Error"));
      }
  }
}


export const meetingColumnUpdateddata = (updatedData) => {

  return async (dispatch) => {
    dispatch(CommonsliceActions.loaderStart("meetingLoader"));
    try {
      await Api.post("columns-hide-show/create", updatedData).then((res) => {
        // dispatch(CommonsliceActions.updateColumnsList(updatedData.columns_list))
        if (res.data.status === "success") {
          dispatch(CommonsliceActions.meetingcolumndataSuccess(res.data.columnObjects));
        }
        if (res.data.status === "failure") {
          dispatch(CommonsliceActions.meetingcolumndataFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(CommonsliceActions.meetingcolumndataFailure("Network Error"));
    }
  };
};




export const getaccountdetails = () => {
  
  return async (dispatch) => {
    dispatch(CommonsliceActions.loaderStart("meetingLoader"));
    try {
          await Api.get("/accounts/details/").then((res) => {
        if (res.data.status === "success") {
          dispatch(CommonsliceActions.getAccountListSuccess(res.data));
        }
        if (res.data.status === "failure") {
          dispatch(CommonsliceActions.getAccountListFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(CommonsliceActions.getAccountListFailure("Network Error"));
    }
  };
};


  export default Commonslice.reducer;