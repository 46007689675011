import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import moment from 'moment-timezone';
import ViewLead from "../../components/pageModals/ViewLead";
import RejectReason from "../../components/pageModals/RejectReason";
import { getMuiTheme, headerProps } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import AssigniSalesUserModal from '../pageModals/AssigniSalesUserModal';
import CustomFooter from './CustomFooter';
import { useSelector, useDispatch, connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap'

import {getLeadsList, getColumnsListData, updateColumnsList, subPartnersList, cancelLeadApproval, approveLeads, updateMeddicValue, updateKey, updateViewRowDetails} from "../../store/leads";
import { useMemo } from 'react';

const LeadsTable = React.memo(({updateViewRowData, showUpdateLeadModal, leadsList, page, rowsPerPage, leadsListCount, columnsList, subPartnersList,
                           getLeadsData, getColumnsList, updateColumnsListData, subPartnersListData, cancelLeadApprovalData, approveLeadsData, updateMeddicData, updateKeyData, isFetching,leadlistLoader}) => {


  const [tableData, setTableData] = useState([]);

  // const [columnsList, setColumnsList] = useState([]);
  // const [showUpdateLeadModal, setShowUpdateLeadModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [rowId, setRowId] = useState("");
  const [rowMetaData, setRowMetaData] = useState("");
  // const [defaultTableText, setDefaultTableText] = useState(props.progress)
  // const [open, setOpen] = React.useState(false);
  // const [snackBarMessage, setsnackBarMessage] = useState(false);
  var [rejectLeadId, setRejectLeadId] = useState(null);
  var [rejectTypeLead, setRejectTypeLead] = useState(null);
  const [accountId, setAccountId] = useState(false);
  const [leadTypeActive, setLeadTypeActive] = useState("all");
  const queryParams = new URLSearchParams(window.location.search);
  // const [subPartners, setSubPartners] = useState({});
  const [showAssigniSales, setShowAssigniSales] = useState(false);
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const [colors, setColors] = useState({})
  const permissions = user.permissions;


  const approveSales = async (event, data, meta) => {
    event.stopPropagation();
    var updatedData = {
      'status': "approved",
      'lead_id': meta,
      'type': 'sales_approval',
    }
    approveLeadsData(updatedData)
  }

  const rejectSales = async (event, data, meta) => {
    event.stopPropagation();
    // setShowUpdateLeadModal(false);
    updateKeyData("showUpdateLeadModal", false)
    setRejectLeadId(meta);
    setRejectTypeLead("sales_approval");
    setShowRejectModal(true);
    // props.reFreshLeadTable();
    getLeadsData();

  }

  const cancelSalesApproval = async (event, data, meta) => {
    event.stopPropagation();

    var updatedData = {
      'status': "pending",
      'lead_id': meta,
      'type': 'sales_approval',
    }
    cancelLeadApprovalData(updatedData)
  }
  const approveTechnical = async (event, data, meta) => {
    event.stopPropagation();
    var updatedData = {
      'status': "approved",
      'lead_id': meta,
      'type': 'technical_approval'
    }
    approveLeadsData(updatedData)
  }

  const filterLeadType = async (type) => {

    setLeadTypeActive(type);
    var data = [];
    if (type === "all") {
      data = leadsList?.leads;
    } else {
      data = leadsList?.leads.filter(function (item) {
        if (item.lead_type === type) {
          return item;
        }
      });
    }
    setTableData(data);
  }

  const updateMeddic = async (index, key, value, lead_id) => {

    var updatedData = {}
    updatedData[key] = value
    updatedData['lead_id'] = lead_id

    updateMeddicData(updatedData)
  }

  const onCellClickHandler = (meta) => {

    if (meta.colIndex === 2 || meta.colIndex === 1) {

    }else if (meta.colIndex in [3, 4, 5, 6, 7, 8, 9, 10, 11, 12]) {
      return;
    }

    
    // setShowUpdateLeadModal(true);
    // setRowMetaData(meta);
    // setRowId()
    // setAccountId();


    updateViewRowData({meta: meta, rowId:tableData[meta.dataIndex]._id, accountId:tableData[meta.dataIndex].account_id })

    updateKeyData("showUpdateLeadModal", true)
  }

  const rejectTechnical = async (event, data, meta) => {
    event.stopPropagation();
    // setShowUpdateLeadModal(false);
    updateKeyData("showUpdateLeadModal", false)
    setShowRejectModal(true);
    setRejectLeadId(meta);
    setRejectTypeLead("technical_approval");

  }

  const cancelTechnicalApproval = async (event, data, meta) => {
    event.stopPropagation();

    var updatedData = {
      'status': "pending",
      'lead_id': meta,
      'type': 'technical_approval',
    }
    cancelLeadApprovalData(updatedData)
  }
 

  // const handleClose = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   setOpen(false);
  // };

  useEffect(() => {
    setTableData(leadsList?.leads);
    // handleClose();
  }, [leadsList?.leads]);

  useMemo(() => {
    getLeadsData();
  }, [])

  useEffect(() => {

    getColumnsList(userData._id);

    subPartnersListData();

    let searchParamLeadId = queryParams.get('lead_id') ? queryParams.get('lead_id') : null;

  }, []);

  const columns = [
    {
      name: "_id",
      label: "id",
      options: {
        filter: false,
        display: false,
        setCellHeaderProps: headerProps,
        
      }
    },
    {
      name: "user_id",
      label: "Account Manager",
      options: { 
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? (value.first_name +' '+value.last_name) : (tableMeta.rowData[30]? (tableMeta.rowData[30].first_name +' '+ tableMeta.rowData[30].last_name) : "user not found")
        ),
        display: columnsList?.includes('user_id') ? true : false,
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "company_name",
      label: "Organization Name",
      options: {
        filter: true,
        display: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
            color: '#243d6a',
            maxWidth: '500px',
            overflow: 'auto',
            fontWeight: 'bold'
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 1,
            background: "#e6e6e6",
            zIndex: 101,
            textColor: '#FFFFFF',
            maxWidth: '500px',
            overflow: 'auto',
          }
        })
      }
    },
    {
      name: "meddic",
      label: "M",
      options: {
        filter:false,
        display: columnsList?.includes('meddic') ? true : false,
        setCellProps: (value, tableMeta) => (
          {
            style: {
              whiteSpace: "nowrap",
              textColor: '#FFFFFF',
              backgroundColor: `${(value.props.children.props.value === 2) ? '#42C542' : (value.props.children.props.value === 1) ? '#ffed52' : "#FF5656"}`,
              fontWeight: 'bold',
              width: '200px',
              maxWidth: '500px',
              overflow: 'auto',
            }
          }),
          setCellHeaderProps: () => ({
            style: {
              background: "#e6e6e6",
            }
          }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <select value={value?.m || 0} onChange={(event) => updateMeddic(tableMeta.rowIndex, 'm', event.target.value, tableMeta.rowData[0])} className={(value?.m === 2) ? 'hide-downarraow bg-green' : (value?.m === 1) ? 'hide-downarraow bg-partial' : "hide-downarraow bg-red"}>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
              </select>
            </>
          )
          }
      }
    },
    {
      name: "meddic",
      label: "Eb",
      options: {
        filter:false,
        display: columnsList?.includes('meddic') ? true : false,
        setCellProps: (value, tableMeta) => (
          {
            style: {
              whiteSpace: "nowrap",
              textColor: '#FFFFFF',
              backgroundColor: `${(value.props.children.props.value === 2) ? '#42C542' : (value.props.children.props.value === 1) ? '#ffed52' : "#FF5656"}`,
              fontWeight: 'bold',
              width: '200px',
              maxWidth: '500px',
              overflow: 'auto',
            }
          }),
          setCellHeaderProps: () => ({
            style: {
              background: "#e6e6e6",
            }
          }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <select value={value?.eb || 0} onChange={(event) => updateMeddic(tableMeta.rowIndex, 'eb', event.target.value, tableMeta.rowData[0])} className={(value?.eb === 2) ? 'hide-downarraow bg-green' : (value?.eb === 1) ? 'hide-downarraow bg-partial' : "hide-downarraow bg-red"}>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
              </select>
            </>
          )


        }
      }
    },
    {
      name: "meddic",
      label: "Dc",
      options: {
        filter:false,
        display: columnsList?.includes('meddic') ? true : false,
        setCellProps: (value, tableMeta) => (
          {
            style: {
              whiteSpace: "nowrap",
              textColor: '#FFFFFF',
              backgroundColor: `${(value.props.children.props.value === 2) ? '#42C542' : (value.props.children.props.value === 1) ? '#ffed52' : "#FF5656"}`,
              fontWeight: 'bold',
              width: '200px',
              maxWidth: '500px',
              overflow: 'auto',
            }
          }),
          setCellHeaderProps: () => ({
            style: {
              background: "#e6e6e6",
            }
          }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>

              <select value={value?.dc || 0} onChange={(event) => updateMeddic(tableMeta.rowIndex, 'dc', event.target.value, tableMeta.rowData[0])} className={(value?.dc === 2) ? 'hide-downarraow bg-green' : (value?.dc === 1) ? 'hide-downarraow bg-partial' : "hide-downarraow bg-red"}>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
              </select>


            </>
          )


        }
      }
    },
    {
      name: "meddic",
      label: "Dp",
      options: {
        filter:false,
        display: columnsList?.includes('meddic') ? true : false,
        setCellProps: (value, tableMeta) => (
          {
            style: {
              whiteSpace: "nowrap",
              textColor: '#FFFFFF',
              backgroundColor: `${(value.props.children.props.value === 2) ? '#42C542' : (value.props.children.props.value === 1) ? '#ffed52' : "#FF5656"}`,
              fontWeight: 'bold',
              width: '200px',
              maxWidth: '500px',
              overflow: 'auto',
            }
          }),
          setCellHeaderProps: () => ({
            style: {
              background: "#e6e6e6",
            }
          }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <select value={value?.dp || 0} onChange={(event) => updateMeddic(tableMeta.rowIndex, 'dp', event.target.value, tableMeta.rowData[0])} className={(value?.dp === 2) ? 'hide-downarraow bg-green' : (value?.dp === 1) ? 'hide-downarraow bg-partial' : "hide-downarraow bg-red"}>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
              </select>



            </>
          )


        }
      }
    },
    {
      name: "meddic",
      label: "Ip",
      options: {
        filter:false,
        display: columnsList?.includes('meddic') ? true : false,
        setCellProps: (value, tableMeta) => (
          {
            style: {
              whiteSpace: "nowrap",
              textColor: '#FFFFFF',
              backgroundColor: `${(value.props.children.props.value === 2) ? '#42C542' : (value.props.children.props.value === 1) ? '#ffed52' : "#FF5656"}`,
              fontWeight: 'bold',
              width: '200px',
              maxWidth: '500px',
              overflow: 'auto',
            }
          }),
          setCellHeaderProps: () => ({
            style: {
              background: "#e6e6e6",
            }
          }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <select value={value?.ip || 0} onChange={(event) => updateMeddic(tableMeta.rowIndex, 'ip', event.target.value, tableMeta.rowData[0])} className={(value?.ip === 2) ? 'hide-downarraow bg-green' : (value?.ip === 1) ? 'hide-downarraow bg-partial' : "hide-downarraow bg-red"}>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
              </select>

            </>
          )


        }
      }
    },
    {
      name: "meddic",
      label: "Ch",
      options: {
        filter:false,
        display: columnsList?.includes('meddic') ? true : false,
        setCellProps: (value, tableMeta) => (
          {
            style: {
              whiteSpace: "nowrap",
              textColor: '#FFFFFF',
              backgroundColor: `${(value.props.children.props.value === 2) ? '#42C542' : (value.props.children.props.value === 1) ? '#ffed52' : "#FF5656"}`,
              fontWeight: 'bold',
              width: '200px',
              maxWidth: '500px',
              overflow: 'auto',
            }
          }),
          setCellHeaderProps: () => ({
            style: {
              background: "#e6e6e6",
            }
          }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <select value={value?.ch || 0} onChange={(event) => updateMeddic(tableMeta.rowIndex, 'ch', event.target.value, tableMeta.rowData[0])} className={(value?.ch === 2) ? 'hide-downarraow bg-green' : (value?.ch === 1) ? 'hide-downarraow bg-partial' : "hide-downarraow bg-red"}>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
              </select>

            </>
          )

        }
      }
    },
    {
      name: "meddic",
      label: "Score",
      options: {
        filter:false,
        display: columnsList?.includes('meddic') ? true : false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            background: "#e6e6e6",
          }
        }),
        setCellProps: (value, tableMeta, updateValue) => (
          {
            style: {
              whiteSpace: "nowrap",
              textColor: '#FFFFFF',
              fontWeight: 'bold',
              width: '200px',
              maxWidth: '500px',
              overflow: 'auto',
            }
          }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <b>{colors[tableMeta.rowIndex]?.finalScore || value?.score || 0} %</b>
          )
        }
      }
    },
    {
      name: "sales_approval",
      label: "Sales Approval",
      options: {
        filter: true,
        sort: false,
        empty: true,
        filterOptions: {
          renderValue: val => {
            return val?.[0]?.toUpperCase() + val?.slice(1);
          }
        },
        display: columnsList?.includes('sales_approval') ? true : false,
        onRowClick: false,
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          if ((userData.user_role_id?.role_name === "sales_manager" || permissions?.includes("approveLeads")) && (value === "pending" || value === null)) {
            return (
              <div style={{ display: "flex", textAlign: 'center', justifyItems: 'center', justifyContent: 'center' }}>
                <a style={{ textAlign: 'center' }} onClick={(e) => { approveSales(e, value, tableMeta.rowData[0]) }}>
                  <span style={{ cursor: 'pointer', textAlign: 'center' }} className='btn-sm btn-group-sm btn btn-table-commone btn-success'>Approve</span>
                </a>
                <>
                  <a style={{ textAlign: 'center' }} onClick={(e) => { rejectSales(e, value, tableMeta.rowData[0]) }}>
                    <span style={{ cursor: 'pointer', textAlign: 'center' }} className="btn-sm btn-group-sm btn btn-table-commone btn-danger">Reject</span>
                  </a>
                </>
              </div>
            );
          } else if (value === null) {
            return "Submit For Approval"
          } else if (userData?.user_role_id?.role_name === "sales_manager" && value === "approved") {
            return (
              <a style={{ textAlign: 'center' }} onClick={(e) => { cancelSalesApproval(e, value, tableMeta.rowData[0]) }}>
                <span style={{ cursor: 'pointer', textAlign: 'center' }} className="btn-sm btn-group-sm btn btn-table-commone btn-danger">Revert Approval</span>
              </a>)
          } else {
            return <span style={{ textTransform: 'capitalize' }}>{value}</span>;
          }

        }
      }
    },
    {
      name: "technical_approval",
      label: "Technical Approval",
      options: {
        filter: true,
        sort: false,
        empty: true,
        filterOptions: {
          renderValue: val => {
            return val?.[0]?.toUpperCase() + val?.slice(1);
          }
        },
        display: columnsList?.includes('technical_approval') ? true : false,
        onRowClick: false,
        setCellHeaderProps: headerProps,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (permissions.includes("approveLeads") && (value === "pending" || value === null)) {
            return (
              <div style={{ display: "flex", textAlign: 'center', justifyItems: 'center', justifyContent: 'center' }}>
                <a style={{ textAlign: 'center' }} onClick={(e) => { approveTechnical(e, value, tableMeta.rowData[0]) }}>
                  <span style={{ cursor: 'pointer', textAlign: 'center' }} className='btn-sm btn-group-sm btn btn-table-commone btn-success'>Approve</span>
                </a>

                <>
                  <a style={{ textAlign: 'center' }} onClick={(e) => { rejectTechnical(e, value, tableMeta.rowData[0]) }}>
                    <span style={{ cursor: 'pointer', textAlign: 'center' }} className="btn-sm btn-group-sm btn btn-table-commone btn-danger">Reject</span>
                  </a>
                </>

              </div>
            );
          } else if (value === null) {
            return "Submit For Approval"
          } else if (userData?.user_role_id?.role_name === "technical_manager" && value === "approved") {
            return (
              <a style={{ textAlign: 'center' }} onClick={(e) => { cancelTechnicalApproval(e, value, tableMeta.rowData[0]) }}>
                <span style={{ cursor: 'pointer', textAlign: 'center' }} className="btn-sm btn-group-sm btn btn-table-commone btn-danger">Revert Approval</span>
              </a>)
          } else {
            return <span style={{ textTransform: 'capitalize' }}>{value}</span>;
          }
        }
      }
    },
    {
      name: "sales_activity_id",
      label: "Sales Activity",
      options: {
        display: columnsList?.includes('sales_activity_id') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value.name : ""
        ),
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "products",
      label: "Products",
      options: {
        display: columnsList?.includes('products') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? (
            Array.isArray(value) ? value.map(product => product.name).join(', ') : value.name
          ) : ""
        ),
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "lead_stage_id",
      label: "Sales Stage",
      options: {
        display: columnsList?.includes('lead_stage_id') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value.name : ""
        ),
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "contact_name",
      label: "Contact Name",
      options: {
        filter: true,
        display: columnsList?.includes('contact_name') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "contact_number",
      label: "Contact Number",
      options: {
        filter: true,
        display: columnsList?.includes('contact_number') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        display: columnsList?.includes('email') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "drf_status",
      label: "DRF Status",
      options: {
        display: columnsList?.includes('drf_status') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value.name : ""
        ),
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "company_website",
      label: "Organization Website",
      options: {
        filter: true,
        display: columnsList?.includes('company_website') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "designation",
      label: "Designation",
      options: {
        filter: true,
        display: columnsList?.includes('designation') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "department",
      label: "Department",
      options: {
        filter: true,
        display: columnsList?.includes('department') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "company_address",
      label: "Organization Address",
      options: {
        display: columnsList?.includes('company_address') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          (value ? value.street : "", value ? value.city : "")
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "follow_up_task",
      label: "Follow Up",
      options: {
        display: columnsList?.includes('follow_up_task') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "follow_up_task_detail",
      label: "Follow Up Details",
      options: {
        display: columnsList?.includes('follow_up_task_detail') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "follow_up",
      label: "Follow Date Time",
      options: {
        display: columnsList?.includes('follow_up') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "industry",
      label: "Industry",
      options: {
        display: columnsList?.includes('industry') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value.name : ''
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "remarks",
      label: "Remarks",
      options: {
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
            color: '#243d6a',
            maxWidth: '500px',
            overflow: 'auto',
            fontWeight: 'bold'
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 1,
            background: "#e6e6e6",
            zIndex: 101,
            textColor: '#FFFFFF',
            maxWidth: '500px',
            overflow: 'auto',
          }
        }),
        display: columnsList?.includes('remarks') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "dropped_reason",
      label: "Dropped Reason",
      options: {
        display: leadTypeActive === "Dropped" || leadTypeActive === "all",
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
            color: '#243d6a',
            maxWidth: '500px',
            overflow: 'auto',
            fontWeight: 'bold'
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 1,
            background: "#e6e6e6",
            zIndex: 101,
            textColor: '#FFFFFF',
            maxWidth: '500px',
            overflow: 'auto',
          }
        }),
      }
    },
    {
      name: "lead_source",
      label: "Lead Source",
      options: {
        filterOptions: {
          renderValue: val => {
            return val ? val[0].toUpperCase() + val.slice(1) : "No Lead Source"
          }
        },
        display: columnsList?.includes('lead_source') ? true : false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "sub_for_appr_date",
      label: "Submitted Date",
      options: {
        display: columnsList?.includes('sub_for_appr_date') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "user_id",
      label: "Partner Name",
      options: {
        display: columnsList?.includes('user_id') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {
            var partner = null;
            subPartnersList && subPartnersList?.forEach((item) => {
              if (item.members.includes(value._id)) {
                partner = item.key;
              }
            })
            return partner;
          } else {
            return "Not Found"
          }
        },
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "created_at",
      label: "Created at ",
      options: {
        display: columnsList?.includes('created_at') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "updated_at",
      label: "Updated at ",
      options: {
        display: columnsList?.includes('updated_at') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "Assigned By",
      lable:"Assiged By",
      options: {
        display: columnsList?.includes('Assigned By') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value.first_name + " "+value.last_name : "NA" 
        
        ),
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "tasks",
      label:"Last Added Task",
      options: {
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value?.task_name : "NA" 
        
        ),
        filter: false,
        setCellHeaderProps: headerProps,
      }
    },
  //   {
  //     name: "tasks",
  //     label: "Last Added Task",
  //     options: {
  //       filter: false,
  //       display: false,
  //       options: {
  //         setCellProps: () => ({
  //           style: {
  //             display:"none",
  //           }
  //         }),
  //         setCellHeaderProps: () => ({
  //           style: {
  //             display:"none",
              
  //           },
  //         }),
  //       setCellHeaderProps: headerProps,
  //     },
  //   }
  // }
  ];

  // const handleRowClick = (rowData, rowMeta) => {
  //   // setShowUpdateLeadModal(true);
  //   updateKeyData("showUpdateLeadModal", true)
  //   setRowMetaData(rowMeta);
  //   setRowId(tableData[rowMeta.dataIndex]._id)
  //   setAccountId(tableData[rowMeta.dataIndex].account_id);


//  };
const LoaderText = () => {
  return   leadlistLoader ? <div className="parent-div">
  <div className="loader"></div>
</div>:"No Data Found.." 
}

  // };


  const options = {
    textLabels: {
      body: {
        noMatch: LoaderText()
      }
    },
    // rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    // rowsPerPageOptions : [20, 50, 100, 500, 1000, 1500, 2000, 2500],
    filter: true,
    filterType: "dropdown",
    responsive: "simple",
    tableBodyHeight: (window.innerHeight - 130).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 130).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    download: false,
    print: false,
    count : leadsList?.totalCount,
    selectableRows:permissions.includes("canAssignLeads")? true : "none",
    customToolbarSelect: (selectedRows, displayData) => {
      var lead_ids_data = selectedRows.data.map(item1 => {
        return displayData[item1.index]?.data[0];
      })
      return (
        <div className="assing_to_isales">
        {permissions.includes("canAssignLeads") ?
          <>
            <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => setShowAssigniSales(true)} >Assign To</Button>
            {showAssigniSales ? <AssigniSalesUserModal leadIds={lead_ids_data} refreshAssignuser={() => getLeadsData()} show={showAssigniSales} onHide={() => setShowAssigniSales(false)} /> : ""}
          </>
       : false}
      </div>
      )
    },
    setRowProps: (row, dataIndex, rowIndex) => {
      if (row[22]) {
        if (row[22]['postponed_date']) {
          var difference = moment(moment(row[22]['postponed_date']), "DD/MM/YYYY HH:mm:ss").diff(moment(), "DD/MM/YYYY HH:mm:ss");
        } else {
          var difference = moment(moment(row[22]['action_date']), "DD/MM/YYYY HH:mm:ss").diff(moment(), "DD/MM/YYYY HH:mm:ss");
        }
        return {
          style: { backgroundColor: difference >= 0 ? "" : "#ffe8e8" },
        };
      } else {
        return {
          style: { backgroundColor: "#ebebeb" }
        }
      }
    },

    onChangeRowsPerPage (numberOfRows) {
      //
    },
    onViewColumnsChange: async (changedColumn, action) => {

      var columns = Object.assign([], columnsList)
      if (action === "add") {
        columns.push(changedColumn);
      }
      if (action === "remove") {

        // var index = columns.indexOf(changedColumn);
        // if (index !== -1) {
        //   columns.splice(index, 1);
        // }
        var columns = columnsList.filter(item => item !== changedColumn);
      }


      const updatedData = {
        user_id: userData._id,
        table_name: "leads",
        columns_list: columns,
      }
      updateColumnsListData(updatedData)

    },
    onDownload: (buildHead, buildBody, columns, data) => {
      let rowDatas = data.map(item => {
        const temp = [
          "#######",
          item.data[1] ? item.data[1].first_name : "account manager not found",
          item.data[2] ? item.data[2] : "NA",
          item.data ? item.data[3] : "NA",
          item.data ? item.data[4] : "NA",
          item.data[5] ? item.data[5].name : "NA",
          item.data[6] ? item.data[6].name : "NA",
          item.data[7] ? item.data[7] : "NA",
          item.data[8] ? item.data[8] : "NA",
          item.data[9] ? item.data[9] : "NA",
          item.data[10] ? item.data[10] : "NA",
          item.data[11] ? item.data[11] : "NA",
          item.data[12] ? item.data[12] : "NA",
          item.data[13] ? item.data[13].street + ", " + item.data[13].state + ", " + item.data[13].postal_code + ", " + item.data[13].country : "NA",
          item.data[14] ? item.data[14] : "NA",
          item.data[15] ? item.data[15] : "NA",
          item.data[16] ? item.data[16].replace("T", " ").replace("Z", " ") : "NA",
          item.data[17] ? item.data[17].name : "NA",
          item.data[18] ? item.data[18] : "NA",
          item.data[19] ? moment(item.data[19].replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('ddd Do MMM YYYY') : "NA",
          item.data[20] ? moment(item.data[20].replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('ddd Do MMM YYYY') : "NA",

        ]

        return { data: temp }
      })

      let val = `${buildHead(columns)}${buildBody(rowDatas)}`

      return val
    },
    setTableProps: () => {
      return {
        height: '20px',
      };
    },
    onCellClick: (colData, cellMeta) => onCellClickHandler(cellMeta),
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage
    ) => {
      const startIndex = page * rowsPerPage;
      const endIndex = (page + 1) * rowsPerPage;
      return (
        <CustomFooter
          totalPoValue = {tableData && sumPoValue(startIndex, endIndex, "budget")}
          // rowsPerPageOptions={[20,50,70,100,200,500,1000,2000]}
          // rowsPerPage: {(window.innerHeight > 720 ? 20 : 15)}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage? rowsPerPage : 15}
          onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)}
          onChangePage={(_, page) => changePage(page)}
        />
      );
    }

  };

  const sumPoValue = (startIndex, endIndex, column) => {
    
    if(column == "budget"){
      return tableData
      .slice(startIndex, endIndex)
      .map(a => a.budget)
      .reduce((total, salary) => (total += salary), 0);
    }

  };

  return (
    <>
      <React.Fragment>
       
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={<div style={{ display: "flex", flexDirection: "row", justifyContent:"flex-start", alignItems:"center" }} >
              <b>Leads List</b>&nbsp;&nbsp;&nbsp;
              <span onClick={() => filterLeadType("all")} className={leadTypeActive === "all" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> All </span>&nbsp;&nbsp;
              <span onClick={() => filterLeadType("Prospect")} className={leadTypeActive === "Prospect" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> Prospect </span>&nbsp;&nbsp;
              <span onClick={() => filterLeadType("Suspect")} className={leadTypeActive === "Suspect" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> Suspect </span>&nbsp;&nbsp;
              <span onClick={() => filterLeadType("Dropped")} className={leadTypeActive === "Dropped" ? "badges badge-pill cursor-pointer badge-primary" : "badges badge-pill cursor-pointer badge-light "}> Dropped </span>&nbsp;&nbsp;
            </div>
           
            }
            page={page}
            data={tableData}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
       
      </React.Fragment>

      {showUpdateLeadModal ? <ViewLead show={showUpdateLeadModal} reFreshViewLeadModal={() => getLeadsData()} accountId={accountId} onHide={() => updateKeyData("showUpdateLeadModal", false)} rowId={rowId} metaData={rowMetaData} /> : ""}

      {showRejectModal ? <RejectReason refreshRejectModal={() => getLeadsData()} rejectLeadId={rejectLeadId} rejectTypeLead={rejectTypeLead} show={showRejectModal} onHide={() => setShowRejectModal(false)} /> : ""}
    </>
  )
});

const mapStateToProps = (state) => ({
  leadsList : state.leads.leadsList,
  rowsPerPage : state.leads.rowsPerPage,
  page : state.leads.page,
  columnsList : state.leads.columnsList,
  subPartnersList : state.leads.subPartnersList,
  showUpdateLeadModal : state.leads.showUpdateLeadModal,
  isFetching : state.leads.isFetching,
  leadlistLoader:state.leads.leadlistLoader
});

const mapDispatchToProps = (dispatch) => ({
  getLeadsData : (data) => dispatch(getLeadsList(data)),
  getColumnsList : (data) => dispatch(getColumnsListData(data)),
  updateColumnsListData : (data) => dispatch(updateColumnsList(data)),
  subPartnersListData : () => dispatch(subPartnersList()),
  cancelLeadApprovalData : (data) => dispatch(cancelLeadApproval(data)),
  approveLeadsData : (data) => dispatch(approveLeads(data)),
  updateMeddicData : (data) => dispatch(updateMeddicValue(data)),
  updateKeyData : (key, value) => dispatch(updateKey(key, value)),
  updateViewRowData : (data) => dispatch(updateViewRowDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeadsTable);