import { createSlice } from "@reduxjs/toolkit";
import Api from "./axios";
import { toast } from "react-toastify";
var userObj = JSON.parse(localStorage.getItem("user")) || null;

const intialAuthState = {
    isFetching: false,
    error: false,
    errorPayload: false,
    user: userObj,
    msg: "",
    leadsAnalysis: null,
    open:false,
    showModal: false,
    meetinglistdata: [],
    meetingcolumnupdated:[],
    Meeting_columnlist:[],
    Meeting_columns:[],
    getmeetingLoader:false,
    columnsList:[],
    columnObjects: [],
    meetingsListNew: [],
    totalRows: 0
}

const Meetingslice = createSlice({
    name: "meetinglist",
    initialState: intialAuthState,
    reducers: {
        Open: (state, action) => {
          state.open = true; 
        },
        close : (state, action) => {
          state.open = false; 
        },
    
        openModal: (state, action) => {
            state.showModal = true; 
          },
          closeModal: (state, action) => {
            state.showModal = false; 
          },
          loaderStart(state, action) {
            state.isFetching = true;
            state.msg = "Fetching...";
            state[action.payload] = true;
          },
          loaderStop(state, action) {
            state.isFetching = false;
            state.msg = "Fetching...";
          },
           //METTING LIST REDUCERS START HERE
    meetingListSuccess(state, action) {
      state.isFetching = false;
        state.error = false;
        state.errorPayload = null;
        state.meetinglistdata = action.payload;
        // toast.success("meeting list data") 
      },
      meetingListFailure(state, action) {
        state.isFetching = false;
        state.error = false;
        state.errorPayload = null;
        state.meetinglistdata = [];
        toast.error("No data found ")
      },
      meetingcolumndataSuccess (state,action) {
       
        state.error = false;
        state.errorPayload = null;
        state.meetingcolumnupdated = action.payload;
        // toast.success("meeting column updated") 
      },
      meetingcolumndataFailure (state,action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.meetingcolumnupdated = [];
        toast.error("Error in column updated") 
  
      },
  
  //
  
      Meeting_columnlistSuccess  (state,action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.Meeting_columnlist = action.payload;
        // toast.success("Meeting_columnlistSuccess") 
  
      },
  
      Meeting_columnlistFailure  (state,action) {
        state.meetingLoader = false;
        state.error = false;
        state.errorPayload = null;
        state.Meeting_columnlist = [];
        toast.error(" Error in Meeting_columnlistSuccess") 
  
      },

      // get column table
      getmeetingColumnsuccess(state,action) {
      
        state.error = false;
        state.errorPayload = null;
        state.Meeting_columns = action.payload;
        // toast.success("Meeting_columnlistSuccess") 
  
      },
      getmeetingColumnFailure(state,action) {
        
        state.error = false;
        state.errorPayload = null;
        state.Meeting_columns = [];
        // toast.success("Meeting_columnlistSuccess") 
  
      },
      meetingColumnsListSuccess(state, action){
        state.isFetch = false
        state.error = false
        state.errorPayload = false
        state.columnsList = action.payload.columns_list
        state.columnObjects = action.payload.column_objects
    },
    meetingColumnsListFailure(state, action){
        state.isFetch = false
        state.error = true
        state.errorPayload = action.payload
        state.columnsList = []
    },
    meetingDataUpdatedSuccess(state, action){
      state.meetingsListNew = action.payload.data
      state.totalRows = action.payload.totalRows      
    },
    updateColumnsList(state, action){
      state.columnsList = action.payload
    },
    }
});
export const MeetingActions = Meetingslice.actions;
// METTING LIST ACTIONS START HERE
          // get meeting lst
export const getMeetinglistdata = (updatedData) => {
    
    const data = {
      from_date: updatedData.from_date,
      to_date: updatedData.to_date,
    };
    return async (dispatch) => {
      dispatch(MeetingActions.loaderStart());
      try {
        await Api.post(`/meetings/${updatedData?.user_id}`, data).then((res) => {
          if (res.data.status === "success") {
            dispatch(MeetingActions.meetingListSuccess(res?.data?.meetings));
          }
          if (res.data.status === "failure") {
            dispatch(MeetingActions.meetingListFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(MeetingActions.meetingListFailure("Network Error"));
      }
    };
  };
  
  
  
  export const meetingUpdateddata = (oupdatedData) => {

    return async (dispatch) => {
      dispatch(MeetingActions.loaderStart("meetingLoader"));
      try {
        await Api.post("columns-hide-show/create", oupdatedData).then((res) => {
          if (res.data.status === "success") {
            dispatch(MeetingActions.organizationsuccess(res.data));
          }
          if (res.data.status === "failure") {
            dispatch(MeetingActions.organizationFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(MeetingActions.organizationFailure("Network Error"));
      }
    };
  };
  
  
  export const meetingColumnUpdateddata = (updatedData) => {
  
    return async (dispatch) => {
      dispatch(MeetingActions.loaderStart("meetingLoader"));
      try {
        await Api.post("columns-hide-show/create", updatedData).then((res) => {
          if (res.data.status === "success") {
            dispatch(MeetingActions.meetingcolumndataSuccess(res.data));
          }
          if (res.data.status === "failure") {
            dispatch(MeetingActions.meetingcolumndataFailure(res.data.msg));
          }
        });
      } catch (err) {
        dispatch(MeetingActions.meetingcolumndataFailure("Network Error"));
      }
    };
  };


  
export const getmeetingColumnListHandle = (userid) => {
  

  return async (dispatch) => {
    dispatch(MeetingActions.loaderStart("meetingLoader"));
    try {
      var userData = JSON.parse(localStorage.getItem("user"))
      await Api.get(`/columns-hide-show/list/meetings/`+ userData?.user?._id).then((res) => {
        if (res.data.status === "success") {
          dispatch(MeetingActions.getmeetingColumnsuccess(res.data));
        }
        if (res.data.status === "failure") {
          dispatch(MeetingActions.getmeetingColumnFailure(res.data.msg));
        }
      });
    } catch (err) {
      dispatch(MeetingActions.getmeetingColumnFailure("Network Error"));
    }
  };
};

export const getColumnsList = () => {
  return async (dispatch) => {
      dispatch(MeetingActions.loaderStart())
      try {
          var userData = JSON.parse(localStorage.getItem("user"))
          await Api.get("/columns-hide-show/list/meetings/" + userData?.user?._id).then((res) => {
                  if (res.data.status === "success") {
                      dispatch(MeetingActions.meetingColumnsListSuccess(res.data.columnsList))
                  }
                  if (res.data.status === "failure") {
                      toast.error(res.data.msg)
                      dispatch(MeetingActions.meetingColumnsListFailure(res.data.msg))
                  }
              })
      }
      catch (err) {
          toast.error("Network Error!")
          dispatch(MeetingActions.meetingColumnsListFailure("Network Error"));
      }
  }
}

export const fetchMeetingsList = (data) => {
  return async (dispatch) => {

    dispatch(MeetingActions.loaderStart())
    try {
        var userData = JSON.parse(localStorage.getItem("user"))
        await Api.post("/meetings/page/meetings-pagination", data).then((res) => {

          // if (res.data.status === "success") {
              dispatch(MeetingActions.meetingDataUpdatedSuccess(res.data))
          // }
          if (res.data.status === "failure") {
              toast.error(res.data.msg)
              dispatch(MeetingActions.meetingDataUpdatedFailure(res.data.msg))
          }
      })
    }
    catch (err) {
        toast.error("Network Error!")
        dispatch(MeetingActions.meetingColumnsListFailure("Network Error"));
    }
  }
}

  export default Meetingslice.reducer;

  