import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import axios from 'axios';
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-cartesian';
import { components } from "react-select";
import { config, SelectYear, QuartersFilter, MonthlyFilter } from './chartConfig'
import Badge from 'react-bootstrap/Badge';
import moment from 'moment';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { AiOutlineBarChart } from 'react-icons/ai';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { AiOutlineDatabase } from 'react-icons/ai';
import MUIDataTable from "mui-datatables";
import { getMuiTheme, headerProps } from '../commonColumns/commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { useSelector, useDispatch, connect } from 'react-redux';
import {  getYears,  fetchIsalesChartData } from "../../store/dashboard";
import { toast } from 'react-toastify';
import { toPng } from 'html-to-image'
const PlotlyComponent = createPlotlyComponent(Plotly);

const IsalesChart = React.memo(({isalesLoader, yearsList, isalesChart, isalesChartTableData, fetchIsalesChartDataList, getYearsData}) => {
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const handle = useFullScreenHandle();
  const [data, setData] = useState([]);
  // const [years, setYears] = useState([]);
  // const [salesStages, setSalesStages] = useState([]);
  // const [accountManagers, setAccountManagers] = useState([]);
  const [defaultTableText, setDefaultTableText] = useState('Loading...')
  const [filterMonth, setFilterMonth] = useState();
  const [filterYear, setFilterYear] = useState("");
  const [filterAccountManager, setFilterAccountManager] = useState([]);
  const [filterSalesStage, setFilterSalesStage] = useState([]);
  const [filterQuarter, setFilterQuarter] = useState();
  
  const [tableData, setTableData] = useState([]);
  const [showChart, setShowChart] = useState(true);
  const [tableHeight, setTableHeight] = useState("333px");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterDisplay, setFilterDisplay] = useState(true);
  const [columnsDisplay, setColumnsDisplay] = useState(true);
  const [fromDate, setFromDate] = useState(moment().startOf('month').format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().endOf('month').format("YYYY-MM-DD"));

  // const dispatch = useDispatch();
  // const {    } = useSelector(state => state.dashboard);

  var defaultMonth = { label: "All", value: false };
  
  var deYear = moment().format('YY');
  var deYearName = moment().format('YYYY')
  var defaultYear = {value: parseInt(deYear), label:deYearName}

  const currentYear = moment().year();


  const formatWeekSelectLabel = (date, invalidLabel) => {
    return `${moment(date).format("MMM Do YYYY")}`
  };
  const reportChange = ((state, handle) => {
    var isalesChart = document.getElementById("isalesChartComponent");
    if (isalesChart) {
      isalesChart = document.getElementById("isalesChartComponent").getElementsByClassName("user-select-none")[0]
      isalesChart.id = "isalesChart";
    }

    if (state) {
      if (isalesChart) {
        document.getElementById("isalesChart").style.height = (window.innerHeight - 60).toString() + 'px';
      }
      setTableHeight((window.innerHeight - 170).toString() + 'px');
      setRowsPerPage(15);
      setColumnsDisplay(false);
      setFilterDisplay(false);
    } else {
      if (isalesChart) {
        document.getElementById("isalesChart").style.height = "450px";
      }
      setTableHeight('333px');
      setRowsPerPage(10);
      setColumnsDisplay(true);
      setFilterDisplay(true);
    }

  });


  const containerRef = useRef(null); 
  const downloadContent = () => {
  const container = containerRef.current
  
  toPng(container)
  .then(function(dataUrl) {
    const link = document.createElement('a')
    link.download = 'I-Sales-chart.png';
    link.href = dataUrl;
    link.click();
  })
  .catch(function(error) {
    console.log("error:",error)
  });
  
  }
  
   
  const config = {
    responsive: true,
    displaylogo: false,
    showTips: true,
    pan2d: true,
    modeBarButtonsToRemove: ['sendDataToCloud', 'hoverClosestPie', 'select2d', 'lasso2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'toImage'],
    modeBarButtonsToAdd: [
      {
        name: 'Download',
        icon: Plotly.Icons.camera, // Download icon
        click: function() {
          toast.success("Downloaded Successfully!")
          // Download the chart as PNG
          downloadContent();
        },
        title: 'Download As Png'
      },
  
    ],
  };

  
  const chartButtonClicked = async () => {

    setShowChart(true);
    if (!columnsDisplay) {
      handle.enter()
    }
  }

  const getForecastChartData = async () => {
    let quatervalue;
    let monthvalue;
    var yearvalue="";

    var account_manager = filterAccountManager.map(item => {
      return item.value;
    });

    var sales_stages = filterSalesStage.map(item => {
      return item.value;
    });
    // if(filterYear && filterYear.length>0){
    //   yearvalue = filterYear.map(item => {
    //     return item.value
    //   })
    // }
    if(filterYear){
      yearvalue = filterYear?.value
    }
// Quater //
  if (filterQuarter === "Q1") {
    quatervalue = 1;
  } else if (filterQuarter === "Q2") {
    quatervalue = 2;
  } else if (filterQuarter === "Q3") {
    quatervalue = 3;
  } else if (filterQuarter === "Q4") {
    quatervalue = 4;
  } else {
    quatervalue = null;
  }
// 
// Months//
if (filterMonth === "All") {
  monthvalue = false;
}if (filterMonth === "January") {
monthvalue = 1;
} else if (filterMonth === "February") {
monthvalue = 2;
} else if (filterMonth === "March") {
monthvalue = 3;
} else if (filterMonth === "April") {
monthvalue = 4;
}else if (filterMonth === "May") {
monthvalue = 5;
}else if (filterMonth === "June") {
monthvalue = 6;
}else if (filterMonth === "July") {
monthvalue = 7;
}else if (filterMonth === "August") {
monthvalue = 8;
}else if (filterMonth === "September") {
monthvalue = 9;
} else if (filterMonth === "October") {
monthvalue = 10;
}else if (filterMonth === "November") {
monthvalue = 11;
} else if (filterMonth === "December") {
monthvalue = 12;
} else {
monthvalue = null;
}

    var data = {
      account_manager: account_manager,
      sales_stages: sales_stages,
      from_date: fromDate,
      to_date: toDate,
      user_id: userData._id,
      year:yearvalue?yearvalue:currentYear
    }

    if(filterYear &&  yearvalue){
      data.year = yearvalue
      delete data.from_date
      delete data.to_date
     setFromDate(moment().format("YYYY-MM-DD"))
     setToDate(moment().format("YYYY-MM-DD"))
   
    }
    if(filterQuarter && quatervalue > 0){
      data.quarters = quatervalue
      delete data.from_date
      delete data.to_date
     setFromDate(moment().format("YYYY-MM-DD"))
     setToDate(moment().format("YYYY-MM-DD"))
    }
    if(filterMonth && monthvalue > 0){
      data.months = monthvalue
      delete data.from_date
      delete data.to_date
     setFromDate(moment().format("YYYY-MM-DD"))
     setToDate(moment().format("YYYY-MM-DD"))
    }

    fetchIsalesChartDataList([data,currentYear])

  }

  var  monthsList = [{ label: "All", value: "All" }, { label: 'January', value: 'January' }, { label: 'February', value: "February" }, { label: 'March', value: "March" }, { label: 'April', value: "April" }, { label: 'May', value: "May" }, { label: 'June', value: "June" }, { label: 'July', value: "July" }, { label: 'August', value: "August" }, { label: 'September', value: "September" }, { label: 'October', value: "October" }, { label: 'November', value: "November" }, { label: 'December', value: "December" }]
  const quartersList = [{ value: "all", label: "Annual" }, { value: 'Q1', label: 'Q1' }, { value: 'Q2', label: "Q2" }, { value: 'Q3', label: 'Q3' }, { value: 'Q4', label: 'Q4' }]
  

  useMemo(() => {
    console.log("filterYear", filterYear);
    getForecastChartData();
  }, [filterMonth, filterYear, filterAccountManager, filterSalesStage, filterQuarter, fromDate, toDate]);

  useMemo(() => {

    getForecastChartData();
    // dispatch(getIsalesUsers());
    getYearsData();
    // getSalesConfidenceStages();
  }, [])
  const removeFilterUserListBadge = (value) => {
    var inputValue = value.target.getAttribute('value');
    const filterAccountManagers = filterAccountManager.filter(item => item.value !== inputValue);
    setFilterAccountManager(filterAccountManagers);
  }
  
  const removeYearProductListBadge = (value) => {
    var inputValue = value.target.getAttribute('value');
    console.log("input value is", inputValue)
    // var Yearfilter = filter.year.filter(item => item.value.toString() !== inputValue.toString())
    var Yearfilter = filterYear.value.toString() === inputValue.toString() ? "" : filterYear.value;
    console.log("input value is after", Yearfilter);
     setFilterYear(Yearfilter)
  }
  const removequatervalueBadge = (value) => {
    var inputValue = value.target.getAttribute('value');
    console.log("yearbadge2", inputValue);
    var updatedQuarter = filterQuarter === inputValue ? "" : filterQuarter;
    setFilterQuarter(updatedQuarter);
  }
  
  const removeMonthrvalueBadge = (value) => {
    var inputValue = value.target.getAttribute('value');
    console.log("yearbadgemonth", inputValue);
    var updatedMonth = filterMonth === inputValue ? "" : filterMonth;
    setFilterMonth(updatedMonth);;;
  }
 

  const layout = {

    paper_bgcolor: 'transparent ',
    font: {
      size: 12,
      color: '#6E8898'
    },
    margin: {
      l: 60,
      r: 60,
      b: 90,
      t: 60,
    },
    title: "iSales Report",
    useResizeHandler: true,
    style: { width: "100%", height: "100%" },
    showlegend: true,
    legend: { "orientation": "h" },
  }
  const startEndDateHandle = (date) => {
    setFromDate(moment(date).format('YYYY-MM-DD'));
    setFilterYear(null)
    setFilterMonth(null)
    setFilterQuarter(null)
  }
  const startEndDateHandle1 = (date) => {
    setToDate(moment(date).format('YYYY-MM-DD'));
    setFilterYear(null)
    setFilterMonth(null)
    setFilterQuarter(null)
  }


  const columns = [
    {
      name: "_id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "company_name",
      label: "Company Name",
      options: {
        display: true,
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "account_manager",
      label: "Account Manager",
      options: {
        display: true,
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "assigned_by",
      label: "Assigned By",
      options: {
        display: true,
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "contact_person",
      label: "Contact Person",
      options: {
        display: true,
        filter: true,
        
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "designation",
      label: "Designation",
      options: {
        filter: true,
        sort: true,
        display:true,
       
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "contact_number",
      label: "Contact No",
      options: {
        filter: true,
        sort: true,
        display:true,
        

        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "email",
      label: "Email Id",
      options: {
        filter: true,
        sort: true,
        display:true,
        setCellHeaderProps: headerProps,
        
      }
    },
    {
      name: "lead_type",
      label: "Lead Type",
      options: {
        display: true,
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
      {
        name: "status",
        label: "Status",
        options: {
          display:false,
          filter: false,
          sort: false,
          empty: true,
        }
      }
   
  ];


  const options = {
    textLabels: {
      body: {
        noMatch: defaultTableText
      }
    },
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: (window.innerHeight - 130).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 130).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    print: false,
    download: false,
  };


  return (
    <>
    
    { isalesLoader ? 
      <div className="parent-div">
            <div className="loader"></div>
          </div>

          : 
          <>
          <div ref={containerRef}>  
      <FullScreen enabled={true} handle={handle} onChange={reportChange} >
        <div className="container-fluid py-3 px-2">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 mx-auto">
              <div className="row row_gutter" >
                <div style={{display:'flex', flexDirection:'row'}}>
                <div className="box11">
                <button onClick={chartButtonClicked}
                    className={showChart === true ? "tab1 active_tab1 " : "tab1 "}><AiOutlineBarChart className={showChart === true ? "common_chart_icons common_chart_icons11 " : "common_chart_icons "} /></button>
                
                  <button className={showChart === false ? "tab1 active_tab1 " : "tab1 "} onClick={() => setShowChart(false)}> <AiOutlineDatabase className={showChart === false ? "common_chart_icons common_chart_icons11 " : "common_chart_icons "} /> </button>
                </div>
                <div className="col-md-1 col-lg-1" style={{ width: '8%' }}>
                  <button onClick={handle.enter} className={columnsDisplay === false ? "tab1 active_tab1 " : "tab1 "}>
                    <BsArrowsFullscreen className={columnsDisplay === false ? "common_chart_icons33 common_chart_icons22 " : "common_chart_icons33 "} />
                  </button>
                </div>
                
                <div className="col-md-1 col-lg-2" >
                  <div >
                    <SelectYear  defaultYear = {defaultYear}  years={yearsList} disableYearFilter={false} updateFilter={value => setFilterYear(value)} />
                  </div>
                </div>
                <div className="col-md-1 col-lg-2" >
                  <div>
                    <MonthlyFilter monthsList={monthsList} disableMonthFilter={false}  updateFilter={ (value) => setFilterMonth(value) } />
                  </div>
                </div>
                
                <div className="col-md-1 col-lg-2">
                   <QuartersFilter quartersList={quartersList} disableQuarterFilter={false} 
                   updateFilter={ (value) => setFilterQuarter(value) }  />
                </div>
                <div className="col-md-2 col-lg-2">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      className="form-control"
                      label="From Date"
                      inputVariant="outlined"
                      value={fromDate}
                      onChange={(date) => startEndDateHandle(date)}
                      labelFunc={formatWeekSelectLabel}
                      showTodayButton
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                  </MuiPickersUtilsProvider>
                </div>

                <div className="col-md-2 col-lg-2">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      className="form-control"
                      label="To Date"
                      inputVariant="outlined"
                      value={toDate}
                      onChange={(date) => startEndDateHandle1(date)}
                      labelFunc={formatWeekSelectLabel}
                      showTodayButton
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                  </MuiPickersUtilsProvider>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>

          
        <div className="row ">
          <div className="col-md-12 ml-2">
          {/* {
            filterYear?.map((filter) => (
              <Badge onClick={removeYearProductListBadge}
                pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
            ))
          } */}

          {
            filterYear ?
            filterYear && 
              <Badge onClick={removeYearProductListBadge}
              pill data={filterYear.value} value={filterYear.value} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filterYear.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
          
          :
          <Badge 
          pill data={currentYear} value={currentYear} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{currentYear}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
            }

         
          {
            filterQuarter && 
            <Badge onClick={removequatervalueBadge}
                pill data={filterQuarter} value={filterQuarter} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filterQuarter}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
          }
          {
            filterMonth&& 
            <Badge onClick={removeMonthrvalueBadge}
                pill data={filterMonth} value={filterMonth} style={{ cursor: 'pointer', color: 'black', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filterMonth}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
          }
            {
              filterAccountManager.length > 0 ?
                filterAccountManager.map((filter) => (
                  <Badge onClick={removeFilterUserListBadge}
                    pill data={filter.value} value={filter.value} style={{ cursor: 'pointer', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.label}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                ))
                : ""
            }
          </div>
        </div>
        {showChart ?
            <PlotlyComponent
              data={JSON.parse(JSON.stringify(isalesChart))}
              layout={layout}
              useResizeHandler
              className="w-full h-full"
              config={config}
              id="isalesChartComponent"
            />
            : <React.Fragment>

               <MuiThemeProvider theme={getMuiTheme()}>
                      <MUIDataTable
                      id="isalesChartComponent"
                        title={"Data"}
                        data={isalesChartTableData}
                        columns={columns}
                        options={options}
                        config={config}

                      />
              </MuiThemeProvider>

        </React.Fragment>}
        </div>
      </FullScreen>
      </div>
      </>
        }
    </>
  )
});

const mapStateToProps = (state) => ({
  isalesChartTableData : state.dashboard.isalesChartTableData,
  yearsList : state.dashboard.yearsList,
  isalesChart : state.dashboard.isalesChart,
  isalesLoader : state.dashboard.isalesLoader,
  
});

const mapDispatchToProps = (dispatch) => ({
  getYearsData : () => dispatch(getYears()),
  fetchIsalesChartDataList : (data) => dispatch(fetchIsalesChartData(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(IsalesChart);