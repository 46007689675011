import React, { useState } from 'react';
import './home.css';
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { GoDiffAdded } from "react-icons/go";
import AddNewContact from "../../components/pageModals/AddNewContact";
import AddNewLead from "../../components/pageModals/AddNewLead";
import { AiOutlineLogout } from "react-icons/ai";
import { AiOutlineHome } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import {ContactActions } from "../../store/Contact"

import { leadsActions } from '../../store/leads';

export default function Navbar(value) {

    const dispatch = useDispatch();
    const AddcontactModal = useSelector((state) => state.Contactlist.AddcontactModal);
  
    const AddContactopenModal = () => {
      dispatch(ContactActions.AddContactopenModal());
    };
  
    const closeModal = () => {
      dispatch(ContactActions.closeModal());
    };
  
    
    const {user} = useSelector(state => state.auth)
    const urlPath = value.value;
    const userData = user.user;
    const permissions = user.permissions;
    const [showAddContactsModal, setShowAddContactsModal] = useState(false);
    const [showAddLeadModal, setShowAddLeadModal] = useState(false);

    

    var vars = {showAddLeadModal: false}
    // const [anchorEl, setAnchorEl] = React.useState(null);

    // const openLeadModal = () => {
    //     vars.showAddLeadModal = true
    // }
    const renderSwitch = (urlPath) => {

        switch (urlPath) {
            case 'contacts':
                return permissions.includes("createOwnContacts") ?
                    <>
                        <Button className="btn-sm navbarAddBtn btn_add_common d-flex flex-row justify-content-center align-items-center" onClick={AddContactopenModal} >
                            <GoDiffAdded />&nbsp; Add
                        </Button>

                        {AddcontactModal ? (
                            <AddNewContact/>
                          ) : null}
                       
                    </>
                    : false

            case 'opportunity':
                return (<div style={{ marginLeft: '100px' }}></div>)

            case 'accounts':
                return (<div style={{ marginLeft: '100px' }}></div>)

            case 'meetings':
                return (<div style={{ marginLeft: '100px' }}></div>)

            case 'home':
                return permissions.includes("createOwnLeads") ?
                    <>
                        <Button className="btn-sm navbarAddBtn btn_add_common d-flex flex-row justify-content-center align-items-center" onClick={() => dispatch(leadsActions.updateShowAddNewModal(true))} >
                        {/* () => setShowAddLeadModal(true) */}
                            <GoDiffAdded />&nbsp; Add
                        </Button>
                        <AddNewLead  />
                        {/* refreshNewAddLead={() => value.reFreshNewLeadTable()} show={showAddLeadModal} onHide={() => setShowAddLeadModal(false)} */}
                    </>
                    : false
                    
            case 'leads':
                return permissions.includes("createOwnLeads") ?
                    <>
                        <Button className="btn-sm navbarAddBtn btn_add_common d-flex flex-row justify-content-center align-items-center" onClick={() => dispatch(leadsActions.updateShowAddNewModal(true))} >
                            <GoDiffAdded />&nbsp; Add
                        </Button>
                        <AddNewLead  />
                        {/* refreshNewAddLead={() => value.reFreshNewLeadTable()} show={showAddLeadModal} onHide={() => setShowAddLeadModal(false)} */}
                    </>
                    : false
                    
            default:
                return (permissions.includes("createOwnLeads") && urlPath === "home") ?
                    <>
                        <Button className="btn-sm navbarAddBtn btn_add_common d-flex flex-row justify-content-center align-items-center" onClick={() => setShowAddLeadModal(true)} >
                            <GoDiffAdded />&nbsp; Add
                        </Button>
                        <AddNewLead show={showAddLeadModal} onHide={() => setShowAddLeadModal(false)} />
                    </>
                    : false
        }
    }

    const handleLogout = (event) => {
        // setAnchorEl(null);
        window.localStorage.clear();
        window.location.reload();
    }

    var navbarWidth = window.innerWidth > 1400 ? window.innerWidth - 410 : "1000px"

    //console.log("window", navbarWidth);
    return (
        <>
            <div classname="new_navbar">
                <nav className="navbar navbar-expand-lg navbar-dark navbar_main_bg_color">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#"><span style={{ 'fontSize': '22px', 'marginLeft': '5px', 'color': '#fff', 'fontWeight': 'bold' }}>KForce</span></a>
                        {renderSwitch(urlPath)}

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent" >
                            <div id="style-10" className='navbar_main_new_classs' style={{maxWidth: navbarWidth}}>
                                <ul className="navbar-nav mx-auto">
                                    <li className="nav-item">
                                        <Link style={{ textDecoration: "none" }} className={urlPath === "home" ? "navbar-links  navbar-active margin_left" : "navbar-links margin_left"} to="/"><AiOutlineHome className="home-icon" /> Home
                                        </Link>
                                    </li>
                                    {permissions.includes("viewLeadsPage") ?
                                        <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "leads" ? "navbar-links navbar-active" : "navbar-links"} to="/leads-list"> Leads
                                            </Link>
                                        </li>
                                        : false}
                                    {permissions.includes("viewMeetingsPage") ?
                                        <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "meetings" ? "navbar-links navbar-active" : "navbar-links"} to="/meetings-list">Meetings
                                            </Link>
                                        </li>
                                        : false}
                                    {permissions.includes("viewContactsPage") ?
                                        <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "contacts" ? "navbar-links navbar-active" : "navbar-links"} to="/contacts-list">Contacts
                                            </Link>
                                        </li>
                                        : false}
                                    {permissions.includes("viewAccountsPage") ?
                                        <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "accounts" ? "navbar-links navbar-active" : "navbar-links"} to="/accounts-list"> Organizations
                                            </Link>
                                        </li>
                                        : false}
                                    {permissions.includes("viewOpportunitiesPage") ?
                                        <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "opportunity" ? "navbar-links navbar-active" : "navbar-links"} to="/opportunities-list">Opportunities
                                            </Link>
                                        </li>
                                        : false}

                                    {permissions.includes("viewAnySalesAchievedChart") ?
                                        <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "setTargets" ? "navbar-links navbar-active" : "navbar-links"} to="/set-target">Sales Targets
                                            </Link>
                                        </li>
                                        : false}
                                    {permissions.includes("viewTicketsListPage") ?
                                        <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "tickets" ? "navbar-links navbar-active" : "navbar-links"} to="/tickets-list">Tickets </Link>
                                        </li>
                                        : false}
                                    {permissions.includes("viewDemoListPage") ?
                                        <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "demo" ? "navbar-links navbar-active" : "navbar-links"} to="/techie-demo">Demo </Link>
                                        </li>
                                        : false}
                                    {permissions.includes("viewBenchmarksPage") ?
                                        <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "benchmark" ? "navbar-links navbar-active" : "navbar-links"} to="/techie-benchmark">Benchmark </Link>
                                        </li>
                                        : false}
                                    {permissions.includes("viewTrainingListPage") ?
                                        <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "training" ? "navbar-links navbar-active" : "navbar-links"} to="/techie-training">Training </Link>
                                        </li>
                                        : false}
                                    {permissions.includes("viewLeaveRequestPage") ?
                                        <li className="nav-item navbar-leave-request">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "leave" ? "navbar-links navbar-active" : "navbar-links"} to="/leave-request">Leave Request </Link>
                                        </li>
                                        : false}
                                    {permissions.includes("viewTechieMeetingsPage") ?
                                        <li className="nav-item navbar-techie-meeting">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "techiemetting" ? "navbar-links navbar-active" : "navbar-links"} to="/techie-metting">Techie Meeting</Link>
                                        </li>
                                        : false}
                                    {permissions.includes("viewCaseStudiesPage") ?
                                        <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "casestudy" ? "navbar-links navbar-active" : "navbar-links"} to="/case-study">Case Study</Link>
                                        </li>
                                        : false}
                                    {permissions.includes("viewTestimonialsPage") ?
                                        <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "testimonial" ? "navbar-links navbar-active" : "navbar-links"} to="/testimonial">Testimonial</Link>
                                        </li>
                                        : false}
                                    {permissions.includes("viewSetTargetsPage") ?
                                        <li className="nav-item navbar-setTarget">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "settarget" ? "navbar-links navbar-active" : "navbar-links"} to="/set-target">Set Target</Link>
                                        </li>
                                        : false}  

                                    {permissions.includes("viewSetTargetsPage") ?
                                        <li className="nav-item navbar-setTarget">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "settarget" ? "navbar-links navbar-active" : "navbar-links"} to="/set-target">Targets</Link>
                                        </li>
                                        : false}  

                                    {permissions.includes("viewResourcesPage") ?
                                        <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "resources" ? "navbar-links navbar-active" : "navbar-links"} to="/resources">Call Script</Link>
                                        </li>
                                        : false} 

                                    {permissions.includes("viewIndustryMetricPage") ?
                                        <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "industrymetric" ? "navbar-links navbar-active" : "navbar-links"} to="/industry-metric">Industry Metric</Link>
                                        </li>
                                        : false}  

                                    {permissions.includes("viewDCRListPage") ?
                                        <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "callrecords" ? "navbar-links navbar-active" : "navbar-links"} to="/call-records">DCR</Link>
                                        </li>
                                    : false}    

                                       {permissions.includes("viewTaskManagerPage") ?
                                        <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "timesheet" ? "navbar-links navbar-active" : "navbar-links"} to="/clock-time">Clock-Time</Link>
                                        </li>
                                        : false} 

                                    {permissions.includes("viewWebinarsPage") ?
                                        <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "webinars" ? "navbar-links navbar-active" : "navbar-links"} to="/events">Events</Link>
                                        </li>
                                    : false} 
            
                                       {permissions.includes("viewDCRListPage") ?
                                        <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "assignedLead" ? "navbar-links navbar-active" : "navbar-links"} to="/assigned-meetings">Assigned Meetings</Link>
                                        </li>
                                       : false} 

                                        {permissions.includes("ViewPayslipPage") ? 
                                        <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "payslip" ? "navbar-links navbar-active" : "navbar-links"} to="/payslip">Payslip</Link>
                                        </li>
                                       :false}

                                       {permissions.includes("ViewHardwareAssetPage") ? 
                                       <li className="nav-item">
                                           <Link style={{ textDecoration: "none" }} className={urlPath === "hardware" ? "navbar-links navbar-active" : "navbar-links"} to="/hardware-assets">Hardware</Link>
                                       </li>
                                       :false}
                                      {permissions.includes("viewRawdataPage") ? 
                                        <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "rowdata" ? "navbar-links navbar-active" : "navbar-links"} to="/rawdata">Raw DataBase</Link>
                                        </li>
                                      :false}
                                       {permissions.includes("viewBattleCardPage") ? 
                                        <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "battlecards" ? "navbar-links navbar-active" : "navbar-links"} to="/battlecards">Battle cards</Link>
                                        </li>
                                      :false} 

                                      {permissions.includes("viewEmailCampaignPage") ? 
                                        <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "emailcampaign" ? "navbar-links navbar-active" : "navbar-links"} to="/email-campaign">Email Campaign</Link>
                                        </li>
                                      :false} 

                                      
                                        {/* <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "calendar" ? "navbar-links navbar-active" : "navbar-links"} to="/calendar">Calendar</Link>
                                        </li> */}
                                     
                                      
                                
                                {permissions.includes("viewHRISPage") ?<li className="nav-item">
                             
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "hris" ? "navbar-links navbar-active" : "navbar-links"} to="/hris">HRIS</Link>
                                        </li>

                                    : false}

                                      
                                      {/* path="/roi-list" */}
                                    {permissions.includes("viewRoiSheetPage") ?
                                      <li className="nav-item">
                                            <Link style={{ textDecoration: "none" }} className={urlPath === "roisheet" ? "navbar-links navbar-active" : "navbar-links"} to="/roi-list">ROI Sheet</Link>
                                        </li>
                                      :false} 

                                    {permissions.includes("viewCalendarPage") ? 
                                      <li className="nav-item">
                                        <Link style={{ textDecoration: "none" }} className={urlPath === "calendar" ? "navbar-links navbar-active" : "navbar-links"} to="/calendar">Calendar</Link>
                                      </li>
                                    :false} 

                                    {permissions.includes("viewAnyProductTargetsChart") ? 
                                      <li className="nav-item">
                                        <Link style={{ textDecoration: "none" }} className={urlPath === "productTargets" ? "navbar-links navbar-active" : "navbar-links"} to="/product-targets">Product Targets</Link>
                                      </li>
                                    :false} 

                                    {permissions.includes("viewTempLeadsPage") ? 
                                      <li className="nav-item">
                                        <Link style={{ textDecoration: "none" }} className={urlPath === "templeads" ? "navbar-links navbar-active" : "navbar-links"} to="/temp-leads">Pending Leads</Link>
                                      </li>
                                    :false} 

                                    
                                </ul>
                            </div>
                            <div className="topbarLeft d-flex flex-row justify-content-end align-items-center">
                                <Link to="/profile"><img onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = "https://kaizenat.com/kaizendynaLogo.png";
                                }} src={userData.profile_pic} className="topbarImg menu-icon" alt="profile" /></Link>

                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <span title="Logout" className=" menu-icon logout-icon d-flex flex-row justify-content-start align-items-center" alt="Logout" onClick={handleLogout}>
                                    <AiOutlineLogout size={30} />
                                </span>

                            </div>
                        </div>
                    </div>
                </nav>
            </div>

        </>
    );
}

