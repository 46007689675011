import React, { useEffect, useState} from 'react';
import MUIDataTable from "mui-datatables";
import moment from 'moment-timezone';
import { getMuiTheme } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import ViewContact from "../../components/pageModals/ViewContact";
import { useSelector,useDispatch, connect } from 'react-redux';
import {ContactColumnUpdateddata,CommonsliceActions} from "../../store/Common"
import {ContactActions,getContactColumnListHandle,getColumnsList} from "../../store/Contact"

const ContactTable = React.memo (({openshowModal,contactlistdata,isFetching,contactupdatedata,newrowId,newrowMetaData,showModal,columnsList,getColumnsList,viewAccountContactdata,keyData,updateColumnsListData}) => {
  const [isFetchingCondition,setIsFetchingCondition] = useState(isFetching)

  const [data, setData] = useState([]);
  useEffect(() => {
    var listArray = []
  
    if(keyData === "viewAccountTableContacts"){
      listArray = viewAccountContactdata
      setIsFetchingCondition(false)
    }else{
      listArray = contactlistdata
    }
    setData(listArray)
},[contactlistdata,viewAccountContactdata,keyData])

  const {user} = useSelector(state => state.auth)
  const userData = user.user;

  useEffect(() => {
    getColumnsList();

  }, []);
  const headerProps = () => ({
    style: {
      whiteSpace: "nowrap",
      background: "#e6e6e6",
      fontWeight: 'bold'
    }
  })

  const columns = [
    {
      name: "user_id",
      label: "Account Manager",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? (value.first_name + ' '+ value.last_name) : "user not found"
        ),
        display: columnsList?.includes('user_id') ? true : false,
        filter: (userData.user_role_id.role_name !== "user") ? true : false,
        setCellHeaderProps: headerProps
      }
    },
    {
      name: "company_name",
      label: "Organization Name",
      options: {
        filter:true,
        display: columnsList?.includes('company_name') ? true : false,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
            color: '#243d6a',
            fontWeight: 'bold',
            maxWidth: '500px',
            overflow: 'auto',
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "#e6e6e6",
            zIndex: 101,
            maxWidth: '500px',
            overflow: 'auto',
          }
        })
      }
    },
    {
      name: "contact_name",
      label: "Contact Name",
      options: {
        filter: true,
        display: columnsList?.includes('contact_name') ? true : false,
        setCellHeaderProps: headerProps
      }
    },
    {
      name: "contact_number",
      label: "Contact Number",
      options: {
        filter: true,
        display: columnsList?.includes('contact_number') ? true : false,
        setCellHeaderProps: headerProps
      }
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        display: columnsList?.includes('email') ? true : false,
        setCellHeaderProps: headerProps
      }
    },
    {
      name: "designation",
      label: "Designation",
      options: {
        filter: true,
        display: columnsList?.includes('designation') ? true : false,
        setCellHeaderProps: headerProps
      }
    },
    {
      name: "department",
      label: "Department",
      options: {
        filter: true,
        display: columnsList?.includes('department') ? true : false,
        setCellHeaderProps: headerProps
      }
    },
    {
      name: "industry",
      label: "Industry",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value.name : "Industry not found"
        ),
        display: columnsList?.includes('industry') ? true : false,
        setCellHeaderProps: headerProps
      }
    },
    {
      name: "city",
      label: "City",
      options: {
        filter: true,
        display: columnsList?.includes('city') ? true : false,
        setCellHeaderProps: headerProps
      }
    },
    {
      name: "state",
      label: "State",
      options: {
        filter: true,
        display: columnsList?.includes('state') ? true : false,
        setCellHeaderProps: headerProps
      }
    },
    {
      name: "created_at",
      label: "Created at ",
      options: {
        display: columnsList?.includes('created_at') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        filter: true,
        setCellHeaderProps: headerProps
      }
    },
    {
      name: "updated_at",
      label: "Updated at ",
      options: {
        display: columnsList?.includes('updated_at') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        filter: true,
        setCellHeaderProps: headerProps
      }
    },

  ];

  const handleRowClick = (rowData, rowMeta) => {
    
    openshowModal()
    newrowMetaData(rowMeta)
    newrowId(data[rowMeta.dataIndex]._id); 
  };

  const changePage = page => {
    this.setState({
      isLoading: true
    });
    this.xhrRequest(page).then(res => {
      this.setState({
        isLoading: false,
        page: page,
        data: res.data,
        count: res.total
      });
    });
  };

//   const LoaderText = () => {
//     return   isFetching ? <div className="parent-div">
//     <div className="loader"></div>
//   </div>:"No Data Found.." 
//  }
const LoaderText = () => {
  return isFetchingCondition ? (
    <div className="parent-div">
      <div className="loader"></div>
    </div>
  ) : data?.length === 0 ? (
    "No Data Found.."
  ) : null;
};
  const options = {
    textLabels: {
      body: {
        noMatch: LoaderText()
      }
    },
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    // rowsPerPageOptions : [20, 50, 100, 500, 1000, 1500, 2000, 2500],
    filter: true,
    filterType: "dropdown",
    responsive: "simple",
    tableBodyHeight: (window.innerHeight - 130).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 130).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    download: false,
    print: false,
    onRowClick: handleRowClick,
    onViewColumnsChange: async (changedColumn, action) => {
      var columns = Object.assign([], columnsList)
      if (action === "add") {
        columns.push(changedColumn);
      }
      if (action === "remove") {
        // var index = columnsList.indexOf(changedColumn);
        // if (index !== -1) {
        //     columnsList.splice(index, 1);
        //     columnsList(columnsList);
        // }
        var columns = columns.filter(item => item !== changedColumn);
      }
      const updatedData = {
        user_id: userData._id,
        table_name: "contacts",
        columns_list: columns,
      }
      contactupdatedata(updatedData)
      updateColumnsListData(columns)
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      let rowDatas = data.map(item => {
        const temp = [
          item.data[0] ? item.data[0].first_name : "NA",
          item.data[1] ? item.data[1] : "NA",
          item.data[2] ? item.data[2] : "NA",
          item.data[3] ? item.data[3] : "NA",
          item.data[4] ? item.data[4] : "NA",
          item.data[5] ? item.data[5] : "NA",
          item.data[6] ? item.data[6] : "NA",
          item.data[7] ? item.data[7].name : "NA",
          item.data[8] ? item.data[8] : "NA",
          item.data[9] ? item.data[9] : "NA",
          item.data[10] ? item.data[10].replace("T", " ").replace("Z", " ").split(" ")[0] : "NA",
          item.data[11] ? item.data[11].replace("T", " ").replace("Z", " ").split(" ")[0] : "NA",
        ]

        return { data: temp }
      })

      let val = `${buildHead(columns)}${buildBody(rowDatas)}`

      return val
    },
    onTableChange: (action, tableState) => {
      

      if (action === 'filterChange') {
        // Access filtered data from tableState.filterList
        const newFilterList = tableState.filterList.map((filters, index) => {
          return {
            columnName: columns[index].name, // assuming columns is defined in your component
            filters,
          };
        });

        // Use the newFilterList to make an API call or pass it to another function
        // makeApiCall(newFilterList);
      }
    },
    // onTableChange: (action, tableState) => {
   
    //   if (action === 'filterChange') {
    //     // Access filtered data from tableState.displayData
    //     const newFilteredData = tableState.displayData.map(row => row.data);

    //     // setFilteredData(newFilteredData);
    //   }
    // },
  };

  return (
    <>
      <React.Fragment>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={"Contacts List"}
            data={data}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </React.Fragment>

      {showModal?<ViewContact/> : ""}
    </>
  )
});

const mapStateToProps = (state) => ({
  user : state.auth.user,
  contactlistdata : state.Contactlist.contactlistdata,
  getcolumn:state.Contactlist.getcolumn,
  showModal : state.Contactlist.showModal,
  isFetching : state.Contactlist.isFetching,
  getrowId : state.Contactlist.rowId,
  columnsList:state.Contactlist.columnsList
});

const mapDispatchToProps = (dispatch) => ({
  contactupdatedata:(data) => dispatch(ContactColumnUpdateddata(data)),
  newrowId:(data) => dispatch(ContactActions.setRowId(data)),
  newrowMetaData:(data) => dispatch(ContactActions.setrowMetaData(data)),
  openshowModal:(data) => dispatch(ContactActions.openModal(data)),
  getColumnsList:(data) => dispatch(getColumnsList()),
  updateColumnsListData: (data) => dispatch(ContactActions.updateColumnsList(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactTable);
