import React, { useState, useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap'
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import{createLeaveModal,LeaverequestActions} from "../../store/Leaverequest"
import { useDispatch } from 'react-redux';
const LeaveModal = React.memo (( {user,Type,createLeaveModal,showleavemodal,openshowModal,closemodal,leavetypelist}) => {
//     console.log("userchecking",user.user)
// console.log("leavetypelist",leavetypelist)
// added leavetype new
const userGender = user?.user?.gender; 

if (userGender === 'Male') {
  leavetypelist = [
    ...leavetypelist,
    { label: 'Paternity Leave', value: 'Paternity Leave' }
  ];
} else if (userGender === 'Female') {
  leavetypelist = [
    ...leavetypelist,
    { label: 'Maternity Leave', value: 'Maternity Leave' }
  ];
}
// console.log("finaldatas",leavetypelist);


    const dispatch = useDispatch
    const [disable, setDisable] = React.useState(false);
    const [error, setError] = useState(null);
    const [preliminaryEnd, setPreliminaryEnd] = useState(null);
    
    const [preliminaryStart, setPreliminaryStart] = useState(null);
    const [timeDiff, setTimeDiff] = useState(0);

    const [activity, setActivity] = useState({
        leave_type: '',
        reason: ''
    })
    const { register, handleSubmit, formState: { errors: errors }, setError: setActivityError, reset: reset } = useForm();

    const addLeaveRequestHandle = async (e) => {
        setTimeout(() => { }, 500);
        const activityData = {
            leave_type: activity.leave_type,
            no_of_days: timeDiff.toString(),
            start_date: preliminaryStart,
            end_date: preliminaryEnd,
            leave_reason: activity.reason,
        }
        // console.log("props reponse is",Type);
        if(Type === "wfh"){
            activityData.leave_type = "Work From Home";
        }
        console.log("activity reponse is ",activityData);
        createLeaveModal(activityData)
    }

    const onInputChange = (e) => {
        setActivity({ ...activity, [e.target.name]: e.target.value }, []);
    }

    useEffect(() => {
        if (preliminaryEnd !== null && preliminaryStart !== null) {
            let start = new Date(preliminaryEnd);
            let end = new Date(preliminaryStart);
            setTimeDiff((start - end) / (1000 * 60 * 60 * 24) + 1);
        }
    }, [preliminaryEnd, preliminaryStart]);


    return (
        <>
            <Modal
            show={() => openshowModal()}
            onHide={() => closemodal()}
                backdrop={false}
                disableBackdrop={true}
                dialogClassName="modal-90w"
                arialabelledby="example-custom-modal-styling-title">
                <Modal.Header >
                    <Modal.Title id="example-custom-modal-styling-title">
                    {Type=== "wfh" ? "Work From Home" :  "Leave Request" }
                    </Modal.Title>
                    <a onClick={() => closemodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
                </Modal.Header>
                <Modal.Body>
                {error && (
                    <div style={{ marginBottom: '16px' }}>
                        <Alert severity="error">{error}</Alert>
                    </div>
                )}
                    <div className="from">
                        <form key={1} onSubmit={handleSubmit(addLeaveRequestHandle)} >

                            
                            <div className="row">
                            {Type !== "wfh" ? 
                                <div className="col-md-6">
                                    <TextField variant="outlined"
                                        {...register("leave_type", {
                                            required: {
                                                value: true,
                                                message: "Leave Type is required"
                                            },
                                        })}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={activity.leave_type}
                                        select
                                        helperText={errors.leave_type ? errors.leave_type.message : ''}
                                        error={Boolean(errors.leave_type)}
                                        onChange={e => onInputChange(e)}
                                        type="text" className="form-control" name="leave_type" label="Type" >
                                        {leavetypelist.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                        }
                                    </TextField>
                                </div>
                                : " " }
                            </div>
                            
                            <br />
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <span style={{ marginTop: "0px !important", paddingTop: "0px", paddingBottom: "5px" }}>Select From and To Dates</span><br />
                                        <div className='col-md-12'>
                                            <br />
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <TextField variant="outlined" type="date"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        label="Start Date" name="start_date" className="form-control" value={activity.start_date} id="preliminary-review-start"
                                                        onChange={(e) => setPreliminaryStart(e.target.value)}
                                                        inputProps={{
                                                            min: new Date().toISOString().split('T')[0] 
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                <TextField
                                                variant="outlined"
                                                {...register("reason", {
                                                    required: {
                                                        value: true,
                                                        message: "End Date is required"
                                                    }
                                                })}
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                label="End Date"
                                                name="end_date"
                                                className="form-control"
                                                value={activity.end_date}
                                                id="preliminary-review-end"
                                                onChange={(e) => setPreliminaryEnd(e.target.value)}
                                                inputProps={{
                                                    min: new Date().toISOString().split('T')[0] 
                                                }}
                                            />
                                                </div>
                                                <br />
                                                <div className="nbcol-3" style={{marginTop:'10px'}}>
                                                    <div className="form-group">
                                                        <label htmlFor="total-time">No. of Days : {timeDiff} {timeDiff >= 0 ? "Days" : "Day"}</label>
                                                        <p className="total-time" id="preliminary-review-total">
                                                            
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <br />
                            <div className='row'>
                                <div className="col-md-10">
                                    <TextField multiline rows={7}
                                        variant="outlined"
                                        
                                        {...register("reason", {
                                            
                                            required: {
                                                value: true,
                                                message: "This field is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Must be at least 10 characters"
                                            }
                                        })}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={e => onInputChange(e)}
                                        required
                                        value={activity.reason}
                                        helperText={errors.reason ? errors.reason.message : ''}
                                        error={Boolean(errors.reason)}
                                        type="text" className="form-control" id="reason" name="reason" label={Type === "wfh" ? "Information to Manager" :  "Leave Reason"} />
                                </div>
                            </div>
                            <div className="row">
                                <div >
                                    <button disabled={disable} className="btn btn-sm btn-primary btn_submit_common_green  bottomBarButtons" id="submit"  >Submit</button>&nbsp;&nbsp;
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal >

        </>
    );


});
const mapStateToProps = (state) => ({
  user : state.auth.user,
  Type : state.LeaveRequestlists.Type, 
  showleavemodal:state.LeaveRequestlists.showleavemodal,
  leavetypelist  :state.LeaveRequestlists.LeaveTypeList
});

const mapDispatchToProps = (dispatch) => ({

  createLeaveModal:(data) =>dispatch(createLeaveModal(data)),
  openshowModal:(data) => dispatch(LeaverequestActions.showleaveOpenmodal(data)),
  closemodal:(data) => dispatch(LeaverequestActions.showleaveClosemodal(data)),
  
 
});

export default connect(mapStateToProps,mapDispatchToProps)(LeaveModal);
