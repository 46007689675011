
import React from "react";
import Navbar from "./Navbar";
import "./home.css";
import ForecastSalesStagesReport from "../../components/dashboard/ForecastSalesStagesReport";
import ActivityChart from "../../components/dashboard/Activitychart";
import FunnelChart from "../../components/dashboard/FunnelChart";
import Regionchart from "../../components/dashboard/Regionchart";
import TasksList from "../../components/dashboard/TasksList";
import NonPerformingAccounts from "../../components/dashboard/NonPerformingAccounts";
import PreSalesReport from "../../components/dashboard/PreSalesReport";
import PostSalesReport from "../../components/dashboard/PostSalesReport";
import QueriesClosedReport from "../../components/dashboard/QueriesClosedReport";
import SalesTarget from "../../components/dashboard/SalesTarget"
import AnsysSalesTarget from "../../components/dashboard/AnsysSalesTarget";
import QuartervsQuarterChart from "../../components/dashboard/QuartervsQuarterChart";
import MonthvsMonth from "../../components/dashboard/MonthvsMonth";
import ProductTargets from "../../components/dashboard/ProductTargets";
import InstaReport from "../../components/dashboard/InstaReport";
import LeaveRequestChart from "../../components/dashboard/LeaveRequestChart";
import TicketsChart from "../../components/dashboard/TicketsChart";
import AllReportsTable from "../../components/dashboard/AllReportsTable";
import IsalesChart from "../../components/dashboard/IsalesChart";
import { AiFillCaretRight } from "react-icons/ai";
import EfficiencyChart from "../../components/dashboard/EfficiencyChart";
import UpdateIsalesMeetings from '../../components/dashboard/UpdateIsalesMeetings';
import CardElements from '../../components/dashboard/CardElements';
import { useSelector } from 'react-redux';
import EmployeeLeaveStatus from "../../components/dashboard/EmployeeLeaveStatus";
import LineChart from "../../components/dashboard/LineChart";
import LineChartPost from '../../components/dashboard/LineChart-Post';
import LineChartSales from "../../components/dashboard/LineChart-Sales";
import SalesStageChart from "../../components/dashboard/SalesStageChart";
import StateWiseRevenueChart from "../../components/dashboard/StateWiseRevenueChart";
import TechieClockTimeHrsChart from "../../components/dashboard/TechieClockTimeHrsChart";

export default function Home() {

  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const permissions = user.permissions;
  const [openOppoWithNoTask, setOppoWithNoTask] = React.useState(false);


  return (
        <div className="home-page">
      <Navbar value="home" />
      
      <> 
      <div class="container-fluid">

      <div className="row">
      {permissions?.length> 0 && permissions.includes("viewAnyInstantReport") ?
        <div className="col-lg-12 col-md-12 col-12">
           <div className="row">
                <CardElements />
            </div>
          </div>
          : false}
        </div>
      
      
        {permissions?.length >0  && permissions.includes("viewOwnIsalesMeetings") ?
          <div className="activity_chart_main shadow">
              <UpdateIsalesMeetings />
           </div>
           : false}
      </div>
     
      <div class="container-fluid">
      <div class="row">
      {permissions?.length > 0 && (permissions.includes("viewOwnLeadsOpportunitiesList") || permissions.includes("viewAnyLeadsOpportunitiesList")) ?
                <div className="col-lg-3 col-md-12 col-12">
                    <div className="activity_chart_main shadow">
                      <TasksList />
                    </div>
                </div>
                : false}
              
              
                {(userData?.user_role_id?.role_name !== "user" && permissions.includes("viewRegionWiseChart")) ?
                  <div className="col-lg-9 col-md-12 col-12">
                    <div className="activity_chart_main shadow">
                      <Regionchart /> 
                    </div>
                  </div>
                  : false}
                
                
                  {permissions?.length>0 && (permissions.includes("viewOwnSalesStagesChart") || permissions.includes("viewAnySalesStagesChart")) ?
                  <div className="row justify-content-md-center " style={{marginTop:"25px"}}>
                  <div className="col-lg-12 col-md-12 col-12">
                 
                
                  <div className="Opportunites_chart shadow">
                            <ForecastSalesStagesReport />
                          </div>
                    </div>
                    </div>
                    : false}
                  
                   
                    {permissions?.length > 0  && (permissions.includes("viewAnyInstantReport") || permissions.includes("viewOwnInstantReport")) ?
                  
                    <div className="col-lg-12 col-md-12 col-12">
                    <div className="activity_chart_main shadow" style={{ padding: '20px' }}>
                    <InstaReport />
                  </div>
                  </div>
                  
                  : false}

      </div>
      </div>
   
      <div class="container-fluid">
      <div class="row">
      {permissions?.length > 0 && permissions.includes("viewEmployeeLeaveStatusList") &&
      <div className="row justify-content-md-center " style={{marginTop:"25px"}}>
              <div className="col-lg-12 col-md-12 col-12 mb-4 mb-lg-0">
                <div className="shadow p-3 mb-5 bg-white rounded">
                  <EmployeeLeaveStatus />
                </div>
              </div>
              </div>
  }
 
              {permissions?.length > 0 && permissions.includes("viewAllSalesTechTablesReport") &&
              <div className="col-lg-12 col-md-12 col-12 mb-4 mb-lg-0">
                                <div className="activity_chart_main shadow" style={{ padding: '20px' }}>
                                  <AllReportsTable />
                                </div>
                              </div>
                          } 
                           
                          

                {permissions?.length > 0 && (permissions.includes("viewOwnOpportunitiesWithNoTask") || permissions.includes("viewAnyOpportunitiesWithNoTask")) ?
          <>
          <div style={{marginTop:"25px" ,marginBottom:"20px"}}>
          
              <span style={{ fontSize: "16px", color: 'grey', fontWeight: 'bold' }} onClick={() => setOppoWithNoTask(!openOppoWithNoTask)}>View Opportunities With No Task <AiFillCaretRight /></span>
              {(openOppoWithNoTask === true) ?
                <>
                  <div className="row" id="style-10" style={{ maxHeight: '280px', overflowY: 'scroll' }} >
                    <div className="col-12">
                      <div className="Opportunites_chart shadow" style={{ background: '#fff', marginBottom: '0 !important', borderRadius: '5px' }}>
                        <NonPerformingAccounts />
                      </div>
                    </div>
                  </div>
                </>
                : ""}
                </div>
          </>
          : ""}
          
          
        {permissions?.length > 0 && (permissions.includes("viewOwnPreSalesChart") || permissions.includes("viewAnyPreSalesChart")) ?
        <div className={permissions.includes("viewOwnPreSalesChart") ? "col-lg-12 col-md-12 col-12 mb-8 mb-lg-0": "col-lg-12 col-md-12 col-12 mb-4 mb-lg-0" } >
        <div className="Opportunites_chart shadow" style={{margin:'10px'}}>                      
        <PreSalesReport />
      </div>
      </div>
  : false}

      </div>
      </div>
    
      
   <div class="container-fluid">
      <div class="row">

      {permissions?.length>0 && (permissions.includes("viewAnyPostSalesChart") || permissions.includes("viewOwnPostSalesChart")) ?
                      <div className={permissions.includes("viewOwnPostSalesChart") ? "col-lg-6 col-md-6 col-6 mb-4 mb-lg-0": "col-lg-12 col-md-12 col-12 mb-4 mb-lg-0" } >
                        <div className="Opportunites_chart shadow" style={{margin:'10px'}}>
                          <PostSalesReport />
                        </div>
                      </div>
                     : false}
                   
                  
                {permissions?.length>0 && (permissions.includes("viewAnyPostSalesChart") || permissions.includes("viewOwnPostSalesChart")) ?
                <div className={permissions.includes("viewOwnPostSalesChart") ? "col-lg-6 col-md-6 col-6 mb-4 mb-lg-0": "col-lg-12 col-md-12 col-12 mb-4 mb-lg-0" } >
                  <div className="Opportunites_chart shadow" style={{margin:'10px'}}>
                    <TicketsChart />
                  </div>
                </div>
              : false}
             
             
                            {permissions?.length>0 && (permissions.includes("viewOwnQueriesClosedChart") || permissions.includes("viewAnyQueriesClosedChart")) ?
                <div className={permissions.includes("viewOwnQueriesClosedChart") ? "col-lg-6 col-md-6 col-6 mb-4 mb-lg-0" : "col-lg-12 col-md-12 col-12 mb-4 mb-lg-0"}>
                  <div className="Opportunites_chart shadow" style={{margin:'10px'}}>
                    <QueriesClosedReport />
                  </div>
                </div>
              : false}
           
             

                            {permissions?.length>0 && (permissions.includes("viewOwnQuartervsQuarterChart") || permissions.includes("viewAnyQuartervsQuarterChart")) ?
                <div className={permissions.includes("viewOwnQuartervsQuarterChart") ? "col-lg-6 col-md-6 col-6 mb-4 mb-lg-0" :"col-lg-12 col-md-12 col-12 mb-4 mb-lg-0"}>
                  <div className="activity_chart_main shadow" style={{margin:'10px'}}>
                    <QuartervsQuarterChart />
                  </div>
                </div>
              : false}

      </div>
      </div>
     
      
      <div class="container-fluid">
      <div class="row">

                    {permissions?.length>0 && (permissions.includes("viewOwnMonthvsMonthChart") || permissions.includes("viewAnyMonthvsMonthChart")) ?
                <div className={permissions.includes("viewOwnMonthvsMonthChart") ? "col-lg-6 col-md-6 col-6 mb-4 mb-lg-0" : "col-lg-12 col-md-12 col-12 mb-4 mb-lg-0"}>
                  <div className="activity_chart_main shadow" style={{margin:'10px'}}>
                    <MonthvsMonth />
                  </div>
                </div>
              : false}  
             
              
                          {/* {permissions?.length>0 && (permissions.includes("viewOwnFunnelChart") || permissions.includes("viewAnyFunnelChart")) ?
              <div className="col-lg-6 col-md-6 col-6 mb-4 mb-lg-0">
                  <div className="activity_chart_main shadow" style={{margin:'10px'}}>
                    <FunnelChart />
                  </div>
              </div>
            : false}   */}
            {permissions?.length>0 && (permissions.includes("viewOwnSalesLineChart") || permissions.includes("viewAnySalesLineChart")) ?
            <div className="row justify-content-md-center " style={{marginBottom:"25px"}}>
                <div className="col-lg-12 col-md-12 col-12">
                      <div className="Opportunites_chart shadow">
                      <LineChartSales />
                    </div>
                    </div>
              </div>
              : null}
              
              
              {permissions?.length>0 && (permissions.includes("viewOwnPreSalesLineChart") || permissions.includes("viewAnyPreSalesLineChart")) ?
                            <div className="row justify-content-md-center " style={{marginBottom:"25px"}}>
                <div className="col-lg-12 col-md-12 col-12">
                      <div className="Opportunites_chart shadow">
                      <LineChart />
                    </div>
             
                </div>
              </div>
              : null}
      </div>
      </div>
 
  
      <div class="container-fluid">
      <div class="row">
      
      {permissions?.length>0 && (permissions.includes("viewOwnPostSalesLineChart") || permissions.includes("viewAnyPostSalesLineChart")) ?
                    <div className="row justify-content-md-center " style={{marginBottom:"25px"}}>
                      <div className="col-lg-12 col-md-12 col-12">
                       <div className="Opportunites_chart shadow">
                            <LineChartPost />
                          </div>
                      </div>
                    </div>
                    : null }
                  
                 
                    {permissions?.length>0 && (permissions.includes("viewOwnSalesStagesLineChart") || permissions.includes("viewAnySalesStagesLineChart")) ?
                      <div className="row justify-content-md-center " style={{marginBottom:"25px"}}>
                      <div className="col-lg-12 col-md-12 col-12">
                                   <div className="Opportunites_chart shadow">
                      <SalesStageChart />
                    </div>
                </div>
              </div>
              : false}

              {/* {permissions?.length>0 && (permissions.includes("viewOwnSalesStagesChart") || permissions.includes("viewAnySalesStagesChart")) ?
                                     <div className="col-lg-12 col-md-12 col-12">
                    <div className="Opportunites_chart shadow">
                      <ForecastSalesStagesReport />
                    </div>
                  </div>
                : false} */}

 {permissions?.length>0 && (permissions.includes("viewOwnSalesAchievedChart") || permissions.includes("viewAnySalesAchievedChart")) ?
 <div className="row justify-content-md-center" style={{marginTop:"25px"}}>
                       <div className="col-lg-12 col-md-12 col-12 mb-4 mb-lg-0">
                                    <div className="Opportunites_chart shadow">
                       <SalesTarget />
                    </div>
                    </div>
                </div>
                : false}

      </div>
      </div>
   
  
     
      <div class="container-fluid">
      <div class="row">
      {permissions?.length>0 && permissions.includes("viewOwnAnsysSalesAchievedChart") || permissions.includes("viewAnyAnsysSalesAchievedChart") ?
      <div className="col-lg-12 col-md-12 col-12 mb-4 mb-lg-0" style={{marginTop:"25px"}}>
                        
                          <div className="Opportunites_chart shadow">
                            <AnsysSalesTarget />
                          </div>
                      </div>
                      : false}
               
                {permissions?.length>0 && permissions.includes("viewOwnProductTargetChart") || permissions.includes("viewAnyProductTargetChart") ?
                            <div className="col-lg-12 col-md-12 col-12 mb-4 mb-lg-0" style={{marginTop:"25px"}}>
                 
                    <div className="Opportunites_chart shadow">
                      <ProductTargets />
                    </div>
                 
            </div>
           : false} 
      </div>
      </div>
   
      <div class="container-fluid">
      <div class="row">

      {permissions?.length>0 && (permissions.includes("viewOwnLeaveRequestChart") || permissions.includes("viewAnyLeaveRequestChart")) ?
      <div className={permissions.includes("viewOwnLeaveRequestChart") ? "col-6" : ""}>
      <div className="activity_chart_main shadow">
           <LeaveRequestChart />
       </div>
       </div>
  : "" } 

  {permissions?.length>0 && (permissions.includes("viewOwnActivitiesOverviewChart") || permissions.includes("viewAnyActivitiesOverviewChart")) ?
     <div className={permissions.includes("viewOwnActivitiesOverviewChart") ? "col-6" :""}>
     <div className="activity_chart_main shadow">
           <ActivityChart />
     </div>
</div>
: "" } 
 

  {permissions?.length>0 && (permissions.includes("viewOwnIsalesChart") || permissions.includes("viewAnyIsalesChart")) ?
    <div className="col-6">
    <div className="activity_chart_main shadow">
        <IsalesChart />
    </div>
</div>
   : "" } 
   
   {permissions?.length>0 && permissions.includes("viewTaskEfficiencyPage") ?
   <div className="col-6">
   <div className="activity_chart_main shadow">
   <EfficiencyChart />
   </div>
</div>
  : "" } 
    </div>
  </div>
  <br/>

  <div class="container-fluid">
  <div class="row">
  {permissions?.length>0 && (permissions.includes("viewOwnStateWiseRevenuePieChart") || permissions.includes("viewAnyStateWiseRevenuePieChart")) ?
                <div className="col-12">                  
                 <div className="activity_chart_main shadow">
                      <StateWiseRevenueChart />
                    </div>
                    </div>
                  : ""} 

                  {permissions?.length>0 && (permissions.includes("techieMeetingAccountsChart")) ?
                                     <div className="col-12">
                                      <div className="activity_chart_main shadow">
                                        <TechieClockTimeHrsChart />
                                      </div>
                                      </div>
                                    : ""}
                                    </div>
                                    </div>
  
                           </>
                          </div>
  );

}



