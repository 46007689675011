import React, { useState, useRef, useEffect } from 'react';
import axios from "axios";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import TextField from '@material-ui/core/TextField';
import { Button, Modal } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';
import LeadListTable from "../../components/commonColumns/TasksListTable";
import LeadMeetingsTable from "../../components/commonColumns/LeadMeetingsTable";
import AddNewContact from "../pageModals/AddNewContact";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import DragAndDrop from './DragAndDrop';
import DemoModal from "../../components/pageModals/DemoModal";

import IsalesRequestModal from "../../components/pageModals/IsalesRequestModal.js"
import { useSelector, connect } from 'react-redux';
import { toast } from 'react-toastify';
import Badge from 'react-bootstrap/Badge'
import {getLeadStagesList, getProductsList,getNewProductsList, getRegionsList} from "../../store/dashboard";
import{isalesFixNewMeetingDetails, getPersonsList,  updateOrganizationDetailsUpdated, getLeadsList, getDrfStatusList, addNewLeadTask,addNewMeeting, getAccontManagersList, getIndustriesList, getAnnualRevenueRangeList, getIndustryMetricsList, meddicUpdate, leadsActions, getEconomicBuyersList,getDistrictsList} from "../../store/leads";
import {CommonsliceActions} from "../../store/Common.js"
import  {ContactActions} from "../../store/Contact.js"
import { useDispatch } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import statesList from '../../components/json/statesList';
// const ViewLead = (props) => {
  const ViewLead = React.memo(({viewRowId,closeViewLeadModal,getDistrictsData,ContactopenModal,newselectedAccount,newproductsLists,newaccountId,getNewProductsData, companyContactsList, updateOrganizationDetailsData, economicBuyerList, closeModal, annualRevenueRangeList, viewLeadDetails, viewMeetings, viewTasksList, isalesFixNewMeetingData, getEconomicBuyersData, getLeadsData, showUpdateLeadModal, getLeadStagesData,addNewLeadTaskData,meddicUpdateData,addNewMeetingData, getIndustryMetricsData,getRegionsData, regionsList, industryMetricList, getIndustriesData,getProductsData,getAnnualRevenueRangeData, productsList, getAccontManagersData, industriesList, accountManagersList, drfStatusList, getDrfStatusData, salesActivitiesList, viewAccountId, getPersonsListData,districtsData}) => {
const dispatch =useDispatch();
  // console.log('showUpdateLeadModal', viewLeadDetails)
  // console.log('showUpdateLeadModal',viewAccountId)
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const permissions = user.permissions;
  const { register: activityRegister, handleSubmit: activityHandleSubmit, setError: activitySetError, formState: { errors: activityErrors } } = useForm();

  const [reminderDate, setReminderDate] = React.useState(moment(twodays).format('LLLL'));
  const [disable, setDisable] = React.useState(false);
  const [disableApprovalBtn, setDisableApprovalBtn] = useState(false);
  const [toggleState, settoggleTab] = useState(5);
  const [roiSheetUrl, setRoiSheetUrl] = useState();
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [showIsalesrequestModal, setShowIsalesrequestModal] = useState(false);
  const [allTechieActionsDetails, setAllTechieActionsDetails] = useState(false);

  const [query, setQuery] = useState('');
  const [expandedCategories, setExpandedCategories] = useState([]);
  const [expandedProducts, setExpandedProducts] = useState([]);
  const [expandedSubproducts, setExpandedSubproducts] = useState([]);
  const [subProductsList, setSubProductsList] = useState([]);
  const [productsListData, setProductsListData] = useState(newproductsLists);
    const [expandedAccordions, setExpandedAccordions] = useState([]);
    const [isSearchListVisible, setIsSearchListVisible] = useState(true);
    const searchContainerRef = useRef(null);
  const toggleTab = (index) => {
    settoggleTab(index);
  };
  const formatWeekSelectLabel = (date, invalidLabel) => {
    return `${moment(date).format("MMM Do YYYY LT")}`
  };


  var today = new Date();

  var threedays = moment().add(3, 'days');
  var twodays = moment().add(2, 'days');
  var initialTimeLine = moment();
  var finalTimeLine = moment(initialTimeLine).add(12, 'M');

  const [updated, setUpdated] = useState(false);
  const [showDroppedReason, setShowDroppedReason] = useState(false);
  const [tasksList, setTasksList] = useState([]);
  const [actionDate, setActionDate] = React.useState(moment(threedays).format('LLLL'));
  const [meetingDate, setMeetingDate] = useState(today);
  const [outSourcingList, setOutSourcingList] = useState(['product_0,license_0']);
  const [productName, setProductName] = useState();
  // console.log("product1",productName)
  const [NoOfLicenses, setNoOfLicenses] = useState();
  // console.log("product2",NoOfLicenses)
  // const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [leadType, setLeadType] = useState("");
  const [processData, setProcessData] = useState();
  const [dragDrop, setDragDrop] = useState();
  const [licenseProductArray, setLicenseProductArray] = useState([]);
  var getDaysBetweenDates = function (startDate, endDate) {
    var now = startDate.clone(), dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format('MMM-YYYY'));
      now.add(1, 'M');
    }
    return dates;
  };

  const initial_meeting_info = useRef();
  const da_meeting_summaryRef = useRef();
  const [new_meeting_date_time, setNewMeetingDateTime] = React.useState(moment(twodays).format('LLLL'))
  const account_manager_ref = useRef();

  console.log("viewLeadDetails", viewRowId)
  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setQuery(searchTerm);
  
    // Filter the data based on the search query
    const filteredData = newproductsLists.filter((category) =>
      category.name.toLowerCase().includes(searchTerm) ||
      category.subproducts.some((subproduct) => subproduct.name.toLowerCase().includes(searchTerm)) ||
      category.subproducts.some((subproduct) =>
        subproduct.childProducts.some((childProduct) => childProduct.name.toLowerCase().includes(searchTerm))
      )
    );
//   console.log("filterd data are inside handle search", filteredData)
    // Update the state with the filtered data
    setProductsListData(filteredData);
  };
  useEffect(() => {
    if (query.trim() === '') {
      // If the query is empty, close all accordions
      setExpandedCategories([]);
      setExpandedProducts([]);
      setExpandedSubproducts([]);
    } else {
      // Open accordions based on search results
      const categoriesToExpand = productsListData
        .filter(
          (category) =>
            category.name.toLowerCase().includes(query) ||
            category.subproducts.some((subproduct) => subproduct.name.toLowerCase().includes(query)) ||
            category.subproducts.some((subproduct) =>
              subproduct.childProducts.some((childProduct) =>
                childProduct.name.toLowerCase().includes(query)
              )
            )
        )
        .map((category) => category.name);

      setExpandedCategories(categoriesToExpand);
      console.log('inside filter data are//', categoriesToExpand)
      const productsToExpand = productsListData.reduce((acc, category) => {
        category.subproducts.forEach((product) => {
          if (
            product.name.toLowerCase().includes(query) ||
            product.childProducts.some((childProduct) =>
              childProduct.name.toLowerCase().includes(query)
            )
          ) {
            acc.push(product.name);
          }
        });

        return acc;
      }, []);

      setExpandedProducts(productsToExpand);
   
      const subproductsToExpand = productsListData.reduce((acc, category) => {
        category.subproducts.forEach((subproduct) => {
          if (
            subproduct.name.toLowerCase().includes(query) ||
            subproduct.childProducts.some((childProduct) =>
              childProduct.name.toLowerCase().includes(query)
            )
          ) {
            acc.push(subproduct.name);
          }
        });

        return acc;
      }, []);

      setExpandedSubproducts(subproductsToExpand);
    }
  }, [query, productsListData]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Close the search list if the click is outside the search container
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setIsSearchListVisible(false);
        
        setExpandedCategories([]);
        setExpandedProducts([]);
        setExpandedSubproducts([]);
      }
    };

    // Attach the event listener to the document body
    document.body.addEventListener('click', handleOutsideClick);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleCategoryAccordionChange = (categoryName) => (event, isExpanded) => {
    setExpandedCategories((prevExpanded) =>
      isExpanded
        ? [...prevExpanded, categoryName]
        : prevExpanded.filter((name) => name !== categoryName)
    );
  };

  const handleProductAccordionChange = (productName) => (event, isExpanded) => {
    setExpandedProducts((prevExpanded) =>
      isExpanded
        ? [...prevExpanded, productName]
        : prevExpanded.filter((name) => name !== productName)
    );
  };

//   const childProductDataHandle = (listData) => {
//     if (!subProductsList.includes(listData)) {
//       setSubProductsList((prevSelected) => [...prevSelected, listData]);
//     }
//   };
const childProductDataHandle = (listData, isChecked) => {
    if (isChecked) {
      // Checkbox is checked, add the data
      setSubProductsList((prevSelected) => [...prevSelected, listData]);
    } else {
      // Checkbox is unchecked, remove the data
      const updatedList = subProductsList.filter(item => item.product_id !== listData.product_id);
      setSubProductsList(updatedList);
    }
  };
const removeFilterSubProductsBadge = (event) => {
    const element = event.currentTarget;
    const productId = element.getAttribute('data');
    const filterProducts = subProductsList.filter(item => item.product_id !== productId);
    setSubProductsList(filterProducts);
  }
  


  useEffect(() => {
    // var selectedProductslist = []
    //   if(viewLeadDetails?.products?.length > 0){
    //     selectedProductslist =  newproductsLists?.subproducts?.childProducts?.filter((option) => viewLeadDetails?.products.includes(option.product_id))
    //   }else{
    //     selectedProductslist =  newproductsLists?.subproducts?.childProducts?.filter((option) => [viewLeadDetails?.product].includes(option.product_id))
    //   }
    //   console.log("selected product is", selectedProductslist)
    // Assuming viewLeadDetails contains product IDs
const productIds = viewLeadDetails?.products || [];

// Filter the ProductsCategoriesList based on productIds
const filteredProductsList = newproductsLists.map(category => ({
  ...category,
  subproducts: category.subproducts.map(subproduct => ({
    ...subproduct,
    childProducts: subproduct.childProducts.filter(childProduct =>
      productIds.includes(childProduct.product_id)
    ),
  })),
}));

// Extract childProducts from the filtered data
const filteredChildProducts = filteredProductsList.flatMap(category =>
  category.subproducts.flatMap(subproduct => subproduct.childProducts)
);

console.log("Filtered child products:", filteredChildProducts);
      setSubProductsList(filteredChildProducts)

      var selectedProducts = []
      if(viewLeadDetails?.products?.length > 0){
        selectedProducts =  productsList.filter((option) => viewLeadDetails?.products.includes(option.value))
      }else{
        selectedProducts =  productsList.filter((option) => [viewLeadDetails?.product].includes(option.value))
      }

  
       setActivity({
          da_company_name: viewLeadDetails.company_name,

          da_lead_type : viewLeadDetails.lead_type,
          da_drf_status : viewLeadDetails.drf_status,
          da_dropped_reason : viewLeadDetails.dropped_reason,
          region : viewLeadDetails.region,

          da_m: parseInt(viewLeadDetails.meddic?.m || 0),
          da_ch: parseInt(viewLeadDetails.meddic?.ch || 0),
          da_co: parseInt(viewLeadDetails.meddic?.co || 0),
          da_dc: parseInt(viewLeadDetails.meddic?.dc || 0),
          da_dp: parseInt(viewLeadDetails.meddic?.dp || 0),
          da_eb: parseInt(viewLeadDetails.meddic?.eb || 0),
          da_ip: parseInt(viewLeadDetails.meddic?.ip || 0),
          da_score: parseInt(viewLeadDetails.meddic?.score || 0),

          da_industry : viewLeadDetails?.industry?._id,

          da_industry_metric : viewLeadDetails.industry_metric,
          da_economic_buyer: viewLeadDetails.economic_buyer ? viewLeadDetails.economic_buyer : null,
          da_fj_bool : viewLeadDetails.dc_fj_bool,
          da_dc_financial_justification_notes: viewLeadDetails.financial_justification,
          da_decision_criteria_technical_validation_bool : viewLeadDetails.dc_tv_bool,
          da_dc_technical_validation_notes : viewLeadDetails.technical_validation,
          da_identify_pain_notes : viewLeadDetails.da_identify_pain_notes,
          da_champion_contact: viewLeadDetails.champion_contact ? viewLeadDetails.champion_contact || (viewLeadDetails.champion ? viewLeadDetails.champion : null) : null,
          da_product: viewLeadDetails.product ? viewLeadDetails.product : null,
          da_budget: viewLeadDetails.budget,
          da_timeline: viewLeadDetails.timeline,
          da_sales_reject_reason: viewLeadDetails.sales_reject_reason ? viewLeadDetails.sales_reject_reason : null,
          da_technical_reject_reason: viewLeadDetails.technical_reject_reason ? viewLeadDetails.technical_reject_reason : null,
          isales_contact_id : viewLeadDetails.isales_contact_id,
          da_activity_status: (viewLeadDetails.sales_activity_id ? viewLeadDetails.sales_activity_id._id : null),
          da_contact_name: viewLeadDetails.contact_name,
          meeting_type: viewLeadDetails.meeting_type,
          da_annual_revenue: viewLeadDetails.annual_revenue,
          da_product: viewLeadDetails.product ? viewLeadDetails.product : null,
          da_company_website: viewLeadDetails.company_website,
          da_city: (viewLeadDetails.company_address ? viewLeadDetails.company_address.city : null),
          da_street: (viewLeadDetails.company_address ? viewLeadDetails.company_address.street : null),
          da_state: (viewLeadDetails.company_address ? viewLeadDetails.company_address.state : null),
          da_postal_code: (viewLeadDetails.company_address ? viewLeadDetails.company_address.postal_code : null),
          da_country: (viewLeadDetails.company_address ? viewLeadDetails.company_address.country : null),

          da_lead_type: viewLeadDetails.lead_type,
          da_product : viewLeadDetails.product,

          da_licenseProductsList : viewLeadDetails?.license_product_list,
          da_champion_contact  : viewLeadDetails?.champion_contact,
          da_economic_buyer : viewLeadDetails?.economic_buyer,
          da_identify_pain_notes : viewLeadDetails?.identify_pain_notes,
          products : selectedProducts
       });
       newselectedAccount(viewLeadDetails.company_name)
       setSelectedState({ label: (viewLeadDetails.company_address ? viewLeadDetails.company_address.state : null), value: (viewLeadDetails.company_address ? viewLeadDetails.company_address.state : null) })
       setSelectedCity({label:(viewLeadDetails.company_address ? viewLeadDetails.company_address.city : null), value:(viewLeadDetails.company_address ? viewLeadDetails.company_address.city : null)})
        setAllTechieActionsDetails({
          lead_id: viewRowId,
          account_id: viewAccountId,
          product: viewLeadDetails.product,
          products: viewLeadDetails?.products,
        })

        newaccountId(viewAccountId)
       if (viewLeadDetails.decision_processes) {
        setProcessData(viewLeadDetails.decision_processes)
        setDragDrop(<DragAndDrop data={viewLeadDetails.decision_processes} DragCompleted={(data) => dragCompleted(data)} />);
      } else {
        setDragDrop(<DragAndDrop DragCompleted={(data) => dragCompleted(data)} />);
      }
      setUpdated(!updated)
      setTasksList(viewTasksList);
      // console.log("updated activity", activity)
  }, [viewLeadDetails, viewMeetings]);
  const AddcontactModal = useSelector((state) => state.Contactlist.AddcontactModal);
  
  const [meetingsList, setMeetingList] = useState([]);
  // console.log("table data here...", meetingsList)
  const [dcFj, setDcFj] = useState("no");
  const [dcTv, setDcTv] = useState("no");
  var timeLineDateList = getDaysBetweenDates(initialTimeLine, finalTimeLine);
  const task_name = useRef();
  const task_description = useRef();
  const action_date = useRef();
  const reminder_date = useRef();
  const contact_name = useRef();
  const [totalProductsLicense, setTotalProductsLicense] = useState([]);
  // const [region, setRegion] = useState(null);
  const [activity, setActivity] = useState({
    da_company_name: "",
    da_company_website: "",
    da_industry: "",
    da_company_address: "",
    da_activity_status: "",
    da_contact_name: "",
    da_contact_number: "",
    da_email: "",
    da_designation: "",
    da_department: "",
    da_street: "",
    da_city: "",
    da_state: "",
    da_postal_code: "",
    da_country: "",
    da_need: "",
    da_budget: "",
    da_timeline: "",
    da_description: "",
    da_business_type: "",
    da_owner: "",
    da_requirement: "",
    da_annual_revenue: "",
    da_last_updated_by: "",
    da_our_soln_for_challenges: "",
    da_sales_reject_reason: "",
    da_technical_reject_reason: "",
    da_product: "",
    da_assigned_by: "",
    da_meeting_summary: "",
    internal_contact_person:"",
    meeting_type:"",
    da_meeting_date: "",
    meetings_list: [],
    metric_sheet: "",
    da_industry_metric : "",
    products: []
  });

  const { da_sales_reject_reason, da_technical_reject_reason, da_company_name, da_company_website, da_activity_status,
    da_street, da_city, da_state, da_postal_code, da_country, da_budget, da_timeline,
    da_licenseProductsList, meeting_type,da_meeting_date} = activity;

// Convert the string to an object with label and value properties
// const selectedState = { label: da_state, value: da_state };
const [selectedState,setSelectedState] =useState({ label: da_state, value: da_state })
const [selectedCity,setSelectedCity] =useState({ label: da_city, value: da_city })
console.log("state name is", selectedState)
  const dragCompleted = (data) => {
    if (data?.length > 0) {
      activity.decision_processes = data.map(item => {
        return item.content;
      })
      setProcessData(activity.decision_processes?.toString())
    } else {
      setProcessData([])
    }

  }

  const handleReminderDateChange = (date) => {

    var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    var action = moment(actionDate)
    let remind = moment(newDate)
    let diff = remind.diff(action, 'minutes');
    
    if(diff > 0){
      let remidDate = moment(actionDate).subtract(30, 'm').format('YYYY-MM-DD HH:mm:ss'); 
      setReminderDate(remidDate);
      alert("Reminder date should be earlier than action date")
      return;
    }else{
      setReminderDate(newDate);
    }

  };

  const handleMeetingDateTimeChange = (date) => {
    var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    setNewMeetingDateTime(newDate);
  }

  const submitForApproval = async (type) => {
    setDisableApprovalBtn(true);
    var totalProductsLicenses = [];
    if (outSourcingList.length > 0 && outSourcingList[0] !== '') {
      outSourcingList?.map(item => {
        let separate = item.split(",");
        var product_name = document.getElementById(separate[0]).value;
        var licenses = document.getElementById(separate[1]).value;
        if (product_name !== '' && licenses !== '') {
          totalProductsLicenses.push([product_name, licenses]);
        }
      });
    }

    if (activity.da_licenseProductsList && activity.da_licenseProductsList.length > 0) {
      da_licenseProductsList.map(item => {
        totalProductsLicenses.push([item[0], item[1]]);
      })
    }

    if (type === "submit") {

      if (!activity.da_economic_buyer) {
        setDisableApprovalBtn(false);
        activitySetError("da_economic_buyer", {
          type: "manual",
          message: "Economic Buyer is required",
        });
        activitySetError("da_economic_buyer", {
          type: "focus"
        }, { shouldFocus: true }

        );
        window.location.href = "#da_economic_buyer";
        return false;
      }
      if (dcFj === "no" && !activity.da_dc_financial_justification_notes) {
        setDisableApprovalBtn(false);
        activitySetError("da_decision_criteria_notes", {
          type: "manual",
          message: "Financial Justification  is required",
        });
        activitySetError("da_decision_criteria_notes", {
          type: "focus"
        }, { shouldFocus: true }
        );

        toast.error('Financial Justification is required')
        return false;

      }

      if (dcTv === "no" && !activity.da_dc_technical_validation_notes) {
        setDisableApprovalBtn(false);
        activitySetError("da_decision_criteria_notes", {
          type: "manual",
          message: "Technical validation  is required",
        });
        activitySetError("da_decision_criteria_notes", {
          type: "focus"
        }, { shouldFocus: true }
        );

        toast.error("Technical Validation is required")
        return false;

      }

      if (dcFj === "yes" && !roiSheetUrl) {
        setDisableApprovalBtn(false);
        toast.error("Roi Shee is required")
        return false;
      }

      if (processData?.length < 1) {
        setDisableApprovalBtn(false);
        activitySetError("da_decision_processs_notes", {
          type: "manual",
          message: "Decision Process is required",
        });
        activitySetError("da_decision_processs_notes", {
          type: "focus"
        }, { shouldFocus: true }
        );

        toast.error("Decision Process is required")
        return false;

      }


      if (!activity.da_identify_pain_notes) {
        setDisableApprovalBtn(false);
        activitySetError("da_identify_pain_notes", {
          type: "manual",
          message: "Identify pain data is required",
        });
        activitySetError("da_identify_pain_notes", {
          type: "focus"
        }, { shouldFocus: true }
        );

        toast.error("Identify pain data is required")
        return false;

      }

      if (!activity.da_timeline) {
        setDisableApprovalBtn(false);
        activitySetError("da_timeline", {
          type: "manual",
          message: "Timeline is required",
        });
        window.location.href = "#da_timeline"
      }

      if (!activity.da_budget) {
        setDisableApprovalBtn(false);
        activitySetError("da_budget", {
          type: "manual",
          message: "Budget is required",
        });
        window.location.href = "#da_budget"
      }

      if (totalProductsLicenses && totalProductsLicenses.length < 1) {
        setDisableApprovalBtn(false);
        activitySetError("product_name", {
          type: "manual",
          message: "Product Name and licenses are required",
        });
        toast.error("Existing CAD/CAE details required")
        return false;
      }

    }
    toast.info("Uploading...")

    if (activity.da_budget !== 0 && activity.da_timeline !== "") {

      const data = {
        lead_id: viewRowId,
        budget: activity.da_budget,
        timeline: activity.da_timeline,
        license_product_list: totalProductsLicenses,

        metric_rating: activity.da_metric_rating,
        metric_notes: activity.da_metric_notes,
        metric_contact: activity.da_metric_contact,

        economic_buyer_rating: activity.da_economic_buyer_rating,
        economic_buyer_notes: activity.da_economic_buyer_notes,
        economic_buyer: activity.da_economic_buyer,

        decision_criteria_rating: activity.da_decision_criteria_rating,
        decision_criteria_notes: activity.da_decision_criteria_notes,
        decision_criteria_contact: activity.da_decision_criteria_contact,

        decision_criteria_financial_justification: activity.da_dc_financial_justification_notes,
        decision_criteria_technical_validation: activity.da_dc_technical_validation_notes,

        decision_processs_rating: activity.da_decision_processs_rating,
        decision_processs_notes: activity.da_decision_processs_notes,
        decision_processs_contact: activity.da_decision_processs_contact,


        identify_pain_rating: activity.da_identify_pain_rating,
        identify_pain_notes: activity.da_identify_pain_notes,
        identify_pain_contact: activity.da_identify_pain_contact,

        champion_rating: activity.da_champion_rating,
        champion_notes: activity.da_champion_notes,
        champion_contact: activity.da_champion_contact,

        competetion_rating: activity.da_competetion_rating,
        competetion_notes: activity.da_competetion_notes,

        dc_fj_bool: activity.dc_fj_bool || "no",
        dc_tv_bool: activity.dc_tv_bool || "no",

        financial_justification: activity.da_dc_financial_justification_notes,
        technical_validation: activity.da_dc_technical_validation_notes,

        decision_processes: activity.decision_processes?.toString() || processData,

        m: activity.da_m,
        ch: activity.da_ch,
        co: activity.da_co,
        dc: activity.da_dc,
        dp: activity.da_dp,
        eb: activity.da_eb,
        ip: activity.da_ip,
        score: activity.da_score,

        industry_metric : activity.da_industry_metric
      }

      try {

        var url;
        if (type === "save") {
          url = "leads/save-meddic-details"
        } else if (type === "submit") {
          url = "leads/submit-for-approval"
        }
        meddicUpdateData(type, url, data)

      } catch (err) {
        setDisableApprovalBtn(false);
        setDisable(false);
        toast.error(err.response.data.msg)
        if (err.response.data.status === 400) {
          activitySetError("op_" + err.response.data.key, {
            type: "manual",
            message: err.response.data.message,
          });
        }

        if (err.response.data.status === 403) {
          toast.error(err.response.data.msg)
        }
      }
    }
  }
  
  const handleActionDateChange = (date) => {

    var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    var remidDate = moment(date).subtract(30, 'm').format('YYYY-MM-DD HH:mm:ss'); 
    setActionDate(newDate);
    setReminderDate(remidDate)

  }

  const handleMeetingDateChange = (date) => {
    var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');

    setMeetingDate(newDate);
  }

  const refreshContactsList = async () => {
    alert("alerts ")
    console.log("refresh contacts list")
    getEconomicBuyersList(viewAccountId)
    getEconomicBuyersData(viewAccountId)
    getPersonsListData(viewAccountId)
  }

  const returnValue = async (contactId) => {
    activity.contact_id = contactId;
  }

  const addSalesActivity = async () => {
    toast.info("Uploading..")
    var internal_contact = []
    if(activity.internal_contact_person?.length>0){
      internal_contact = activity.internal_contact_person?.map(item => {
        return item.value
      })
    }

    var updateData = {
      lead_id: viewRowId,
      sales_activity: activity.da_activity_status,
      contact_id: activity.contact_id,
      meeting_summary: da_meeting_summaryRef.current.value,
      meeting_type: activity.meeting_type,
      internal_contact_person: internal_contact,
      meeting_date: meetingDate
    }

    try {
      addNewMeetingData(updateData)
    } catch (err) {
      setDisable(false);
      toast.error(err.response.data.msg)
      if (err.response.data.status === 400) {
        activitySetError("op_" + err.response.data.key, {
          type: "manual",
          message: err.response.data.message,
        });
      }
      if (err.response.data.status === 403) {
        toast.error(err.response.data.msg)
      }
    }
  }

  const addNewLeadTask = async (e) => {
    e.preventDefault();
  
    if (!task_name.current.value) {
      activitySetError("task_name", {
        type: "manual",
        message: "Task Name is required",
      });
  
      // Scroll to the element with the specified ID
      document.getElementById("task_name").scrollIntoView({
        behavior: "smooth",
      });
  
      return;
    }
  

    toast.info("Uploading...")
    var updateData = {
      lead_id: viewRowId,
      task_name: task_name.current.value,
      task_description: task_description.current.value,
      action_date: moment(actionDate).format('YYYY-MM-DD HH:mm:ss'),
      reminder_date: moment(reminderDate).format('YYYY-MM-DD HH:mm:ss'),
    }

    try {
      addNewLeadTaskData(updateData)
    } catch (err) {
      setDisable(false);
      toast.error(err.response.data.msg)
      if (err.response.status === 400) {
        activitySetError("op_" + err.response.data.key, {
          type: "manual",
          message: err.response.data.message,
        });

      }
      if (err.response.status === 403) {
        toast.error(err.response.data.msg)
      }
    }
  }


  const imageHandler = async (e) => {

    var formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("fileName", e.target.files[0].name);
    formData.append("lead_id", viewRowId);

    try {

      const res = await axios.post(
        "/leads/roi/file-upload",
        formData
      );
      setRoiSheetUrl(res.data.roi_sheet_url)
    } catch (ex) {

    }
  }

  const onInputChange = (e) => {

    if ((e.target.name === "da_economic_buyer" || e.target.name === "da_champion_contact") && e.target.value === "") {
      // setShowAddContactModal(true);
      return;
    }

    if (e.target.name === "da_decision_criteria_financial_justification_bool") {
      setActivity({ ...activity, ["dc_fj_bool"]: e.target.value }, []);
    }

    if (e.target.name === "da_decision_criteria_technical_validation_bool") {
      setActivity({ ...activity, ["dc_tv_bool"]: e.target.value }, []);
    }

    // if(e.target?.key === "products"){
    //   setActivity({ ...activity, ["products"]: e.target.value }, []);
    //   return;
    // }

    setActivity({ ...activity, [e.target.name]: e.target.value }, []);

    if (['da_m', 'da_ch', 'da_co', 'da_dc', 'da_dp', 'da_eb', 'da_ip'].includes(e.target.name)) {
      activity[e.target.name] = parseInt(e.target.value)

      var da_score = parseInt(activity.da_m) + parseInt(activity.da_ch)
        + parseInt(activity.da_dc) + parseInt(activity.da_dp) + parseInt(activity.da_eb) + parseInt(activity.da_ip)

      setActivity({ ...activity, ["da_score"]: parseInt((da_score / 12) * 100) }, []);

    }
    
    setUpdated(!updated);
  };

  const fixNewMeeting = async () => {

    if(!activity.isales_contact_id){
      toast.error('Isales Contact Id is required!')
      return;
    }

    toast.info("Uploading...")
    var updateData = {
      lead_id: viewRowId,
      assigned_to: account_manager_ref.current.value,
      isales_meeting_date_time: moment(new_meeting_date_time).format('YYYY-MM-DD HH:mm:ss'),
      isales_meeting_info: initial_meeting_info.current.value,
      // initial_meeting_info.current.value,
      isales_contact_id : activity.isales_contact_id
    }

    try {
      isalesFixNewMeetingData(updateData)
      // getLeadsData()
    } catch (err) {
      setDisable(false);
      toast.error(err.response.data.msg)
      if (err.response.status === 400) {
        activitySetError("op_" + err.response.data.key, {
          type: "manual",
          message: err.response.data.message,
        });
      }
      if (err.response.status === 403) {
        toast.error(err.response.data.msg)
      }
    }

  }

  // const getDetailsHandle = async (id) => {

  //   const res = await axios.get("/leads/unique/details/" + id);
  //   const activityData = res.data.leadDetail;
  //   console.log("props", res.data)
  //   setMeetingList(res.data.meetings)
  //   getPersonsList(activityData.account_id)

  //   setActivity({
  //     da_company_name: activityData.company_name,
  //     da_company_website: activityData.company_website,
  //     da_industry: activityData.industry ? activityData.industry._id : null,
  //     da_activity_status: (activityData.sales_activity_id ? activityData.sales_activity_id._id : null),
  //     da_contact_name: activityData.contact_name,
  //     da_contact_number: activityData.contact_number,
  //     da_email: activityData.email,
  //     da_designation: activityData.designation,
  //     da_department: activityData.department,
  //     da_street: (activityData.company_address ? activityData.company_address.street : null),
  //     da_city: (activityData.company_address ? activityData.company_address.city : null),
  //     da_state: (activityData.company_address ? activityData.company_address.state : null),
  //     da_postal_code: (activityData.company_address ? activityData.company_address.postal_code : null),
  //     da_country: (activityData.company_address ? activityData.company_address.country : null),
  //     da_need: activityData.need,
  //     da_budget: activityData.budget,
  //     da_timeline: activityData.timeline,
  //     da_description: activityData.description,
  //     da_business_type: activityData.business_type,
  //     da_lead_source: activityData.lead_source,
  //     da_owner: activityData.owner,
  //     da_requirement: activityData.requirement,
  //     da_approval: activityData.approval,
  //     da_sales_approval: activityData.sales_approval,
  //     da_technical_approval: activityData.technical_approval,
  //     da_annual_revenue: activityData.annual_revenue,
  //     da_last_updated_by: activityData.last_updated_by ? activityData.last_updated_by.first_name : null,
  //     da_our_soln_for_challenges: activityData.solutions_for_challenges ? activityData.solutions_for_challenges : null,
  //     da_sales_reject_reason: activityData.sales_reject_reason ? activityData.sales_reject_reason : null,
  //     da_technical_reject_reason: activityData.technical_reject_reason ? activityData.technical_reject_reason : null,
  //     da_product: activityData.product ? activityData.product : null,
  //     da_assigned_by: activityData.assigned_by ? activityData.assigned_by : null,
  //     da_economic_buyer: activityData.economic_buyer ? activityData.economic_buyer : null,
  //     da_technical_decision_maker: activityData.technical_decision_maker ? activityData.technical_decision_maker : null,
  //     da_others_involved_inprocess: activityData.others_involved_inprocess ? activityData.others_involved_inprocess : null,
  //     da_licenseProductsList: activityData.license_product_list ? activityData.license_product_list : null,
  //     da_any_field_failures_potential: activityData.field_failures ? activityData.field_failures : null,
  //     da_products_certificates: activityData.products_certificates ? activityData.products_certificates : null,
  //     da_imp_criteria_for_procurement: activityData.imp_criteria_for_procurement ? activityData.imp_criteria_for_procurement : null,
  //     da_outsourcing_cost_per_service: activityData.outsourcing_cost_per_service ? activityData.outsourcing_cost_per_service : null,
  //     da_outsourcing_how_many: activityData.outsourcing_how_many ? activityData.outsourcing_how_many : null,
  //     da_prospects_customer_list: activityData.prospects_customer_list ? activityData.prospects_customer_list : null,
  //     da_champion: activityData.champion ? activityData.champion : null,
  //     da_prospects_competitors_list: activityData.prospects_competitors_list ? activityData.prospects_competitors_list : null,
  //     da_potential_benefit: activityData.potential_benefit ? activityData.potential_benefit : null,
  //     da_which_customer_use_our_solutions: activityData.which_customer_use_our_solutions ? activityData.which_customer_use_our_solutions : null,
  //     da_lead_type: activityData.lead_type ? activityData.lead_type : "",
  //     da_dropped_reason: activityData.dropped_reason ? activityData.dropped_reason : "",

  //     da_drf_status: activityData.drf_status ? activityData.drf_status : "",

  //     da_metric_rating: activityData.metric_rating ? activityData.metric_rating : null,
  //     da_metric_notes: activityData.metric_notes ? activityData.metric_notes : null,
  //     da_metric_contact: activityData.metric_contact ? activityData.metric_contact : null,

  //     da_economic_buyer_rating: activityData.economic_buyer_rating ? activityData.economic_buyer_rating : null,
  //     da_economic_buyer_notes: activityData.economic_buyer_notes ? activityData.economic_buyer_notes : null,
  //     da_economic_buyer: activityData.economic_buyer ? activityData.economic_buyer : null,

  //     da_decision_criteria_rating: activityData.decision_criteria_rating ? activityData.decision_criteria_rating : null,
  //     da_decision_criteria_notes: activityData.decision_criteria_notes ? activityData.decision_criteria_notes : null,
  //     da_decision_criteria_contact: activityData.decision_criteria_contact ? activityData.decision_criteria_contact : null,

  //     da_decision_processs_rating: activityData.decision_processs_rating ? activityData.decision_processs_rating : null,
  //     da_decision_processs_notes: activityData.decision_processs_notes ? activityData.decision_processs_notes : null,
  //     da_decision_processs_contact: activityData.decision_processs_contact ? activityData.decision_processs_contact : null,

  //     da_identify_pain_rating: activityData.identify_pain_rating ? activityData.identify_pain_rating : null,
  //     da_identify_pain_notes: activityData.identify_pain_notes ? activityData.identify_pain_notes : null,
  //     da_identify_pain_contact: activityData.identify_pain_contact ? activityData.identify_pain_contact : null,

  //     da_champion_rating: activityData.champion_rating ? activityData.champion_rating : null,
  //     da_champion_notes: activityData.champion_notes ? activityData.champion_notes : null,
  //     da_champion_contact: activityData.champion_contact ? activityData.champion_contact || (activityData.champion ? activityData.champion : null) : null,

  //     da_competetion_notes: activityData.competetion_notes ? activityData.competetion_notes : null,
  //     da_competetion_rating: activityData.competetion_rating ? activityData.competetion_rating : null,

  //     da_m: activityData.meddic?.m || 0,
  //     da_ch: activityData.meddic?.ch || 0,
  //     da_co: activityData.meddic?.co || 0,
  //     da_dc: activityData.meddic?.dc || 0,
  //     da_dp: activityData.meddic?.dp || 0,
  //     da_eb: activityData.meddic?.eb || 0,
  //     da_ip: activityData.meddic?.ip || 0,
  //     da_score: activityData.meddic?.score || 0,
  //     da_dc_technical_validation_notes: activityData.technical_validation,
  //     da_dc_financial_justification_notes: activityData.financial_justification,
  //     da_dc_fj: activityData.dc_fj_bool,
  //     da_dc_tv: activityData.dc_fj_bool,
  //     da_account_id: activityData.account_id,
  //     metric_sheet: activityData.metric_sheet,
      
  //     da_region: activityData.region
  //   });

  //   setAllTechieActionsDetails({
  //     lead_id: id,
  //     account_id: activityData.account_id,
  //     product: activityData.product,

  //   })

  //   if (activityData.roi_sheet) {
  //     setRoiSheetUrl(activityData.roi_sheet)
  //   }

  //   if (activityData.decision_processes) {
  //     setProcessData(activityData.decision_processes)
  //     setDragDrop(<DragAndDrop data={activityData.decision_processes} DragCompleted={(data) => dragCompleted(data)} />);
  //   } else {
  //     setDragDrop(<DragAndDrop DragCompleted={(data) => dragCompleted(data)} />);
  //   }

  //   if (activityData.lead_type) {
  //     setLeadType(activityData.lead_type);
  //   }

  //   if (activityData.dropped_reason) {
  //     setShowDroppedReason(true);
  //   }

  // }

  const onInputLicenseChange = (e, index) => {

    if (e.target.name === "license") {
      setNoOfLicenses(e.target.value);
    }

    if (productName && NoOfLicenses) {

      let newArr = [...licenseProductArray]; 
      newArr[index] = [productName, NoOfLicenses]; 

      setLicenseProductArray(newArr);
    }
  }

  const onInputProductChange = (e, index) => {
    if (e.target.name === "product_name") {
      setProductName(e.target.value);
    }
  }

  // const MetricSheetUpload = async (e) => {
  //   // setOpen(true);
  //   // setsnackBarMessage("Uploading..");
  //   toast.info("Uploading...")
  //   //console.log("e", e)
  //   //console.log("this", lead_id)
  //   var formData = new FormData();
  //   formData.append("file", e.target.files[0]);
  //   formData.append("fileName", e.target.files[0].name);
  //   formData.append("lead_id", viewRowId);

  //   try {

  //     const res = await axios.post(
  //       "/accounts/upload-metric_file",
  //       formData
  //     );

  //     if (res.data.status === "success") {
  //       setActivity({ ...activity, metric_sheet: res.data.metric_sheet_url })
  //     }

  //     toast.success("File uploaded successfully")
  //   } catch (err) {
  //     toast.error("Only .ppt and .pdf files are allowed.")
  //   }
  // }

  

  const updateOrganizationDetails = async () => {

    if(!activity.da_drf_status){
      toast.error("Drf Status is required")
      return;
    }

    if (activity.da_lead_type === "Dropped" && !activity.da_dropped_reason) {
      toast.error("Dropped Reason is required")
      return;
    }

    // var selectedProducts = activity.products?.map((option) => option.value);
    var selectedProducts = subProductsList?.map((option) => option.product_id);
    toast.info("Uploading...")
    const updateData = {
      lead_id: viewRowId,
      company_name: activity.da_company_name,
      company_website: activity.da_company_website,
      industry: activity.da_industry,
      street: activity.da_street,
      // city: activity.da_city,
      // state: activity.da_state,
      city:selectedCity?.value,
      state: selectedState?.value,
      postal_code: activity.da_postal_code,
      country: activity.da_country,
      annual_revenue: activity.da_annual_revenue,
      product: activity.da_product,
      user_id: userData._id,
      lead_type: activity.da_lead_type || leadType,
      dropped_reason: activity.da_dropped_reason,
      drf_status: activity.da_drf_status,
      region : activity.region,
      products : selectedProducts,
    }
    if(selectedProducts?.length>0){
      updateData.product =  selectedProducts[0]
    }

    try {
        updateOrganizationDetailsData(updateData)
        closeViewLeadModal(false)
        setQuery('')
        // If the query is empty, close all accordions
        setExpandedCategories([]);
        setExpandedProducts([]);
        setExpandedSubproducts([]);
    } catch (err) {

      setDisable(false)
      toast.error(err.response.data.msg)
      if (err.response.data.status === 400) {
        activitySetError("op_" + err.response.data.key, {
          type: "manual",
          message: err.response.data.message,
        });
      }
      if (err.response.data.status === 403) {
        toast.error(err.response.data.msg)
      }
    }
  }


  // const addNewProductLicense = (index) => {
  //   if (productName === undefined || NoOfLicenses === undefined || productName === "" || NoOfLicenses === "") {
  //     // Display an alert message for undefined values or empty strings
  //     alert("Please enter values for Product Name and Number of Licenses");
  //   } else {
  //     // Proceed with adding a new product license
  //     setTotalProductsLicense(...totalProductsLicense, [[productName, NoOfLicenses]]);
  //     console.log("products_lic2", totalProductsLicense);
  
  //     const min = 1;
  //     const max = 100;
  //     const rand = min + Math.random() * (max - min);
  //     setOutSourcingList([...outSourcingList, 'product_' + rand + ',' + 'license_' + rand]);
  //   }
  // };
  const addNewProductLicense = (index) => {
    if (
      (productName === undefined || productName.trim() === "") &&
      (NoOfLicenses === undefined || NoOfLicenses.trim() === "")
    ) {
      // alert("Please enter productName and Licenses Number");
      toast.error("Please enter product Name and Licenses")
    } else if (productName === undefined || productName.trim() === "") {
      // alert("Please enter productName");
      toast.error("Please enter product Name")
    } else if (NoOfLicenses === undefined || NoOfLicenses.trim() === "") {
      // alert("Please enter Licenses Number");
      toast.error("Please enter Licenses")
    } else {
      // Process the data
  
      // Clear the data
      setProductName(undefined);
      setNoOfLicenses(undefined);
  
      setTotalProductsLicense(...totalProductsLicense, [[productName, NoOfLicenses]]);
      console.log("products_lic2", totalProductsLicense);
  
      const min = 1;
      const max = 100;
      const rand = min + Math.random() * (max - min);
      setOutSourcingList([...outSourcingList, 'product_' + rand + ',' + 'license_' + rand]);
    }
  };

  useEffect(() => {
    getDrfStatusData()
    getAccontManagersData();
    getIndustriesData();
    getProductsData();
    getAnnualRevenueRangeData();
    getLeadStagesData();
    getIndustryMetricsData();
    getRegionsData();
    // setProductName();
    // setNoOfLicenses()

  }, [])


  

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#000" : '#000080',
        color: "white",
        opacity: '1.5'
      };
    }
  };


  const handleSetIsalesContactPerson = (value) => {
    console.log("value", value);
    if (value.__isNew__ !== true) {
      activity.isales_contact_id = value.value ? value.value : "";
    }

    if (value.__isNew__ === true) {
      ContactopenModal(true)
      // setShowAddContactModal(true);
    }
  }


  const handleSetContactName = (value) => {
    if (value.__isNew__ !== true) {
      activity.contact_id = value.value ? value.value : "";

    }

    if (value.__isNew__ === true) {
      // setShowAddContactModal(true);
      ContactopenModal()
    }
  }


  const IconWithTooltip = () => (
    <Tooltip title="If sales activity changes and contact is selected and provide meeting summary, then a meeting will be created.">

      <HelpIcon />
    </Tooltip>
  );

  const handleStateChange = (value) => {
    console.log("new state inside state fun", value)
    setSelectedState(value)
    // activity.da_state = value
    getDistrictsData(value.value)
    
}
const handleDistrictChange = (value) => {
    // activity.city = value
    setSelectedCity(value)
    setUpdated(!updated)
}
  return (
    <div className='modal_responsive'>
      <Modal
        show={showUpdateLeadModal}
        onHide={() => leadsActions.updateKey("showUpdateLeadModal", false)}
        backdrop={false}
        disableBackdrop={true}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title">
        <Modal.Header >
          <Modal.Title id="example-custom-modal-styling-title">
            {(userData.user_role_id?.role_name === "user") ? "Update Lead" : "View Lead"}
          </Modal.Title>
          <a onClick={() => closeViewLeadModal(false)} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div>
              
            </div>
            <br />
            <div className="row ">
              <div className='headerBand'>
                <label>Organization Details </label>
              </div>
              <div className='headerBandButtons'>
              {permissions.includes("createOwnDemos") || permissions.includes("createOwnBenchmarks") || permissions.includes("createOwnTrainings") ?
                <div className="col-md-6">
                  <div className="dropdown">
                    <a style={{ background: '#5160b4', color: '#fff' }} className="btn btn-sm btn-white bottomBarButtons dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                      Request Techie
                    </a>

                  
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      {permissions.includes("createOwnDemos") ?
                      <>
                        <li>
                          <>
                            <a className="dropdown-item techie_activity" onClick={() => { setShowDemoModal(true); }} >Request Demo/Technical Presentation</a>

                            {showDemoModal ? <DemoModal multiProducts={subProductsList} accountId={viewAccountId} allTechieActionsDetails={allTechieActionsDetails} show={showDemoModal} onHide={() => setShowDemoModal(false)} /> : ""}
                          </>
                        </li>
                           <li>
                           <>
                             <a className="dropdown-item techie_activity" onClick={() => { setShowIsalesrequestModal(true); }} >Request Isales</a>
                             {showIsalesrequestModal ? <IsalesRequestModal leadId={viewRowId} accountId={viewAccountId} keyType={"lead"} allTechieActionsDetails={allTechieActionsDetails} show={showIsalesrequestModal} onHide={() => setShowIsalesrequestModal(false)} /> : ""}
                           </>
                         </li>
                         </>
                        : false}
                    </ul>
                  </div>
                </div>
                : false}

                
              {/* <div className="col-md-6">
                  <div className="">
                  
                    </div>
                    </div> */}
              </div>

            </div>

            <br />
            <div class="row">
              <div class="col-md-6 col-12">
                <TextField variant="outlined"
                  {...activityRegister("da_company_name", {
                    minLength: {
                      value: 3,
                      message: "Organization Name must be at least 3 characters"
                    }
                  })}
                  required
                  helperText={activityErrors.da_company_name ? activityErrors.da_company_name.message : ''}
                  error={Boolean(activityErrors.cu_city)}
                  type="text" className="form-control" onChange={e => onInputChange(e)} value={da_company_name} name="da_company_name" inputProps={
                    { readOnly: true, }
                  } label="Organization Name" />
              </div>
              <div className='col-md-6'>
                <TextField variant="outlined"
                  {...activityRegister("da_lead_type", {
                    required: {
                      value: true,
                      message: "Lead Type is required"
                    },
                    minLength: {
                      value: 3,
                      message: "Lead Type must be at least 3 characters"
                    }
                  })}
                  select
                  value={activity.da_lead_type || ""}
                  type="text" className="form-control" name="da_lead_type" label="Lead Type" onChange={e => onInputChange(e)} >
                  {
                    ["Prospect", "Suspect", "Dropped"].map((item) => (
                      <MenuItem key={item} selected={activity.da_lead_type === item} value={item}>{item}</MenuItem>
                    ))
                  }

                </TextField>
              </div>

            </div>
            <br />
            <div class="row">

              <div class="col-md-6">
                <TextField variant="outlined"
                  {...activityRegister("da_drf_status", {
                    required: {
                      value: true,
                      message: "Drf Status is required"
                    },
                  })}
                  select
                  required
                  value={activity.da_drf_status || ""}
                  helperText={activityErrors.da_drf_status ? activityErrors.da_drf_status.message : ''}
                  error={Boolean(activityErrors.da_drf_status)}
                  InputLabelProps={{
                    shrink: true,
                  }}

                  type="text" className="form-control" name="da_drf_status" label="Drf Status" onChange={e => onInputChange(e)} >
                  {
                    drfStatusList?.map((option) => (
                      <MenuItem key={option.value} selected={activity.da_drf_status === option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>


              <div class="col-md-6">
                <TextField variant="outlined"
                  {...activityRegister("region", {
                    required: {
                      value: true,
                      message: "Region is required"
                    },
                  })}
                  select
                  required
                  value={activity.region || ""}
                  helperText={activityErrors.region ? activityErrors.region.message : ''}
                  error={Boolean(activityErrors.region)}
                  InputLabelProps={{
                    shrink: true,
                  }}

                  type="text" className="form-control" name="region" label="Region" onChange={e => onInputChange(e)} >
                  {
                    regionsList?.map((option) => (
                      <MenuItem key={option.value} selected={activity.region === option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>


            </div>
            <div className="row mt-4">
            <div className="col-md-6">
                             <div ref={searchContainerRef} className="search-container">    
      <TextField
        variant="outlined"
        type="text"
        value={query}
        onChange={handleSearch}
        onFocus={() => setIsSearchListVisible(true)}
        className="form-control search_products_field"
        placeholder="Search Products from Below List"
      />

      {isSearchListVisible && (
        <div className="dropdown_content12">
          {productsListData?.map((category, index) => (
            <Accordion
              key={index}
              expanded={expandedCategories.includes(category.name)}
              onChange={handleCategoryAccordionChange(category.name)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`category-${index}-content`}
                id={`category-${index}-header`}
                className="styel_summary"
              >
                <Typography className="accordion-text">
                  {category.name}
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography className="typography_list">
                  {category.subproducts.length > 0 && (
                    <ul className="subProducts_list">
                      {category.subproducts.map((subproduct, subIndex) => (
                        <Accordion
                          key={subIndex}
                          expanded={expandedProducts.includes(subproduct.name)}
                          onChange={handleProductAccordionChange(subproduct.name)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`product-${index}-${subIndex}-content`}
                            id={`product-${index}-${subIndex}-header`}
                            className="styel_summary"
                          >
                            <Typography className="accordion-text">
                              {subproduct.name}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography className="typography_list">
                                {subproduct.childProducts.length > 0 && (
                                <ul className="childProducts_list">
                                    {subproduct.childProducts.map((childProduct, childIndex) => (
                                    <li key={childIndex}>
                                        <input
                                        type="checkbox"
                                        id={`checkbox-${childIndex}`}
                                        // checked={}
                                        // onChange={() => childProductDataHandle(childProduct)}
                                        checked={subProductsList.some(item => item.product_id === childProduct.product_id)}
                                        onChange={(e) => childProductDataHandle(childProduct, e.target.checked)}
                                        />
                                        <label htmlFor={`checkbox-${childIndex}`}  className="checkbox-label">
                                          {childProduct.name}
                                        </label>
                                    </li>
                                    ))}
                                </ul>
                                )}
                            </Typography>
                            </AccordionDetails>
                          {/* <AccordionDetails>
                            <Typography className="typography_list">
                              {subproduct.childProducts.length > 0 && (
                                <ul className="childProducts_list">
                                  {subproduct.childProducts.map(
                                    (childProduct, childIndex) => (
                                      <li
                                        onClick={() =>
                                          childProductDataHandle(childProduct)
                                        }
                                        key={childIndex}
                                      >
                                        {childProduct.name}
                                      </li>
                                    )
                                  )}
                                </ul>
                              )}
                            </Typography>
                          </AccordionDetails> */}
                        </Accordion>
                      ))}
                    </ul>
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}
    </div>
             </div>
             <div className="col-md-6">
                {
                subProductsList?.length > 0 ?
                    subProductsList.map((filter) => (
                    <Badge onClick={removeFilterSubProductsBadge}
                        pill data={filter.product_id} value={filter.product_id} style={{ cursor: 'pointer', background: 'grey', color: 'white', fontWeight: 'normal', border: '1px solid grey', marginRight: '10px' }}>{filter.name}<span style={{ cursor: 'pointer', color: 'grey', padding: '-10px' }}>&nbsp;x</span></Badge>
                    ))
                    : ""
                }
                            
              </div>
            </div>
            <br />
            {(showDroppedReason && activity.da_lead_type === "Dropped") ?
              <div className='row'>

                <div className='col-md-6'>
                  <TextField variant="outlined"
                    {...activityRegister("da_dropped_reason", {
                      minLength: {
                        value: 3,
                        message: "Dropped Reason must be at least 3 characters"
                      }
                    })}
                    select
                    rows={2}
                    value={activity.da_dropped_reason || ""}
                    type="text" className="form-control" name="da_dropped_reason" label="Dropped Reason" onChange={e => onInputChange(e)} >

                      {
                          ["DRF rejected", "NO Cad data", "Conflict Account"].map((option) => (
                                
                                <MenuItem key={option} selected={activity.da_dropped_reason === option} value={option}>
                                    {option}
                                </MenuItem>
                            
                          ))
                      }

                  </TextField>
                </div>

              </div>
              : ""}
            <br />
            <div class="row">
              <div class="col-md-6">
              </div>
              <div className="col-md-6">
              </div>
            </div>
            <br />
            <div className="row ">
              <div className="col-md-6 justify-content-start" style={{ marginLeft: '1rem' }}>
                <span className="band-left-border ml-5">Last Updated By : <b>{viewLeadDetails?.last_updated_by?.first_name}</b></span>
              </div>
            </div>

            <div className="container">
              <div className="lock_tabs" style={{ whiteSpace: "nowrap", maxWidth: '1000px', overflowX: 'auto', marginTop: "1rem" }}>

                <a className={toggleState === 5 ? "tab active_tab" : "tab"} onClick={() => toggleTab(5)} >
                  Organization Details
                </a>

                <a className={toggleState === 4 ? "tab active_tab" : "tab"} onClick={() => toggleTab(4)} >
                  Meetings
                </a>

                <a className={toggleState === 1 ? "tab active_tab" : "tab"} onClick={() => toggleTab(1)} >
                  Add New Task
                </a>

                <a className={toggleState === 2 ? "tab active_tab" : "tab"} onClick={() => toggleTab(2)} >
                  MEDDICC
                </a>

              </div>
              <div className="tab_content">
                <div className={toggleState === 1 ? "content active_content" : "content"} >
                  <div className="row">
                    <br />
                    <form>
                    <div class="row">
                      <div>
                        <div className="row">
                          <div className="col-md-10">
                            <TextField variant="outlined"
                              type="text" label="Action Item Name"
                              inputRef={task_name}
                              name="task_name"
                              required
                              value={activity.task_name}
                              onChange={e => onInputChange(e)}
                              className="form-control" />
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-md-10 ">
                            <TextField multiline rows={2}
                              variant="outlined"
                              type="text" label="Action Item Description"
                              inputRef={task_description} name="task_description"
                              onChange={e => onInputChange(e)}
                              value={activity.task_description}
                              className="form-control" />
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-md-5">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DateTimePicker
                                className="form-control"
                                label="Action Date"
                                inputVariant="outlined"
                                value={actionDate}
                                onChange={handleActionDateChange}
                                labelFunc={formatWeekSelectLabel}
                                showTodayButton
                                inputRef={action_date}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </div>

                          <div className="col-md-5">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DateTimePicker
                                className="form-control"
                                label="Reminder Date"
                                inputVariant="outlined"
                                value={reminderDate}
                                onChange={handleReminderDateChange}
                                labelFunc={formatWeekSelectLabel}
                                showTodayButton
                                inputRef={reminder_date}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </MuiPickersUtilsProvider>

                          </div>

                        </div>
                        <br />
                        <div className="row">
                          <div>
                            {(permissions.includes('updateOwnLeads') || permissions.includes("updateAnyLeads"))
                              ? <button  disabled={disable} className="btn btn-sm upadte_common_btn" onClick={addNewLeadTask} >Add New Task</button> : false}
                          </div>
                        </div>
                        <br />
                      </div> 
                    </div>
                    </form>
                    
                  </div>
                  <div className="row">
                    <LeadListTable data={tasksList} rowId={viewRowId}/>
                  </div>
                </div>

                <div className={toggleState === 2 ? "content active_content" : "content"} >
                  <div className="sales_approach_main">
                    <div className="sales_approach_sub1" style={{ background: "#113667" }}>
                      <select className={(activity.da_m === 2) && "yes_no1 yes_no_green" || (activity.da_m === 0) && "yes_no1 yes_no_red" || (activity.da_m === 1) && "yes_no1 yes_no_partial" || "yes_no1"} name="da_m" value={activity.da_m} onChange={e => onInputChange(e)}>
                        <option value="">Select</option>
                        <option className='text_opion' value={2}>Yes</option>
                        <option className='text_opion' value={0}>No</option>
                        <option value="1">Partial</option>
                      </select>
                      <div className="sales_approach_heading1">
                        <p><span className='font-30'>M</span>etric</p>
                        <p>Economic Impact of the solution </p>
                      </div>
                      <div className='sales_approach_content1'>
                        <div className="about_soluctiondiv1">
                          <div className="content_textpart1">
                              <div class="col-md-5">
                                <br />
                                <TextField variant="outlined"
                                    select
                                    value ={activity.da_industry_metric || ""}
                                    type="text" className="form-control" name="da_industry_metric" onChange={e => onInputChange(e)} >
                                    {
                                        industryMetricList?.map((option) => (
                                             
                                              <MenuItem key={option._id} selected={activity.da_industry_metric === option._id} value={option._id}>
                                                  {option.metric_name}
                                              </MenuItem>
                                          
                                        ))
                                    }
                                </TextField>
                                {
                                  industryMetricList?.map(item => {
                                    
                                    if(item._id === activity.da_industry_metric){
                                      return ( 
                                      <>
                                        <span style={{color:'white'}}>Selected Industry - {item.metric_name}</span><br />
                                        <u><a style={{color:'white'}} target="_blank" href={item.file_path}>Download / View File</a></u>
                                      </>)
                                    }
                                    
                                  })
                                  
                                }

                                
                              </div>
                            <span>{activity.da_metric_notes}</span>
                          </div>

                        </div>
                      </div>
                    </div>


                    <div className="sales_approach_sub" style={{ background: "#225798" }}>
                      <select className={(activity.da_eb === 2) && "yes_no yes_no_green" || (activity.da_eb === 0) && "yes_no yes_no_red" || (activity.da_eb === 1) && "yes_no yes_no_partial" || "yes_no"} name="da_eb" value={activity.da_eb} onChange={e => onInputChange(e)}>
                        <option value="">Select</option>
                        <option className='text_opion' value={2}>Yes</option>
                        <option className='text_opion' value={0}>No</option>
                        <option value="1">Partial</option>
                      </select>
                      <div className="sales_approach_heading">
                        <p><span className='font-30'>E</span>conomic Buyer</p>
                        <p>Discretionary use of funds/budget</p>
                      </div>
                      <div className='sales_approach_content'>
                        <div className="about_soluctiondiv">
                          <div className="content_textpart dropdown_box">

                            <TextField variant="outlined"
                              multiline row={3}
                              {...activityRegister("da_economic_buyer", {
                                minLength: {
                                  value: 3,
                                  message: "Economic Buyer must be at least 3 characters"
                                },
                              })}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              select
                              id="da_economic_buyer"
                              helperText={activityErrors.da_economic_buyer ? activityErrors.da_economic_buyer.message : ''}
                              error={Boolean(activityErrors.da_economic_buyer)}
                              type="text" label="" name="da_economic_buyer" className="form-control"
                              value={activity.da_economic_buyer || ""}
                              onChange={e => onInputChange(e)} >
                              {
                                economicBuyerList?.length >0 && economicBuyerList.map((option) => (
                                  <MenuItem key={option.label} selected={activity?.da_economic_buyer === option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))
                              }
                            </TextField>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sales_approach_sub" style={{ background: "#3278ca" }}>
                      <select className={(activity.da_dc === 2) && "yes_no yes_no_green" || (activity.da_dc === 0) && "yes_no yes_no_red" || (activity.da_dc === 1) && "yes_no yes_no_partial" || "yes_no"} name="da_dc" value={activity.da_dc} onChange={e => onInputChange(e)}>
                        <option value="">Select</option>
                        <option className='text_opion' value={2}>Yes</option>
                        <option className='text_opion' value={0}>No</option>
                        <option value="1">Partial</option>
                      </select>
                      <div className="sales_approach_heading">
                        <p><span className='font-30'>D</span>ecision criteria</p>
                        <p>Key player driving the opportunity</p>
                      </div>
                      <div className='sales_approach_content'>


                        <div className="about_soluctiondiv">
                          <div className="content_textpart">
                            <div className='decision_criteria_main'>
                              <div className="decision_criteria_sub">
                                <div className="sub_heading">
                                  <h6>Financial Justification(ROI)</h6>
                                </div>
                                <div className="descrion_subhading_textdropdown">
                                  <select className='descision_yes_no' name="da_decision_criteria_financial_justification_bool" value={dcFj} onChange={(e) => setDcFj(e.target.value)} >
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                  </select>
                                </div>
                                <div className="decision_textb">
                                  {dcFj === "yes" ?
                                    <div className="browse_file">

                                      <input type="file" className='custom-file-input' id="file" onChange={imageHandler} name="file" accept=".xlsx, .xls, .csv" style={{ display: "none" }} />
                                      <label className='browse_btn' for="file"> Upload ROI File </label>
                                      {
                                        roiSheetUrl &&
                                        <a target="_blank" href={roiSheetUrl} className='browse_btn'>View file</a>
                                      }
                                    </div>
                                    :
                                    <>
                                      <TextField variant="outlined"
                                        {...activityRegister("da_dc_financial_justification_notes", {

                                          minLength: {
                                            value: 3,
                                            message: "Decision Criteria must be at least 3 characters"
                                          },

                                        })}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        value={activity.da_dc_financial_justification_notes}
                                        id="da_dc_financial_justification_notes"
                                        helperText={activityErrors.da_dc_financial_justification_notes ? activityErrors.da_dc_financial_justification_notes.message : ''}
                                        error={Boolean(activityErrors.da_dc_financial_justification_notes)}
                                        type="text" label="" name="da_dc_financial_justification_notes" className="form-control" onChange={e => onInputChange(e)} >

                                      </TextField>
                                    </>
                                  }

                                </div>
                              </div>
                              <div className="decision_criteria_sub">
                                <div className="sub_heading">
                                  <h6>Technical Validation</h6>
                                </div>
                                <div className="descrion_subhading_textdropdown">
                                  <select className='descision_yes_no' name="da_decision_criteria_technical_validation_bool" value={dcTv} onChange={(e) => setDcTv(e.target.value)} >
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                    <option value="partial">Partial</option>
                                  </select>
                                </div>
                                <div className="decision_textb">
                                  {dcTv === "yes" ? ""
                                    :
                                    <TextField variant="outlined"

                                      {...activityRegister("da_dc_technical_validation_notes", {

                                        minLength: {
                                          value: 3,
                                          message: "Technical Validation must be at least 3 characters"
                                        },

                                      })}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      value={activity.da_dc_technical_validation_notes}
                                      id="da_dc_technical_validation_notes"
                                      helperText={activityErrors.da_dc_technical_validation_notes ? activityErrors.da_dc_technical_validation_notes.message : ''}
                                      error={Boolean(activityErrors.da_dc_technical_validation_notes)}
                                      type="text" label="" name="da_dc_technical_validation_notes" className="form-control" onChange={e => onInputChange(e)} >

                                    </TextField>
                                  }

                                </div>
                              </div>
                            </div>

                          </div>

                        </div>
                      </div>
                    </div>
                    <div className="sales_approach_sub" style={{ background: "#4397ff" }}>
                      <select className={(activity.da_dp === 2) && "yes_no yes_no_green" || (activity.da_dp === 0) && "yes_no yes_no_red" || (activity.da_dp === 1) && "yes_no yes_no_partial" || "yes_no"} name="da_dp" value={activity.da_dp} onChange={e => onInputChange(e)}>
                        <option value="">Select</option>
                        <option className='text_opion' value={2}>Yes</option>
                        <option className='text_opion' value={0}>No</option>
                        <option value="1">Partial</option>
                      </select>
                      <div className="sales_approach_heading">
                        <p><span className='font-30'> D</span>ecision process</p>
                        <p>Compare vendors offeringsy</p>
                      </div>
                      <div className='sales_approach_content_extra'>

                        <div className="about_soluctiondiv">
                          <div className="content_textpart">
                            {dragDrop}
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="sales_approach_sub" style={{ background: "#75b2ff" }}>
                      <select className={(activity.da_ip === 2) && "yes_no yes_no_green" || (activity.da_ip === 0) && "yes_no yes_no_red" || (activity.da_ip === 1) && "yes_no yes_no_partial" || "yes_no"} name="da_ip" value={activity.da_ip} onChange={e => onInputChange(e)}>
                        <option value="">Select</option>
                        <option className='text_opion' value={2}>Yes</option>
                        <option className='text_opion' value={0}>No</option>
                        <option value="1">Partial</option>
                      </select>
                      <div className="sales_approach_heading">
                        <p><span className='font-30'>I</span>dentify pain</p>
                        <p>Pain Points: Define the cosst of doing nothing</p>
                      </div>
                      <div className='sales_approach_content'>

                        <div className="about_soluctiondiv">
                          <div className="content_textpart">
                            <TextField variant="outlined"
                              multiline rows={2}
                              {...activityRegister("da_identify_pain_notes", {
                                minLength: {
                                  value: 3,
                                  message: "Identify Pain must be at least 3 characters"
                                },
                              })}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={activity.da_identify_pain_notes}
                              id="da_identify_pain_notes"
                              helperText={activityErrors.da_identify_pain_notes ? activityErrors.da_identify_pain_notes.message : ''}
                              error={Boolean(activityErrors.da_identify_pain_notes)}
                              type="text" label="" name="da_identify_pain_notes" className="form-control" onChange={e => onInputChange(e)} >
                            </TextField>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="sales_approach_sub" style={{ background: "#a5ceff" }}>
                      <select className={(activity.da_ch === 2) && "yes_no yes_no_green" || (activity.da_ch === 0) && "yes_no yes_no_red" || (activity.da_ch === 1) && "yes_no yes_no_partial" || "yes_no"} name="da_ch" value={activity.da_ch} onChange={e => onInputChange(e)}>
                        <option value="">Select</option>
                        <option className='text_opion' value={2}>Yes</option>
                        <option className='text_opion' value={0}>No</option>
                        <option value="1">Partial</option>
                      </select>
                      <div className="sales_approach_heading">
                        <p><span className='font-30'>C</span>hampion</p>
                        <p>Key player with power and influence </p>
                      </div>
                      <div className='sales_approach_content'>

                        <div className="about_soluctiondiv">
                          <div className="content_textpart dropdown_box">
                            <TextField variant="outlined"
                              multiline row={3}
                              {...activityRegister("da_champion_contact", {
                                minLength: {
                                  value: 3,
                                  message: "Champion Contact must be at least 3 characters"
                                },
                              })}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              select
                              value={activity.da_champion_contact || ""}
                              id="da_champion_contact"
                              helperText={activityErrors.da_champion_contact ? activityErrors.da_champion_contact.message : ''}
                              error={Boolean(activityErrors.da_champion_contact)}
                              type="text" label="" name="da_champion_contact" className="form-control" onChange={e => onInputChange(e)} >
                              {
                                economicBuyerList?.length >0 && economicBuyerList.map((option) => (
                                // economicBuyerList?.map((option) => (
                                  <MenuItem key={option} selected={activity.da_champion_contact === option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))
                              }
                            </TextField>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <span><b>Score - {activity?.da_score} %</b></span>&nbsp;&nbsp;&nbsp;
                      MEDDIC Guidance - &nbsp;
                      {(activity?.da_score > 74) && <span style={{ backgroundColor: '#000', color: 'lightgreen', fontSize: '20px' }}><b>Forecast</b></span>}
                      {(activity?.da_score > 49 && activity?.da_score < 74) && <span style={{ backgroundColor: '#000', color: 'lightblue', fontSize: '20px' }}>Upside</span>}
                      {(activity?.da_score > 26 && activity?.da_score < 49) && <span style={{ backgroundColor: '#000', color: 'yellow', fontSize: '20px' }}>Qualified Pipeline </span>}
                      {(activity?.da_score < 26) && <span style={{ backgroundColor: '#000', color: '#fff', fontSize: '20px' }}>Unqualified Pipeline</span>}
                    </div>


                    <br />
                    <div className="row" id="decisioinParameters">
                      <br />
                      <span>Existing CAD/CAE Software ? </span>

                      {
                        outSourcingList?.map((item, index) => (
                          <>
                        
                            <div className="col-md-12 row">
                              <div className="col-md-3">
                                <input
                                  helperText={activityErrors.product_name ? activityErrors.product_name.message : ''}
                                  error={Boolean(activityErrors.product_name)}
                                  placeholder="Product Name *" name="product_name" id={item.split(',')[0]} className="form-control" onChange={e => onInputProductChange(e, index)} />
                              </div>
                              <div className="col-md-3">
                                <input placeholder="No. Of. License *" type="number" name="license" id={item.split(',')[1]} className="form-control" onChange={e => onInputLicenseChange(e, index)} />
                              </div>
                              <div className="col-md-2" style={{ marginTop: '5px' }}>
                                <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => addNewProductLicense(index)}>Add</Button>
                              </div>
                            </div>


                          </>
                        ))
                      }
                    </div>
                    <br />

                    <div className='row' id="da_budget">
                      {
                        (activity?.da_licenseProductsList && activity?.da_licenseProductsList.length > 0) ?
                          activity?.da_licenseProductsList?.map(item => {
                            return (
                              <>
                                <span>{item[0]} - {item[1]} license</span>
                              </>
                            )
                          })
                          : false
                      }
                    </div>
                    <br />
                    <div className="row">
                      <div className='col-lg-6' style={{ marginTop: '15px' }}>
                        <TextField
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                          }}

                          helperText={activityErrors.da_budget ? activityErrors.da_budget.message : ''}
                          error={Boolean(activityErrors.da_budget)}
                          variant="outlined"
                          type="number" name="da_budget" label="Budget*"
                          className="form-control" value={da_budget} onChange={e => onInputChange(e)} />
                      </div>

                      <div className='col-md-6' style={{ marginTop: '15px' }}>
                        <TextField variant="outlined"
                          select
                          helperText={activityErrors.da_timeline ? activityErrors.da_timeline.message : ''}
                          error={Boolean(activityErrors.da_timeline)}
                          type="text" label="Timeline*" name="da_timeline" className="form-control" value={da_timeline} onChange={e => onInputChange(e)} >
                          {
                            timeLineDateList?.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))
                          }
                        </TextField>
                      </div>
                    </div>



                    <div class="row">
                      <div className="col-lg-12 d-flex flex-column justify-content-center align-items-center">

                        <br />
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <a style={disableApprovalBtn ? { pointerEvents: "none", backgroundColor: '#ffffff' } : null} className="btn btn btn-primary  bottomBarButtons upadte_common_btn" onClick={() => submitForApproval("save")}  ><span className="glyphicon glyphicon-search" aria-hidden="true"></span>{activity.da_approval == null ? "Save Meddicc" : "Save Meddicc"}</a>
                          {
                            activity?.da_score > 75 ?

                              permissions.includes("leadSubmitForApproval") || permissions.includes("submitForApproval") ? <a style={disableApprovalBtn ? { pointerEvents: "none", backgroundColor: '#ffffff' } : null} className="btn btn btn-primary  bottomBarButtons upadte_common_btn" onClick={() => submitForApproval("submit")}  ><span className="glyphicon glyphicon-search" aria-hidden="true"></span>{activity.da_approval == null ? "Submit for Approval" : "Modify & Submit Again"}</a> : false

                              : ""
                          }
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />

                    <div className="row">

                      <div className="col-md-6">
                        {activity.da_sales_approval === "rejected" ? <span style={{ color: 'red' }}>Sales Approval Rejected</span> : ""}

                        {(da_sales_reject_reason !== null) ?
                          <TextField
                            InputLabelProps={{
                              shrink: true,
                            }}
                            multiline rows={2}
                            readOnly={true}
                            variant="outlined"
                            type="text" name="da_sales_reject_reason" label="Sales Reject Reason"
                            className="form-control" value={da_sales_reject_reason} />
                          : null}
                      </div>


                      <div className="col-md-6">
                        {activity.da_technical_approval === "rejected" ? <span style={{ color: 'red' }}>Tech Approval Rejected</span> : ""}

                        {(da_technical_reject_reason !== null) ?

                          <TextField
                            InputLabelProps={{
                              shrink: true,
                            }}
                            multiline rows={2}
                            readOnly={true}
                            variant="outlined"
                            type="text" name="da_technical_reject_reason" label="Tech Reject Reason"
                            className="form-control" value={da_technical_reject_reason} />
                          : null}
                      </div>

                    </div>


                  </div>
                </div>

                <div className={toggleState === 4 ? "content active_content" : "content"}>

                  {permissions.includes('isalesFixNewMeeting') ?
                    <>
                      <div>
                        <br />
                            <div className="row">
                              <div className="col-md-5">


                              <CreatableSelect
                              closeMenuOnSelect={true}
                              className="basic-single"
                              classNamePrefix="select"
                              isSearchable="true"
                              options={companyContactsList}
                              placeholder="Search Contact Person"
                              name="isales_contact_id"
                              onChange={handleSetIsalesContactPerson}
                              styles={colourStyles}
                              autoComplete="none"
                              required
                              formatCreateLabel={() => `Add New Contact`}
                              isValidNewOption={() => true}

                            >
                            </CreatableSelect>
                              </div>
                            </div>
                              <br />
                              <div className="row">
                              <div className="col-md-5">

                                <TextField variant="outlined"
                                  {...activityRegister("assigning_account_manager", {
                                    minLength: {
                                      value: 3,
                                      message: "Account Manager must be at least 1 characters"
                                    }
                                  })}
                                  select
                                  value={activity.assigning_account_manager}
                                  helperText={activityErrors.assigning_account_manager ? activityErrors.assigning_account_manager.message : ''}
                                  error={Boolean(activityErrors.assigning_account_manager)} 
                                  inputRef={account_manager_ref}
                                  type="text" className="form-control" name="assigning_account_manager" label="Select Account Manager" >
                                  {
                                    accountManagersList?.map((option, product) => (
                                      <MenuItem key={option.value} selected={product === option.value} value={option.value}>
                                        {option.label}
                                      </MenuItem>
                                    ))
                                  }

                                </TextField>
                                </div>
                              
                              <div className="col-md-5">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <DateTimePicker
                                    className="form-control"
                                    label="Meeting Date & Time"
                                    inputVariant="outlined"
                                    value={new_meeting_date_time}
                                    onChange={handleMeetingDateTimeChange}
                                    labelFunc={formatWeekSelectLabel}
                                    showTodayButton
                                    disablePast
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />

                                </MuiPickersUtilsProvider>

                              </div>
                              <br />
                            </div>
                            <br />

                            <div className="row">
                              <div className="col-md-10 ">
                                <TextField multiline rows={2}
                                  variant="outlined"
                                  type="text" label="Initial Meeting Information"
                                  inputRef={initial_meeting_info} name="initial_meeting_info"
                                  // onChange={e => onInputChange(e)}
                                  className="form-control" />
                              </div>
                            </div>

                          </div>
                        
                      <div className="col-md-3" style={{ marginTop: '5px' }}>
                        <Button className="btn-sm navbarAddBtn btn_add_common" onClick={() => fixNewMeeting()}>Assign Lead</Button>
                      </div>
                    </>
                    :
                    <>
                      <div className="meeting_border shadow withText" style={{ border: '1px solid #c4c4c4', padding: '1rem', background: '#fff' }}>

                        <label className='labels'>Meeting Details (<span style={{ fontSize: '10px' }}>All fields mandatory to add new meeting</span>)</label>
                        <br />
                        <div class="row">
                          <div className="col-md-6">
                            <TextField variant="outlined" type="text" label="Activity Status"
                              name="da_activity_status" className="form-control"
                              select
                              value={da_activity_status}
                              onChange={e => onInputChange(e)} >
                              {
                                salesActivitiesList?.map((option, da_activity_status) => (
                                  <MenuItem key={option.value} selected={da_activity_status === option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))
                              }
                            </TextField>

                          </div>
                          <div class="col-md-6">
                            <CreatableSelect
                              closeMenuOnSelect={true}
                              className="basic-single"
                              classNamePrefix="select"
                              isSearchable="true"
                              options={economicBuyerList}
                              placeholder="Search Contact Person"
                              inputRef={contact_name}
                              name="contact_name"
                              onChange={handleSetContactName}
                              styles={colourStyles}
                              autoComplete="none"
                              required
                              formatCreateLabel={() => `Add New Contact`}
                              isValidNewOption={() => true}

                            >
                            </CreatableSelect>
                            <br />

                          </div>
                        </div>
                        <br />
                        <div className='row'>
                        <div className='col-md-6'>
                        <Select
                            isMulti
                            placeholder="Select Internal Contact"
                            name="colors"
                            options={accountManagersList}
                            className="basic-multi-select"
                            onChange={(value) => setActivity({...activity, internal_contact_person: value })}
                            classNamePrefix="select">

                          </Select>
              </div>
              <div className='col-md-6'>
                <TextField variant="outlined"
                  {...activityRegister("meeting_type", {
                    required: {
                      value: true,
                      message: "Meeting Type is required"
                    },
                    minLength: {
                      value: 3,
                      message: "Meeting Type must be at least 3 characters"
                    }
                  })}
                  select
                  value={meeting_type}
                  type="text" className="form-control" name="meeting_type" label="Meeting Type" onChange={e => onInputChange(e)} >
                  {
                    ["Online", "Offline"].map((item, leadType) => (
                      <MenuItem key={item} selected={leadType} value={item}>{item}</MenuItem>
                    ))
                  }

                </TextField>
              </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-md-12">
                            <TextField
                              InputProps={{
                                endAdornment: <IconWithTooltip />
                              }}

                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              multiline
                              label="Meeting Summary"
                              className="form-control"
                              name="da_meeting_summary"
                              inputRef={da_meeting_summaryRef}
                              // onChange={e => onInputChange(e)}
                              // value={activity.da_meeting_summary}
                            >

                            </TextField>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <br />
                          <div className="col-md-6">

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DateTimePicker
                                className="form-control"
                                label="Meeting Date"
                                inputVariant="outlined"
                                value={meetingDate}
                                onChange={handleMeetingDateChange}
                                labelFunc={formatWeekSelectLabel}
                                showTodayButton 
                                maxDate={new Date()}
                                minDate={ today.setDate(today.getDate() - 1)}
                                inputRef={da_meeting_date}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div>
                            {(permissions.includes('updateOwnLeads') || permissions.includes("updateAnyLeads"))
                              ? <a disabled={disable} className="btn btn-sm upadte_common_btn" onClick={() => addSalesActivity()} >Add Meeting</a> : false}
                          </div>
                        </div>

                      </div>
                      <LeadMeetingsTable tasksList={meetingsList} />
                    </>
                  }

                </div>

                <div className={toggleState === 5 ? "content active_content" : "content"}>
                  <div className="row">
                    <div class="col-md-6">
                      <TextField variant="outlined"
                        {...activityRegister("da_industry", {
                          minLength: {
                            value: 3,
                            message: "Industry must be at least 3 characters"
                          }
                        })}
                        select
                        required
                        helperText={activityErrors.da_industry ? activityErrors.da_industry.message : ''}
                        error={Boolean(activityErrors.da_industry)}
                        type="text" className="form-control" 
                        value={activity.da_industry || ""} name="da_industry" onChange={e => onInputChange(e)} label="Industry " >

                        {
                          industriesList?.map((option) => (
                            <MenuItem key={option.value} selected={activity.da_industry === option.value} value={option.value}>

                              {option.label}
                            </MenuItem>
                          ))
                        }
                      </TextField>
                    </div>
                    <div class="col-md-6">
                      <TextField variant="outlined"
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><b>₹</b></InputAdornment>
                        }}
                        select
                        required
                        value={activity.da_annual_revenue || ""}
                        helperText={activityErrors.da_annual_revenue ? activityErrors.da_annual_revenue.message : ''}
                        error={Boolean(activityErrors.da_annual_revenue)}

                        type="number" className="form-control" name="da_annual_revenue" label="Annual Revenue" onChange={e => onInputChange(e)} >
                        {
                          annualRevenueRangeList?.map((option) => (
                            <MenuItem key={option.value} selected={activity.da_annual_revenue === option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))
                        }

                      </TextField>
                    </div>
                  </div>
                  <br />
                  <div class="row">
                    <div className="col-md-6">

                    {/* <Select
                      // defaultValue={}
                      isMulti
                      name="products"
                      label="Product"
                      placeholder="Select Products"
                      options={productsList}
                      className="basic-multi-select"
                      onChange={value => onInputChange({target: {name: "products", value:value}})}
                      value={activity.products}
                      classNamePrefix="select">

                    </Select> */}

                      {/* <TextField variant="outlined"
                        {...activityRegister("da_product", {
                          minLength: {
                            value: 3,
                            message: "Product must be at least 3 characters"
                          }
                        })}
                        select
                        required
                        value={activity.da_product || ""}
                        helperText={activityErrors.da_product ? activityErrors.da_product.message : ''}
                        error={Boolean(activityErrors.da_product)}
                        type="text" className="form-control" name="da_product" label="Product" onChange={e => onInputChange(e)} >

                        {
                          productsList?.map((option) => (
                            <MenuItem key={option.value} selected={activity.da_product === option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))
                        }

                      </TextField> */}

                      {/* <Select
                        isMulti
                        placeholder="Select Product"
                        name="colors"
                        options={productsList}
                        className="basic-multi-select"
                        // onChange={(value) => setOpportunity({...opportunity, op_product: value })}
                        classNamePrefix="select" /> */}

                    </div>
                    <div className="col-md-6">
                      <TextField variant="outlined"
                        {...activityRegister("da_company_website", {
                          minLength: {
                            value: 3,
                            message: "Organization Website must be at least 3 characters"
                          }
                        })}
                        required
                        helperText={activityErrors.da_company_website ? activityErrors.da_company_website.message : ''}
                        error={Boolean(activityErrors.da_company_website)}
                        type="text" className="form-control" onChange={e => onInputChange(e)} value={da_company_website} name="da_company_website" label="Organization Website" />
                    </div>
                  </div>
                  <br />
                  <div className="meeting_border shadow" style={{ border: '1px solid #c4c4c4', padding: '1rem', background: '#fff' }}>
                    <div className="row">
                      <div className="col-md-6">
                        <TextField variant="outlined" required multiline rows={2} label="Street" name="da_street" className="form-control" onChange={e => onInputChange(e)} value={da_street} />
                      </div>
                      {/* <div class="col-md-6">
                        <TextField variant="outlined" required type="text" label="City" name="da_city" className="form-control" value={da_city} onChange={e => onInputChange(e)} />
                      </div> */}
                         <div className="col-md-6">
                        <TextField variant="outlined" required type="text" label="Country" name="da_country" className="form-control" value={da_country} onChange={e => onInputChange(e)} />
                      </div>
                    </div>
                    <br />
                    <div className="row">

                      <div class="col-md-3">
                                 <Select
                                    closeMenuOnSelect={true}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable="true"
                                    options={statesList}
                                    placeholder="Search State"
                                    
                                    name="da_state"
                                    value = {selectedState}
                                    // defaultValue={da_state}
                                    // value={statesList[0]}
                                    
                                    // onChange={handleSetPhoneCodes}
                                    onChange={handleStateChange}
                                    
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    // required
                                />
                        {/* <TextField variant="outlined" required type="text" label="State" name="da_state" className="form-control" value={da_state} onChange={e => onInputChange(e)} /> */}
                      </div>
                      <div className="col-md-3">
                                   <Select
                                        closeMenuOnSelect={true}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isSearchable="true"
                                        options={districtsData}
                                        placeholder="Search District"
                                        value={selectedCity}
                                        
                                        name="city"
                                        // defaultValue={}
                                        // value={statesList[0]}
                                        
                                        // onChange={handleSetPhoneCodes}
                                        onChange={handleDistrictChange}
                                        
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        // required
                                    />
                      </div>
                      <div class="col-md-3">
                        <TextField variant="outlined" required type="number" label="Postal Code" name="da_postal_code" className="form-control" value={da_postal_code} onChange={e => onInputChange(e)} />
                      </div>
                   
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div>
                      {(permissions.includes('updateOwnLeads') || permissions.includes("updateAnyLeads"))
                        ? <a disabled={disable} className="btn btn-sm   upadte_common_btn" onClick={() => updateOrganizationDetails()} >Update Organization Details</a> : false}
                    </div>
                  </div>

                  <br />
                </div>
              </div>
            </div>

          </form>
        </Modal.Body>
      </Modal>
     
      {/* {AddcontactModal ? (
        <AddNewContact/>
      ) : null} */}
       {AddcontactModal ? <AddNewContact show={AddcontactModal} keyData="CommonKeyData" otherCompanyName={da_company_name} otherAccountId={viewAccountId} onHide={() => dispatch(ContactActions.AddContactcloseModal())}/> : ""}
       {/* {showAddContactModal && <AddNewContact companyName={da_company_name} accountId={viewAccountId} refreshContactslist={() => refreshContactsList()} returnValue={(contactId) => returnValue(contactId)} show={showAddContactModal} onHide={() => setShowAddContactModal(false)} />} */}
    </div>
  );
});

const mapStateToProps = (state) => ({
  user : state.auth.user,
  salesActivitiesList : state.dashboard.salesActivitiesList,
  viewRowId : state.leads.viewRowId,
  economicBuyerList : state.leads.economicBuyerList,
  drfStatusList : state.leads.drfStatusList,
  accountManagersList : state.leads.accountManagersList,
  productsList : state.dashboard.productsList,
  industryMetricList : state.leads.industryMetricList,
  regionsList : state.dashboard.regionsList,
  viewAccountId : state.leads.viewAccountId,
  showUpdateLeadModal : state.leads.showUpdateLeadModal,
  viewLeadDetails : state.leads.viewLeadDetails,
  viewMeetings : state.leads.viewMeetings,
  viewTasksList : state.leads.viewTasksList,
  industriesList : state.leads.industriesList,
  annualRevenueRangeList : state.leads.annualRevenueRangeList,
  companyContactsList : state.leads.companyContactsList,
  newproductsLists :state.dashboard.newproductsList,
  districtsData: state.leads.districtsData,
});

const mapDispatchToProps = (dispatch) => ({
  getLeadStagesData : () => dispatch(getLeadStagesList()),
  getPersonsListData : (data) => dispatch(getPersonsList(data)),
  getDrfStatusData : () => dispatch(getDrfStatusList()),
  getAccontManagersData : () => dispatch(getAccontManagersList()),
  getIndustriesData : () => dispatch(getIndustriesList()),
  getProductsData : () => dispatch(getProductsList()),
  getAnnualRevenueRangeData : () => dispatch(getAnnualRevenueRangeList()),
  getIndustryMetricsData : () => dispatch(getIndustryMetricsList()),
  getRegionsData : () => dispatch(getRegionsList()),
  meddicUpdateData : (type, url, data)=> dispatch(meddicUpdate(type, url, data)),
  getLeadsData : () => dispatch(getLeadsList()),
  addNewLeadTaskData : (data) => dispatch(addNewLeadTask(data)),
  addNewMeetingData : (data) => dispatch(addNewMeeting(data)),
  getEconomicBuyersData : (data) => dispatch(getEconomicBuyersList(data)),
  isalesFixNewMeetingData : (data) => dispatch(isalesFixNewMeetingDetails(data)),
  // closeModal : (data) => dispatch(leadsActions.updateKey(data)),
  updateOrganizationDetailsData : (data) => dispatch(updateOrganizationDetailsUpdated(data)),
  closeViewLeadModal : (data) => dispatch(leadsActions.updateCloseViewLeadModal(data)),
  ContactopenModal:() => dispatch(ContactActions.AddContactopenModal()),
  newselectedAccount:(data) => dispatch(CommonsliceActions.setcompanyName(data)),
  newaccountId:(data) => dispatch(CommonsliceActions.setRowId(data)),
  getNewProductsData :() => dispatch(getNewProductsList()),
  getDistrictsData : (data) => dispatch(getDistrictsList(data)),
  // openContactModal : (data) => dispatch(ContactActions.AddContactopenModal())
});

export default connect(mapStateToProps,mapDispatchToProps)(ViewLead);