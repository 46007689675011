import React, { useContext, useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AssignDemoModal from '../pageModals/AssignDemoModal';
import moment from 'moment-timezone';
import { getMuiTheme, headerProps } from './commonComponents';
import { MuiThemeProvider } from "@material-ui/core/styles";
import ViewAccount from "../../components/pageModals/ViewAccount";
import AddTechActivityModal from "../pageModals/AddTechActivityModal";
import EmailCampaignModal from '../../components/pageModals/EmailCampaignModal';
import ViewEmailContent from '../../components/pageModals/ViewEmailContent';
import { Button } from 'react-bootstrap';
import { connect, useSelector ,useDispatch} from 'react-redux';
import{getdigitalmarketingdata,EmailcampaignActions,updateColumns,getColumnsList} from "../../store/EmailCampaign"
import {meetingColumnUpdateddata,CommonsliceActions} from "../../store/Common"
import {DemoActions,} from "../../store/Demo"

const EmailCampaignTable = React.memo (({getDigitalMarketingHandledata,getdigitalmarketinglists,emailCampaignUpdateColumnsdata,isFetching,viewContentModal,Email_campaignshowModal,showModal_assign,setdemoListId,showModal_activity,
  addActivityRowid,q,openmodal,rowMetaData,rowId,viewaccountopenmodal,show_demoModal,Rowcontent,updateColumnsList,columnsList,getColumnsList}) => {
  
  const EmailCampaigndata = getdigitalmarketinglists
  
  const dispatch = useDispatch();
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const permissions = user.permissions;
  const [snackBarMessage, setsnackBarMessage] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [demoListId, setDemoListId] = useState(null);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showAddActivityModal, setShowAddActivityModal] = useState(false);
  // const [columnsList, setColumnsList] = useState([]);
  const [showAccountModal, setShowAccountModal] = useState(false);
  // const [rowId, setRowId] = useState("");
  // const [rowContent, setRowContent] = useState("");
  // const [rowMetaData, setRowMetaData] = useState("");
  const [leadTypeActive, setLeadTypeActive] = useState("all");
  const [tableData, setTableData] = useState([]);
  const [addActivityRow, setAddActivityRow] = useState(false);
  const [showCampaignModal, setShowCampaignModal] = useState(false);
  const [viewContent, setViewContent] = useState(false);
  

  // const fetchData = async() => {
  //   dispatch(getdigitalmarketingdata());
  // }
  // useEffect(() => {
  //   fetchData();
    
  // }, [])

  const getEventsList = async () => {
    const res = await axios.get('/digital-marketing/list')
    
  }

  useEffect(() => {
    getDigitalMarketingHandledata()
    getColumnsList();
    setsnackBarMessage("Fetching Records...!");
    setOpen(true);
    getEventsList();
  }, []);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // const getColumnsList = async () => {
  //   const res = await axios.get("/columns-hide-show/list/demos/" + userData._id);
  //   setColumnsList(res.data.columnsList ? res.data.columnsList.columns_list : []);
  // }

  const startDemo = async (id, element) => {
    var updatedData = {
      'status': "started",
      'demo_id': id
    }
    try {
      const res = await axios.post("/demos/update-timings", updatedData);
      handleClick();
      setsnackBarMessage("Successfully started");
      // props.refreshDemoTiming();

    } catch (err) {
    }
  }


  const completedDemo = async (id, element) => {
    var updatedData = {
      'status': "completed",
      'demo_id': id
    }
    try {
      const res = await axios.post("/demos/update-timings", updatedData);
      handleClick();
      setsnackBarMessage("Successfully Completed");
      // props.refreshDemoTiming();

    } catch (err) {
    }

  }

  const assignTechnical = async (event, data, meta) => {

    event.stopPropagation();
    // setShowAssignModal(true);
    openmodal()
    // setDemoListId(meta);
    setdemoListId(meta)
  }

  const onCellClickHandler = (meta) => {
    setViewContent(true);
    // setRowContent(tableData[meta.dataIndex].content)
    Rowcontent(tableData[meta.dataIndex].content)
    if (meta.colIndex === 1) {
      // setShowAccountModal(true);
      // setRowMetaData(meta);
      // setRowId(tableData[meta.dataIndex].account_id?._id)
      viewaccountopenmodal()
      rowMetaData(meta);
      rowId(tableData[meta.dataIndex].account_id?._id)
    }
  }

  useEffect(() => {
    filterDemoType("pending")
    setLeadTypeActive("pending");
    handleClose();
  }, []);


  const updateActivity =  async (event, data, meta)=> {
      event.stopPropagation();
      // setAddActivityRow(meta)
      addActivityRowid(meta)
      q()
      // setShowAddActivityModal(true);
  }
  
  const filterDemoType = async (type) => {

    setLeadTypeActive(type);
    var data = [];
    if (type === "all") {
      data = EmailCampaigndata;
    } else {
      data = EmailCampaigndata?.filter(function (item) {
        if (item.approval_status === type) {
          return item;
        }
      });
    }
    setTableData(data);
  }
  
  const deleteDemoRequest =  async (event, data, meta) => {
    event.stopPropagation();

      var updatedData = {
        'demo_id': meta,
      }

      try {
        var res = await axios.post("/demos/remove", updatedData);
        // props.refreshDemoTiming();
        handleClick();
        if(res.data.status === "success"){
          setsnackBarMessage("Successfully Deleted");
        }else{
          setsnackBarMessage(res.data.msg);
        }
      } catch (err) {
        setOpen(true);
        setsnackBarMessage(err.response.data.msg);
        setTimeout(() => {
          setOpen(false);
        }, 1000);
      }
  }

  const columns = [
    {
      name: "_id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "event_type",
      label: "Subject",
      options: {
        filter: true,
        display: columnsList?.includes('event_type') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "contacts_count",
      label: "No. Of. Contacts",
      options: {
        filter: true,
        display: columnsList?.includes('contacts_count') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? value : ""
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        display: columnsList?.includes('created_at') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? moment(value.replace("T", " ").replace("Z", " ")).tz("Asia/Kolkata").format('D MMM  YYYY') : ""
        ),
        filter: true,
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "template_id",
      label: "Template Id",
      options: {
        filter: true,
        display: columnsList?.includes('template_id') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          (value ? value: "")
        ),
        setCellHeaderProps: headerProps,
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        display: columnsList?.includes('status') ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => (
          (value ? value: "")
        ),
        setCellHeaderProps: headerProps,
      }

    }
  ];

  const LoaderText = () => {
    return   isFetching ? <div className="parent-div">
    <div className="loader"></div>
  </div>:"No Data Found.." 
 }


  const options = {
    textLabels: {
      body: {
        noMatch: LoaderText()
      }
    },
    rowsPerPage: window.innerHeight > 720 ? 20 : 15,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: (window.innerHeight - 130).toString() + 'px',
    tableBodyMaxHeight: (window.innerHeight - 130).toString() + 'px',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectableRows: "none",
    print: false,

    onViewColumnsChange: async (changedColumn, action) => {
      var columns = Object.assign([], columnsList)
      if (action === "add") {
        columns.push(changedColumn);
      }

      if (action === "remove") {
         columns = columns.filter(item => item !== changedColumn);
      }

      const updatedData = {
        user_id: userData._id,
        table_name: "emailCampaign",
        columns_list: columns,
      }
      // Emailcampaignupdatedata(updatedData)
      emailCampaignUpdateColumnsdata(updatedData)
      updateColumnsList(columns)

    },

    
    download: false,
  };
// const array =  ["a","b","c","d","e","f"]

// array.forEach((e) => { console.log("inside for each", e)})
  return (
    <>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={<div style={{ display: "flex", flexDirection: "row" }} >
          <b>Email Campaign's List</b>&nbsp;&nbsp;&nbsp;
            <>
              <Button className="btn-sm navbarAddBtn btn_add_common"  onClick={() => dispatch(EmailcampaignActions.Email_campaign_openModal())}>Add</Button>
              {Email_campaignshowModal ? <EmailCampaignModal/> : ""}
            </>
        </div>}
          data={EmailCampaigndata}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>

      {showModal_assign ? <AssignDemoModal /> : ""}
      {showModal_activity ? <AddTechActivityModal/> : ""}
      {show_demoModal ? <ViewAccount /> : ""}
      {viewContentModal ? <ViewEmailContent  /> : ""}
   
    </>
  )
});
const mapStateToProps = (state) => ({
    user : state.auth.user,
    getdigitalmarketinglists : state.Emailcampaignlist.getdigitalmarketinglists,
    isFetching : state.Emailcampaignlist.isFetching,
    viewContentModal:state.Emailcampaignlist.showViewEmailContent, 
    Email_campaignshowModal:state.Emailcampaignlist.Email_campaignshowModal,
     showModal_assign:state.Demolist.showModal_assign,
     showModal_activity: state.Commonlist.showModal_activity,
     show_demoModal : state.Commonlist.demo_showModal,
     columnsList:state.Emailcampaignlist.columnsList
});
const mapDispatchToProps = (dispatch) => ({

    getDigitalMarketingHandledata:(data) => dispatch(getdigitalmarketingdata(data)),
    emailCampaignUpdateColumnsdata:(data) => dispatch(updateColumns(data)),
    setdemoListId:(data) => dispatch(DemoActions.setDemoRowId(data)),
    addActivityRowid:(data) => dispatch(CommonsliceActions.setaddActivityRowId(data)),
    q:(data) => dispatch(CommonsliceActions.activity_openModal(data)),
    openmodal:(data) => dispatch(DemoActions.assign_openModal(data)),
    rowMetaData:(data) => dispatch(CommonsliceActions.setrowMetaData(data)),
    rowId:(data) => dispatch(CommonsliceActions.setRowId(data)),
    viewaccountopenmodal:(data) => dispatch(CommonsliceActions.demo_openModal(data)),
    Rowcontent:(data) => dispatch(EmailcampaignActions.setRowcontent(data)),
    updateColumnsList:(data) => dispatch(EmailcampaignActions.updateColumns(data)),
    getColumnsList:(data) => dispatch(getColumnsList(data)),
});
export default connect(mapStateToProps,mapDispatchToProps) (EmailCampaignTable)

