import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import {benchmarksassign,BenchmarkActions,getTechieList,getProductsList} from "../../store/Benchmark"
import { connect, useDispatch, useSelector } from 'react-redux';

  export const AssignBenchmarkModal = React.memo(({benchmarkdata,openshowModal,closemodal,benchmarkids,getTechieList,getProductsList,techieList}) => {
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  // const [techieList, setTechieList] = useState([]);
  // const [productsList, setProductsList] = useState([]);
  const [disable, setDisable] = useState(false);
  const [demoData, setDemoData] = useState({
    techie_names: '',
    product_list: '',
    comment: ''
  })
  const { register: rejectRegister, handleSubmit: assignHandleSubmit, setError: rejectSetError, formState: { errors: rejectErrors } } = useForm();
  useEffect(() => {
    getTechieList();
    getProductsList();
  }, [])
  
  const assignSubmit = async (data) => {
    setDisable(true);
    var updatedData = {
      'approval_status': "approved",
      'benchmark_id': benchmarkids,
      'assigned_to': demoData.techie_names,
      'remarks': demoData.comment
    }
    benchmarkdata(updatedData)
    closemodal();
  }
  const onInputChange = (e) => {
    setDemoData({ ...demoData, [e.target.name]: e.target.value }, []);
  };

  return (
    <>
      <Modal
      show={() => openshowModal()}
      onHide={() => closemodal()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
          <h6> {(userData.user_role_id.role_name === "user") ? "Assign Benchmark" : "Assign Benchmark"} </h6> 
          </Modal.Title>
          <a onClick={() => closemodal()} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={assignHandleSubmit(assignSubmit)}>
            <div className="row headerBand">
              <label>Assign Benchmark</label>
            </div>
            <br />
            <div class="row">
              <div class="col-md-6">

                <TextField variant="outlined"
                  {...rejectRegister("techie_names", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={demoData.techie_names}
                  select
                  helperText={rejectErrors.techie_names ? rejectErrors.techie_names.message : ''}
                  error={Boolean(rejectErrors.techie_names)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="techie_names" label="Techie Name"
                >
                  {techieList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                  }
                </TextField>

              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <TextField variant="outlined"
                  multiline row={2}
                  {...rejectRegister("comment", {
                    required: {
                      value: true,
                      message: "comment is required"
                    },
                    minLength: {
                      value: 5,
                      message: "comment must be at least 5 characters"
                    }

                  })}
                  helperText={rejectErrors.comment ? rejectErrors.comment.message : ''}
                  error={Boolean(rejectErrors.comment)}
                  type="text" className="form-control" value={demoData.comment} name="comment" label="Remarks" onChange={e => onInputChange(e)} />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col col-12">
                <button disabled={disable} type="submit" style={{ background: '#212060', color: '#FFFFFF', float: "left" }} className="btn btn-sm  bottomBarButtons upadte_common_btn">Send</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
})
const mapStateToProps = (state) => ({
    user : state.auth.user,
    benchmarkids : state.Benchmarklist.benchmarkListId,
    techieList:state.Benchmarklist.techieList,
    productList:state.Benchmarklist.productList
})

const mapDispatchToProps = (dispatch) => ({
  benchmarkdata :(data) => dispatch(benchmarksassign(data)),
  openshowModal:(data) => dispatch(BenchmarkActions.assign_openModal(data)),
  closemodal:(data) => dispatch(BenchmarkActions.assign_closeModal(data)),
  getTechieList :(data) => dispatch(getTechieList()),
  getProductsList :(data) => dispatch(getProductsList()),
  });

  export default connect(mapStateToProps,mapDispatchToProps) (AssignBenchmarkModal)
