import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import AddNewContact from "../pageModals/AddNewContact";
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { useDispatch, useSelector, connect } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
// import {createtimesheet } from "../../store/Timesheet"

import { DateTimePicker, MuiPickersUtilsProvider, DatePicker, TimePicker } from "@material-ui/pickers";

import {addNewDailyTask, updateShowModal,getTasksList} from "../../store/timesheet";

const CreateTimesheetModal = React.memo((props) => {
  const {getTasksData,addNewDailyTaskData,showCreateTimeSheetModal,updateShowModalData,ListTimesheetData,status} =props;
// console.log("timelist data are",ListTimesheetData,status)
// const CreateTimesheetModal = (props) => {
//   const dispatch = useDispatch();

    var today = new Date();
      var twodays = moment().add(2, 'days');
    const end_time = useRef();
    const start_time = useRef();
    const task_date = useRef();  
  const [open, setOpen] = React.useState(false);
  const [companiesList, setCompaniesList] = useState();
  const [selectedAccount, setSelecteAccount] = useState("");
  const [companyName, setCompanyName] = useState(false);
  const [accountId, setAccountId] = useState(false);
  const [productList, setProductList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [teamMembersList,setTeamMembersList]= useState([]);
  const [disable, setDisable] = React.useState(false);
  const [newDateTime, setnewDateTime] = React.useState(moment(twodays).format('LLLL'));
  const [modalHide, setModalHide] = useState("showopportunitiesmodal");
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [existingCompany, setExistingCompany] = useState(false);
  //const [userRole, setUserRole] = useState(null);

console.log("account id is", accountId)

const formatWeekSelectLabel = (date, invalidLabel) => {
  return `${moment(date).format("MMM Do YYYY")}`
};
const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
          ...styles,
          backgroundColor: isFocused ? "#000" : '#000080',
          color: "white",
          opacity: '1.5'
      };
  }
};

  const [timesheetData, setTimesheetData] = useState({
    title:"",
    task_date : moment(new Date()).format('YYYY-MM-DD'),
    task_start_time: new Date(),
    task_end_time: new Date(),
    status:"",
    assignedto:"",
    description: "",
    company_name: "Kaizenat",
  });
  const [activity, setActivity] = useState({
    company_name: "",
});



var threedays = moment().add(3, 'days');
  const { register: timesheetRegister, handleSubmit: timesheetHandleSubmit, setError: timesheetSetError, reset: reset, formState: { errors: timesheetErrors } } = useForm();
  const {user} = useSelector(state => state.auth)
  const userData = user.user;
  const userDataId= userData.user_role_id._id
  const userRoleData = user.user.user_role_id.role_name;
  const permissions = user.permissions;

  const ProductList = async () => {
    const res = await axios.get("/products/search")
    setProductList(res.data.productsList)
  }
  const getTimesheetStatus = () => {
    setStatusList([{ label: "Open", value: 'Open' }, { label: "Closed", value: 'Closed' }])
  }
  
  const getAssignedMembersList = async () => {
        
    const res = await axios.get("/user/team-members-list");
    setTeamMembersList(res.data.list);
}

  useEffect(() => {
    ProductList();
    getTimesheetStatus();
    getAssignedMembersList();
  }, [])


  const onInputChange = (e) => {
    setTimesheetData({ ...timesheetData, [e.target.name]: e.target.value }, []);
  };


const handleSetCompanyName = (value) => {
  // console.log("account id is", value)
  setSelecteAccount(value.value)
  if (value.__isNew__ === true) {
  } else {
    setAccountId(value.value);
    // getContactList(value.value);
  }
}

const getCompaniesList = async (search) => {
  setCompaniesList([]);
  const res = await axios.get("/accounts/search-company/" + search.target.value);
  setCompaniesList(res?.data?.AccountsList);
}

useEffect(() => {
  console.log("activity", activity)
}, [activity])
const onNewOptionClick = (option) => {
}


  const addNewTechieMeeting = async (e) => {

    setDisable(true);
    setTimeout(() => { }, 500);

//     var updatedData = {

    const updatedData = {
     
        task_status: timesheetData.status,
        task_title:timesheetData.title,
        start_date_time:timesheetData.task_date + " " + moment(timesheetData.task_start_time).format("HH:mm:ss"),
        end_date_time: timesheetData.task_date + " " + moment(timesheetData.task_end_time).format("HH:mm:ss"),
        assigned_by:userDataId,
        assigned_to: timesheetData.assignedto,
        task_description:timesheetData.description,
        company_name: "",

        // account_id:accountId ? accountId : ""

        ...(accountId !== false && { account_id: accountId }),

    }
//      if(accountId){
//       updatedData.account_id = accountId ? accountId : ""
//      }
const ListData = {
  task_status :  status,
  from_date : ListTimesheetData.from_date,
  to_date : ListTimesheetData.to_date,
  filter_user_id : ListTimesheetData.assignees,
  limit:"",
  page_number:""
}

    try {

      addNewDailyTaskData(updatedData)
      getTasksData(ListData)
      reset({
        keepErrors: false,
        keepDirty: true,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      })




    //dispatch(createtimesheet(updatedData));
    // reset({
    //   keepErrors: false,
    //   keepDirty: true,
    //   keepIsSubmitted: false,
    //   keepTouched: false,
    //   keepIsValid: false,
    //   keepSubmitCount: false,
    // })
    
  }catch(err){
    console.log("error", err)
  }
}

  useEffect(() => {
    setCompaniesList(timesheetData.account_id);
  }, [])

  const handleMeetingDateTimeChange = (date) => {
    var newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    setnewDateTime(newDate);
    timesheetData.new_date_time = newDate;
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const refreshContactsList = async () => {
  }

  const returnValue = async (contactId) => {
    timesheetData.contact_id = contactId;
  }

 

  return (
    <>
      <Modal
        show={showCreateTimeSheetModal}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        className={modalHide}
      >

        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
           <h6> {"Create Clock Time"}</h6>
          </Modal.Title>
          <a onClick={() => updateShowModalData(false)} className="btn btn-sm btn-white btn-danger bottomBarButtons">Cancel</a>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={timesheetHandleSubmit(addNewTechieMeeting)}>
            <div class="row">
              <div class="col-md-6">
              <TextField variant="outlined"
                  {...timesheetRegister("title", {
                    required: {
                      value: true,
                      message: "title is required"
                    },
                    minLength: {
                      value: 2,
                      message: "title must be at least 5 characters"
                    }

                  })}
                  helperText={timesheetErrors.title ? timesheetErrors.title.message : ''}
                  error={Boolean(timesheetErrors.title)}
                  required
                  type="text" className="form-control" value={timesheetData.title} name="title" label="Title" onChange={e => onInputChange(e)} />
              </div>

              <div className="col-md-6">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            className="form-control"
                                            label="Task Date"
                                            inputVariant="outlined"
                                            value={timesheetData.task_date}
                                            onChange={(date) => setTimesheetData({...timesheetData, task_date: moment(date).format('YYYY-MM-DD') })}
                                            showTodayButton
                                            labelFunc={formatWeekSelectLabel}
                                            minDate={ permissions.includes('EnableTimeSheetBackDate') ? true :  today.setDate(today.getDate() - 2) }
                                            inputRef={task_date}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                       </MuiPickersUtilsProvider>
              </div>
            </div>
            <br />
            
            <div className="row">

              <div class="col-md-6">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <TimePicker
                                            className="form-control"
                                            label="Start Time"
                                            inputVariant="outlined"
                                            value={timesheetData.task_start_time}
                                            onChange={(date) => setTimesheetData({...timesheetData, task_start_time: date })}
                                            showTodayButton
                                            inputRef={start_time}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                       </MuiPickersUtilsProvider>
              </div>
              <div className="col-md-6">
                       <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <TimePicker
                                            className="form-control"
                                            label="End time"
                                            inputVariant="outlined"
                                            value={timesheetData.task_end_time}
                                            onChange={(time) => setTimesheetData({...timesheetData, task_end_time: time })}
                                            showTodayButton
                                            inputRef={end_time}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                       </MuiPickersUtilsProvider>
                </div>
            </div>
            <br />
            <div className="row">
             <div className='col-md-6'>
             <TextField variant="outlined"
                  {...timesheetRegister("status", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={timesheetData.status}
                  select
                  helperText={timesheetErrors.status ? timesheetErrors.status.message : ''}
                  error={Boolean(timesheetErrors.status)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="status" label="Status"
                >
                  {statusList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                  }
                </TextField>
             </div>
             {userRoleData === 'techie' && (
             <div className="col-md-6">
              {/* <CreatableSelect
                closeMenuOnSelect={true}
                className="basic-single"
                classNamePrefix="select"
                isSearchable="true"
                options={companiesList}
                placeholder="Search Organization Name"
                styles={colourStyles}
                onChange={handleSetCompanyName}
                onKeyDown={getCompaniesList}
                onNewOptionClick={onNewOptionClick}
              /> */}
                <Select
                  closeMenuOnSelect={true}
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable="true"
                  options={companiesList}
                  placeholder="Search Organization Name"
                  styles={colourStyles}
                  onChange={handleSetCompanyName}
                  onKeyDown={getCompaniesList}
                ></Select>
              <br />
              <br />
            </div>
             )}
             {permissions.includes('AssignTeamMemberTask')  && 
              <div class="col-md-6">
              <TextField variant="outlined"
                  {...timesheetRegister("assignedto", {
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={timesheetData.assignedto}
                  select
                  helperText={timesheetErrors.assignedto ? timesheetErrors.assignedto.message : ''}
                  error={Boolean(timesheetErrors.assignedto)}
                  onChange={e => onInputChange(e)}
                  required
                  type="text" className="form-control" name="assignedto" label="Assign to"
                >
                  {teamMembersList?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                  }
                </TextField>
              </div>
              }
              <br/>
              <br />
              <br/>
             
              <div className='row'>
                <div className='col-md-6'>
                <TextField variant="outlined"
                  multiline row={2}
                  {...timesheetRegister("description", {
                    required: {
                      value: true,
                      message: "Description is required"
                    },
                  })}
                  helperText={timesheetErrors.description ? timesheetErrors.description.message : ''}
                  error={Boolean(timesheetErrors.description)}
                  required
                  type="text" className="form-control" value={timesheetData.description} name="description" label="Description" onChange={e => onInputChange(e)} />
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div>
                <button disabled={disable} className="btn btn-sm btn-primary  bottomBarButtons upadte_common_btn" id="submit">Submit</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {showAddContactModal ? <AddNewContact show={showAddContactModal} companyName={selectedAccount} accountId={accountId} refreshContactslist={() => refreshContactsList()} returnValue={(contactId) => returnValue(contactId)} onHide={() => setShowAddContactModal(false)} /> : ""}
    

    </>
  )

});
// export default CreateTimesheetModal;

const mapStateToProps = (state) => ({
  tasksList : state.timesheet.tasksList,
  showCreateTimeSheetModal : state.timesheet.showCreateTimeSheetModal,
});

const mapDispatchToProps = (dispatch) => ({
  addNewDailyTaskData : (data) => dispatch(addNewDailyTask(data)),
  updateShowModalData : (data) => dispatch(updateShowModal(data)),
  getTasksData : (data) => dispatch(getTasksList(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTimesheetModal);
